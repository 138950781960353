import { useEffect, useState } from 'react'
import DatePickerInput from './DatePickerInput'
import { useUpdateDispatch } from 'src/app/hooks/mutations'
import CustomSelectInput from './CustomSelectInput'
import { useGetCurrentAgencyContext } from 'src/app/hooks/queries'
import { useToast } from 'src/components/toasts'
import Button from '../Shared/Elements/Button'
import ModalBody from '../Shared/Elements/ModalBody'
import ModalFooter from '../Shared/Elements/ModalFooter'
import { cleanGraphQLError } from './FormError'

const DispatchEditForm = ({
  dispatchTime,
  onFinish,
  dispatchId,
  fieldKey,
  validator
}) => {
  const { add } = useToast()
  const [newDispatchTime, setNewDispatchTime] = useState(dispatchTime)
  const { updateOneDispatch, loading } = useUpdateDispatch({
    refetchQueries: ['GetSubmission']
  })

  async function onSubmit(time) {
    if (validator) {
      const errorMessage = await validator(time)

      if (errorMessage) {
        add({ content: errorMessage, color: 'danger' })
        return
      }
    }

    const res = await updateOneDispatch({
      variables: {
        data: {
          [fieldKey]: time || null
        },
        where: {
          id: dispatchId
        }
      }
    })

    if (res.errors) {
      add({
        content: cleanGraphQLError(res.errors),
        color: 'danger'
      })
      return
    }
    add({ content: 'Dispatch updated', color: 'success' })
  }

  return (
    <div>
      <ModalBody>
        <DatePickerInput
          onChange={date => {
            setNewDispatchTime(date)
          }}
          showTimeSelect={true}
          name="dispatchTime"
          id="dispatchTime"
          value={newDispatchTime}
          placeholder="Time of incident"
          timeIntervals={1}
        />
      </ModalBody>
      <ModalFooter onClose={onFinish}>
        <Button
          variant="primary"
          onClick={async () => {
            await onSubmit(newDispatchTime)
            onFinish()
          }}
        >
          {loading ? 'Saving...' : 'Save'}
        </Button>
      </ModalFooter>
    </div>
  )
}

export const DispatchEditPersonnelForm = ({
  personnel,
  onFinish,
  dispatchId
}) => {
  const { add } = useToast()
  const { users } = useGetCurrentAgencyContext()
  const [newPersonnel, setPersonnel] = useState()
  const { updateOneDispatch, loading } = useUpdateDispatch()

  useEffect(() => {
    personnel &&
      setPersonnel({
        value: personnel.id,
        label: personnel.name
      })
  }, [personnel])

  async function onSubmit(newPersonnel) {
    const res = await updateOneDispatch({
      variables: {
        data: {
          personnel: {
            ...(!newPersonnel?.value
              ? {
                  disconnect: true
                }
              : {
                  connect: {
                    id: newPersonnel.value
                  }
                })
          }
        },
        where: {
          id: dispatchId
        }
      }
    })

    if (res.errors) {
      add({
        content: 'Error updating dispatch. Please try again.',
        color: 'danger'
      })
    }
  }

  const userOpts = users.map(u => ({
    label: u.name,
    value: u.id
  }))

  return (
    <div>
      <CustomSelectInput
        options={userOpts}
        onChange={setPersonnel}
        defaultValue={newPersonnel}
        value={newPersonnel}
        name={'responderDispatch'}
        label={`Responder`}
        isClearable
      />

      <ModalFooter onClose={onFinish}>
        <Button
          variant="primary"
          onClick={async () => {
            await onSubmit(newPersonnel)
            onFinish()
          }}
        >
          {loading ? 'Saving...' : 'Save'}
        </Button>
      </ModalFooter>
    </div>
  )
}

export const DispatchEditUnitForm = ({
  unit,
  onFinish,
  dispatchId
}) => {
  const { positions } = useGetCurrentAgencyContext()
  const [newUnit, setUnit] = useState()
  const { updateOneDispatch, loading } = useUpdateDispatch({
    refetchQueries: ['GetSubmission']
  })

  useEffect(() => {
    unit &&
      setUnit({
        value: unit.id,
        label: unit.name
      })
  }, [unit])

  async function onSubmit(newUnit) {
    await updateOneDispatch({
      variables: {
        data: {
          unit: {
            connect: {
              id: newUnit.value
            }
          }
        },
        where: {
          id: dispatchId
        }
      }
    })
  }

  const unitOpts = positions.map(u => ({
    label: u.name,
    value: u.id
  }))

  return (
    <div>
      <CustomSelectInput
        options={unitOpts}
        onChange={setUnit}
        defaultValue={newUnit}
        value={newUnit}
        name={'responderDispatch'}
        label={`Responder`}
      />
      <ModalFooter onClose={onFinish}>
        <Button
          variant="primary"
          onClick={async () => {
            await onSubmit(newUnit)
            onFinish()
          }}
        >
          {loading ? 'Saving...' : 'Save'}
        </Button>
      </ModalFooter>
    </div>
  )
}

export default DispatchEditForm
