import { useContext, useState } from 'react'
import { useMutation } from '@apollo/client'
import { gql } from '@apollo/client'

import { cloneDeep } from '@apollo/client/utilities'
import ModalButton from '../Shared/ModalButton'

import {
  ARCHIVE_DISPATCH,
  COMPLETE_DISPATCH
} from 'src/app/hooks/mutations'
import { Flex, Box } from 'theme-ui'
import Spinner from 'src/images/icons/Spinner'

import { DISPATCH_SCREEN_DATA } from 'src/app/hooks/queries'
import { useToast } from 'src/components/toasts'
import { cleanGraphQLError } from '../Forms/FormError'
import Icon from '../Shared/Icon'
import Button from '../Shared/Elements/Button'
import { getClosePlusLocationState } from './ActiveDispatchSubmissionsTableActions'
import { SubmissionModalContext } from '../Shared/SubmissionModalLayout'
import { useLocation } from 'react-router'

const SET_DISPATCH_ONSCENE = gql`
  mutation SetDispatchOnScene($id: ID, $time: DateTime) {
    setDispatchOnScene(id: $id, time: $time) {
      id
      onScene
      unit {
        id
        positionStatus
        dispatchStatus
      }
      submissions {
        id
      }
    }
  }
`

const SET_DISPATCH_ENROUTE = gql`
  mutation SetDispatchenRoute($id: ID, $time: DateTime) {
    setDispatchEnRoute(id: $id, time: $time) {
      id
      enRoute
      unit {
        id
        positionStatus
        dispatchStatus
      }
      submissions {
        id
      }
    }
  }
`

const DispatchTableActions = ({
  dispatchId,
  onScene,
  enRoute,
  submissionId,
  isFinalActiveDispatchOnSubmission,
  onFinish,
  hasType,
  hasUnit
}) => {
  const modalContext = useContext(SubmissionModalContext)
  const showSubmissionModal = modalContext?.showSubmissionModal
  const { add } = useToast()
  const [completingLoading, setCompletingLoading] = useState(false)
  const [completeDispatch] = useMutation(COMPLETE_DISPATCH, {
    variables: {
      id: dispatchId
    },
    update: (store, { data: { completeDispatch } }) => {
      // Read the data from our cache for this query.

      const data = cloneDeep(
        store.readQuery({
          query: DISPATCH_SCREEN_DATA
        })
      )

      if (completeDispatch.completedAt) {
        const dId = completeDispatch
          ? completeDispatch.id
          : dispatchId

        data.myAgenciesSubmissionsWithActiveDispatches = data.myAgenciesSubmissionsWithActiveDispatches.filter(
          d => {
            const completedDispatchIsFinal =
              d.dispatches?.filter(
                d => d.completedAt === null && d.id === dId
              )?.length === 1

            return completedDispatchIsFinal ? false : true
          }
        )

        data.myAgenciesSubmissionsWithActiveDispatches = data.myAgenciesSubmissionsWithActiveDispatches.map(
          s => ({
            ...s,
            dispatches: s?.dispatches?.filter(d => d.id !== dId) || []
          })
        )
        // Write our data back to the cache.
        store.writeQuery({
          query: DISPATCH_SCREEN_DATA,
          data: { ...data }
        })
      }
    }
  })

  const location = useLocation()

  const [
    setDispatchOnScene,
    { loading: onSceneLoading }
  ] = useMutation(SET_DISPATCH_ONSCENE, {
    variables: {
      id: dispatchId
    },
    refetchQueries: ['GetDispatchData']
  })

  const [
    setDispatchEnRoute,
    { loading: enRouteLoading }
  ] = useMutation(SET_DISPATCH_ENROUTE, {
    variables: {
      id: dispatchId
    },
    refetchQueries: ['GetDispatchData']
  })

  async function closeDispatch(index) {
    setCompletingLoading(index)
    await completeDispatch()
    setCompletingLoading(false)
    onFinish && onFinish()
  }

  return (
    <Flex
      sx={{
        justifyContent: 'center',
        flexWrap: 'nowrap',
        flexDirection: ['column', null, 'row'],
        gap: '4px'
      }}
    >
      {hasUnit && !enRoute && (
        <Box
          sx={{
            flex: '1 1 33.33%',
            minWidth: '55px'
          }}
        >
          <Button
            sx={{
              width: '100%',

              borderColor: 'orange'
            }}
            variant="orange"
            // block
            disabled={enRouteLoading}
            onClick={async () => {
              await setDispatchEnRoute()
              onFinish && onFinish()
            }}
            title="En Route"
          >
            {enRouteLoading ? (
              <Spinner height={15} white />
            ) : (
              <>{'ER'}</>
            )}
          </Button>
        </Box>
      )}
      {hasUnit && !onScene && (
        <Box
          sx={{
            flex: '1 1 33.33%',
            minWidth: '55px'
          }}
        >
          <Button
            sx={{ width: '100%' }}
            disabled={onSceneLoading}
            onClick={async () => {
              await setDispatchOnScene({
                variables: {
                  id: dispatchId,
                  time: null
                }
              })
              onFinish && onFinish()
            }}
            variant="success"
            title="On Scene"
          >
            {onSceneLoading ? <Spinner height={15} white /> : <>OS</>}
          </Button>
        </Box>
      )}
      {isFinalActiveDispatchOnSubmission && (
        <Box
          sx={{
            flex: '1 1 33.33%',
            minWidth: '55px'
          }}
        >
          <Button
            sx={{ width: '100%' }}
            disabled={completingLoading === 0}
            onClick={async () => {
              try {
                await closeDispatch(0)
                showSubmissionModal({
                  id: submissionId,
                  ...getClosePlusLocationState({
                    submissionId,
                    location,
                    hasUnit,
                    onFinish: () => showSubmissionModal(null),
                    useTodaysPosition: true
                  })
                })
              } catch (e) {
                setCompletingLoading(false)
                add({
                  color: 'danger',
                  content: cleanGraphQLError(e)
                })
              }
            }}
            variant="danger"
            title="Close +"
          >
            {completingLoading === 0 ? <Spinner white /> : 'C +'}
          </Button>
        </Box>
      )}
      <Box
        sx={{
          flex: '1 1 33.33%',
          minWidth: '55px'
        }}
      >
        {hasType || !isFinalActiveDispatchOnSubmission ? (
          <Button
            onClick={async () => {
              try {
                await closeDispatch(1)
              } catch (e) {
                setCompletingLoading(false)
                add({
                  color: 'danger',
                  content: cleanGraphQLError(e)
                })
              }
            }}
            disabled={completingLoading === 1}
            title="Quick Close"
            block
            variant="danger"
          >
            {completingLoading === 1 ? (
              <Spinner height={15} white />
            ) : (
              'C'
            )}
          </Button>
        ) : (
          <ModalButton
            buttonLabel={
              completingLoading === 1 ? (
                <Spinner height={15} white />
              ) : (
                'C'
              )
            }
            buttonProps={{
              title: 'Quick Close',
              block: true,
              variant: 'danger'
            }}
            modalHeader={
              'This call is missing an incident type. Are you sure you want to continue with it unknown?'
            }
            renderModalActions={toggle => (
              <Button
                variant="danger"
                onClick={() => {
                  closeDispatch(1)
                  toggle()
                }}
                disabled={completingLoading === 1}
              >
                {completingLoading === 1 ? (
                  <Spinner height={15} white />
                ) : (
                  'Yes, Close the Call'
                )}
              </Button>
            )}
          />
        )}
      </Box>
      <Box
        sx={{
          flex: '1 1 75px',
          minWidth: '55px'
        }}
      >
        <ArchiveDispatchButton
          dispatchId={dispatchId}
          onFinish={onFinish}
        />
      </Box>
    </Flex>
  )
}

export const ArchiveDispatchButton = ({
  dispatchId,
  update,
  onFinish
}) => {
  const [
    cancelDispatch,
    { loading: cancelDispatchLoading }
  ] = useMutation(ARCHIVE_DISPATCH, {
    variables: {
      id: dispatchId
    },
    //Update cache...
    update: update || handleUpdateArchivedDispatch
  })

  function handleUpdateArchivedDispatch(
    store,
    { data: { archiveOneDispatch } }
  ) {
    // Read the data from our cache for this query.
    const data = cloneDeep(
      store.readQuery({
        query: DISPATCH_SCREEN_DATA
      })
    )
    if (data.myAgenciesSubmissionsWithActiveDispatches) {
      const dId = archiveOneDispatch
        ? archiveOneDispatch.id
        : dispatchId

      data.myAgenciesSubmissionsWithActiveDispatches = data.myAgenciesSubmissionsWithActiveDispatches.filter(
        d => {
          const archivedDispatchIsFinal =
            d.dispatches?.filter(
              d => d.completedAt === null && d.id === dId
            )?.length === 1

          return archivedDispatchIsFinal ? false : true
        }
      )

      data.myAgenciesSubmissionsWithActiveDispatches = data.myAgenciesSubmissionsWithActiveDispatches.map(
        s => ({
          ...s,
          dispatches: s?.dispatches?.filter(d => d.id !== dId) || []
        })
      )

      // Write our data back to the cache.
      store.writeQuery({
        query: DISPATCH_SCREEN_DATA,
        data: { ...data }
      })
    }
  }

  return (
    <ModalButton
      buttonProps={{
        variant: 'secondary',
        sx: {
          width: '100%',
          flex: 1,
          height: '33.5px'
        },
        title: 'Cancel + Archive'
      }}
      buttonLabel={
        <Icon icon="close" width={15} height={15} color="white" />
      }
      localModalPosition="left"
      modalHeader="Are you sure you want to archive this resource? This does NOT archive the incident."
      renderModalActions={toggle => (
        <Button
          onClick={async () => {
            await cancelDispatch()
            toggle()
            onFinish && onFinish()
          }}
          variant="danger"
          disabled={cancelDispatchLoading}
        >
          {cancelDispatchLoading ? (
            <Spinner height={15} white />
          ) : (
            'Yes, cancel resource'
          )}
        </Button>
      )}
    />
  )
}

export default DispatchTableActions
