const Dispatch = ({ height, width, color }) => {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Dispatching</title>
      <g fill={color || '#111111'}>
        <path d="M15.758,23.805l5.9-2.146,2.146-5.9L11.21,9.88a1,1,0,0,0-1.33,1.33Z" />
        <path d="M48.242,23.805l5.877-12.6a1,1,0,0,0-1.328-1.328L40.2,15.759l2.146,5.9Z" />
        <path d="M48.242,40.2l-5.9,2.146-2.146,5.9,12.6,5.878a1,1,0,0,0,1.33-1.33Z" />
        <path d="M11.209,54.119l12.6-5.878-2.146-5.9-5.9-2.146-5.877,12.6a1,1,0,0,0,1.328,1.328Z" />
        <path
          d="M62.342,31.06,40.78,23.22,32.939,1.658a1,1,0,0,0-1.878,0L23.22,23.22,1.658,31.06a1,1,0,0,0,0,1.88L23.22,40.78l7.841,21.562a1,1,0,0,0,1.878,0L40.78,40.78l21.562-7.84a1,1,0,0,0,0-1.88ZM32,37a5,5,0,1,1,5-5A5,5,0,0,1,32,37Z"
          fill={color || '#111111'}
        />
      </g>
    </svg>
  )
}

export default Dispatch
