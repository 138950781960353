function Dots(props) {
  const title = props.title || 'menu 5'

  return (
    <svg
      height="12"
      width="12"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill={props.color || '#303035'}>
        <circle cx="6" cy="6" r="1.5" />
        <circle
          cx="10.5"
          cy="6"
          fill={props.color || '#303035'}
          r="1.5"
        />
        <circle
          cx="1.5"
          cy="6"
          fill={props.color || '#303035'}
          r="1.5"
        />
      </g>
    </svg>
  )
}

export default Dots
