/** @jsxImportSource theme-ui */

import { Box, Flex, Grid } from 'theme-ui'

import { Table } from '../../Shared/Table'
import { RichTextRender } from '../../Shared/RichTextRender'
import ModalButton from '../../Shared/ModalButton'
import isEmpty from 'lodash/isEmpty'

import Spinner from 'src/images/icons/Spinner'
import { GET_FORM } from 'src/app/hooks/queries'
import { useQuery } from '@apollo/client'
import Elipses from '../../Shared/Elipses'
import { MapComponent } from '../../Forms/GoogleMapInput'
import SurflineWeatherIcons from '../../Shared/SurflineWeatherIcons'
import { Overline } from '../../Shared'
import ViewFileModal from '../Documents/ViewFileModal'
import { prettyDate } from 'src/utils'

const FormResponseTableView = ({
  formId,
  formResponse,
  spinnerProps
}) => {
  const { data } = useQuery(GET_FORM, {
    variables: {
      where: { id: formId },
      fieldsWhere: {
        archived: {
          equals: false
        }
      },
      fieldItemsWhere: {
        archived: {
          equals: false
        }
      }
    }
  })

  const form = data?.form

  if (!form)
    return (
      <Spinner containerHeight={'200px'} centered {...spinnerProps} />
    )

  return (
    <ResponseTableRenderer form={form} formResponse={formResponse} />
  )
}

export const ResponseTableRenderer = ({ formResponse, form }) => {
  return (
    <>
      <Flex sx={{ flexDirection: 'column', mt: 20 }}>
        <Table
          columns={null}
          rows={form.fields?.map((field, k) => {
            const fieldResponse = formResponse.fields.find(
              f => f.formField.id === field.id
            )

            return {
              values: [
                <Box sx={{ flex: 1 }} key={1}>
                  <RichTextRender
                    style={{
                      padding: '0px'
                    }}
                    text={field.title}
                  />
                </Box>,

                <Grid
                  key={2}
                  gap={20}
                  sx={{ flex: 2 }}
                  columns={[2, null, 4]}
                >
                  {field.fieldItems.map((fi, k) => {
                    const fieldItemResponse =
                      fieldResponse?.fieldItems?.find(
                        f => f.formFieldItem.id === fi.id
                      ) || {}
                    const hasRelation = !isEmpty([
                      ...(fieldItemResponse?.assets || []),
                      ...(fieldItemResponse?.users || []),
                      ...(fieldItemResponse?.positions || []),
                      ...(fieldItemResponse?.file || []),
                      ...(fieldItemResponse?.locations || [])
                    ])

                    return (
                      <Flex key={k} sx={{ flexDirection: 'column' }}>
                        <Flex
                          sx={{
                            fontWeight: 'bold',
                            textTransform: 'capitalize'
                          }}
                        >
                          <Elipses maxLines={5}>
                            {fi.helpText}
                          </Elipses>
                        </Flex>
                        {hasRelation ? (
                          renderRelations(fieldItemResponse)
                        ) : fi.valueTypeFormat === 'signature' ? (
                          <img
                            sx={{ bg: 'white' }}
                            width={120}
                            src={fieldItemResponse?.value}
                          />
                        ) : fi.type === 'text' ? (
                          <RichTextRender
                            style={{
                              padding: '0px'
                            }}
                            text={fieldItemResponse?.prettyValue}
                          />
                        ) : (
                          fieldItemResponse?.prettyValue
                        )}
                      </Flex>
                    )
                  })}
                </Grid>
              ]
            }
          })}
        />
        {form?.autoWeather && (
          <Box
            sx={{
              borderTop: 'default',
              p: 10
            }}
          >
            <Overline>Weather</Overline>
            <SurflineWeatherIcons
              addUnits
              extended
              weather={formResponse?.weather}
            />
          </Box>
        )}
      </Flex>
    </>
  )
}

function renderRelations(fi) {
  return (
    <Box>
      {fi?.assets?.map(u => u.name)?.join(', ')}
      {fi?.users?.map(u => u.name)?.join(', ')}
      {fi?.positions?.map(u => u.name)?.join(', ')}
      {fi?.locations?.map(u => {
        return (
          <Flex>
            <ModalButton
              buttonProps={{
                variant: 'link',
                icon: 'geo',
                size: 'sm'
              }}
              buttonLabel={u.prettyAddress || `${u.lat}, ${u.lng}`}
            >
              {() => (
                <MapComponent lat={u.lat} lng={u.lng} zoom={15} />
              )}
            </ModalButton>{' '}
          </Flex>
        )
      })}
      {fi?.file?.map((u, k) => (
        <ModalButton
          buttonProps={{ variant: 'link', sx: { p: '0px' } }}
          key={k}
          buttonLabel={u.fileType}
        >
          {({ toggle }) => {
            return <ViewFileModal closeModal={toggle} f={u} />
          }}
        </ModalButton>
      ))}
    </Box>
  )
}

export default FormResponseTableView
