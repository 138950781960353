import React from 'react'

function Sweep(props) {
  const title = props.title || 'clear data'

  return (
    <svg
      height={props?.height || '48'}
      width={props?.width || '48'}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill={props.color || '#f7f7f7'}>
        <path
          d="M45,31H32a1,1,0,0,1,0-2H45a1,1,0,0,1,0,2Z"
          fill={props.color || '#f7f7f7'}
        />
        <path
          d="M45,41H31a1,1,0,0,1,0-2H45a1,1,0,0,1,0,2Z"
          fill={props.color || '#f7f7f7'}
        />
        <path
          d="M45,36H31a1,1,0,0,1,0-2H45a1,1,0,0,1,0,2Z"
          fill={props.color || '#f7f7f7'}
        />
        <path
          d="M45,46H34a1,1,0,0,1,0-2H45a1,1,0,0,1,0,2Z"
          fill={props.color || '#f7f7f7'}
        />
        <path
          d="M42.445,1.336a1.5,1.5,0,0,0-2.109.219L25.01,20.429A4.961,4.961,0,0,0,23,20a22.379,22.379,0,0,0-4.977.548c-.008.151-.023.3-.023.452a9,9,0,0,0,8.525,8.976,19.713,19.713,0,0,1,1.007-2.857A7.79,7.79,0,0,0,28,25a4.955,4.955,0,0,0-.74-2.583l15.4-18.972A1.5,1.5,0,0,0,42.445,1.336Z"
          fill={props.color || '#f7f7f7'}
        />
        <path
          d="M28.382,44.5a18.464,18.464,0,0,1-2.291-12.545A11.006,11.006,0,0,1,16.006,21.118C7.314,24.089,2,32.811,2,45a1,1,0,0,0,1,1H7.016a33.21,33.21,0,0,1,.28-5.133,1,1,0,0,1,1.982.266A31.163,31.163,0,0,0,9.017,46h5.114a29.811,29.811,0,0,1-.035-5.083,1,1,0,0,1,1.994.166A27.837,27.837,0,0,0,16.14,46h5.1a23.932,23.932,0,0,1-.929-4.922,1,1,0,1,1,1.994-.156A21.983,21.983,0,0,0,23.341,46h4.176a1,1,0,0,0,.865-1.5Z"
          fill={props.color || '#f7f7f7'}
        />
      </g>
    </svg>
  )
}

export default Sweep
