function Modules(props) {
  const fill = props.fill || 'currentColor'
  const secondaryfill = props.secondaryfill || fill

  const width = props.width || '100%'
  const height = props.height || '100%'
  const title = props.title || 'app store'

  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{title}</title>
      <g fill={secondaryfill}>
        <path
          d="M25,4H6A2,2,0,0,0,4,6V25a2,2,0,0,0,2,2H25a2,2,0,0,0,2-2V6A2,2,0,0,0,25,4Z"
          fill={fill}
        />
        <path d="M62.451,14.171,49.829,1.549a1.883,1.883,0,0,0-2.658,0L34.549,14.171a1.883,1.883,0,0,0,0,2.658L47.171,29.451a1.883,1.883,0,0,0,2.658,0L62.451,16.829A1.883,1.883,0,0,0,62.451,14.171Z" />
        <path
          d="M58,37H39a2,2,0,0,0-2,2V58a2,2,0,0,0,2,2H58a2,2,0,0,0,2-2V39A2,2,0,0,0,58,37Z"
          fill={fill}
        />
        <path
          d="M25,37H6a2,2,0,0,0-2,2V58a2,2,0,0,0,2,2H25a2,2,0,0,0,2-2V39A2,2,0,0,0,25,37Z"
          fill={fill}
        />
      </g>
    </svg>
  )
}

export default Modules
