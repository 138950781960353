/** @jsxImportSource theme-ui */
import { useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Box } from 'theme-ui'
import Button from './Elements/Button'

function CopyBox({ value, text, icon, buttonProps = {} }) {
  const [copied, onCopy] = useState()
  return (
    <CopyToClipboard
      style={{ cursor: 'pointer' }}
      text={value}
      onCopy={() => onCopy(value)}
    >
      {icon ? (
        <Button
          icon={icon}
          variant={copied === value ? 'success' : 'secondary'}
          {...buttonProps}
        >
          {text}
        </Button>
      ) : (
        <Box
          sx={{
            bg: 'gray100',
            borderRadius: '8px',
            p: 8,
            border: '2px',
            borderColor: 'success',
            borderStyle: 'dashed',
            wordBreak: 'break-word',
            whiteSpace: 'pre-wrap'
          }}
        >
          {text || value}
          {copied === value && (
            <span
              sx={{
                color: 'success',
                float: 'right',
                fontWeight: 'bold'
              }}
            >
              {' '}
              Copied!
            </span>
          )}
        </Box>
      )}
    </CopyToClipboard>
  )
}

export default CopyBox
