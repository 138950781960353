/** @jsxImportSource theme-ui */
import { forwardRef } from 'react'
import { Box } from 'theme-ui'

const InputGroup = (
  { children, variant = null, sx, ...props },
  ref
) => {
  return (
    <Box
      variant={variant}
      {...props}
      sx={{
        position: 'relative',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'stretch',
        width: '100%',
        ...sx
      }}
    >
      {children}
    </Box>
  )
}

export default forwardRef(InputGroup)
