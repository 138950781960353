function Like(props) {
  const fill = props.fill || 'currentColor'
  const secondaryfill = props.secondaryfill || fill
  const width = props.width || '100%'
  const height = props.height || '100%'
  const title = props.title || 'like'

  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{title}</title>
      <g
        fill={secondaryfill}
        stroke={secondaryfill}
        strokeLinecap="square"
        strokeWidth="4"
        transform="translate(0.5 0.5)"
      >
        <path
          d="M17,44H38.575a4.982,4.982,0,0,0,4.917-4.24l2.449-16A4.991,4.991,0,0,0,41.024,18H27V9c0-7.312-6-7-6-7a63.372,63.372,0,0,1-2.035,11C17.649,16.905,13,20,12,24"
          fill="none"
          stroke={fill}
          strokeLinecap="butt"
        />
        <rect height="24" width="10" fill="none" x="2" y="20" />
      </g>
    </svg>
  )
}

export default Like
