function World(props) {
  const fill = props.color
  const secondaryfill = props.color
  const width = props.width || '100%'
  const height = props.height || '100%'
  const title = props.title || 'world'

  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill={secondaryfill}>
        <path d="M54.178,33.507h0c-.641-1.52-4.117-3.3-5.744-3.773l-1.753-1.6-.207-.146a18.59,18.59,0,0,0-5.253-1.161,13.933,13.933,0,0,0-2.936-1.4c-1.148-.292-3.729.494-4.786.843l-.273.09-.184.222c-.981,1.187-4.185,5.213-4.185,7.228,0,1.019.974,1.935,2.1,3A9.732,9.732,0,0,1,32.4,38.332a11.664,11.664,0,0,0,.241,1.395c.251,1.2.671,3.22.165,4.006a10.435,10.435,0,0,0-.83,8.2,3.27,3.27,0,0,0,2.145,2.2,3.008,3.008,0,0,0,.807.1c1.909,0,4.193-1.572,6.239-2.979a16.213,16.213,0,0,1,2.359-1.469c1.785-.7,2.637-3,3.259-4.681a9.325,9.325,0,0,1,.451-1.1A9.619,9.619,0,0,1,50.9,41.133C52.891,40.141,55.077,35.644,54.178,33.507Z" />
        <path
          d="M32,2A30,30,0,1,0,62,32,30.034,30.034,0,0,0,32,2Zm0,58a27.868,27.868,0,0,1-17.878-6.469l1.869-2.8a4.581,4.581,0,0,0,2.463-1.6c1.332-2.4-.343-5.912-.85-6.869a11.568,11.568,0,0,0-.5-2.829c-.384-.96-1.963-1.884-2.856-2.343C13.6,35.976,12.319,33.476,12.319,32a2.371,2.371,0,0,1,2.3-2.476,6.31,6.31,0,0,0,4.015-2.682c.937-.286,2.988-1.032,3.687-2.255.749-1.313-.086-4.329-.575-5.819a7.4,7.4,0,0,0-.118-3.579,6.813,6.813,0,0,0-2.206-2.806,16.989,16.989,0,0,0-2.445-3.994,27.823,27.823,0,0,1,11.053-4.1,3.974,3.974,0,0,0-.514,2.846c.342.942,1.688,1.637,2.821,2.068-.143,1.114-.155,2.937,1.036,3.891s2.651.538,3.622.271l.371-.1a23.19,23.19,0,0,1,.165,4.044l-.013.4.271.3c.107.118,2.663,2.908,5.34,3.284a2.865,2.865,0,0,0,.415.028c2.426,0,5.234-2.761,6.177-3.767a50.518,50.518,0,0,0,6.831-2.122A27.981,27.981,0,0,1,32,60Z"
          fill={fill}
        />
      </g>
    </svg>
  )
}

export default World
