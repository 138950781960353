const GPS = ({ height, width, color }) => {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>crosshair</title>
      <g
        fill={color || '#111111'}
        stroke={color || '#111111'}
        strokeLinecap="square"
        strokeWidth="2"
      >
        <circle
          cx="16"
          cy="16"
          fill="none"
          r="13"
          stroke={color || '#111111'}
        />
        <line
          fill="none"
          stroke={color || '#111111'}
          x1="16"
          x2="16"
          y1="1"
          y2="6"
        />
        <line
          fill="none"
          stroke={color || '#111111'}
          x1="31"
          x2="26"
          y1="16"
          y2="16"
        />
        <line
          fill="none"
          stroke={color || '#111111'}
          x1="16"
          x2="16"
          y1="31"
          y2="26"
        />
        <line
          fill="none"
          stroke={color || '#111111'}
          x1="1"
          x2="6"
          y1="16"
          y2="16"
        />
        <circle cx="16" cy="16" fill="none" r="3" />
      </g>
    </svg>
  )
}
export default GPS
