/** @jsxImportSource theme-ui */

import { Box } from 'theme-ui'

const Collapse = ({
  isOpen,
  children,
  maxHeight = '1000px',
  absolute,
  styles,
  bordered,
  ...rest
}) => {
  return (
    <Box
      sx={{
        overflow: isOpen ? 'visible' : 'hidden',
        height: 'auto',
        maxHeight: !isOpen ? '0px' : maxHeight,
        transition: 'max-height 0.35s ease',
        ...(bordered &&
          isOpen && {
            border: '1px solid',
            borderColor: 'gray200'
          }),
        ...(absolute && {
          position: absolute ? 'absolute' : 'relative',
          right: '0',
          left: '0'
        }),
        ...styles
      }}
    >
      {children}
    </Box>
  )
}

export default Collapse
