import { toast } from 'react-toastify'

const typeMap = {
  success: 'success',
  danger: 'error',
  warning: 'warning',
  info: 'info'
}

export const useToast = () => {
  return {
    add: ({ content, color, action }) =>
      toast(content, {
        // hideProgressBar: true,
        type: typeMap?.[color] || 'info',
        ...(action && { closeButton: action, closeOnClick: false })
      })
  }
}
