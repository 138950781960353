/** @jsxImportSource theme-ui */
import { forwardRef } from 'react'

const ListGroupItemHeading = (
  { children, variant, sx, compactView, ...props },
  ref
) => {
  return compactView ? (
    <h6 sx={{ ...sx }} {...props}>
      {children}
    </h6>
  ) : (
    <h5 sx={{ ...sx }} {...props}>
      {children}
    </h5>
  )
}

export default forwardRef(ListGroupItemHeading)
