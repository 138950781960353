/** @jsxImportSource theme-ui */
import { forwardRef } from 'react'
import { Box } from 'theme-ui'

const InputGroupText = (
  { children, variant = null, sx, ...props },
  ref
) => {
  return (
    <Box
      variant={variant}
      {...props}
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '0.5rem 0.75rem',
        marginBottom: '0px',
        fontSize: '14px',
        fontWeight: 'normal',
        lineHeight: '1.5',
        color: 'gray700',
        textAlign: 'center',

        backgroundColor: 'gray200',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'gray300',
        borderRadius: '0.2rem',
        whiteSpace: 'normal',
        ...sx
      }}
    >
      {children}
    </Box>
  )
}

export default forwardRef(InputGroupText)
