import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { mobile, tablet } from 'src/styles/index'
import { Box } from 'theme-ui'

export const HideOnMobile = styled.div`
  ${mobile(css`
    display: none;
  `)}
`

export const HideOnTablet = styled.div`
  ${tablet(css`
    display: hide;
  `)}
`

export const HideOnDesktop = styled.div`
  display: none;
  ${mobile(css`
    display: block;
  `)}
`

export const Space = styled.div`
${p =>
  p.fill &&
  css`
    flex: 1;
  `}
  width: ${p =>
    p.block
      ? '100%'
      : typeof p.width === 'number'
      ? p.width + 'px'
      : 'auto'};
  height: ${p =>
    typeof p.height === 'number' ? p.height + 'px' : 'auto'};
  flex-shrink: 0;

  margin-bottom: ${props =>
    props.size && props.theme.space[props.size]};
  border-top: ${props =>
    props.border && `1px solid ${props.theme.colors.gray300}`};
  ${p =>
    mobile(css`
      ${typeof p.widthOnMobile === 'number' &&
        css`
          width: ${p.widthOnMobile}px;
        `};

      ${typeof p.heightOnMobile === 'number' &&
        css`
          height: ${p.heightOnMobile}px;
        `};
    `)};

  ${p =>
    p.fillRow &&
    css`
      flex-grow: 1;
      margin-right: auto;
      margin-left: auto;
    `};

  ${p =>
    p.fillColumn &&
    css`
      flex-grow: 1;
      margin-top: auto;
      margin-bottom: auto;
    `};
`

export const Overlay = styled.div`
  &::after {
    content: '';
    background-color: ${({ bc }) => bc || 'black'};
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    z-index: 1;
    opacity: ${props => (props.isOpen ? '.8' : '0')};
    visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};
  }
`

export const CenteredContent = styled(Box)`
  min-height: ${p => p.height || `75vh`};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${p => {
    return p.theme?.colors?.[p.bc] || p.bc || 'transparent'
  }};
`
