import React from 'react'

function OFfline(props) {
  const title = props.title || 'wifi off'

  return (
    <svg
      height={props.height || '64'}
      width={props.width || '64'}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g
        fill={props.color || '#f7f7f7'}
        stroke={props.color || '#f7f7f7'}
        strokeLinecap="square"
        strokeLinejoin="miter"
        strokeMiterlimit="10"
        strokeWidth="4"
      >
        <path
          d="M33.994,42.339 C36.327,43.161,38,45.385,38,48c0,3.314-2.686,6-6,6c-2.615,0-4.839-1.673-5.661-4.006"
          fill="none"
          stroke={props.color || '#f7f7f7'}
        />
        <path
          d="M47.556,32.444 C43.575,28.462,38.075,26,32,26c-6.075,0-11.575,2.462-15.556,6.444"
          fill="none"
          stroke={props.color || '#f7f7f7'}
        />
        <path
          d="M59.224,21.276 C52.256,14.309,42.632,10,32,10c-10.631,0-20.256,4.309-27.224,11.276"
          fill="none"
          stroke={props.color || '#f7f7f7'}
        />
        <line fill="none" x1="10" x2="58" y1="54" y2="6" />
      </g>
    </svg>
  )
}

export default OFfline
