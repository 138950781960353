import { Box } from 'theme-ui'

import Icon from 'src/app/components/Shared/Icon'

const FormFieldItemFormRequiredButton = ({
  fieldItem,
  handleChange
}) => {
  return (
    <Box
      style={{
        cursor: 'pointer',
        padding: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      color="naked"
      onClick={() =>
        handleChange({ value: !fieldItem.required, name: 'required' })
      }
    >
      <Icon
        icon="Asterix"
        color={fieldItem.required ? 'success' : 'currentColor'}
        title="Make Required Field"
        height={15}
        width={15}
      />
    </Box>
  )
}

export default FormFieldItemFormRequiredButton
