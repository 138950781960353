const Grid = ({ height, width, color }) => {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>grid view</title>
      <g fill={color || '#111111'}>
        <rect height="4" width="4" fill={color || '#111111'} />
        <rect height="4" width="4" fill={color || '#111111'} x="6" />
        <rect height="4" width="4" fill={color || '#111111'} x="12" />
        <rect height="4" width="4" fill={color || '#111111'} y="6" />
        <rect
          height="4"
          width="4"
          fill={color || '#111111'}
          x="6"
          y="6"
        />
        <rect
          height="4"
          width="4"
          fill={color || '#111111'}
          x="12"
          y="6"
        />
        <rect height="4" width="4" fill={color || '#111111'} y="12" />
        <rect
          height="4"
          width="4"
          fill={color || '#111111'}
          x="6"
          y="12"
        />
        <rect
          height="4"
          width="4"
          fill={color || '#111111'}
          x="12"
          y="12"
        />
      </g>
    </svg>
  )
}

export default Grid
