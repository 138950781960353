import { useState } from 'react'

import FormError from './FormError'
import { useToast } from 'src/components/toasts'

import ModalButton from '../Shared/ModalButton'

import RichTextInput, {
  CustomRichText
} from '../Shared/RichTextInput'

import Button from '../Shared/Elements/Button'

import DOMPurify from 'dompurify'
import { gql, useMutation } from '@apollo/client'
import ModalFooter from '../Shared/Elements/ModalFooter'

const UPDATE_ONE_NOTE = gql`
  mutation updateOneNote(
    $data: NoteUpdateInput!
    $where: NoteWhereUniqueInput!
  ) {
    updateOneNote(data: $data, where: $where) {
      id
      text
    }
  }
`

const UpdateNoteModalForm = ({ noteId, text }) => {
  return (
    <ModalButton
      buttonProps={{ variant: 'link', icon: 'pencil', size: 'xs' }}
      modalHeader="Update Note"
      contentWidth={'95vw'}
    >
      {({ toggle }) => (
        <UpdateNoteForm
          onFinish={toggle}
          noteId={noteId}
          text={text}
        />
      )}
    </ModalButton>
  )
}

const UpdateNoteForm = ({ noteId, text, onFinish }) => {
  const [value, setValue] = useState(text)
  const { add } = useToast()

  const [updateOneNote, { error, loading }] = useMutation(
    UPDATE_ONE_NOTE
  )

  async function updateNote(e) {
    e?.preventDefault()
    try {
      const res = await updateOneNote({
        variables: {
          data: {
            text: DOMPurify.sanitize(value || '')
          },
          where: {
            id: noteId
          }
        }
      })

      if (res.data.updateOneNote) {
        add({
          color: 'success',
          content: 'Note Added'
        })
        onFinish()
        setValue(null)
      }
    } catch (e) {
      console.log(e)
      add({
        color: 'danger',
        content: 'Oops there was an error.'
      })
    }
  }
  return (
    <>
      <CustomRichText>
        <RichTextInput
          value={value || ''}
          onChange={content => {
            setValue(content)
          }}
          minHeight={'400px'}
        />
      </CustomRichText>
      {error && <FormError error={error.message} />}
      <ModalFooter onClose={onFinish}>
        <Button
          variant="primary"
          onClick={e => updateNote(e)}
          disabled={loading}
        >
          {loading ? 'Saving...' : 'Save Note'}
        </Button>
      </ModalFooter>
    </>
  )
}

export default UpdateNoteModalForm
