import { useEffect, useState } from 'react'
import { Flex } from 'theme-ui'
import FormError from '../Forms/FormError'
import Button from './Elements/Button'
import Badge from './Elements/Badge'
import Input from './Elements/Input'

export const MultiValueInput = ({
  value,
  onAdd,
  errorMessage,
  defaultArrayValue,
  ...rest
}) => {
  const [arr, setArray] = useState([])

  useEffect(() => {
    if (defaultArrayValue) {
      setArray(defaultArrayValue)
    }
  }, [defaultArrayValue])

  function handleAddValue(v) {
    if (errorMessage) {
      return
    }

    if (!v || !v.length) {
      return
    }

    const newArr = [...arr, v]

    setArray(newArr)
    onAdd(newArr)
  }

  function handleRemoveValue(v) {
    if (errorMessage) {
      return
    }

    const newArr = [...arr].filter(x => x !== v)

    setArray(newArr)
    onAdd(newArr)
  }

  const onKeyDown = (event, value) => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter') {
      event.stopPropagation()
      event.preventDefault()

      handleAddValue(value)
    }
  }
  return (
    <div onKeyDown={e => onKeyDown(e, value)}>
      <Flex sx={{ alignItems: 'center', marginBottom: '10px' }}>
        <Input value={value} {...rest} />
        <Button
          sx={{ fontSize: '16px', fontWeight: 'bold' }}
          variant="naked"
          onClick={() => handleAddValue(value)}
        >
          +
        </Button>
      </Flex>
      <FormError customError={errorMessage} />
      <Flex sx={{ flexWrap: 'wrap' }}>
        {arr.map((value, index) => (
          <Badge
            key={index}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              margin: '10px 3px 10px 0px',
              width: 'auto',
              color: 'white',
              bg: 'secondary',
              p: '5px 10px'
            }}
          >
            {value}
            <div
              style={{ marginLeft: '12px', cursor: 'pointer' }}
              onClick={() => handleRemoveValue(value)}
            >
              x
            </div>
          </Badge>
        ))}
        {arr.length > 0 && (
          <Button
            variant="link"
            onClick={() => {
              onAdd([])
            }}
          >
            Clear all
          </Button>
        )}
      </Flex>
    </div>
  )
}
