import { Box } from 'theme-ui'

import PrefilledQuickSurvey from '../components/QuickSurvey/PrefilledQuickSurvey'
import { QuickSurvey } from '../components/QuickSurvey/QuickSurvey'

import PayWallScreen from '../components/PayWalls/PayWallScreen'
import { useGetCurrentAgencyContext } from '../hooks/queries'

export const QuickSurveyBranch = ({ onFinish, id, ...rest }) => {
  const { currentAgency } = useGetCurrentAgencyContext()

  return (
    <PayWallScreen featureLabel="incidentReporting">
      {id ? (
        <Box>
          <PrefilledQuickSurvey
            currentAgency={currentAgency}
            id={id}
            onFinish={onFinish}
            {...rest}
          />
        </Box>
      ) : (
        <QuickSurvey
          extendedQuickSurvey={true}
          includeResponseInputs={true}
        />
      )}
    </PayWallScreen>
  )
}

export default QuickSurvey
