function SubmitForm(props) {
  const title = props.title || 'Submit Form'

  return (
    <svg
      height={props.height || '48'}
      width={props.width || '48'}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill={props.color || '#212121'}>
        <path d="M16.211,13.635l7,9a1.035,1.035,0,0,0,1.578,0l7-9A1,1,0,0,0,31,12.021H26v-9a2,2,0,0,0-4,0v9H17a1,1,0,0,0-.789,1.614Z" />
        <path
          d="M46.937,27.649l-6-16A1,1,0,0,0,40,11H36a1,1,0,0,0,0,2h3.307l5.25,14H32a1,1,0,0,0-1,1v5H17V28a1,1,0,0,0-1-1H3.443l5.25-14H12a1,1,0,0,0,0-2H8a1,1,0,0,0-.937.649l-6,16L1,28V43a2,2,0,0,0,2,2H45a2,2,0,0,0,2-2V28Z"
          fill={props.color || '#212121'}
        />
      </g>
    </svg>
  )
}

export default SubmitForm
