import React from 'react'

function Verified({ height, width, color, ...props }) {
  const title = props.title

  return (
    <svg
      height={height || '64'}
      width={width || '64'}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{title}</title>
      <g fill={color || '#f7f7f7'}>
        <path
          d="M56.164,5.014L32.164,1.014c-.109-.018-.219-.018-.328,0L7.836,5.014c-.482,.08-.836,.497-.836,.986v30c0,7.7,4.3,14.81,12.422,20.566,3.785,2.664,7.908,4.811,12.262,6.382,.205,.069,.427,.069,.632,0,4.354-1.571,8.477-3.718,12.262-6.382,8.122-5.756,12.422-12.866,12.422-20.566V6c0-.489-.354-.906-.836-.986ZM28.349,41.177l-9.47-9.47c-.391-.391-.391-1.024,0-1.414l1.414-1.414c.391-.391,1.024-.391,1.414,0l7.18,7.18,15.25-17.787c.359-.419,.99-.468,1.41-.109l1.518,1.299c.42,.359,.468,.991,.109,1.41l-17.357,20.248c-.379,.442-1.054,.468-1.466,.056Z"
          fill={color || '#f7f7f7'}
        />
      </g>
    </svg>
  )
}

export default Verified
