import { theme } from 'src/styles'

function Team(props) {
  const title = props.title || 'team'

  return (
    <svg
      height={props.height || '48'}
      width={props.width || '48'}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill={props.color || theme.colors.gray700}>
        <path d="M46,1H22c-0.553,0-1,0.448-1,1v21c0,0.382,0.218,0.73,0.561,0.898 C21.7,23.966,21.851,24,22,24c0.218,0,0.435-0.071,0.614-0.21L31.343,17H46c0.553,0,1-0.448,1-1V2C47,1.448,46.553,1,46,1z" />
        <path
          d="M19.146,39.78C17.548,38.512,14.837,37,11,37s-6.548,1.512-8.146,2.78C1.676,40.715,1,42.134,1,43.672V46 c0,0.553,0.447,1,1,1h18c0.553,0,1-0.447,1-1v-2.328C21,42.134,20.324,40.715,19.146,39.78z"
          fill={props.color || theme.colors.gray700}
        />
        <path
          d="M45.146,39.78C43.548,38.512,40.837,37,37,37s-6.548,1.512-8.146,2.78C27.676,40.715,27,42.134,27,43.672V46 c0,0.553,0.447,1,1,1h18c0.553,0,1-0.447,1-1v-2.328C47,42.134,46.324,40.715,45.146,39.78z"
          fill={props.color || theme.colors.gray700}
        />
        <circle
          cx="11"
          cy="28"
          fill={props.color || theme.colors.gray700}
          r="6"
        />
        <circle
          cx="37"
          cy="28"
          fill={props.color || theme.colors.gray700}
          r="6"
        />
      </g>
    </svg>
  )
}

export default Team
