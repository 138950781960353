import FormList from '../../Operations/Forms/FormList'
import ModalButton from '../ModalButton'

const OtherFormListButton = ({
  onAttach,
  variant = 'link',
  text = "Don't see the form? Click here.",
  featuredForms = [],
  submissionId,
  ...rest
}) => {
  return (
    <ModalButton
      modalHeader={'Select a form to attach'}
      buttonLabel={text}
      buttonProps={{
        variant,
        type: 'button'
      }}
    >
      {({ toggle }) => (
        <FormList
          formType={'incidentAttachedForms'}
          hideFilters={['showSupReview', 'showArchived']}
          submissionId={submissionId}
          onFormSubmit={
            onAttach &&
            (val => {
              const res = onAttach(val)
              toggle()
              return res
            })
          }
          filterForms={form =>
            !form.supervisorReviewConfig &&
            !featuredForms?.includes(form.id)
          }
          submitToastText="Form Attached"
          disableAutoSave
          hideCreateButton
          hideEdit
          hideResponseModal
          onFormSubmitFinish={() => {
            toggle()
            rest.onFinish && rest.onFinish()
          }}
          {...rest}
        />
      )}
    </ModalButton>
  )
}

export default OtherFormListButton
