import NotFound from '../../../images/NotFound.jpeg'
import BackgroundImage from './BackgroundImage'
import { Box } from 'theme-ui'
import Button from './Elements/Button'
import Jumbotron from './Elements/Jumbotron'

const ErrorBoundaryPage = props => {
  return (
    <>
      <BackgroundImage
        style={{ height: '100vh' }}
        Tag="section"
        src={NotFound}
      >
        <Jumbotron
          sx={{
            backgroundColor: 'transparent',
            color: 'white',
            textAlign: 'center',
            padding: ['4rem 2rem', null, null, '2rem 1rem']
          }}
        >
          <Box sx={{ maxWidth: '600px', textAlign: 'left' }}>
            {' '}
            <h1>Oops, this is probably our fault..</h1>
            <p>
              You just encountered an unforseen error. Follow the
              steps below if you keep reaching this page.
            </p>
            <ol>
              <li>Refresh the page.</li>
              <li>Logout and log back in</li>
              <li>Clear your browser cache</li>
              <li>If none of these work, please contact support</li>
            </ol>
            <Button
              variant="primary"
              onClick={() => props.clearError()}
            >
              Refresh
            </Button>
          </Box>
        </Jumbotron>
      </BackgroundImage>
    </>
  )
}

export default ErrorBoundaryPage
