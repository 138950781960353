/** @jsxImportSource theme-ui */
import { memo, useState, useRef } from 'react'

import { useQuery } from '@apollo/client'

import { CenteredContent } from '../../components'

import { Box, Flex } from 'theme-ui'
import { gql } from '@apollo/client'

import Button from '../Shared/Elements/Button'
import Icon from '../Shared/Icon'
import CustomCarousel from '../Shared/CustomCarousel'
import { CreateBulletinModalButton } from '../Forms/CreateBulletin'

import { FILE_FRAGMENT } from 'src/app/hooks/fragments'

import BulletinRichTextModalContent from '../Bulletins/BulletinRichTextModal'
import { RolesOnly } from 'src/app/services/auth'
import { Link } from 'react-router-dom'
import { BulletinListGroup } from '../Bulletins/BulletinListGroup'

export const GET_BULLETINS = gql`
  query GetBulletinList(
    $where: BulletinWhereInput
    $cursor: BulletinWhereUniqueInput
    $take: Int
    $skip: Int
  ) {
    myAgenciesBulletins(
      take: $take
      where: $where
      cursor: $cursor
      orderBy: [{ liveUntil: desc }, { createdAt: desc }]
      skip: $skip
    ) {
      id
      createdAt
      liveUntil
      isPrivate
      isActive
      body
      title
      urgency
      readByCount
      hasRead
      whiteList {
        id
        name
      }
      whiteListedGroups {
        id
        name
        color
      }
      author {
        id
        name
      }

      attachments {
        ...FileFragment
      }
    }
  }
  ${FILE_FRAGMENT}
`

const BulletinList = () => {
  const carouselRef = useRef()
  const [autoplay, setAutoplay] = useState(false)
  const [listView, setListView] = useState(false)
  const { data } = useQuery(GET_BULLETINS, {
    pollInterval: 120000,
    notifyOnNetworkStatusChange: true
  })

  const bulletins = data?.myAgenciesBulletins

  function toggleAutoplay() {
    !autoplay
      ? carouselRef.current.slickPlay()
      : carouselRef.current.slickPause()
    setAutoplay(!autoplay)
  }

  function toggleListView() {
    setListView(!listView)
  }

  return (
    <Box variant="card">
      {!data ? (
        <CenteredContent>
          <Icon icon="polling" height="40px" width="40px" />
          Fetching Comms Data...
        </CenteredContent>
      ) : (
        <>
          {bulletins?.length ? (
            listView ? (
              <>
                <Flex
                  sx={{
                    position: 'relative',
                    width: '100%',
                    justifyContent: 'flex-end',
                    alignItems: 'stretch',
                    pb: 20,
                    height: 60
                  }}
                >
                  <ListViewButton
                    listView={listView}
                    toggleListView={toggleListView}
                  />
                </Flex>
                <BulletinListGroup
                  hideActions
                  bulletins={bulletins}
                />
              </>
            ) : (
              <CustomCarousel
                slidesToShow={1}
                ref={carouselRef}
                autoplay={autoplay}
                autoplaySpeed={10000}
                pauseOnHover={true}
                draggable={false}
                infinite
                dots={true}
                // adaptiveHeight={true}
                appendDots={CustomPaging}
                prevArrow={<CustomPrevArrow />}
                nextArrow={
                  <CustomNextArrow
                    autoplay={autoplay}
                    toggleAutoplay={toggleAutoplay}
                    listView={listView}
                    toggleListView={toggleListView}
                  />
                }
              >
                {bulletins.map((p, k) => {
                  return (
                    <BulletinRichTextModalContent {...p} key={k} />
                  )
                })}
              </CustomCarousel>
            )
          ) : (
            <CenteredContent height={'500px'}>
              <Icon icon="empty list" width={30} height={30} />
              <h4 style={{ marginTop: 20, textAlign: 'center' }}>
                No Active Bulletins
              </h4>
              <Box
                sx={{ maxWidth: 300, mb: 25, textAlign: 'center' }}
              >
                Create a new bulletin and it will appear here for all
                of your team members.
              </Box>
              <RolesOnly roles={['ADMIN', 'SUPERVISOR']}>
                <CreateBulletinModalButton text="Create Bulletin" />
              </RolesOnly>

              <Link
                state={{ chatType: 'bulletins' }}
                to={'/agency/communications'}
                style={{ marginTop: '10px' }}
              >
                Past Bulletins
              </Link>
            </CenteredContent>
          )}
        </>
      )}
    </Box>
  )
}

function CustomPrevArrow({ onClick }) {
  return (
    <Button
      direction="prev"
      directionText="Previous"
      onClick={onClick}
      variant="link"
      outline
      style={{
        fontWeight: 'bold',
        border: '1px solid #dee2e6',
        fontSize: '16px'
      }}
    >
      ←
    </Button>
  )
}

function CustomPaging(dots) {
  const current = dots.findIndex(d =>
    d.props.className.includes('active')
  )
  return (
    <div
      style={{
        position: 'absolute',
        top: '0px',
        width: '250px',
        left: '50%',
        marginLeft: '-125px',
        textAlign: 'center',

        height: '60px',
        zIndex: 1
      }}
    >
      Bulletins ({current + 1} / {dots.length})
      <Link
        style={{
          fontWeight: 'normal',
          textTransform: 'none',
          display: 'block'
        }}
        state={{ chatType: 'bulletins' }}
        to={'/agency/communications'}
      >
        (Past Bulletins)
      </Link>
    </div>
  )
}

function CustomNextArrow({
  onClick,
  autoplay,
  toggleAutoplay,
  listView,
  toggleListView
}) {
  return (
    <Flex
      sx={{
        position: 'absolute',
        top: '0px',
        right: '0px',
        zIndex: 2
      }}
    >
      <ListViewButton
        listView={listView}
        toggleListView={toggleListView}
      />
      <Button
        variant={autoplay ? 'success' : 'secondary'}
        size="sm"
        onClick={() => {
          toggleAutoplay()
        }}
        style={{ marginRight: '5px' }}
      >
        <Icon title="Autoplay slides" icon={'replay'} color="white" />
      </Button>

      <Button
        direction="next"
        directionText="Next"
        onClick={onClick}
        variant="link"
        outline
        style={{
          fontWeight: 'bold',
          fontSize: '16px',
          border: '1px solid #dee2e6'
        }}
      >
        →
      </Button>
    </Flex>
  )
}

const ListViewButton = ({ listView, toggleListView }) => {
  return (
    <Button
      variant={'secondary'}
      size="sm"
      onClick={() => {
        toggleListView()
      }}
      style={{ marginRight: '5px' }}
    >
      <Icon
        width={15}
        height={15}
        title="List view bulletins"
        icon={listView ? 'carousel' : 'list'}
        color="white"
      />
    </Button>
  )
}

export default memo(BulletinList)
