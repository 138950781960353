import { useState, useEffect } from 'react';
import { Box } from 'theme-ui'
import { useQuery, gql } from '@apollo/client'
import { useLocalStorage } from '../hooks/useLocalStorage'

const GET_TWITTER_ACCESS_TOKEN = gql`
  query getTwitterAccessToken(
    $oauthToken: String!
    $oauthVerifier: String!
  ) {
    getTwitterAccessToken(
      oauthToken: $oauthToken
      oauthVerifier: $oauthVerifier
    )
  }
`

const AuthSuccess = () => {
  // eslint-disable-next-line no-unused-vars
  const [_, setTwitterOauthAccessToken] = useLocalStorage(
    'twitterOauthAccessToken'
  )

  // eslint-disable-next-line no-unused-vars
  const [__, setTwitterOauthAccessTokenSecret] = useLocalStorage(
    'twitterOauthAccessTokenSecret'
  )
  const params = new URLSearchParams(window.location.search)
  const oauthToken = params.get('oauth_token')
  const oauthVerifier = params.get('oauth_verifier')
  const [countdownSeconds, setCountdownSeconds] = useState(5)

  const { loading, error } = useQuery(GET_TWITTER_ACCESS_TOKEN, {
    variables: {
      oauthToken: oauthToken,
      oauthVerifier: oauthVerifier
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    skip: !oauthToken || !oauthVerifier,
    onCompleted: data => {
      if (!data.getTwitterAccessToken) {
        return
      }
      const params = new URLSearchParams(data.getTwitterAccessToken)
      const oauthAccessToken = params.get('oauth_token')
      const oauthAccessTokenSecret = params.get('oauth_token_secret')
      setTwitterOauthAccessToken(oauthAccessToken)
      setTwitterOauthAccessTokenSecret(oauthAccessTokenSecret)
    }
  })

  useEffect(() => {
    if (!loading && !error) {
      let myInterval = setInterval(() => {
        if (countdownSeconds > 0) {
          setCountdownSeconds(countdownSeconds - 1)
        }
        if (countdownSeconds === 0) {
          clearInterval(myInterval)
          window.close()
        }
      }, 1000)
      return () => {
        clearInterval(myInterval)
      }
    }
  }, [countdownSeconds, loading, error])

  return (
    <Box
      sx={{
        width: '800px',
        borderRadius: '0.2rem',
        margin: '0 auto',
        height: '800px',
        padding: '2rem'
      }}
    >
      {loading && !error ? (
        <h2>Authenticating...</h2>
      ) : (
        <h2>
          You have successfully authenticated! This window will close
          in {countdownSeconds} seconds.
        </h2>
      )}
      {!loading && error && (
        <h2 sx={{ color: 'danger' }}>{error?.message}</h2>
      )}
    </Box>
  )
}

export default AuthSuccess
