/** @jsxImportSource theme-ui */

import { gql, useMutation, useQuery } from '@apollo/client'

import capitalize from 'lodash/capitalize'
import uniqBy from 'lodash/uniqBy'
import flatMap from 'lodash/flatMap'
import { useState } from 'react'
import { Flex, Box, Grid } from 'theme-ui'
import { theme } from 'src/styles'
import { useToast } from 'src/components/toasts'
import Spinner from 'src/images/icons/Spinner'
import { CenteredContent } from '../..'
import { cleanGraphQLError } from '../../Forms/FormError'
import FilterByTag from '../../Shared/FilterByTag'
import Icon from '../../Shared/Icon'
import Button from '../../Shared/Elements/Button'

import Badge from '../../Shared/Elements/Badge'
import Input from '../../Shared/Elements/Input'
import ModalFooter from '../../Shared/Elements/ModalFooter'

import { RichTextRender } from '../../Shared/RichTextRender'
import { Divider } from '../../Shared'
import InlineTooltip from '../../Shared/InlineTooltip'
import Card from '../../Shared/Elements/Card'
import CardTitle from '../../Shared/Elements/CardTitle'
import uniq from 'lodash/uniq'
import { getFieldConfig } from './formBuilder/dataMapping'
import { FormPageTypes } from 'src/app/containers/Operations/utils'

const GET_FORM_TEMPLATES = gql`
  query GetFormTemplates($where: FormTemplateWhereInput) {
    formTemplates(take: 100, where: $where) {
      id
      name
      tags
      secureImageUrl
      template
      pulseIncluded
    }
  }
`

const CREATE_FORM_FROM_TEMPLATE = gql`
  mutation CreateFormFromTemplate(
    $templateId: ID!
    $removeFieldItems: [ID]
    $removeStatuses: [ID]
  ) {
    createFormFromTemplate(
      templateId: $templateId
      removeFieldItems: $removeFieldItems
      removeStatuses: $removeStatuses
    ) {
      id
    }
  }
`

const TemplateList = ({ onFinish, formType, onExit }) => {
  const [search, setSearch] = useState('')
  const [selectedTemplate, setSelectedTemplate] = useState()

  const { add } = useToast()
  const [categoryFilter, setCategoryFilter] = useState([])

  const formTypeConfig = FormPageTypes[formType]

  const { data, loading } = useQuery(GET_FORM_TEMPLATES, {
    variables: {
      where: {
        formType: formType
      }
    }
  })
  const [
    createFormFromTemplate,
    { loading: mutationLoading }
  ] = useMutation(CREATE_FORM_FROM_TEMPLATE, {
    refetchQueries: ['GetAgencyForms', 'CurrentAgency']
  })

  async function handleFormCreate(template) {
    const res = await createFormFromTemplate({
      variables: {
        templateId: template?.id,
        removeFieldItems: template?.removeFieldItems,
        removeStatuses: template?.removeStatuses
      }
    })

    if (res.errors) {
      add({
        content: cleanGraphQLError(res.errors[0]),
        color: 'danger'
      })
      return
    }

    if (res.data) {
      add({
        content: `form created from template`,
        color: 'success'
      })
      onFinish()
    }
  }

  const opts =
    data?.formTemplates &&
    data.formTemplates.length > 0 &&
    uniqBy(
      flatMap(data.formTemplates, ({ tags }) =>
        tags
          ? tags.map(t => ({
              id: t,
              name: t,
              color: theme.colors.gray700
            }))
          : []
      ),
      'id'
    )

  const hasStatuses = selectedTemplate?.template?.fields?.some(f =>
    f?.fieldItems?.some(fi => fi?.autoUpdates?.length > 0)
  )

  const allPulseConnections = selectedTemplate?.template?.fields
    ?.flatMap(f => f?.fieldItems)
    ?.flatMap(fi => fi?.autoUpdates?.map(au => au))
    ?.filter(fi => fi)

  const allRelationFromFormQuestionIds = uniq(
    allPulseConnections?.flatMap(
      au => au?.relationFromFormQuestion?.id
    )
  )?.filter(fi => fi)

  const currentRemovedFieldItems =
    selectedTemplate?.removeFieldItems || []
  return loading ? (
    <Spinner centered />
  ) : selectedTemplate ? (
    <Box>
      <Flex
        sx={{
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <h4>
          You are about to create a copy of{' '}
          <strong>{selectedTemplate.name}</strong>:
        </h4>{' '}
        <Button
          onClick={() => setSelectedTemplate(null)}
          variant="link"
          icon="left arrow"
        >
          Back
        </Button>
      </Flex>
      <p>
        Feel free to add and remove questions and statuses. Some
        questions may not be allowed to be remove.
      </p>
      <Divider margin="20px 0px 20px 0px" />
      {hasStatuses && (
        <InlineTooltip
          variant="warning"
          header={'This template includes statuses.'}
          tip={
            'Statuses in this form will automatically be joined with statuses you have configured with the exact same name.'
          }
        />
      )}
      <Box
        sx={{
          mt: '30px'
        }}
      >
        <Flex
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <h5>Sections</h5>
          {selectedTemplate && hasStatuses && (
            <Button
              icon={
                selectedTemplate.removeStatuses?.length > 0
                  ? 'plus'
                  : 'minus'
              }
              variant={
                selectedTemplate.removeStatuses?.length > 0
                  ? 'linkSuccess'
                  : 'linkDanger'
              }
              onClick={() => {
                setSelectedTemplate(prev => {
                  return {
                    ...prev,
                    removeStatuses: selectedTemplate.removeStatuses
                      ?.length
                      ? []
                      : allPulseConnections.map(au => au?.id)
                  }
                })
              }}
            >
              {selectedTemplate.removeStatuses?.length > 0
                ? 'Restore all Pulse'
                : 'Remove all Pulse'}
            </Button>
          )}
        </Flex>

        {[...selectedTemplate?.template?.fields]
          ?.sort((a, b) => a?.order - b?.order)
          ?.map((field, i) => (
            <Box
              key={i}
              sx={{
                mt: '20px',
                border: 'default',
                my: '10px',
                p: '3px'
              }}
            >
              <RichTextRender
                noHover
                text={field?.title}
                mw={200}
                maxLines={1}
              />
              {field?.fieldItems?.map((fi, k) => {
                const fieldConfig = getFieldConfig(fi)
                const isRemoved = currentRemovedFieldItems.includes(
                  fi?.id
                )
                return (
                  <Box
                    key={k}
                    sx={{
                      ml: '30px',
                      bg: 'gray100',
                      border: '1px solid',
                      borderColor: 'gray200',
                      my: '10px',
                      p: '5px'
                    }}
                  >
                    <Flex
                      key={k}
                      sx={{
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        px: '5px'
                      }}
                    >
                      <Flex
                        sx={{
                          flexDirection: 'column',
                          alignItems: 'flex-start'
                        }}
                      >
                        <h7
                          sx={{
                            fontWeight: 'bold'
                          }}
                        >
                          {fi?.title ||
                            fi.helpText ||
                            `Question ${k + 1}`}
                        </h7>
                        <p>{prettyType(fi?.type)}</p>
                      </Flex>
                      {fi.id &&
                        !allRelationFromFormQuestionIds?.includes(
                          fi.id
                        ) && (
                          <Button
                            variant={
                              isRemoved ? 'linkSuccess' : 'linkDanger'
                            }
                            onClick={e => {
                              setSelectedTemplate(prev => {
                                return {
                                  ...prev,
                                  removeFieldItems: !isRemoved
                                    ? [
                                        ...currentRemovedFieldItems,
                                        fi?.id
                                      ]
                                    : currentRemovedFieldItems.filter(
                                        id => id !== fi?.id
                                      )
                                }
                              })
                            }}
                          >
                            {isRemoved ? 'Restore' : 'Remove'}
                          </Button>
                        )}
                    </Flex>
                    <Box sx={{ ml: '10px' }}>
                      {fi?.allowedValues?.length > 0 &&
                        !fieldConfig.treatAsRelation && (
                          <Box
                            sx={{
                              mt: '5px'
                            }}
                          >
                            <strong>Options:</strong>
                            <ul>
                              {fi?.allowedValues?.map(av => (
                                <li>{av}</li>
                              ))}
                            </ul>
                          </Box>
                        )}
                      {!selectedTemplate?.removeFieldItems?.includes(
                        fi?.id
                      ) &&
                        fi?.autoUpdates?.length > 0 && (
                          <>
                            <strong>Pulse Connections:</strong>
                            <Box
                              sx={{
                                mt: '5px'
                              }}
                            >
                              {fi?.autoUpdates?.map(au => {
                                return (
                                  <Flex sx={{ alignItems: 'center' }}>
                                    <Input
                                      name={au?.id}
                                      id={au?.id}
                                      defaultChecked={true}
                                      checked={
                                        !selectedTemplate?.removeStatuses?.includes(
                                          au?.id
                                        )
                                      }
                                      disabled={!au?.id || !fi?.id}
                                      type="checkbox"
                                      onChange={e => {
                                        setSelectedTemplate(prev => {
                                          const currentRemovedStatuses =
                                            selectedTemplate.removeStatuses ||
                                            []
                                          return {
                                            ...prev,
                                            removeStatuses: !e?.target
                                              ?.checked
                                              ? [
                                                  ...currentRemovedStatuses,
                                                  au?.id
                                                ]
                                              : currentRemovedStatuses.filter(
                                                  id => id !== au?.id
                                                )
                                          }
                                        })
                                      }}
                                      label={` ${capitalize(
                                        au?.updatesStatus
                                          ?.statusOnNodeType
                                      )}
                              status "${au?.updatesStatus?.title}"`}
                                    />{' '}
                                  </Flex>
                                )
                              })}
                            </Box>
                          </>
                        )}
                    </Box>
                  </Box>
                )
              })}
            </Box>
          ))}
      </Box>
      <ModalFooter
        onClose={() => {
          setSelectedTemplate(null)
        }}
      >
        <Button
          disabled={mutationLoading}
          onClick={() => {
            handleFormCreate(selectedTemplate)
          }}
          variant="primary"
        >
          {mutationLoading ? 'Creating..' : 'Use This Template'}
        </Button>
      </ModalFooter>
    </Box>
  ) : (
    <>
      <Flex
        sx={{
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          textTransform: 'capitalize'
        }}
      >
        <h4>{formTypeConfig.title} Template Library</h4>{' '}
        <Button
          icon="left arrow"
          variant="link"
          type="button"
          onClick={() => onExit()}
        >
          Back
        </Button>
      </Flex>
      {opts && opts.length > 0 && (
        <Flex
          sx={{
            py: '20px',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '10px',
            flexWrap: 'wrap'
          }}
        >
          <Box sx={{ maxWidth: '220px' }}>
            <Input
              placeholder="Search"
              onChange={e => setSearch(e.target.value)}
            />
          </Box>
          <Box sx={{ width: '200px' }}>
            <FilterByTag
              sx={{ width: '100%' }}
              currentFilter={categoryFilter}
              onFilter={setCategoryFilter}
              tags={opts}
            />
          </Box>
        </Flex>
      )}

      {data.formTemplates && data.formTemplates.length > 0 ? (
        <Grid columns={[1, 1, 2, 4]} sx={{ marginTop: '10px' }}>
          {data.formTemplates
            .filter(opt1 => {
              let searched = true
              if (search) {
                searched = opt1?.name
                  ?.toLowerCase()
                  ?.includes(search?.toLowerCase())
              }
              return (
                searched &&
                (!categoryFilter?.length ||
                  opt1.tags.some(fc =>
                    categoryFilter.some(cf => fc === cf.id)
                  ))
              )
            })
            .map((ft, k) => (
              <Card key={k} onClick={() => setSelectedTemplate(ft)}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    gap: '10px',
                    p: '20px',
                    height: '100%'
                  }}
                >
                  <Flex
                    sx={{
                      width: '100%',
                      justifyContent: 'flex-start',

                      height: '30px',
                      alignItems: 'flex-start'
                    }}
                  >
                    {ft?.tags?.map(t => (
                      <Badge
                        variant="primaryLight"
                        sx={{ marginRight: 1, fontSize: '12px' }}
                      >
                        {t}
                      </Badge>
                    ))}
                  </Flex>
                  <Flex
                    sx={{
                      width: '80px',
                      height: '80px',
                      borderRadius: '20px',
                      bg: 'gray100',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    {ft.secureImageUrl ? (
                      <img
                        style={{
                          maxWidth: '60px',
                          maxHeight: '60px'
                        }}
                        alt={ft.name}
                        src={ft.secureImageUrl}
                      />
                    ) : (
                      <Icon
                        icon="blanktemplate"
                        width={30}
                        height={30}
                      />
                    )}
                  </Flex>

                  <CardTitle
                    sx={{
                      flex: 1,
                      display: 'flex',
                      alignItems: 'center',
                      gap: '5px',
                      textAlign: 'center'
                    }}
                  >
                    {ft.name}
                  </CardTitle>

                  {ft.pulseIncluded && (
                    <Flex
                      sx={{
                        p: '4px',
                        px: '8px',
                        bg: 'gray200',
                        borderRadius: '10px',
                        fontSize: '13px',
                        alignItems: 'center',
                        gap: '5px'
                      }}
                    >
                      <Icon
                        height={20}
                        width={20}
                        color="success"
                        icon="pulse"
                      />
                      Includes Pulse
                    </Flex>
                  )}
                </Box>
              </Card>
            ))}
        </Grid>
      ) : (
        <CenteredContent>
          <p>
            No {formTypeConfig.title} templates found. Please check
            back later or contact support.
          </p>
        </CenteredContent>
      )}
    </>
  )
}

const prettyType = type => {
  if (type === 'boolean') return 'Yes/No'
  return capitalize(type)
}

export default TemplateList
