/** @jsxImportSource theme-ui */

import { Fragment, useEffect, useState } from 'react'
import { Flex, Card } from 'theme-ui'
import { useQuery } from '@apollo/client'

import { GET_NOTIFCATION, GET_VERSION } from 'src/app/hooks/queries'
import config from 'src/config'
import { useSWUpdate } from 'src/utils/swManager'
import { useLocation, useNavigate } from 'react-router'
import Button from '../../Shared/Elements/Button'
import Spinner from 'src/images/icons/Spinner'

export const AlertContainer = ({ children, styles, to }) => {
  const navigate = useNavigate()
  return (
    <Flex
      sx={{
        position: 'relative',
        height: 40,
        justifyContent: 'center',
        alignItems: 'center',
        bg: 'gray300',
        color: 'text',
        flexWrap: 'wrap',
        fontWeight: 600,
        fontSize: [10, 11, 13],
        cursor: to ? 'pointer' : 'default',
        px: '45px',
        textAlign: 'center',

        ...styles
      }}
      onClick={() => {
        if (to?.includes('http')) {
          window.open(to, '_blank')
        } else {
          navigate(to)
        }
      }}
    >
      {children}
    </Flex>
  )
}

export const AgencyNotificationAlert = ({}) => {
  const [hide, setHide] = useState(false)
  const { data } = useQuery(GET_NOTIFCATION)

  const notificationMessage = data?.notificationMessage || null

  if (hide || !notificationMessage) return <Fragment />
  return (
    <AlertContainer
      key={1}
      styles={{
        bg: notificationMessage?.color || 'secondary',
        color: 'white'
      }}
      to={notificationMessage.url}
    >
      {notificationMessage.message}

      <Button
        sx={{ position: 'absolute', right: 0, zIndex: 5000 }}
        icon="close"
        iconColor="white"
        size="sm"
        variant="naked"
        onClick={e => {
          e.stopPropagation()
          setHide(true)
        }}
      />
    </AlertContainer>
  )
}

export const VersionNotificationAlert = () => {
  const { search } = useLocation()
  const [takingTooLong, setTakingTooLong] = useState(false)
  const updated = search?.includes('updated=true')

  const {
    needRefresh,
    installing,
    updateServiceWorker
  } = useSWUpdate()
  const { data, loading } = useQuery(GET_VERSION, {
    pollInterval: 1000000,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only'
  })

  const versionMisMatch =
    data?.frontendVersion !== config.frontendVersion

  const newVersion = needRefresh || versionMisMatch

  useEffect(() => {
    if (!newVersion) return

    if (takingTooLong) {
    } else {
      const timeout = setTimeout(() => {
        setTakingTooLong(true)
      }, 10000)
      return () => clearTimeout(timeout)
    }
  }, [versionMisMatch, needRefresh, takingTooLong])

  if (loading) return <Fragment />
  if (!data || !config) return <Fragment />
  if (!newVersion) return <Fragment />

  return (
    <Card
      onClick={() => updateServiceWorker(true)}
      sx={{
        bg: 'warning',
        p: 20,
        color: 'white',
        borderRadius: 5,
        position: 'fixed',
        zIndex: 1115000,
        bottom: 35,
        left: 15,
        opacity: 0.9,
        maxWidth: '380px',
        width: '100%',

        cursor: 'pointer'
      }}
    >
      {installing || updated ? (
        <>
          <Flex
            sx={{
              alignItems: 'center',
              fontSize: 16,
              gap: '5px',
              py: 10
            }}
          >
            <Spinner />
            downloading new version
          </Flex>
          <p>
            {takingTooLong
              ? 'Taking too long? Click here to force reload.'
              : 'We are downloading the new version, your browser will automatically refresh when the download is complete.'}
          </p>
        </>
      ) : (
        <>
          <h6
            sx={{
              fontWeight: 'bold'
            }}
          >
            New Version Available
          </h6>

          <p>
            Please refresh your browser to get the latest version.
          </p>
        </>
      )}
      <Button
        sx={{ mt: '20px', display: 'block', fontWeight: 'bold' }}
      >
        Click to Force Reload
      </Button>
    </Card>
  )
}
