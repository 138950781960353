import { theme } from 'src/styles'

function Pulse(props) {
  const title = props.title || 'pulse'

  return (
    <svg
      height={props.height || '48'}
      width={props.width || '48'}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{title}</title>
      <g fill={props.color || theme.colors.gray800}>
        <path
          d="M24,1A23,23,0,1,0,47,24,23.025,23.025,0,0,0,24,1ZM42,25H34.566l-5.709,9.515A1,1,0,0,1,28,35h-.038a1,1,0,0,1-.857-.552L17.92,16.076l-5.063,8.439A1,1,0,0,1,12,25H6a1,1,0,0,1,0-2h5.434l5.709-9.515A.963.963,0,0,1,18.038,13a1,1,0,0,1,.857.552L28.08,31.924l5.063-8.439A1,1,0,0,1,34,23h8a1,1,0,0,1,0,2Z"
          fill={props.color || theme.colors.gray800}
        />
      </g>
    </svg>
  )
}

export default Pulse
