function OpenBurger(props) {
  const title = props.title || "menu";

  return (
    <svg
      height={props.height}
      width={props.width}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg">
      <title>{title}</title>
      <g fill={props.color || '#111111'}>
        <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" fill={props.color || "#ffffff"} />
      </g>
    </svg>
  );
}

export default OpenBurger;