import { createContext, useState } from 'react'

import ModalFooter from './ModalFooter'
import Button from './Button'
import { useConfirm } from 'src/app/hooks/useConfirm'

import Modal from './Modal'

export const ConfirmContext = createContext()

export const ConfirmContextProvider = ({ children }) => {
  const [confirm, setConfirm] = useState({
    prompt: '',
    isOpen: false,
    proceed: null,
    cancel: null
  })

  return (
    <ConfirmContext.Provider value={[confirm, setConfirm]}>
      {children}
    </ConfirmContext.Provider>
  )
}

const ConfirmModal = () => {
  const {
    prompt = '',
    secondaryActionText,
    isOpen = false,
    acceptButtonText,
    proceed,
    cancel
  } = useConfirm()
  return (
    <Modal toggle={cancel} modalHeader={prompt} isOpen={isOpen} fade>
      <ModalFooter onClose={!secondaryActionText && cancel}>
        {secondaryActionText && (
          <Button variant="linkGray" onClick={() => proceed(1)}>
            {secondaryActionText}
          </Button>
        )}
        <Button variant="primary" onClick={proceed}>
          {acceptButtonText || "Yes, I'm sure"}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ConfirmModal
