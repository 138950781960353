import DatePicker from 'react-datepicker'

import './styles.css'
import { Space } from '../..'
import moment from 'moment'
import Button from '../../Shared/Elements/Button'
import Input from '../../Shared/Elements/Input'
import { css, Global } from '@emotion/react'
import { Flex } from 'theme-ui'

const DatePickerInput = ({
  value,
  showTimeSelect = true,
  todaySelect = true,
  todaySelectCustom,
  todaySelectText,
  ...rest
}) => {
  const isValid = value && moment(value).isValid()
  const dateFormat =
    window.navigator.language !== 'en-US'
      ? 'dd/MM/yyyy'
      : 'MM/dd/yyyy'

  return (
    <>
      <DatePicker
        autoComplete="off"
        showTimeSelect={showTimeSelect}
        timeFormat="HH:mm"
        timeIntervals={15}
        timeCaption="time"
        dateFormat={dateFormat + (showTimeSelect ? ' HH:mm' : '')}
        {...rest}
        selected={isValid && new Date(value)}
        customInput={<Input autoComplete="off" />}
      />

      {showTimeSelect && (
        <Global
          styles={css`
            .react-datepicker {
              min-width: 328px;
            }
          `}
        />
      )}
      {todaySelect && (
        <Flex sx={{ width: '100%', justifyContent: 'flex-end' }}>
          <Button
            size="sm"
            sx={{ marginTop: '5px', padding: '2px', py: '0px' }}
            variant="warning"
            onClick={() => {
              todaySelectCustom
                ? todaySelectCustom()
                : rest?.onChange(new Date())
            }}
          >
            {todaySelectText || 'Now'}
          </Button>
        </Flex>
      )}
    </>
  )
}

export const DateRangePicker = ({
  onChange,
  value,
  showTimeSelect,
  ...rest
}) => {
  const startDate = value.startDate && new Date(value.startDate)
  const endDate = value.endDate && new Date(value.endDate)
  const dateFormat =
    window.navigator.language !== 'en-US'
      ? 'dd/MM/yyyy'
      : 'MM/dd/yyyy'

  return [
    <DatePicker
      key={1}
      selected={startDate}
      onChange={date => {
        const valid = moment(date).isValid()
        onChange({
          endDate,
          startDate: valid
            ? new Date(
                showTimeSelect
                  ? moment(date)
                  : moment(date).startOf('day')
              )
            : undefined
        })
      }}
      selectsStart
      placeholderText="Select an start date"
      startDate={startDate}
      endDate={endDate}
      customInput={<Input autoComplete="off" />}
      showTimeSelect={showTimeSelect}
      {...rest}
      timeFormat="HH:mm"
      maxDate={rest.minDate || endDate}
      autoComplete="off"
      todayButton="Today"
      dateFormat={dateFormat + (showTimeSelect ? ' HH:mm' : '')}
    />,
    <Space width={10} key={2} />,
    <DatePicker
      key={3}
      selected={endDate}
      todayButton="Today"
      timeFormat="HH:mm"
      onChange={date => {
        const valid = moment(date).isValid()
        const endOfDay = !endDate
          ? moment(date).endOf('day')
          : moment(date)

        onChange({
          startDate,
          endDate: valid ? new Date(endOfDay) : undefined
        })
      }}
      showTimeSelect={showTimeSelect}
      selectsEnd
      startDate={startDate}
      endDate={endDate}
      customInput={<Input autoComplete="off" />}
      placeholderText="Select an end date"
      {...rest}
      minDate={rest.minDate || startDate}
      autoComplete="off"
      dateFormat={dateFormat + (showTimeSelect ? ' HH:mm' : '')}
    />
  ]
}

export default DatePickerInput
