import { useEffect } from 'react'
import { Space } from '..'
import FormGroup from 'src/app/components/Shared/Elements/FormGroup'
import { cleanGraphQLError } from '../../components/Forms/FormError'
import { useToast } from 'src/components/toasts'
import Spinner from 'src/images/icons/Spinner'
import Form from '../Shared/Elements/Form'
import Label from '../Shared/Elements/Label'
import PhoneNumberInput from '../Shared/Forms/PhoneInput'

import Button from '../Shared/Elements/Button'
import useForm from 'src/app/hooks/useForms'
import ModalFooter from '../Shared/Elements/ModalFooter'

const MFAEnableForm = ({ phone, setupMfa }) => {
  const { add } = useToast()
  const {
    values: { phone: p },

    setValues,
    handleSubmit
  } = useForm(onEnroll, {
    phone: phone
  })

  useEffect(() => {
    setValues({ phone: phone })
  }, [phone])

  async function onEnroll() {
    setupMfa.variables.phoneNumber = p
    const res = await setupMfa.fn({ variables: setupMfa.variables })
    if (!res.errors) {
      add({
        content: 'Enrolled successfully.',
        color: 'success'
      })
      await setupMfa.cb(res)
    } else {
      add({
        content: cleanGraphQLError(res.errors[0]),
        color: 'danger'
      })
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <h3>
        Your organization requires Multi-Factor Auth{' '}
        {setupMfa.loader && <Spinner />}
      </h3>
      <p>Enter the phone number you would like to use</p>
      <Space height={15} />

      <FormGroup>
        <Label>Phone Number</Label>
        <PhoneNumberInput
          type="text"
          name="phoneNumber"
          onChange={val => {
            setValues({ phone: val })
          }}
          value={p}
          required
        />
      </FormGroup>
      <ModalFooter>
        <Button disabled={setupMfa.loader} type="submit">
          Enroll in MFA
        </Button>
      </ModalFooter>
    </Form>
  )
}

export default MFAEnableForm
