import { useContext, useState } from 'react'
import { useMutation } from '@apollo/client'

import { cloneDeep } from '@apollo/client/utilities'
import {
  ARCHIVE_SUBMISSION,
  COMPLETE_ALL_DISPATCH
} from 'src/app/hooks/mutations'
import ModalButton from '../Shared/ModalButton'

import { Flex, Box } from 'theme-ui'
import Spinner from 'src/images/icons/Spinner'
import { DISPATCH_SCREEN_DATA } from 'src/app/hooks/queries'
import { cleanGraphQLError } from '../Forms/FormError'
import { useToast } from 'src/components/toasts'
import Button from '../Shared/Elements/Button'
import Icon from '../Shared/Icon'

import { RevertAllDispatchButton } from './RevertDispatchCloseButtons'
import { SubmissionModalContext } from '../Shared/SubmissionModalLayout'
import useGetNoteWriterInfo from 'src/app/hooks/useGetNoteWriterInfo'
import { useLocation } from 'react-router'

export function getClosePlusLocationState({
  hasUnit,
  onFinish,
  submissionId,
  ...rest
}) {
  return {
    extendedQuickSurvey: true,
    useTodaysPosition: false,
    dispatchSubmissionId: submissionId,
    hideFields: hasUnit ? ['missingUnit'] : [],
    stickySubmit: true,

    modalContentProps: {
      overflow: 'auto'
    },
    modalProps: {
      height: '90vh',
      shouldCloseOnEsc: false,
      customCloseButton: (
        <RevertAllDispatchButton
          submissionId={submissionId}
          onFinish={onFinish}
        />
      )
    },
    ...rest
  }
}

const ActiveDispatchSubmissionsTableActions = ({
  submissionId,
  hasType,
  hasUnit
}) => {
  const location = useLocation()
  const noteWriterInfo = useGetNoteWriterInfo()
  const modalContext = useContext(SubmissionModalContext)

  const showSubmissionModal = modalContext?.showSubmissionModal
  const [completingLoading, setCompletingLoading] = useState(false)
  const { add } = useToast()
  const [completeAllDispatchesBySubmission] = useMutation(
    COMPLETE_ALL_DISPATCH,
    {
      variables: {
        submissionId
      },
      update: (
        store,
        { data: { completeAllDispatchesBySubmission } }
      ) => {
        // Read the data from our cache for this query.
        const data = cloneDeep(
          store.readQuery({
            query: DISPATCH_SCREEN_DATA
          })
        )

        if (!data?.myAgenciesSubmissionsWithActiveDispatches) return

        data.myAgenciesSubmissionsWithActiveDispatches = data.myAgenciesSubmissionsWithActiveDispatches.filter(
          d =>
            d?.id !==
            (completeAllDispatchesBySubmission?.id || submissionId)
        )

        // Write our data back to the cache.
        store.writeQuery({
          query: DISPATCH_SCREEN_DATA,
          data: { ...data }
        })
      }
    }
  )

  const [archiveOneSubmission, { loading: archiving }] = useMutation(
    ARCHIVE_SUBMISSION,
    {
      variables: {
        id: submissionId
      },
      //Update cache...
      update: (store, { data: { archiveOneSubmission } }) => {
        // Read the data from our cache for this query.
        const data = cloneDeep(
          store.readQuery({
            query: DISPATCH_SCREEN_DATA
          })
        )

        if (!data?.myAgenciesSubmissionsWithActiveDispatches) return

        data.myAgenciesSubmissionsWithActiveDispatches = data.myAgenciesSubmissionsWithActiveDispatches.filter(
          d => d.id !== archiveOneSubmission?.id
        )

        // Write our data back to the cache.
        store.writeQuery({
          query: DISPATCH_SCREEN_DATA,
          data: { ...data }
        })
      }
    }
  )

  async function closeCall(loadingIndex) {
    setCompletingLoading(loadingIndex)
    await completeAllDispatchesBySubmission().catch(e => {
      add({
        color: 'danger',
        content: cleanGraphQLError(e)
      })
    })
    return setCompletingLoading(false)
  }

  return (
    <Flex
      sx={{
        justifyContent: 'flex-end',
        flexWrap: 'wrap',
        gap: '4px'
      }}
    >
      <Box sx={{ flex: '1 1 55px', minWidth: '55px' }}>
        <Button
          block
          disabled={completingLoading === 0}
          onClick={async () => {
            try {
              await closeCall(0)
              showSubmissionModal({
                id: submissionId,

                ...getClosePlusLocationState({
                  submissionId,
                  location,
                  hasUnit,
                  onFinish: () => showSubmissionModal(null)
                }),
                ...noteWriterInfo
              })
            } catch (e) {
              setCompletingLoading(false)
              add({
                color: 'danger',
                content: cleanGraphQLError(e)
              })
            }
          }}
          variant="danger"
          title="Close +"
        >
          {completingLoading === 0 ? <Spinner white /> : 'C+'}
        </Button>
      </Box>
      <Box sx={{ flex: '1 1 55px', minWidth: '55px' }}>
        {hasType ? (
          <Button
            onClick={async () => {
              try {
                await closeCall(1)
              } catch (e) {
                setCompletingLoading(false)
                add({
                  color: 'danger',
                  content: cleanGraphQLError(e)
                })
              }
            }}
            title="Quick Close"
            block
            variant="danger"
            disabled={completingLoading === 1}
          >
            {completingLoading === 1 ? (
              <Spinner height={15} white />
            ) : (
              'C'
            )}
          </Button>
        ) : (
          <ModalButton
            buttonLabel={
              completingLoading === 1 ? (
                <Spinner height={15} white />
              ) : (
                'C'
              )
            }
            buttonProps={{
              title: 'Quick Close',
              block: true,
              variant: 'danger'
            }}
            renderModalActions={toggle => [
              <Button
                key={2}
                variant="danger"
                disabled={completingLoading === 1}
                onClick={() => {
                  closeCall(1)
                  toggle()
                }}
              >
                {completingLoading === 1 ? (
                  <Spinner height={15} white />
                ) : (
                  'Yes, Close the Call'
                )}
              </Button>
            ]}
          >
            {() => {
              return (
                <h4>
                  This call is missing an incident type. Are you sure
                  you want to continue with it unknown?
                </h4>
              )
            }}
          </ModalButton>
        )}
      </Box>
      <Box sx={{ flex: '1 1 55px', minWidth: '55px' }}>
        <ModalButton
          buttonProps={{
            variant: 'secondary',
            block: true,
            title: 'Cancel + Archive',
            sx: {
              display: 'flex'
            }
          }}
          localModalPosition="left"
          buttonLabel={
            <Icon icon="close" color="white" height={15} width={15} />
          }
          modalHeader="Are you sure you want to cancel this call for service?"
          renderModalActions={toggle => [
            <Button
              onClick={async () => {
                await archiveOneSubmission()
                toggle()
              }}
              disabled={archiving}
              variant="danger"
            >
              {archiving ? (
                <Spinner height={15} white />
              ) : (
                'Yes, cancel and archive'
              )}
            </Button>
          ]}
        />
      </Box>
    </Flex>
  )
}

export default ActiveDispatchSubmissionsTableActions
