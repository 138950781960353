import { useEffect, useState } from 'react'

const eventMapping = {
  onClick: 'click',
  onDoubleClick: 'dblclick',
  onDragEnd: 'dragend'
}

export default function useGoogleMapMarker({
  position,
  type,
  maps,
  map,
  events,
  title,
  markerSettings
}) {
  const [marker, setMarker] = useState()
  useEffect(() => {
    // const styles = markerStyle(type);
    const marker = new maps.Marker({
      position,
      map,
      title,
      ...markerSettings
    })

    Object.keys(events).forEach(eventName => {
      marker.addListener(eventMapping[eventName], () =>
        events[eventName](marker)
      )
    })
    setMarker(marker)
    // eslint-disable-next-line
  }, [])

  return marker
}
