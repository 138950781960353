import { Fragment } from 'react'
import { Flex } from 'theme-ui'
import ToolTip from '../Shared/Elements/ToolTip'

import Icon from '../Shared/Icon'

export const BulletinUrgency = [
  {
    label: 'Low',
    value: 'LOW',
    color: 'successLight',
    darkColor: 'success',
    isPublic: true
  },
  {
    label: 'Medium',
    value: 'MEDIUM',
    color: 'yellowLight',
    darkColor: 'yellow',
    isPublic: true
  },
  {
    label: 'High',
    value: 'HIGH',
    color: 'orangeLight',
    darkColor: 'orange',
    isPublic: true
  },
  {
    label: 'Critical',
    value: 'CRITICAL',
    color: 'dangerLight',
    darkColor: 'danger'
  }
]

export const PrivateIcon = ({ isPrivate, ...props }) => {
  if (!isPrivate) return <Fragment />
  const randomId = Math.random()

  return (
    <>
      <ToolTip place="right" target={'isPrivate' + randomId}>
        Private Bulletin
      </ToolTip>
      <Icon
        width={'15px'}
        height={'15px'}
        icon="lock"
        data-tip
        data-for={'isPrivate' + randomId}
        title={' '}
        noTitle
        {...props}
      />
    </>
  )
}

export const UrgencyIcon = ({ urgency, ...props }) => {
  const bulletinUrgency = BulletinUrgency.find(
    b => b.value === urgency
  )

  if (!bulletinUrgency) return <Fragment />
  const randomId = Math.random()

  return (
    <>
      <ToolTip place="right" target={'UrgencyIcon' + randomId}>
        {bulletinUrgency.label} Urgency
      </ToolTip>
      <Icon
        width={'20px'}
        height={'20px'}
        icon="priority"
        data-tip
        data-for={'UrgencyIcon' + randomId}
        title={' '}
        noTitle
        color={bulletinUrgency.darkColor}
        {...props}
      />
    </>
  )
}

export const UrgencyIconButtons = ({
  urgency,
  onClick,
  activeValue,
  ...props
}) => {
  return (
    <Flex sx={{ gap: '5px' }}>
      {BulletinUrgency.map(bu => (
        <Icon
          width={'20px'}
          height={'20px'}
          icon="priority"
          title={bu.label}
          color={bu.color}
          onClick={() => onClick(bu.value)}
          style={{
            cursor: 'pointer',
            opacity: !activeValue
              ? 1
              : activeValue.includes(bu.value)
              ? 1
              : 0.5
          }}
          {...props}
        />
      ))}
    </Flex>
  )
}
