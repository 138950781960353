import { useEffect, useState } from 'react'

function useOnScreen(ref, rootMargin = '0px') {
  const [isIntersecting, setIntersecting] = useState(false)

  // <-- variable to hold ref value
  // State and setter for storing whether element is visible

  useEffect(() => {
    const callbackFunction = entries => {
      const [entry] = entries
      setIntersecting(entry.isIntersecting)
    }

    let observerRefValue = null

    const observer = new IntersectionObserver(callbackFunction, {
      rootMargin
    })
    if (ref.current) {
      observer.observe(ref.current)
      observerRefValue = ref.current
    }
    return () => {
      if (observerRefValue) observer.unobserve(observerRefValue) //
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // Empty array ensures that effect is only run on mount and unmount
  return isIntersecting
}

export default useOnScreen
