/** @jsxImportSource theme-ui */
import { forwardRef } from 'react'
import { Box } from 'theme-ui'

const InputGroupText = (
  { children, variant = null, sx, ...props },
  ref
) => {
  return (
    <Box
      as="small"
      variant={variant}
      {...props}
      sx={{
        display: 'block',
        marginTop: '0.25rem',
        color: 'gray600',
        ...sx
      }}
    >
      {children}
    </Box>
  )
}

export default forwardRef(InputGroupText)
