/** @jsxImportSource theme-ui */
import { forwardRef, useState } from 'react'

import {
  Input as ThemeUIInput,
  Select,
  Textarea,
  Checkbox,
  Label,
  Box
} from 'theme-ui'
import PasswordError from '../../PasswordError'

import Button from './Button'
import CharacterCount from './CharacterCount'

const Input = (
  {
    variant = 'input.primary',
    children,
    disabled,
    type = 'text',
    required,
    id,
    name,
    placeholder,
    value,
    defaultValue,
    onChange,
    checked,
    label,
    step,
    onClick,
    showCharacterCount = false,
    maxLength = null,
    withExpandedView,
    expandedViewRich,
    ...rest
  },
  ref
) => {
  let props = {
    variant: variant,
    disabled: disabled,
    type: type,
    sx: { ...rest.sx, ...rest.style },
    value: value,
    placeholder: placeholder,
    onChange: onChange,
    defaultValue: defaultValue,
    id: id,
    name: name,
    checked: checked,
    step: step,
    onClick: onClick,
    maxLength: maxLength,
    ...rest
  }

  if (type === 'select') {
    const style = {
      ...props?.style,
      width: '100%'
    }
    return (
      <Select {...props} style={style}>
        {children}
      </Select>
    )
  }
  if (type === 'textarea') {
    return (
      <Box
        sx={{
          position: 'relative',
          width: '100%'
        }}
      >
        <Textarea {...props} />

        {showCharacterCount && maxLength && value.length > 0 && (
          <CharacterCount
            maxCharacters={maxLength}
            currentLength={value.length}
          />
        )}
      </Box>
    )
  }
  if (type === 'checkbox') {
    return (
      <Label
        check
        htmlFor={id}
        sx={{
          margin: '5px 0',
          display: 'flex',
          alignItems: 'center',
          padding: '0px 0px 0px 20px',
          color: disabled && 'gray500',
          ...rest.sx
        }}
      >
        <Checkbox
          {...props}
          disabled={disabled}
          variant={null}
          sx={{
            marginLeft: '-20px',
            opacity: disabled && 0.5
          }}
        />
        {label}
      </Label>
    )
  }
  if (type === 'text') {
    return (
      <Box
        sx={{
          position: 'relative',
          width: '100%'
        }}
      >
        <ThemeUIInput
          {...props}
          sx={{ width: '100%' }}
          onWheel={e => e.target.blur()}
        />

        {showCharacterCount && maxLength && value.length > 0 && (
          <CharacterCount
            maxCharacters={maxLength}
            currentLength={value.length}
          />
        )}
      </Box>
    )
  }
  return <ThemeUIInput {...props} onWheel={e => e.target.blur()} />
}

export default forwardRef(Input)

export const PasswordInput = ({
  onSuccess,
  passwordStrengthCheck,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false)
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%'
      }}
    >
      <Input
        {...props}
        type={showPassword ? 'text' : 'password'}
        sx={{
          paddingRight: '40px'
        }}
      />
      <Button
        variant="naked"
        sx={{
          position: 'absolute',
          right: '10px',
          top: '12px',

          padding: '0px'
        }}
        type="button"
        onClick={() => setShowPassword(!showPassword)}
      >
        {showPassword ? 'hide' : 'show'}
      </Button>
      {passwordStrengthCheck && (
        <PasswordError onSuccess={onSuccess} input={props.value} />
      )}
    </Box>
  )
}
