export default ({ height, width, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width={width}
      height={height}
    >
      <title>support 2</title>
      <g className="nc-icon-wrapper" fill={color || '#000000'}>
        <path
          d="M53.211,10.806A29.516,29.516,0,0,0,32,2a29.516,29.516,0,0,0-21.211,8.806,29.948,29.948,0,0,0,0,42.388A29.516,29.516,0,0,0,32,62a29.516,29.516,0,0,0,21.211-8.806A29.948,29.948,0,0,0,53.211,10.806ZM36.9,44h0a13.114,13.114,0,0,1-9.8.1h0a11.3,11.3,0,0,1-4.3-2.9,13.037,13.037,0,0,1,0-18.4,13.6,13.6,0,0,1,4.3-2.9h0A13.326,13.326,0,0,1,32,19a11.621,11.621,0,0,1,4.9,1h0a11.3,11.3,0,0,1,4.3,2.9A12.771,12.771,0,0,1,45,32.1a13.19,13.19,0,0,1-3.8,9.2A14.85,14.85,0,0,1,36.9,44ZM54.4,15.9,45.3,25A15.91,15.91,0,0,0,39,18.7l9.1-9.1a28.242,28.242,0,0,1,6.3,6.3ZM12.5,12.5a30.705,30.705,0,0,1,3.4-2.9L25,18.7A15.91,15.91,0,0,0,18.7,25L9.6,15.9A30.705,30.705,0,0,1,12.5,12.5ZM9.6,48.1,18.7,39A15.91,15.91,0,0,0,25,45.3l-9.1,9.1a28.242,28.242,0,0,1-6.3-6.3Zm41.9,3.4a30.705,30.705,0,0,1-3.4,2.9L39,45.3A15.91,15.91,0,0,0,45.3,39l9.1,9.1A30.705,30.705,0,0,1,51.5,51.5Z"
          fill={color || '#000000'}
        />
      </g>
    </svg>
  )
};
