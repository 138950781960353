import styled from '@emotion/styled'
import { ToastContainer, cssTransition } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// Provider
// ==============================

const CustomStyles = styled.div`
  .Toastify__toast--success {
    background: ${p =>
      p.theme.colors
        .success} !important; /* Your desired success background color */
    color: #fff !important; /* Your desired success text color */
  }

  .Toastify__toast--info {
    background: ${p =>
      p.theme.colors
        .info} !important; /* Your desired info background color */
    color: #fff !important; /* Your desired info text color */
  }

  .Toastify__toast--error {
    background: ${p =>
      p.theme.colors
        .danger} !important; /* Your desired error background color */
    color: #fff !important; /* Your desired error text color */
  }

  .Toastify__toast--warning {
    background: ${p =>
      p.theme.colors
        .warning} !important; /* Your desired warning background color */
    color: #fff !important; /* Your desired warning text color */
  }
`

export const ToastProvider = ({ children }) => {
  return (
    <CustomStyles>
      {children}
      <ToastContainer
        style={{
          zIndex: 50000000
        }}
        autoClose={3000}
        toastStyle={{
          minHeight: 'auto',
          borderRadius: '0.45rem',
          fontFamily: `'Arial', Monaco, Consolas, 'Liberation Mono'`
        }}
        theme="colored"
      />
    </CustomStyles>
  )
}
