import { gql, useQuery } from '@apollo/client'
import CustomSelectInput from '../../Forms/CustomSelectInput'

import stripHtml from 'src/utils/stripHtml'
import { getFieldConfig } from '../../Operations/Forms/formBuilder/dataMapping'
import Label from '../../Shared/Elements/Label'
import { Box } from 'theme-ui'

export const GET_FIELD_ITEM_OPTIONS = gql`
  query GetFormFieldItemOptions($where: FormWhereUniqueInput!) {
    form(where: $where) {
      id
      fields(where: { archived: { equals: false } }) {
        id
        title
        fieldItems(where: { archived: { equals: false } }) {
          id
          type
          helpText
          allowOther
          allowMultipleAnswers
          valueTypeFormat
          allowedValues
        }
      }
    }
  }
`

const FieldItemSelect = ({
  onFieldItemSelect,
  formId,
  value,
  filtersOnly,
  excludeOptions,
  includeLabel,
  ...props
}) => {
  const { data, loading } = useQuery(GET_FIELD_ITEM_OPTIONS, {
    variables: {
      where: { id: formId }
    }
  })

  return !data || loading ? (
    'Loading options...'
  ) : (
    <Box {...props}>
      {includeLabel && <Label>{includeLabel || 'Question'}</Label>}

      <CustomSelectInput
        id={`fieldItems_${formId}`}
        placeholder="Select a question "
        isMulti={true}
        getOptionValue={v => v.id}
        getOptionLabel={v => v.name}
        isClearable={true}
        defaultValue={[]}
        value={value}
        onChange={selected => {
          onFieldItemSelect(selected)
        }}
        isOptionDisabled={option => {
          const fieldConfig = getFieldConfig(option)
          if (
            filtersOnly
              ? !fieldConfig?.filterable
              : !fieldConfig?.trendable
          ) {
            return true
          }

          return (
            value &&
            (value.id
              ? value.id === option.id
              : value.some(fi => fi.id === option.id))
          )
        }}
        options={data.form.fields.map((field, k) => ({
          id: field.id,

          label: `${k + 1}. ${stripHtml(field.title)}`,
          options: field.fieldItems
            .filter(fi => !excludeOptions?.includes(fi.id))
            .map((fieldItem, i) => {
              const fieldConfig = getFieldConfig(fieldItem)

              const title = `${k + 1}.${i + 1}. ${
                fieldConfig.title
              } ${
                fieldItem.helpText ? `(${fieldItem.helpText})` : ''
              } ${
                fieldItem.allowMultipleAnswers ? `(Multi-select)` : ''
              }`

              return {
                ...fieldItem,
                name: title
              }
            })
        }))}
        {...props}
      />
    </Box>
  )
}

export default FieldItemSelect
