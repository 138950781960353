function Share(props) {
  const title = props.title || 'Share'

  return (
    <svg
      height={props.height || '64'}
      width={props.width || '64'}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill={props.color || '#F7F7F7'}>
        <path
          d="M60.644,4.234a1,1,0,0,0-1.009-.165l-56,22a1,1,0,0,0-.214,1.746l9.042,6.43,22.87-10.592L17,37.472V59a1,1,0,0,0,1.606.8L32.986,48.84,48.421,59.815A.992.992,0,0,0,49,60a1.016,1.016,0,0,0,.367-.069,1,1,0,0,0,.612-.732l11-54A1,1,0,0,0,60.644,4.234Z"
          fill={props.color || '#F7F7F7'}
        />
      </g>
    </svg>
  )
}

export default Share
