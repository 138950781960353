function Operations(props) {
  const title = props.title || 'Operations'

  return (
    <svg
      height={props.height || '48'}
      width={props.width || '48'}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g>
        <path
          d="M41,3H7C5.895,3,5,3.895,5,5v40c0,1.105,0.895,2,2,2h34c1.105,0,2-0.895,2-2V5C43,3.895,42.105,3,41,3z"
          fill="#A67C52"
        />
        <path
          d="M37,42H11c-0.552,0-1-0.448-1-1V9c0-0.552,0.448-1,1-1h26c0.552,0,1,0.448,1,1v32C38,41.552,37.552,42,37,42 z"
          fill="#FFFFFF"
        />
        <path
          d="M28,5c0-2.209-1.791-4-4-4s-4,1.791-4,4h-3c-0.552,0-1,0.448-1,1v4c0,0.552,0.448,1,1,1h14 c0.552,0,1-0.448,1-1V6c0-0.552-0.448-1-1-1H28z"
          fill="#EFD358"
        />
        <path
          d="M22,31c-0.256,0-0.512-0.098-0.707-0.293l-4-4c-0.391-0.391-0.391-1.023,0-1.414s1.023-0.391,1.414,0 L22,28.586l7.293-7.293c0.391-0.391,1.023-0.391,1.414,0s0.391,1.023,0,1.414l-8,8C22.512,30.902,22.256,31,22,31z"
          fill="#72C472"
        />
        <circle cx="24" cy="5" fill="#B29930" r="1" />
      </g>
    </svg>
  )
}

export default Operations
