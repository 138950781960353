import { Box } from 'theme-ui'
import Button from './Elements/Button'

export const StickyButton = ({
  stickySubmit,
  children,
  preButtonText,
  ...rest
}) => {
  let stickyStyles

  if (stickySubmit) {
    stickyStyles = {
      backgroundColor: 'background',
      right: '0px',
      padding: 10,
      left: '0px',
      cursor: 'pointer',
      zIndex: 500,
      position: 'fixed',
      bottom: '0px'
    }
  }

  return (
    <Box sx={{ width: '100%', ...stickyStyles }}>
      {preButtonText}
      <Button
        variant="primary"
        sx={{
          maxWidth: '90vw',
          margin: 'auto',
          zIndex: 100000,
          height: '50px',
          cursor: 'pointer'
        }}
        block
        type="button"
        {...rest}
      >
        {children}
      </Button>
    </Box>
  )
}

export default StickyButton
