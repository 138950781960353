/** @jsxImportSource theme-ui */

import Button from '../../Shared/Elements/Button'
import { useConfirm } from 'src/app/hooks/useConfirm'

export const MatchFieldItemAllowedToStatusTemplate = ({
  fieldItem,
  newOptions,
  onSync
}) => {
  const { isConfirmed } = useConfirm()

  return (
    <Button
      onClick={async () => {
        const confirm = await isConfirmed(
          `You are about to change the options for "${fieldItem?.helpText ||
            fieldItem.title}" to "${newOptions.join(
            ', '
          )}".  Are you sure you want to do this?`
        )

        if (!confirm) return

        await onSync(newOptions)
      }}
      variant="danger"
      size="sm"
      sx={{ ml: 10 }}
    >
      Click to Sync
    </Button>
  )
}

export default MatchFieldItemAllowedToStatusTemplate
