import { useEffect, useState, useRef, useContext } from 'react'
import { GoogleMapsContext } from '../../components/layout'

const eventsMapping = {
  onCenterChanged: ['center_changed', map => map.getCenter()],
  onBoundsChanged: ['bounds_changed', map => map.getBounds()]
}

export default function useGoogleMap({ zoom, center, events }) {
  const [mapState, setMapState] = useState({ loading: true })
  const mapRef = useRef()
  const { googleMaps } = useContext(GoogleMapsContext)

  useEffect(() => {
    if (googleMaps) {
      const map = new googleMaps.maps.Map(mapRef.current, {
        zoom,
        center
      })
      Object.keys(events).forEach(eventName =>
        map.addListener(eventsMapping[eventName][0], () =>
          events[eventName](eventsMapping[eventName][1](map))
        )
      )

      setMapState({ maps: googleMaps.maps, map, loading: false })
    }
    // eslint-disable-next-line
  }, [googleMaps])
  return { mapRef, ...mapState }
}
