/** @jsxImportSource theme-ui */
import { forwardRef } from 'react'
import { Text } from 'theme-ui'

const CardTitle = ({ children, ...props }, ref) => {
  props.sx = {
    marginBottom: '0.75rem'
  }
  return (
    <Text as="h5" {...props}>
      {children}
    </Text>
  )
}

export default forwardRef(CardTitle)
