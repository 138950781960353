import React, { Fragment } from 'react'
import Modal from '../Shared/Elements/Modal'
import ModalFooter from '../Shared/Elements/ModalFooter'
import ModalBody from '../Shared/Elements/ModalBody'
import Button from '../Shared/Elements/Button'

export const SessionTimeoutPopup = ({ onLoginAgain, isOpen }) => {
  return (
    <Modal
      isOpen={isOpen}
      height={200}
      width={350}
      size={350}
      modalHeader="Session Expired / Unauthorized"
      customCloseButton={<Fragment />}
    >
      <ModalBody
        style={{
          height: '100%'
        }}
      >
        <p>
          Oops, looks like you viewed something you don't have access
          to or your session expired.
        </p>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onLoginAgain}>Click here to re-login</Button>
      </ModalFooter>
    </Modal>
  )
}
