import { useNavigate } from 'react-router'
import { CenteredContent } from '../components'
import Button from '../components/Shared/Elements/Button'
import Icon from '../components/Shared/Icon'

const Unauthorized = () => {
  const navigate = useNavigate()
  return (
    <CenteredContent>
      <Icon icon="lost" />
      <h3 style={{ paddingTop: 35, textAlign: 'center' }}>
        Looks like you are not allowed to view this content.
      </h3>
      <Button variant="link" onClick={() => navigate('/agency')}>
        Let's get you back to safety
      </Button>
    </CenteredContent>
  )
}

export default Unauthorized
