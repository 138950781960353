/** @jsxImportSource theme-ui */

import { useEffect, useState, Fragment } from 'react'

import { Box, Flex } from 'theme-ui'
import ListGroupItem from './Elements/ListGroupItem'
import Collapse from './Elements/Collapse'
import Icon from './Icon'

const CollapsablePanel = ({
  containerStyle,
  buttonOptions = {},
  hideDropdown,
  open = false,
  maxHeight,
  defaultOpen,
  children
}) => {
  const [isOpen, setIsOpen] = useState(open || defaultOpen)

  useEffect(() => {
    if (!defaultOpen) {
      setIsOpen(open)
    }
  }, [open])

  const toggle = () => {
    if (hideDropdown) return
    setIsOpen(!isOpen)
  }

  return (
    <div style={containerStyle}>
      <ListGroupItem
        sx={{
          ...buttonOptions.style,
          textAlign: 'left',
          gap: '10px',
          cursor: 'pointer',
          border: 'none',
          borderRadius: '0px !important',
          borderBottom: buttonOptions.hide
            ? 'none'
            : '1px solid #e5e5e5'
        }}
      >
        {!buttonOptions.hide && (
          <Flex
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
            onClick={toggle}
          >
            {buttonOptions.text ? buttonOptions.text : 'Open'}

            {hideDropdown ? (
              <Fragment />
            ) : isOpen ? (
              <Box
                sx={{
                  fontWeight: 'bold',
                  fontSize: '20px',
                  lineHeight: '20px'
                }}
              >
                {buttonOptions?.closeIcon ? (
                  <Icon
                    height={10}
                    width={10}
                    icon={buttonOptions?.closeIcon}
                  />
                ) : (
                  '-'
                )}
              </Box>
            ) : (
              <Box
                sx={{
                  fontWeight: 'bold',
                  fontSize: '20px',
                  lineHeight: '20px'
                }}
              >
                {buttonOptions?.openIcon ? (
                  <Icon
                    height={10}
                    width={10}
                    icon={buttonOptions?.openIcon}
                  />
                ) : (
                  '+'
                )}
              </Box>
            )}
          </Flex>
        )}

        <Box>
          <Collapse maxHeight={maxHeight} isOpen={isOpen}>
            {isOpen && (
              <Box sx={{ pt: 20 }}>{children({ toggle })}</Box>
            )}
          </Collapse>
        </Box>
      </ListGroupItem>
    </div>
  )
}

export default CollapsablePanel
