import isEmpty from 'lodash/isEmpty'
import { Box, Flex } from 'theme-ui'
import CustomRadioButtonsInput from '../Forms/CustomRadioButtonsInput'
import ModalButton from '../Shared/ModalButton'
import Person from 'src/images/icons/Person.jsx'
import DemographicDataInputs from '../Forms/DemographicDataInputs'
import Button from '../Shared/Elements/Button'

const RelatedIncidentPad = ({
  quickStatInfo,
  handleChange,
  values,
  handleSelect,
  nonDeletedIP,
  involvedPartyLabel,
  hideInvolvedParty,
  setValues,
  numPadTitle
}) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      {numPadTitle && (
        <Box sx={{ width: '100%', display: 'block', mb: '10px' }}>
          {numPadTitle}
        </Box>
      )}
      <CustomRadioButtonsInput
        id={'quickStats' + quickStatInfo.label}
        type="number"
        name={'quickStats' + quickStatInfo.label}
        placeholder="Number"
        onChange={handleChange}
        value={
          values['quickStats' + quickStatInfo.label] || undefined
        }
        handleSelect={handleSelect}
        options="numberPad"
        noZero={true}
        minValue={0}
        withInput={true}
        actionButton={
          !hideInvolvedParty && (
            <ModalButton
              buttonProps={{
                size: 'lg',
                sx: {
                  margin: '5px'
                },
                variant:
                  nonDeletedIP && !isEmpty(nonDeletedIP)
                    ? 'primary'
                    : 'secondary'
              }}
              buttonLabel={
                <Flex
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: 1
                  }}
                >
                  <Person
                    color="white"
                    height={15}
                    width={15}
                    style={{ marginRight: '3px' }}
                  />
                  {nonDeletedIP && !isEmpty(nonDeletedIP)
                    ? ` (${nonDeletedIP.length})`
                    : ' +'}
                </Flex>
              }
              renderModalActions={toggle => (
                <Button onClick={toggle} variant="primary">
                  Done
                </Button>
              )}
            >
              {() => (
                <>
                  <DemographicDataInputs
                    partyCount={
                      (values['quickStats' + quickStatInfo.label] &&
                        values['quickStats' + quickStatInfo.label]) ||
                      1
                    }
                    involvedParties={nonDeletedIP}
                    onAdd={() => {
                      setValues(val => {
                        let addCount = false
                        const currentCount =
                          val['quickStats' + quickStatInfo.label] || 0

                        if (
                          currentCount <
                          (nonDeletedIP && nonDeletedIP.length + 1)
                        ) {
                          addCount = true
                        }

                        return {
                          ...val,
                          ...(addCount && {
                            ['quickStats' +
                            quickStatInfo.label]: `${(parseInt(
                              currentCount
                            ) || 1) + 1}`
                          }),
                          [involvedPartyLabel]: val[
                            involvedPartyLabel
                          ]
                            ? [
                                ...val[involvedPartyLabel],
                                {
                                  id: val[involvedPartyLabel].length
                                }
                              ]
                            : [{ id: 0 }]
                        }
                      })
                    }}
                    onRemove={id =>
                      setValues(val => ({
                        ...val,
                        [involvedPartyLabel]: val[
                          involvedPartyLabel
                        ].map(ip =>
                          ip.id === id && !isEmpty(ip)
                            ? {
                                ...ip,
                                deleted: true
                              }
                            : ip
                        )
                      }))
                    }
                    onUpdate={updates => {
                      setValues(val => {
                        return {
                          ...val,

                          [involvedPartyLabel]: val[
                            involvedPartyLabel
                          ]
                            ? val[involvedPartyLabel].map(ip =>
                                ip.id === updates.id
                                  ? {
                                      ...ip,
                                      ...updates
                                    }
                                  : ip
                              )
                            : [{ ...updates }]
                        }
                      })
                    }}
                  />
                </>
              )}
            </ModalButton>
          )
        }
      />
    </Flex>
  )
}

export default RelatedIncidentPad
