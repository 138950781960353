/** @jsxImportSource theme-ui */

import { useState, useEffect, useRef } from 'react'
import useOnScreen from 'src/app/hooks/useOnScreen'
import { Box } from 'theme-ui'

const ListGroupItem = ({
  children,
  expandable,
  expandedContent,
  expandOnView,
  variant,
  sx = {},
  expanded: expandedProp,
  containerStyles,
  flexRow,
  expandInViewOnly,
  compactView,
  ...props
}) => {
  const hasBeenExpanded = useRef()
  const containerRef = useRef()
  const onScreen = useOnScreen(containerRef, '10px')
  const [expanded, setExpanded] = useState(expandedProp)

  const shouldExpand = expandInViewOnly
    ? hasBeenExpanded?.current || onScreen
    : true

  if (
    expandInViewOnly &&
    !hasBeenExpanded?.current &&
    expandedProp &&
    onScreen
  ) {
    hasBeenExpanded.current = onScreen
  } else if (expandInViewOnly && !expandedProp) {
    hasBeenExpanded.current = false
  }

  useEffect(() => {
    setExpanded(expandedProp)
  }, [expandedProp])

  return (
    <Box ref={containerRef} sx={{ ...containerStyles }}>
      <Box
        as="li"
        variant={variant ? variant : 'ListGroupItem'}
        sx={{
          position: 'relative',
          cursor: expandable && 'pointer',
          padding: compactView && '10px 10px',
          ...sx
        }}
        onClick={
          expandable
            ? e => {
                e.stopPropagation()
                setExpanded(!expanded)
              }
            : undefined
        }
        {...props}
      >
        {expandable && (
          <Box
            sx={{
              position: 'absolute',
              right: '12px',
              top: '37%',
              cursor: 'pointer'
            }}
          >
            {!expanded ? '+' : '-'}
          </Box>
        )}

        {children}
      </Box>
      {expandable && shouldExpand && expanded && (
        <Box
          variant={variant ? variant : 'ListGroupItem'}
          sx={{ borderTop: '0px' }}
        >
          {expandedContent}
        </Box>
      )}
    </Box>
  )
}

export default ListGroupItem
