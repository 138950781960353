import ModalButton from '../../../Shared/ModalButton'

import Button from 'src/app/components/Shared/Elements/Button'
import Input from 'src/app/components/Shared/Elements/Input'
import FormGroup from 'src/app/components/Shared/Elements/FormGroup'
import Icon from 'src/app/components/Shared/Icon'

const FormFieldItemFormHelpTextModal = ({
  fieldItem,
  handleChange
}) => {
  return (
    <ModalButton
      renderModalActions={toggle => (
        <Button onClick={toggle}>Done</Button>
      )}
      buttonLabel={
        <Icon
          icon="help"
          height={15}
          width={15}
          title="Add Help Text"
          color={fieldItem.helpText ? 'success' : 'text'}
        />
      }
      buttonProps={{
        variant: 'link',
        sx: { padding: '0px' }
      }}
      modalHeader={'Help Text'}
    >
      {() => {
        return (
          <FormGroup>
            <Input
              onChange={handleChange}
              id="helpText"
              name="helpText"
              placeholder="Help Text"
              type="textarea"
              value={fieldItem.helpText}
            />
          </FormGroup>
        )
      }}
    </ModalButton>
  )
}

export default FormFieldItemFormHelpTextModal
