import { Fragment } from 'react'

import { Flex } from 'theme-ui'
import { theme } from 'src/styles'

import Modules from 'src/images/icons/Modules'

import { CenteredContent } from '..'
import { useGetCurrentAgencyContext } from 'src/app/hooks/queries'
import SupportButton from 'src/components/SupportButton'
import capitalize from 'lodash/capitalize'
import startCase from 'lodash/startCase'

export const getPermission = (settings, key) => {
  const permission =
    settings && settings.modules.find(m => m.key === key)
  return permission && permission.value
}

export const getTruthyPermissionsFromSettings = settings => {
  return settings?.modules.filter(v => v.value).map(v => v.key)
}

export const checkForModule = (settings, featureLabel) =>
  getTruthyPermissionsFromSettings(settings).includes(featureLabel)

export const useGetPermission = label => {
  const { currentAgency } = useGetCurrentAgencyContext()
  const settings = currentAgency && currentAgency.settings
  return getPermission(settings, label)
}

const PayWallScreen = ({
  featureLabel,
  agencyWhiteList,
  hideContent,
  children,
  cardStyle
}) => {
  const { currentAgency } = useGetCurrentAgencyContext()

  const settings = currentAgency && currentAgency.settings
  const hasFeature = agencyWhiteList
    ? agencyWhiteList.includes(currentAgency?.id)
    : getTruthyPermissionsFromSettings(settings).includes(
        featureLabel === 'pulse' ? 'statuses' : featureLabel
      )

  if (!hasFeature && hideContent) return <Fragment />

  const content = (
    <CenteredContent height={'50vh'} style={{ textAlign: 'center' }}>
      <Modules
        width="40px"
        height="40px"
        style={{ marginBottom: 20 }}
        secondaryfill={theme.colors.success}
      />
      <h4 style={{ marginBottom: 15 }}>
        Sorry your plan does not have access to{' '}
        {startCase(
          featureLabel === 'incidentReporting'
            ? 'incident reporting'
            : featureLabel
        )}{' '}
        module
      </h4>
      <p style={{ maxWidth: '400px' }}>
        Please contact your account manager to upgrade your plan.
      </p>
      <Flex>
        <SupportButton
          buttonProps={{
            icon: 'opentab',
            sx: {
              p: 10,
              mt: '10px'
            },
            variant: 'primary'
          }}
        >
          {' '}
          Upgrade Now
        </SupportButton>
      </Flex>
    </CenteredContent>
  )
  if (!hasFeature) {
    return cardStyle ? content : content
  }
  return <>{children}</>
}

export default PayWallScreen
