/** @jsxImportSource theme-ui */
import { forwardRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Flex } from 'theme-ui'

const NavbarBrand = (
  { children, variant = 'NavbarBrand', sx, href, ...props },
  ref
) => {
  return (
    // Possibility that we want to make the component accept props
    // specifically for the reach-router <Link>
    <RouterLink to={href}>
      <Flex
        variant={variant}
        sx={{ alignItems: 'center', ...sx }}
        {...props}
      >
        {children}
      </Flex>
    </RouterLink>
  )
}

export default forwardRef(NavbarBrand)
