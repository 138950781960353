/** @jsxImportSource theme-ui */

import { Fragment, useState } from 'react'
import Elipses from '../../../components/Shared/Elipses'
import Logo from 'src/components/Logo'
import { useWindowSize } from 'src/styles'
import { Box, Flex } from 'theme-ui'
import ModalButton from '../../Shared/ModalButton'
import NotificationDropdown from './NotificationDropdown'
import {
  AgencyNotificationAlert,
  AlertContainer
} from './NotificationAlert'

import useGetOfflineQueue from 'src/app/hooks/useOffline'
import { useGetCurrentAgencyContext } from 'src/app/hooks/queries'
import { prettyDate } from 'src/utils'
import { useNetworkStatus } from 'src/app/hooks/useNetworkStatus'
import Icon from '../../Shared/Icon'
import LogoutButton from '../../Shared/LogoutButton'
import Avatar from 'src/app/components/Shared/Avatar'
import DailyAssignmentForm from '../../Forms/DailyAssignmentForm'
import DailyAssignmentLabel from '../../DailyAssignmentLabel'
import DailyDispatchLabel from '../../Dispatch/DailyDispatchLabel'
import DailyDispatcherForm from '../../Forms/DailyDispatcherForm'
import { RolesOnly } from 'src/app/services/auth'
import Button from '../../Shared/Elements/Button'
import ListGroupItem from '../../Shared/Elements/ListGroupItem'
import ListGroupItemHeading from '../../Shared/Elements/ListGroupItemHeading'
import Nav from '../../Shared/Elements/Nav'
import NavBar from '../../Shared/Elements/NavBar'
import NavbarBrand from '../../Shared/Elements/NavbarBrand'
import NavItem from '../../Shared/Elements/NavItem'
import ModalBody from '../../Shared/Elements/ModalBody'
import Dropdown from '../../Shared/Elements/Dropdown'
import Modal from '../../Shared/Elements/Modal'

import { CloseModalButton } from '../../Shared/Elements/ModalHeader'
import SupportButton from 'src/components/SupportButton'
import { getTruthyPermissionsFromSettings } from '../../PayWalls/PayWallScreen'

import InlineTooltip from '../../Shared/InlineTooltip'
import Badge from '../../Shared/Elements/Badge'
import useSessionHoursRemaining from 'src/app/hooks/useSessionHoursRemaining'
import Switch from '../../Shared/Elements/Switch'

const OfflineIncidentItem = ({
  fo,
  currentAgency,
  rerunOperation,
  dequeueOperation,
  isOnline,
  loading,
  ...props
}) => {
  const vars = fo.data

  const type = currentAgency.activities.find(
    a => a.id === vars?.data?.type
  )

  return (
    <ListGroupItem {...props}>
      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'no-wrap'
        }}
      >
        <Box>
          <Badge sx={{ textTransform: 'capitalize', mb: '5px' }}>
            {fo?.dataType === 'bulkIncident'
              ? 'Bulk Incidents'
              : fo?.dataType}
          </Badge>
          <ListGroupItemHeading>
            {fo?.dataType === 'bulkIncident'
              ? `Bulk Submission (${vars?.data?.length} categories)`
              : fo?.dataType === 'form'
              ? fo?.formName
              : type && type.label}
          </ListGroupItemHeading>
          <p>
            {prettyDate(
              vars.data?.incidentTime ||
                vars?.incidentDate ||
                vars?.data?.submissionDate,
              'L HH:mm'
            )}
          </p>
        </Box>
        <Flex>
          <Button
            variant="primary"
            disabled={loading === fo.qid || !isOnline}
            onClick={async () => {
              await rerunOperation(fo)
            }}
            icon={loading === fo.qid ? 'spinner' : 'play'}
          >
            Submit
          </Button>
          <Button
            onClick={async () => {
              dequeueOperation(fo.qid)
            }}
            variant="linkDanger"
            icon="trash"
          />
        </Flex>
      </Flex>
    </ListGroupItem>
  )
}

const TopNav = ({
  handleSidebarOpenState,
  hideHamburger,
  sidebarOpenState
}) => {
  const {
    currentAgency,
    user,
    users,
    loggedIn,
    setAllPositionsShowing,
    allPositionsShowing
  } = useGetCurrentAgencyContext()
  const isOnline = useNetworkStatus()
  const isAuthed = loggedIn

  const { mobile } = useWindowSize()
  const {
    queue = [],
    rerunOperation,
    rerunQueue,
    progress,
    loading,
    dequeueOperation
  } = useGetOfflineQueue()

  const hasQueue = queue.length > 0
  const fullQueue = queue.length >= 10

  const {
    sessionExpiringHours,
    sessionExpiringSoon
  } = useSessionHoursRemaining()

  return (
    <div>
      {isAuthed && user && currentAgency && (
        <AgencyNotificationAlert />
      )}
      {user?.role === 'READ_ONLY' && (
        <AlertContainer
          key={2}
          styles={{
            bg: 'warning',
            color: 'white'
          }}
        >
          READ-ONLY MODE
        </AlertContainer>
      )}
      {currentAgency && (!isOnline || (isOnline && hasQueue)) ? (
        <AlertContainer
          key={3}
          styles={{
            gap: '5px',
            bg: isOnline ? 'success' : 'danger',
            color: 'white'
          }}
        >
          {!isOnline && (
            <Icon
              color="white"
              icon={'offline'}
              width={15}
              height={15}
            />
          )}
          {!isOnline && 'OFFLINE MODE: '}
          {sessionExpiringSoon
            ? `Session expiring in ${sessionExpiringHours} hrs , we recommend submitting soon`
            : fullQueue
            ? 'Offline queue is almost full, we recommend submitting soon'
            : `${queue.length} item${queue?.length !== 1 ? 's' : ''}
          ${isOnline ? ' ready to upload' : ' saved'}`}
          <ModalButton
            buttonProps={{
              variant: 'white',
              outline: true,
              size: 'sm',
              sx: { marginLeft: 10 }
            }}
            buttonLabel={!isOnline ? 'See Queue' : 'Upload Now'}
            modalHeader={'Stored Offline'}
          >
            {() => {
              return (
                <>
                  <InlineTooltip
                    sx={{
                      mb: '30px'
                    }}
                    variant={isOnline ? 'success' : 'danger'}
                    header={isOnline ? 'Back online!' : 'Offline'}
                    tip={
                      isOnline
                        ? 'It is best to re-submit when your connection to the internet is stable.  Make sure you are in a good service area.'
                        : 'Must find a secure connection before uploading saved submissions.'
                    }
                  />
                  <Flex
                    sx={{
                      p: 2,
                      justifyContent: 'flex-end',
                      width: '100%'
                    }}
                  >
                    <Button
                      onClick={rerunQueue}
                      disabled={loading || !isOnline}
                    >
                      {typeof progress === 'number'
                        ? `Submitting.. ${progress}%`
                        : 'Submit All'}
                    </Button>
                  </Flex>
                  {queue.map((fo, k) => {
                    return (
                      <OfflineIncidentItem
                        fo={fo}
                        currentAgency={currentAgency}
                        dequeueOperation={dequeueOperation}
                        rerunOperation={rerunOperation}
                        loading={loading}
                        key={k}
                        isOnline={isOnline}
                      />
                    )
                  })}
                </>
              )
            }}
          </ModalButton>
        </AlertContainer>
      ) : (
        <Fragment />
      )}

      <NavBar
        sx={{
          padding: ['0.5rem 10px', null, null, '0.5rem 20px'],
          borderBottomWidth: '1px',
          borderBottomStyle: 'solid',
          borderBottomColor: 'gray200',
          backgroundColor: 'background',
          height: '65px',
          flexWrap: 'nowrap'
        }}
        key={4}
      >
        <Flex sx={{ alignItems: 'center' }}>
          {!hideHamburger && (
            <Box
              as="button"
              onClick={() =>
                handleSidebarOpenState(!sidebarOpenState)
              }
              sx={{
                padding: '0px',
                mt: ['0px', null, null, '3px'],
                mr: '10px',
                border: 'none',
                background: 'none',
                '&:focus': {
                  borderRadius: '0.2rem'
                }
              }}
            >
              {sidebarOpenState ? (
                <Icon
                  icon={'close burger'}
                  height={'24px'}
                  color={'text'}
                />
              ) : (
                <Icon
                  icon={'open burger'}
                  height={'24px'}
                  color={'text'}
                />
              )}
            </Box>
          )}

          <NavbarBrand href="/agency/submission">
            <Logo sx={{ height: ['20px', null, '30px'] }} />
          </NavbarBrand>
        </Flex>

        {isAuthed && user && currentAgency ? (
          <>
            <LoggedInNav
              mobile={mobile}
              setAllPositionsShowing={setAllPositionsShowing}
              allPositionsShowing={allPositionsShowing}
              currentAgency={currentAgency}
              user={user}
              users={users}
            />
          </>
        ) : (
          <Nav sx={{ flexWrap: 'nowrap' }}>
            <NavItem>
              <Dropdown
                buttonVariant="linkGray"
                dropdownTitle={
                  !mobile ? (
                    'Support'
                  ) : (
                    <Icon
                      icon="help"
                      height={20}
                      width={20}
                      sx={{
                        marginTop: '5px'
                      }}
                    />
                  )
                }
                caret={!mobile ? true : false}
                dropdownItems={[
                  <SupportButton
                    buttonProps={{
                      variant: 'dropwdown',
                      sx: {
                        width: '100%',
                        p: '10px'
                      }
                    }}
                  >
                    Contact Us
                  </SupportButton>,
                  <Button
                    href="http://help.yourwatchtower.com"
                    variant="dropdown"
                  >
                    Support Docs
                  </Button>
                ]}
              />
            </NavItem>
            <NavItem>
              <Button size="sm" href="https://www.yourwatchtower.com">
                Learn More
              </Button>
            </NavItem>
          </Nav>
        )}
      </NavBar>
    </div>
  )
}

const NavContent = ({
  mobile,
  users,
  currentAgency,
  setAllPositionsShowing,
  allPositionsShowing,
  user
}) => {
  const [openDailyAssignment, setOpenDailyAssignment] = useState(
    false
  )
  const [
    openDispatchDailyAssignment,
    setOpenDispatchDailyAssignment
  ] = useState(false)

  const hasDispatch = getTruthyPermissionsFromSettings(
    currentAgency?.settings
  )?.includes('dispatching')

  return (
    <>
      <Modal
        style={{ maxWidth: '700px' }}
        toggle={() => setOpenDailyAssignment(!openDailyAssignment)}
        isOpen={openDailyAssignment}
      >
        <ModalBody>
          <DailyAssignmentForm
            onFinish={() => setOpenDailyAssignment(false)}
          />
        </ModalBody>
      </Modal>
      <Modal
        style={{ maxWidth: '700px' }}
        toggle={() =>
          setOpenDispatchDailyAssignment(!openDispatchDailyAssignment)
        }
        isOpen={openDispatchDailyAssignment}
        external={
          <CloseModalButton
            onClick={() => setOpenDailyAssignment(false)}
          />
        }
      >
        <ModalBody>
          <DailyDispatcherForm
            onFinish={() => setOpenDispatchDailyAssignment(false)}
          />
        </ModalBody>
      </Modal>
      <Dropdown
        buttonVariant="linkGray"
        dropdownTitle={
          !mobile ? (
            'Support'
          ) : (
            <Icon
              icon="help"
              height={20}
              width={20}
              sx={{
                marginTop: '5px'
              }}
            />
          )
        }
        caret={!mobile ? true : false}
        dropdownItems={[
          <SupportButton buttonProps={{ variant: 'dropdown' }}>
            Contact Us
          </SupportButton>,
          <Button
            href="http://help.yourwatchtower.com"
            variant="dropdown"
          >
            Support Docs
          </Button>
        ]}
      />
      <Dropdown
        dropdownTitle={!mobile && currentAgency.name}
        buttonVariant="linkGray"
        caret={!mobile ? true : false}
        dropdownMenuHeading={[
          user.name,
          user.email,
          <Flex
            sx={{
              gap: '5px',
              mt: '5px',
              flexWrap: 'wrap',
              maxWidth: '200px'
            }}
          >
            {user?.displayTags?.map(dt => (
              <Badge size="sm" key={dt.id} bg={dt.color}>
                {dt.name}
              </Badge>
            ))}
          </Flex>
        ]}
        dropdownImage={<Avatar height={'30px'} />}
        dropdownItems={[
          <Button
            variant="dropdown"
            sx={{
              px: '0px'
            }}
            onClick={() => setOpenDailyAssignment(true)}
          >
            <Elipses mw={'250px'}>
              Responding as: <DailyAssignmentLabel />
            </Elipses>
          </Button>,
          hasDispatch ? (
            <RolesOnly roles={['ADMIN', 'DISPATCHER', 'SUPERVISOR']}>
              <Button
                sx={{
                  px: '0px'
                }}
                variant="dropdown"
                onClick={() => setOpenDispatchDailyAssignment(true)}
              >
                <Elipses mw={'250px'}>
                  Dispatching as: <DailyDispatchLabel users={users} />
                </Elipses>
              </Button>
            </RolesOnly>
          ) : (
            <Fragment />
          ),
          user.displayTags?.length < 1 ? (
            <Fragment />
          ) : (
            <Button variant="dropdown">
              <Switch
                onClick={() => {
                  setAllPositionsShowing(!allPositionsShowing)
                }}
                style={{ cursor: 'pointer' }}
                id={`allPositionsShowing`}
                name="allPositionsShowing"
                label={'All Resources/Locations'}
                checked={allPositionsShowing ? true : false}
                readOnly
              />
            </Button>
          ),
          <Button
            variant="dropdown"
            href="/agency/profile"
            sx={{ width: '100%' }}
          >
            My Profile
          </Button>,
          <LogoutButton
            variant="dropdown"
            sx={{ width: '100%', textAlign: 'left' }}
          />
        ]}
      />
    </>
  )
}

const LoggedInNav = props => {
  return (
    <Nav sx={{ flexWrap: 'nowrap' }}>
      <NavItem
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '40px',
          paddingTop: 1
        }}
      >
        <NotificationDropdown {...props} />
      </NavItem>

      <NavContent {...props} />
    </Nav>
  )
}

export default TopNav
