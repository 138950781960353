// Function to chunk an array into groups
export function chunkArray(array, chunkSize) {
  const chunks = []
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize))
  }
  return chunks
}

// Function to sequence asynchronous calls with progress tracking
export async function sequenceCallsWithProgress(items, callback) {
  const numItems = items.length
  for (let i = 0; i < numItems; i++) {
    await callback(items[i], i, numItems)
  }
}
