import { theme } from 'src/styles'

function DraggableIcon(props) {
  const title = props.title || 'all directions'

  return (
    <svg
      height={props.height || '48'}
      width={props.width || '48'}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{title}</title>
      <g fill={props.fille || theme.colors.gray700}>
        <path
          d="M38.707,14.879a1,1,0,0,0-1.414,0l-1.414,1.414a1,1,0,0,0,0,1.414L40.172,22H26V7.828l4.293,4.293a1,1,0,0,0,1.414,0l1.414-1.414a1,1,0,0,0,0-1.414L24.707.879a1,1,0,0,0-1.414,0L14.879,9.293a1,1,0,0,0,0,1.414l1.414,1.414a1,1,0,0,0,1.414,0L22,7.828V22H7.828l4.293-4.293a1,1,0,0,0,0-1.414l-1.414-1.414a1,1,0,0,0-1.414,0L.879,23.293a1,1,0,0,0,0,1.414l8.414,8.414a1,1,0,0,0,1.414,0l1.414-1.414a1,1,0,0,0,0-1.414L7.828,26H22V40.172l-4.293-4.293a1,1,0,0,0-1.414,0l-1.414,1.414a1,1,0,0,0,0,1.414l8.414,8.414a1,1,0,0,0,1.414,0l8.414-8.414a1,1,0,0,0,0-1.414l-1.414-1.414a1,1,0,0,0-1.414,0L26,40.172V26H40.172l-4.293,4.293a1,1,0,0,0,0,1.414l1.414,1.414a1,1,0,0,0,1.414,0l8.414-8.414a1,1,0,0,0,0-1.414Z"
          fill={props.fille || theme.colors.gray700}
        />
      </g>
    </svg>
  )
}

export default DraggableIcon
