/** @jsxImportSource theme-ui */

import Badge from './Elements/Badge'

import { getContrastColor } from 'src/utils/getContrastColor'
import { useThemeUI } from 'theme-ui'

const LabelBadge = ({
  text,
  color,
  border,
  styles,
  style,
  ...props
}) => {
  const { theme } = useThemeUI()
  const badgeColor = color || theme.rawColors.gray300

  return (
    <Badge
      sx={{
        background: badgeColor,

        // margin: 3,
        color:
          badgeColor &&
          getContrastColor(badgeColor, 3.5, theme.rawColors.text),
        borderRadius: '4px',
        textAlign: 'left',
        whiteSpace: 'normal',
        wordWrap: 'normal',
        lineHeight: '17px',
        fontSize: 14,
        textTransform: 'capitalize',
        border: border ? `2px solid ${border}` : 'none',

        appearance: 'none',
        ...styles,
        ...style
      }}
      {...props}
    >
      {text}
    </Badge>
  )
}

export default LabelBadge
