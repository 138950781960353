/** @jsxImportSource theme-ui */

import styled from '@emotion/styled'
import { css } from '@emotion/react'
import ToolTip from './Elements/ToolTip'
import uniqueId from 'lodash/uniqueId'

const El = styled.div`
  max-height: ${p => p.mh || 'auto'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${p =>
    p.maxLines &&
    css`
      -webkit-line-clamp: ${p.maxLines};
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      white-space: initial;
      text-overflow: none;
    `}
`

const Elipses = ({ children, noHover, mw, ...props }) => {
  const id = 'elipses_' + uniqueId()
  return (
    <>
      {!noHover && (
        <ToolTip placement="bottom" target={id}>
          {children}
        </ToolTip>
      )}
      <El
        data-tip
        data-for={id}
        sx={{
          maxWidth: mw || '100%',
          ...props.sx
        }}
        {...props}
      >
        {children}
      </El>
    </>
  )
}

export default Elipses
