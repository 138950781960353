import { Alert, Box, Flex } from 'theme-ui'
import { Fragment, useState } from 'react'
import Button from './Elements/Button'
import Icon from './Icon'

const InlineTooltip = ({
  tip,
  customIndex = '0',
  action,
  icon,
  header,
  component,
  bg,
  iconColor,
  isCloseable = true,
  customIcon,
  forceNewLine,
  ...props
}) => {
  const [hide, setHide] = useState(false)
  return hide ? (
    <Fragment />
  ) : (
    <Alert
      variant="primary"
      {...props}
      sx={{
        flexWrap: 'wrap',
        position: 'relative',
        ...(props.sx || {})
      }}
    >
      <Flex
        sx={{
          alignItems: 'center',
          gap: '10px',
          flexWrap: 'wrap',
          width: '80%',
          minWidth: '250px'
        }}
      >
        {customIcon || (
          <Box>
            <Icon
              icon={icon || 'help'}
              height={17}
              width={17}
              title=""
              color={iconColor || 'text'}
              style={{
                textDecoration: 'underline',
                marginRight: 10
              }}
              id={'questionTip' + tip.length + customIndex}
            />
          </Box>
        )}
        <Flex
          sx={{
            flex: 1,
            minWidth: '250px',
            gap: '5px',
            flexWrap: 'wrap',
            textAlign: 'left'
          }}
        >
          {header && <strong>{header}</strong>}{' '}
          <Box sx={{ minWidth: forceNewLine && '100%' }}>{tip}</Box>
        </Flex>
      </Flex>
      {action}
      {isCloseable ? (
        <Button
          onClick={() => setHide(true)}
          icon="close"
          variant="linkGray"
          sx={{
            position: 'absolute',
            top: '3px',
            right: '3px',
            zIndex: 10,
            padding: '0px'
          }}
        />
      ) : (
        <Fragment />
      )}
    </Alert>
  )
}

export default InlineTooltip
