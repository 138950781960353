import { GET_SUBMISSION } from 'src/app/hooks/queries'
import { useQuery } from '@apollo/client'
import Spinner from 'src/images/icons/Spinner'
import get from 'lodash/get'
import { QuickSurvey } from './QuickSurvey'

const PrefilledQuickSurvey = ({ id, ...rest }) => {
  const { data, loading } = useQuery(GET_SUBMISSION, {
    variables: {
      where: {
        id
      }
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only'
  })

  const submission = data && data.submission
  if (loading || !submission) {
    return <Spinner centered />
  }

  const vehicleIncidents = get(submission, 'vehicleIncidents', null)
  const related =
    submission && submission.relatedSubmissions.length === 0
      ? {}
      : submission &&
        submission.relatedSubmissions.reduce(
          (acc, current) => ({
            ...acc,
            ['quickStats' + current.type.label]: current.count
          }),
          {}
        )

  return (
    <QuickSurvey
      stickySubmit={true}
      {...rest}
      preFilledSubmission={
        submission
          ? {
              subLabelOne: get(submission, 'type.subLabelOne'),
              subLabelTwo: get(submission, 'type.subLabelTwo'),
              subLabelThree: get(submission, 'type.subLabelThree'),
              vehicles: vehicleIncidents
                ? vehicleIncidents.map(v => ({
                    prefilled: true,
                    passengers: v.passengers,
                    vehicleType: { value: v.vehicle.type },
                    lNumber: v.vehicle.licenseNumber,
                    ...v.vehicle,
                    vehicleIncidenId: v.id
                  }))
                : [],
              ...submission.weather,
              ...submission,
              ...related,
              id: submission.id
            }
          : null
      }
    />
  )
}

export default PrefilledQuickSurvey
