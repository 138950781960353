import { useSearchParams } from 'react-router-dom'

const useGetAllSearchParams = () => {
  const [searchParams] = useSearchParams()
  let result = {}
  for (const [key, value] of searchParams?.entries()) {
    result[key] = value
  }
  return result
}

export default useGetAllSearchParams
