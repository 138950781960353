function Priority(props) {
  const title = props.noTitle
    ? undefined
    : props.title || 'flag diagonal 34'

  return (
    <svg
      {...props}
      height={props.height || '64'}
      width={props.width || '64'}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill={props.color || '#F7F7F7'}>
        <path d="M4.864,11.496c-0.278-0.476-0.889-0.638-1.368-0.36c-0.477,0.278-0.638,0.891-0.36,1.368 l28,48C31.322,60.822,31.657,61,32.001,61c0.171,0,0.344-0.044,0.503-0.136c0.477-0.278,0.638-0.891,0.36-1.368L4.864,11.496z" />
        <path
          d="M58.479,9.058c-4.412,1.27-10.095-0.117-16.11-1.586c-10.36-2.531-22.103-5.399-30.405,5.031 c-0.256,0.321-0.289,0.767-0.084,1.123l13.669,23.675c0.179,0.31,0.509,0.5,0.866,0.5c0.001,0,0.002,0,0.004,0 c0.358-0.001,0.689-0.195,0.866-0.506c3.804-6.705,8.895-8.612,14.286-10.631c6.596-2.471,13.416-5.025,18.108-16.259 c0.148-0.354,0.08-0.763-0.176-1.05C59.248,9.067,58.85,8.952,58.479,9.058z"
          fill={props.color || '#F7F7F7'}
        />
      </g>
    </svg>
  )
}

export default Priority
