function Explore(props) {
  const title = props.title || 'Explore'

  return (
    <svg
      height={props.height || '24'}
      width={props.width || '24'}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{title}</title>
      <g
        fill={props.color || '#303035'}
        stroke={props.color || '#303035'}
        strokeLinecap="square"
        strokeWidth="2"
      >
        <polyline
          fill="none"
          points="16.568 14.568 22 20 20 22 14.568 16.568"
          strokeLinecap="butt"
        />
        <path
          d="M16.568,14.568l-.022.023a8.036,8.036,0,0,1-1.956,1.955l-.022.022L20,22l2-2Z"
          stroke="none"
        />
        <circle
          cx="10"
          cy="10"
          fill="none"
          r="8"
          stroke={props.color || '#303035'}
        />
      </g>
    </svg>
  )
}

export default Explore
