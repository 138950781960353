import { useMutation } from '@apollo/client'
import { optionalChaining } from 'src/utils'
import { SUBMIT_ACTIVITY } from './mutations'
import { useNetworkStatus } from './useNetworkStatus'
import { useOfflineMutation } from './useOffline'

export const useSubmitNewSubmission = options => {
  const isOnline = useNetworkStatus()

  const defaultOptions = {
    ...options
  }
  const {
    mutate: submitOffline,
    loading: offlineLoading,
    error
  } = useOfflineMutation()
  const [
    submitSubmissionOnline,
    { data: onlineData, error: onlineError, loading: onlineLoading }
  ] = useMutation(SUBMIT_ACTIVITY, defaultOptions)

  const submission = optionalChaining(
    () => onlineData.createSubmission
  )

  async function createSubmission(opts) {
    try {
      return isOnline
        ? await submitSubmissionOnline(opts)
        : await submitOffline(opts.variables)
    } catch (e) {
      return e
    }
  }

  return {
    createSubmission,
    error: error || onlineError,
    loading: offlineLoading || onlineLoading,
    submission
  }
}
