function QRCode(props) {
  const title = props.title || 'barcode qr'

  return (
    <svg
      height={props.height || '64'}
      width={props.width || '64'}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{title}</title>
      <g fill={props.color || '#212121'}>
        <path
          d="M21,1H1v20h20V1z M19,19H3V3h16V19z"
          fill={props.color || '#212121'}
        />
        <path
          d="M1,63h20V43H1V63z M3,45h16v16H3V45z"
          fill={props.color || '#212121'}
        />
        <path
          d="M43,1v20h20V1H43z M61,19H45V3h16V19z"
          fill={props.color || '#212121'}
        />
        <rect height="12" width="12" x="5" y="5" />
        <rect height="12" width="12" x="5" y="47" />
        <rect height="12" width="12" x="47" y="5" />
        <rect
          height="6"
          width="6"
          fill={props.color || '#212121'}
          x="1"
          y="35"
        />
        <rect
          height="6"
          width="6"
          fill={props.color || '#212121'}
          x="57"
          y="24"
        />
        <polygon
          fill={props.color || '#212121'}
          points="41,56 39,56 39,62 63,62 63,60 41,60 "
        />
        <polygon
          fill={props.color || '#212121'}
          points="31,51 26,51 26,44 24,44 24,53 29,53 29,58 24,58 24,60 29,60 29,62 36,62 36,60 31,60 "
        />
        <polygon
          fill={props.color || '#212121'}
          points="31,24 19,24 19,26 33,26 33,18 36,18 36,13 27,13 27,6 31,6 31,9 40,9 40,1 38,1 38,7 33,7 33,1 25,1 25,15 31,15 31,18 25,18 25,20 31,20 "
        />
        <polygon
          fill={props.color || '#212121'}
          points="6,31 16,31 16,35 10,35 10,37 16,37 16,40 27,40 27,38 18,38 18,29 13,29 13,25 11,25 11,29 8,29 8,24 1,24 1,26 6,26 "
        />
        <polygon
          fill={props.color || '#212121'}
          points="41,48 41,52 37,52 37,41 35,41 35,37 32,37 32,29 22,29 22,35 24,35 24,31 30,31 30,43 35,43 35,54 48,54 48,57 53,57 53,48 48,48 48,52 43,52 43,48 "
        />
        <polygon
          fill={props.color || '#212121'}
          points="61,36 47,36 47,42 43,42 43,36 41,36 41,44 49,44 49,38 54,38 54,43 56,43 56,38 61,38 61,48 56,48 56,56 63,56 63,54 58,54 58,50 63,50 63,32 61,32 "
        />
        <polygon
          fill={props.color || '#212121'}
          points="46,30 40,30 40,22 38,22 38,32 54,32 54,30 48,30 48,26 54,26 54,24 43,24 43,26 46,26 "
        />
      </g>
    </svg>
  )
}

export default QRCode
