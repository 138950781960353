import { useEffect, useState } from 'react'

import Button from './Elements/Button'

import { Box, Flex } from 'theme-ui'
import ModalButton from './ModalButton'

import useKeyDown from 'src/app/hooks/useKeyDown'
import styled from '@emotion/styled'
import ModalFooter from './Elements/ModalFooter'
import Spinner from 'src/images/icons/Spinner'
import RichTextInput from './RichTextInputComp'

export const CustomRichText = styled.div`
  .ck-editor__editable {
    height: 40vh;
  }
  .ql-container {
    height: 90%;
  }
`

export const RichTextWithEpandedView = ({
  savedValue,
  setSavedValue,
  loading,
  onSave,
  withQuickSave,
  onBlur,
  ...rest
}) => {
  const [value, setValue] = useState(savedValue)
  const [modalIsOpen, setModalIsOpen] = useState(false)

  useEffect(() => {
    if (savedValue !== value) {
      setSavedValue(value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  async function handleSave() {
    await onSave()
    setValue(null)
    setSavedValue(null)
    setModalIsOpen(false)
  }

  useKeyDown(
    'Enter',
    () => {
      if (withQuickSave && value && onSave) {
        handleSave()
      }
    },
    true
  )

  return (
    <Flex
      sx={{
        alignItems: 'flex-start',
        width: '100%'
      }}
    >
      <Box sx={{ flex: 1 }}>
        <RichTextInput
          hideToolbar
          value={value}
          {...rest}
          onChange={content => {
            if (modalIsOpen) {
              return
            }
            setValue(content)
          }}
          onBlur={() => {
            onBlur && onBlur(value)
          }}
        />
        {withQuickSave && value && onSave && (
          <Button
            variant="primary"
            block
            sx={{ marginRight: '10px', marginTop: '5px' }}
            onClick={handleSave}
            disabled={loading}
            icon={loading && 'spinner'}
          >
            Save (Ctrl + Enter)
          </Button>
        )}
      </Box>
      <ModalButton
        buttonProps={{
          variant: 'linkGray',
          icon: 'resizex',
          iconSize: 'lg',
          sx: {
            boxShadow: 'none',
            marginLeft: '3px',
            minWidth: '20px'
          }
        }}
        modalHeader={'Add Note'}
        modalProps={{
          isOpen: modalIsOpen
        }}
        onClose={() => setModalIsOpen(false)}
        onOpen={() => setModalIsOpen(true)}
      >
        {({ toggle, open }) => {
          //Required to handle the double inputs.. must disable onchange of one while other in use..
          return (
            <RichTextModalContent
              toggle={toggle}
              onSave={onSave}
              open={open}
              defaultValue={value}
              setValue={setValue}
              value={value}
              loading={loading}
              setSavedValue={setSavedValue}
              saveText={withQuickSave && 'Save (Ctrl + Enter)'}
              onBlur={() => {
                onBlur && onBlur(value)
              }}
              {...rest}
            />
          )
        }}
      </ModalButton>
    </Flex>
  )
}

const RichTextModalContent = ({
  toggle,
  onSave,
  loading,
  setSavedValue,
  setValue,
  value,
  onBlur,
  saveText
}) => {
  const [newMessage, setNewMessage] = useState(value)

  useEffect(() => {
    if (value !== newMessage) {
      setNewMessage(value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <>
      <RichTextInput
        onChange={content => {
          setValue(content)
        }}
        value={value}
        minHeight="60vh"
        autoFocus
        onBlur={onBlur}
      />

      <ModalFooter onClose={toggle}>
        <Button
          variant="primary"
          disabled={!value}
          onClick={async () => {
            if (onSave) {
              await onSave()
              setValue(null)
              setSavedValue(null)
            }
            toggle()
          }}
        >
          {loading ? (
            <Spinner white />
          ) : saveText ? (
            saveText
          ) : onSave ? (
            'Save'
          ) : (
            'Done'
          )}
        </Button>
      </ModalFooter>
    </>
  )
}

export default RichTextInput
