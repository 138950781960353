import { theme } from 'src/styles'

function Asterix(props) {
  const fill = props.color || theme.colors.gray800

  return (
    <svg
      viewBox="0 -256 1792 1792"
      id="svg3037"
      version="1.1"
      width={props.width || '100%'}
      height={props.height || '100%'}
      {...props}
    >
      {props.title && <title>{props.title}</title>}
      <defs id="defs3045" />
      <g transform="matrix(1,0,0,-1,53.152542,1308.2034)" id="g3039">
        <path
          d="m 1482,486 q 46,-26 59.5,-77.5 Q 1555,357 1529,311 L 1465,201 Q 1439,155 1387.5,141.5 1336,128 1290,154 L 1024,307 V 0 q 0,-52 -38,-90 -38,-38 -90,-38 H 768 q -52,0 -90,38 -38,38 -38,90 V 307 L 374,154 Q 328,128 276.5,141.5 225,155 199,201 L 135,311 Q 109,357 122.5,408.5 136,460 182,486 L 448,640 182,794 Q 136,820 122.5,871.5 109,923 135,969 l 64,110 q 26,46 77.5,59.5 Q 328,1152 374,1126 L 640,973 v 307 q 0,52 38,90 38,38 90,38 h 128 q 52,0 90,-38 38,-38 38,-90 V 973 l 266,153 q 46,26 97.5,12.5 51.5,-13.5 77.5,-59.5 l 64,-110 q 26,-46 12.5,-97.5 Q 1528,820 1482,794 L 1216,640 z"
          id="path3041"
          fill={fill || theme.colors.gray800}
        />
      </g>
    </svg>
  )
}

export default Asterix
