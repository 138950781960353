import { Children, cloneElement, useEffect } from 'react';
import useGoogleMap from '../../../hooks/useGoogleMaps'

export default function Map({
  center,
  zoom,
  children,
  events,
  onLoad,
  ...props
}) {
  const { maps, map, mapRef, loading } = useGoogleMap({
    zoom,
    center,
    events
  })

  useEffect(() => {
    map && map.panTo(center)
    // eslint-disable-next-line
  }, [center.lat, center.lng])

  return (
    <div style={{ width: '400px', height: '400px', ...props.style }}>
      <div ref={mapRef} style={{ height: '100%' }} />
      {!loading &&
        Children.map(children, child => {
          return cloneElement(child, { map, maps });
        })}
    </div>
  );
}
