import { useMemo } from 'react'

import { GET_SECURE_FILE } from '../../../hooks/queries'

import { useQuery } from '@apollo/client'

import { Fragment } from 'react'
import DocumentViewerModal from './DocumentViewerModal'

export const ViewFileModal = ({ closeModal, f }) => {
  const { data, loading } = useQuery(GET_SECURE_FILE, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      fileId: f.id
    }
  })

  const DocumentModalMemo = useMemo(
    () =>
      loading ? (
        <Fragment centered />
      ) : (
        <DocumentViewerModal
          f={f}
          isOpen={true}
          setModalOpen={closeModal}
          secureFileUrl={data.getSecureFile}
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  )
  return DocumentModalMemo
}

export default ViewFileModal
