/** @jsxImportSource theme-ui */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react'
import 'src/styles/MinimalSideNav.css'
import Icon from 'src/app/components/Shared/Icon'
import { Box } from 'theme-ui'
import { Link } from 'react-router-dom'

const Drawer = ({
  activeItemId,
  isOpen,
  setOpen,
  items,
  onFinalRoute
}) => {
  const [activeSubNav, setActiveSubNav] = useState({
    expanded: true,
    selectedId: activeItemId
  })

  // Listen for parent prop changes and update state
  useEffect(() => {
    setActiveSubNav(originalSubNav => ({
      expanded: originalSubNav.expanded,
      selectedId: activeItemId
    }))
    const navItem = items?.find(item => item?.itemId === activeItemId)

    if (navItem) handleSubNavExpand(navItem, activeItemId)
  }, [activeItemId])

  function handleSubNavExpand(item, nexItemId) {
    if (activeSubNav.expanded) {
      const currentItemOrSubNavItemIsOpen =
        (item.subNav &&
          item.subNav.length > 0 &&
          item.subNav.some(
            _subNavItem => _subNavItem.itemId === nexItemId
          )) ||
        false
      setActiveSubNav({
        expanded: currentItemOrSubNavItemIsOpen,
        selectedId: item.itemId
      })
    } else {
      setActiveSubNav({
        expanded: !!(item.subNav && item.subNav.length > 0), // expand if expandable
        selectedId: item.itemId
      })
    }
  }

  return (
    <>
      {items.length > 0 && (
        <nav
          role="navigation"
          aria-label="side-navigation"
          className="side-navigation-panel"
          style={{
            paddingTop: '20px',

            transition: '1s all'
          }}
        >
          {items.map(item => {
            const ElemBefore = item.elemBefore
            const ElemAfter = item.elemAfter
            const isItemSelected =
              item.itemId === activeSubNav.selectedId
            const isActiveTab =
              // item is expanded and
              activeSubNav.expanded &&
              // either the current expandable section is selected
              (isItemSelected ||
                // or some item in the expandable section of the current item is selected or active
                (item.subNav &&
                  item.subNav.some(
                    _subNavItem =>
                      _subNavItem.itemId === activeSubNav.selectedId
                  )) ||
                false)

            return (
              <ul
                key={item.itemId}
                className="side-navigation-panel-select"
              >
                <li className="side-navigation-panel-select-wrap">
                  <Link
                    sx={{
                      textDecoration: 'none',
                      color: 'text'
                    }}
                    to={item.itemId}
                    onClick={() => {
                      if (!item.subNav || item.subNav.length === 0) {
                        onFinalRoute()
                      } else if (!isOpen) {
                        setOpen(true)
                      }
                    }}
                    className={`side-navigation-panel-select-option`}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        p: ['3px', null, null, '10px'],
                        borderRadius: '4px',
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor:
                          isActiveTab || isItemSelected
                            ? 'gray300'
                            : 'transparent',
                        bg:
                          isActiveTab || isItemSelected
                            ? 'gray100'
                            : 'transparent'
                      }}
                      className="side-navigation-panel-select-option-wrap"
                    >
                      {/** Prefix Icon Component */}
                      {ElemBefore && <ElemBefore open={isOpen} />}
                      {isOpen && (
                        <span className="side-navigation-panel-select-option-text">
                          {item.title}
                        </span>
                      )}
                      {ElemAfter && <ElemAfter open={isOpen} />}
                      {isOpen &&
                        item.subNav &&
                        item.subNav.length > 0 &&
                        (isActiveTab ? (
                          <Icon icon={'up arrow'} height={'10px'} />
                        ) : (
                          <Icon icon={'down arrow'} height={'10px'} />
                        ))}
                    </Box>
                  </Link>
                </li>

                {item.subNav &&
                  item.subNav.length > 0 &&
                  isOpen &&
                  isActiveTab && (
                    <ul className="side-navigation-panel-select-inner">
                      {item.subNav.map(subNavItem => {
                        const SubItemElemBefore =
                          subNavItem.elemBefore

                        return (
                          <li
                            key={subNavItem.itemId}
                            className="side-navigation-panel-select-inner-wrap"
                          >
                            <Link
                              to={{
                                pathname: subNavItem.itemId
                              }}
                              sx={{
                                textDecoration: 'none',
                                color: 'text'
                              }}
                              onClick={() => {
                                setActiveSubNav({
                                  ...activeSubNav,
                                  selectedId: subNavItem.itemId
                                })

                                onFinalRoute()
                              }}
                              className={`side-navigation-panel-select-inner-option ${
                                activeSubNav.selectedId ===
                                subNavItem.itemId
                                  ? 'side-navigation-panel-select-inner-option-selected'
                                  : ''
                              } `}
                            >
                              <span className="side-navigation-panel-select-inner-option-wrap">
                                {/** Prefix Icon Component */}
                                {SubItemElemBefore && (
                                  <SubItemElemBefore />
                                )}

                                <span className="side-navigation-panel-select-inner-option-text">
                                  {subNavItem.title}
                                </span>
                              </span>
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  )}
              </ul>
            )
          })}
        </nav>
      )}
    </>
  )
}

export default Drawer
