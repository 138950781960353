function Replay(props) {
  const title = props.title || 'replay'

  return (
    <svg
      height={props.height || '16'}
      width={props.width || '16'}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g
        fill={props.color || '#F7F7F7'}
        stroke={props.color || '#F7F7F7'}
        strokeMiterlimit="10"
        strokeWidth="1"
      >
        <polyline
          fill="none"
          points="7.5,6.5 10.5,3.5 7.5,0.5 "
          stroke={props.color || '#F7F7F7'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <polyline
          fill="none"
          points="10.5,3.5 0.5,3.5 0.5,12.5 2.5,12.5 "
          stroke={props.color || '#F7F7F7'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <polyline
          fill="none"
          points="8.5,9.5 5.5,12.5 8.5,15.5 "
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <polyline
          fill="none"
          points="5.5,12.5 15.5,12.5 15.5,3.5 13.5,3.5 "
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

export default Replay
