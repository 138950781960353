/** @jsxImportSource theme-ui */
import { useRef, createRef, useEffect, useState } from 'react'

import { useWindowSize } from 'src/styles'
import { Box, Flex } from 'theme-ui'
import Badge from '../Elements/Badge'
import Button from '../Elements/Button'
import Collapse from '../Elements/Collapse'
import NavItem from '../Elements/NavItem'

// Use this with useScrollToFormSection hook

const FormNavigation = ({
  scrollToSection,
  items,
  activeSectionId
}) => {
  const { mobile } = useWindowSize()
  const navRef = useRef(null)
  const itemRefs = useRef(items.map(() => createRef()))

  const [isOverflowing, setIsOverflowing] = useState(false)
  const [dropdownVisible, setDropdownVisible] = useState(false)

  const isElementFullyVisible = (container, element) => {
    const containerRect = container.getBoundingClientRect()
    const elementRect = element.getBoundingClientRect()

    return (
      elementRect.left >= containerRect.left &&
      elementRect.right <= containerRect.right
    )
  }

  const handleNext = direction => {
    const currentIndex = items.findIndex(
      item => item.id === activeSectionId
    )
    const nextIndex =
      direction === 'right'
        ? Math.min(items.length - 1, currentIndex + 1)
        : Math.max(0, currentIndex - 1)

    scrollToSection(items[nextIndex].id)

    // Delay execution to allow for re-render
    setTimeout(() => {
      const nav = navRef.current
      const nextItemElement = itemRefs.current[nextIndex].current

      if (
        nav &&
        nextItemElement &&
        !isElementFullyVisible(nav, nextItemElement)
      ) {
        // Scroll the container so that the next item is fully visible
        nextItemElement.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start'
        })
      }
    }, 100) // Adjust timeout as needed based on rendering performance
  }

  // Include this check in your FormNavigation component's effect hook to adjust on initial render as well
  useEffect(() => {
    // This ensures the active section is in view when the component mounts or the activeSectionId changes
    const activeIndex = items.findIndex(
      item => item.id === activeSectionId
    )
    if (activeIndex !== -1) {
      const nav = navRef.current
      const activeElement = itemRefs.current[activeIndex].current

      if (
        nav &&
        activeElement &&
        !isElementFullyVisible(nav, activeElement)
      ) {
        activeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start'
        })
      }
    }
  }, [activeSectionId, items])

  // Function to check if the Nav container is overflowing
  const checkOverflow = () => {
    const nav = navRef.current
    if (nav) {
      const isOverflow = nav.scrollWidth > nav.clientWidth
      setIsOverflowing(isOverflow)
    }
  }

  useEffect(() => {
    // Check overflow on mount
    checkOverflow()

    // Setup resize event listener to update overflow status
    window.addEventListener('resize', checkOverflow)

    // Cleanup function to remove event listener
    return () => window.removeEventListener('resize', checkOverflow)
  }, [])

  const activeItem = items?.find(item => item.id === activeSectionId)
  const activeItemIndex = items?.findIndex(
    item => item.id === activeSectionId
  )

  const itemsToLeftWithBadge = items.filter(
    (item, index) => index < activeItemIndex && item.badge
  )
  const itemsToRightWithBadge = items.filter(
    (item, index) => index > activeItemIndex && item.badge
  )

  return (
    <Box
      sx={{
        backgroundColor: 'background',
        position: 'sticky',
        top: '0px',
        width: '100%',
        px: '7px',
        borderBottom: 'default',
        zIndex: 100
      }}
    >
      <Flex
        sx={{
          alignItems: 'flex-start',
          gap: '5px',
          justifyContent: 'space-between',
          padding: '10px 0px'
        }}
      >
        {(isOverflowing || mobile) && (
          <Button
            sx={{
              bg: 'background',
              marginLeft: '-15px',
              zIndex: 101 // Ensure the button is above the Nav container
            }}
            disabled={activeItemIndex === 0}
            onClick={() => handleNext('left')}
            icon="left arrow"
            iconColor="text"
            variant={
              itemsToLeftWithBadge.length > 0
                ? itemsToLeftWithBadge[0].color || 'danger'
                : 'secondary'
            }
            outline
          />
        )}
        <Flex
          ref={navRef}
          sx={{
            alignItems: 'center',
            overflowX: 'auto',
            gap: '5px',
            padding: ['0px 5px', null, null, '0px'],
            scrollBehavior: 'smooth',
            '-webkit-overflow-scrolling': 'touch', // Improves scrolling on iOS devices
            position: 'relative', // Makes sure the container allows for absolute positioning
            width: [
              'calc(100% - 30px)',
              null,
              null,
              'calc(100% - 34px)'
            ],
            margin: 'auto'
            // Make sure there's enough items to overflow or set a min-width to test
          }}
        >
          {mobile ? (
            <FormNavItem
              fullWidth
              isActive={activeItem}
              item={
                activeItem || {
                  label: 'Jump To Section'
                }
              }
              onClick={() => {
                setDropdownVisible(!dropdownVisible)
              }}
            />
          ) : (
            items.map((item, index) => (
              <div ref={itemRefs.current[index]} key={item.id}>
                <FormNavItem
                  item={item}
                  isActive={activeSectionId === item.id}
                  onClick={scrollToSection}
                />
              </div>
            ))
          )}
        </Flex>
        {(isOverflowing || mobile) && (
          <Button
            sx={{
              bg: 'background',
              marginRight: '-15px',
              zIndex: 101 // Ensure the button is above the Nav container
            }}
            disabled={activeItemIndex === items.length - 1}
            variant={
              itemsToRightWithBadge.length > 0
                ? itemsToRightWithBadge[0].color || 'danger'
                : 'secondary'
            }
            iconColor="text"
            outline
            onClick={() => handleNext('right')}
            icon="right arrow"
          />
        )}
      </Flex>
      {mobile && (
        <Collapse
          isOpen={dropdownVisible}
          styles={{
            backgroundColor: 'background',
            overflowY: 'scroll'
          }}
          maxHeight="300px"
          bordered
          absolute
        >
          {items.map(item => (
            <Flex
              key={item.id}
              sx={{
                padding: '10px',
                borderBottom: '1px solid #ccc',
                cursor: 'pointer',
                maxLines: 1,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: item.badge ? item.color : 'text',
                alignItems: 'center',
                gap: '5px'
              }}
              onClick={() => {
                scrollToSection(item.id)
                setDropdownVisible(false)
              }}
            >
              {item.badge && (
                <Badge
                  size="md"
                  sx={{
                    py: '1px',
                    px: '0px',
                    width: '20px',
                    minWidth: '20px'
                  }}
                  variant={item.color}
                >
                  {item.badge}
                </Badge>
              )}
              {item.label}
            </Flex>
          ))}
        </Collapse>
      )}
    </Box>
  )
}

const FormNavItem = ({ item, isActive, onClick, fullWidth }) => {
  return (
    <NavItem
      sx={{
        cursor: 'pointer',
        padding: '8px 10px',
        minWidth: fullWidth ? '100%' : 'auto',
        maxWidth: fullWidth ? '100%' : 'auto',
        backgroundColor: isActive ? 'text' : 'background',
        color: isActive ? 'background' : 'text',
        border: '1px solid',
        borderColor: item.color || 'text',
        borderRadius: '5px',

        display: 'block',
        textAlign: 'center',
        fontWeight: isActive ? 'bold' : 'normal'
      }}
      type="button"
      onClick={() => onClick(item.id)}
      key={item.id}
    >
      <Flex
        sx={{
          minWidth: '140px',
          maxWidth: fullWidth ? '100%' : '150px',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textAlign: 'center',
          gap: '10px'
        }}
      >
        {item.badge && (
          <Badge
            size="md"
            sx={{
              py: '1px',
              px: '0px',
              width: '20px',
              minWidth: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            variant={item.color}
          >
            {item.badge}
          </Badge>
        )}{' '}
        {item.label}
      </Flex>
    </NavItem>
  )
}

export default FormNavigation
