/** @jsxImportSource theme-ui */
import { Flex } from 'theme-ui'

import { prettyDate } from 'src/utils'
import Badge from '../Shared/Elements/Badge'
import Icon from '../Shared/Icon'

const BulletinLiveUntilBadge = ({ empty, ...bulletin }) => {
  return (
    <Flex
      sx={{
        gap: '10px',
        alignItems: 'flex-start',
        flexDirection: 'column'
      }}
    >
      <Badge
        reverse
        size="sm"
        variant={bulletin.isActive ? 'success' : 'secondary'}
      >
        {bulletin.isActive && (
          <Icon
            width="16px"
            height="16px"
            icon="pin"
            color="success"
            sx={{ mr: '5px' }}
          />
        )}
        {bulletin.isActive
          ? `Pinned until ${prettyDate(bulletin.liveUntil)} `
          : empty || ``}
      </Badge>
    </Flex>
  )
}

export default BulletinLiveUntilBadge
