/** @jsxImportSource theme-ui */
import { useState } from 'react'
import { useGetCurrentAgencyContext } from 'src/app/hooks/queries'
import { useNetworkStatus } from 'src/app/hooks/useNetworkStatus'
import Spinner from 'src/images/icons/Spinner'
import { Box, Flex } from 'theme-ui'
import { CenteredContent } from '..'
import DailyAssignmentLabel from '../DailyAssignmentLabel'
import DailyAssignmentForm from '../Forms/DailyAssignmentForm'
import CustomTabs from '../Shared/CustomTabs'
import Button from '../Shared/Elements/Button'
import Icon from '../Shared/Icon'
import ModalButton from '../Shared/ModalButton'
import SubmissionModalLayout from '../Shared/SubmissionModalLayout'
import BulkIncident from './BulkIncident'
import EditSubmissionsList from './EditSubmissionsList'
import { QuickSurvey } from './QuickSurvey'
import TappableFormList from './TappableFormList'

const SubmissionPageTabs = ({ ...props }) => {
  const isOnline = useNetworkStatus()
  const [editMode, setEditMode] = useState(false)

  const { error, currentAgency } = useGetCurrentAgencyContext()

  if (error && isOnline && !error?.includes('offline')) {
    return (
      <CenteredContent>
        Oops, there was an error. Try reloading your browser.
      </CenteredContent>
    )
  }
  if (!currentAgency) {
    return <Spinner centered />
  }

  const tappableForms = currentAgency?.tappableForms

  return (
    <Box>
      <Flex
        sx={{
          justifyContent: 'space-between',
          pb: 35,
          width: '100%'
        }}
      >
        <Button
          variant="secondary"
          onClick={() => {
            setEditMode(!editMode)
          }}
        >
          {editMode ? (
            <>
              <Icon
                icon="up arrow"
                style={{
                  transform: 'rotate(-90deg)'
                }}
                color="white"
                height={15}
              />{' '}
              Submit New
            </>
          ) : (
            'Edit Recent Submissions'
          )}
        </Button>

        <Flex sx={{ alignItems: 'center', gap: '4px' }}>
          <ModalButton
            buttonProps={{ variant: 'primary' }}
            buttonLabel={
              <>
                Responding: <DailyAssignmentLabel />
              </>
            }
          >
            {({ toggle }) => (
              <DailyAssignmentForm onFinish={toggle} />
            )}
          </ModalButton>
        </Flex>
      </Flex>
      {editMode ? (
        <EditSubmissionsList />
      ) : (
        <CustomTabs
          tabs={[
            {
              linkTitle: 'Single Incident',
              component: (
                <Box sx={{ pt: '20px', margin: 'auto' }}>
                  <QuickSurvey
                    extendedQuickSurvey
                    includeResponseInputs={true}
                    {...props}
                  />
                </Box>
              )
            },
            {
              linkTitle: 'Daily Log (Bulk)',
              component: (
                <Box
                  sx={{
                    pt: '20px',
                    maxWidth: '700px',
                    margin: 'auto'
                  }}
                >
                  <BulkIncident />
                </Box>
              )
            },
            {
              linkTitle: 'Forms',
              component: (
                <Box
                  sx={{
                    py: '20px',
                    maxWidth: '700px',
                    margin: 'auto'
                  }}
                >
                  <TappableFormList
                    label="What would you like to log?"
                    options={tappableForms || []}
                    onFinish={() => clearForm({ all: true })}
                  />
                </Box>
              )
            }
          ]}
        />
      )}
    </Box>
  )
}

const SubmissionPageTabsWithModal = props => {
  return (
    <SubmissionModalLayout>
      <SubmissionPageTabs {...props} />{' '}
    </SubmissionModalLayout>
  )
}

export default SubmissionPageTabsWithModal
