/** @jsxImportSource theme-ui */
import { useEffect, useState } from 'react'
import { secondsToDhms } from 'src/utils'
import { Flex } from 'theme-ui'
import Input from './Input'

function convertDhmstoSeconds(days, hours, minutes, seconds) {
  if (!days && !hours && !minutes && !seconds) return null
  return (
    (days || 0) * 86400 +
    (hours || 0) * 3600 +
    (minutes || 0) * 60 +
    (seconds || 0)
  )
}

export function TimeDurationInput({ value, onChange }) {
  const [d, h, m, s] = secondsToDhms(value)
  const [duration, setDuration] = useState(value)
  const [days, setDays] = useState(d)
  const [hours, setHours] = useState(h)
  const [minutes, setMinutes] = useState(m)
  const [seconds, setSeconds] = useState(s)

  useEffect(() => {
    const v = convertDhmstoSeconds(days, hours, minutes, seconds)
    if (v && v !== duration && !isNaN(v)) {
      setDuration(v)
      onChange(v)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [days, hours, minutes, seconds])

  return (
    <>
      <Flex>
        <Input
          step={1}
          type="number"
          value={days}
          placeholder="d"
          onChange={e => {
            const val = parseInt(e.target.value)

            setDays(val)
          }}
          min={'0'}
        />
        <Input
          type="number"
          value={hours}
          placeholder="hrs"
          onChange={e => {
            const val = parseInt(e.target.value)

            setHours(val > 23 ? 23 : val)
          }}
          step={1}
          min={'0'}
          max={'24'}
        />
        <Input
          step={1}
          type="number"
          value={minutes}
          placeholder="mins"
          onChange={e => {
            const val = parseInt(e.target.value)

            setMinutes(val > 59 ? 59 : val)
          }}
          min={'0'}
          max={'59'}
        />
        <Input
          step={1}
          type="number"
          value={seconds}
          placeholder="sec"
          onChange={e => {
            const val = parseInt(e.target.value)

            setSeconds(val > 59 ? 59 : val)
          }}
          min={'0'}
          max={'59'}
        />
      </Flex>
      {days || 0}d {hours || 0}h {minutes || 0}m {seconds || 0}s
    </>
  )
}
