import { useEffect, useState } from 'react'
import useGoogleMapMarker from '../../../hooks/useGoogleMapMarker'

const activeIcon =
  'https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png'
const inactiveIcon =
  'https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png'

export default function Marker({
  position,
  type,
  maps,
  map,
  events = {},
  active = false,
  title,
  markerSettings = {}
}) {
  const [currentPosition, setCurrentPosition] = useState()
  const marker = useGoogleMapMarker({
    position,
    type,
    maps,
    map,
    events,
    title,
    markerSettings
  })

  useEffect(() => {
    marker &&
      setCurrentPosition(prev => {
        if (prev !== position && position !== currentPosition) {
          marker.setPosition(position)
          return position
        }
      })
    marker &&
      (active
        ? marker.setIcon(activeIcon)
        : marker.setIcon(inactiveIcon))
    // eslint-disable-next-line
  }, [active, position])

  return null
}
