/** @jsxImportSource theme-ui */

import { useLogout } from '../services/auth'
import Button from '../components/Shared/Elements/Button'
import { CenteredContent } from '../components'
import { Box, Flex } from 'theme-ui'
import QueryString from 'qs'
import Icon from '../components/Shared/Icon'
import { useSmallChat } from 'src/components/SupportButton'

const AuthError = () => {
  const { openChat } = useSmallChat()
  const { message } = QueryString.parse(window.location.search, {
    ignoreQueryPrefix: true
  })
  const { logout } = useLogout()

  return (
    <CenteredContent>
      <Box
        variant="card"
        sx={{
          width: '100%',
          maxWidth: '600px',
          padding: '45px 20px'
        }}
      >
        <CenteredContent height={300} sx={{ textAlign: 'center' }}>
          <Icon icon="lost" color="warning" />
          <h3 sx={{ mt: '20px' }}>{message || 'Auth Error'}</h3>
          <p>
            Looks like your account failed to login properly or isn't
            connected to an agency yet
          </p>
          <Flex sx={{ mt: 20, gap: '20px', alignItems: 'center' }}>
            <Button
              onClick={() => {
                openChat()
              }}
            >
              Reach out to support
            </Button>
            or
            <Button variant="link" onClick={logout}>
              Go to login
            </Button>
          </Flex>
        </CenteredContent>
      </Box>
    </CenteredContent>
  )
}

export default AuthError
