import { Dashboard, DragDrop } from '@uppy/react'

import ImageEditor from '@uppy/image-editor'
import CustomModal from '../Elements/Modal'

const UppyPicker = ({ uppy, open, handleClose }) => {
  return (
    <CustomModal checkOnClose toggle={handleClose} isOpen={open}>
      <Dashboard
        uppy={uppy}
        plugins={['Audio', 'Webcam', 'Url', 'ImageEditor']}
        proudlyDisplayPoweredByUppy={false}
        style={{ marginTop: '40px' }}
      >
        <ImageEditor />
      </Dashboard>
    </CustomModal>
  )
}

export const UppyInlinePicker = ({ handleClose }) => {
  return <Dashboard uppy={uppy} onRequestCloseModal={handleClose} />
}

export const UppyDropPane = ({ uppy }) => {
  return <DragDrop uppy={uppy} />
}

export default UppyPicker
