import Spinner from 'src/images/icons/Spinner'
import MFAEnableForm from './MFAEnableForm'
import OtpVerificationForm from './OtpVerificationForm'

export default ({ mfa, mfaOptions, defaultPhone }) => {
  return mfa === 'ENABLE' ? (
    <MFAEnableForm phone={defaultPhone} {...mfaOptions} />
  ) : mfa ? (
    <OtpVerificationForm {...mfaOptions} />
  ) : (
    <Spinner centered />
  )
}
