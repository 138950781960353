/** @jsxImportSource theme-ui */
import { memo, useState } from 'react'
import { Flex } from 'theme-ui'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import styled from '@emotion/styled'

const CustomTabs = styled(Tabs)`
  @media (max-width: 768px) {
    .css-1vsu51l {
      display: block;
      width: 100%;
      white-space: nowrap;
      overflow-x: auto;
      padding-bottom: 5px;
    }
  }
`

export default memo(
  ({
    tabs,
    tabNavOptions,
    navItemComponent,
    activeTabIndex,
    activeTab: controlledActiveTab,
    setActiveTab: setControlledActiveTab
  }) => {
    const [localActiveTab, setLocalActiveTab] = useState(() => {
      if (tabs.length - 1 < activeTabIndex) return 0
      return activeTabIndex || 0
    })

    const activeTab = controlledActiveTab || localActiveTab
    const setActiveTab = setControlledActiveTab || setLocalActiveTab

    return (
      <CustomTabs selectedIndex={activeTab} onSelect={setActiveTab}>
        <Flex
          sx={{
            justifyContent: 'space-between',
            flexWrap: 'wrap-reverse',
            position: 'relative'
          }}
        >
          <TabList
            sx={{
              borderBottom: 'default',
              margin: '0px',
              padding: '0px',
              position: 'relative'
            }}
            {...tabNavOptions}
          >
            <TabNav
              tabs={tabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </TabList>
          {navItemComponent && (
            <Flex
              sx={{
                listStyle: 'none',
                flex: 1,
                minWidth: '100px',
                pb: ['20px', null, null, '0px'],
                alignItems: 'center',
                justifyContent: 'flex-end'
              }}
            >
              {navItemComponent(tabs[activeTab])}
            </Flex>
          )}
        </Flex>
        {tabs.map((t, k) => {
          return (
            <TabPanel key={k} tabId={`${k}`}>
              {t.component}
            </TabPanel>
          )
        })}
      </CustomTabs>
    )
  }
)

export const TabNav = ({ tabs, activeTab, setActiveTab }) => {
  return tabs.map((t, k) => {
    const isActiveTab = activeTab === k

    return (
      <Tab
        onClick={e => {
          setActiveTab(k)
        }}
        key={k}
        sx={{
          cursor: 'pointer',
          pr: '10px',
          pl: '10px',
          mx: '5px',
          py: '10px',
          minWidth: '100px',
          textAlign: 'center',
          display: 'inline-block',
          border: 'default',
          borderBottom: 'none',
          bottom: '-1px',
          position: 'relative',
          listStyle: 'none',
          fontWeight: isActiveTab && '600',
          pt: '12px',
          bg: isActiveTab ? 'text' : 'background',
          color: isActiveTab ? 'background' : 'text',
          borderTopLeftRadius: '0.2rem',
          borderTopRightRadius: '0.2rem',
          minHeight: '40px'
        }}
      >
        {t.linkTitle}
      </Tab>
    )
  })
}
