function Restore(props) {
  const title = props.title || 'restore'

  return (
    <svg
      height={props.height || '24'}
      width={props.width || '24'}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g
        fill={props.color || '#F7F7F7'}
        stroke={props.color || '#F7F7F7'}
        strokeLinecap="square"
        strokeLinejoin="miter"
        strokeMiterlimit="10"
        strokeWidth="2"
      >
        <polyline
          fill="none"
          points="12 7 12 12 16 16"
          stroke="inherit"
        />
        <path
          d="M1,12A11,11,0,1,0,4.45,4"
          fill="none"
          stroke={props.color || '#F7F7F7'}
          strokeLinecap="butt"
        />
        <polygon
          fill={props.color || '#F7F7F7'}
          points="3 0 0.6 6.8 7.8 6.4 3 0"
          stroke="none"
        />
      </g>
    </svg>
  )
}

export default Restore
