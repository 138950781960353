function CheckList(props) {
  const fill = props.fill || 'currentColor'
  const secondaryfill = props.secondaryfill || fill
  //   const strokewidth = props.strokewidth || 1
  const width = props.width || '100%'
  const height = props.height || '100%'
  const title = props.title || 'check list'

  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill={secondaryfill}>
        <path
          d="M26,11H60a1,1,0,0,0,0-2H26a1,1,0,0,0,0,2Z"
          fill={fill}
        />
        <path d="M60,31H26a1,1,0,0,0,0,2H60a1,1,0,0,0,0-2Z" />
        <path
          d="M60,53H26a1,1,0,0,0,0,2H60a1,1,0,0,0,0-2Z"
          fill={fill}
        />
        <rect
          height="14"
          width="14"
          fill={fill}
          rx="1"
          ry="1"
          x="4"
          y="3"
        />
        <rect
          height="14"
          width="14"
          fill={fill}
          rx="1"
          ry="1"
          x="4"
          y="47"
        />
        <path d="M19.207,23.391,9.9,35.487,5.707,31.293a1,1,0,0,0-1.414,1.414l5,5A1,1,0,0,0,10,38l.064,0a1,1,0,0,0,.729-.389l10-13a1,1,0,1,0-1.586-1.218Z" />
      </g>
    </svg>
  )
}

export default CheckList
