/** @jsxImportSource theme-ui */

import { lazy, Suspense, useMemo } from 'react'
import styled from '@emotion/styled'
import { useQuery } from '@apollo/client'
import { cleanGraphQLError } from '../components/Forms/FormError'
import { Box, Flex } from 'theme-ui'
import {
  GET_HOMEBASE_INFO,
  useGetCurrentAgencyContext
} from '../hooks/queries'
import get from 'lodash/get'
import { CenteredContent } from '../components'
import DispatchStatusColor, {
  DispatchColorKey
} from '../components/Dispatch/DispatchStatusColor'
import { prettyDate } from 'src/utils'
import { RolesOnly } from '../services/auth'
import { CreateBulletinModalButton } from '../components/Forms/CreateBulletin'
import BulletinList from '../components/HomeBase/Bulletin'

import WeatherWidget from '../components/HomeBase/WeatherWidget'
import PayWallScreen from '../components/PayWalls/PayWallScreen'

import ImportantSubmissionList from '../components/Reporting/ImportantSubmissionList'
import moment from 'moment'
import DocumentList from '../components/Operations/Documents/DocumentList'
import FormList from '../components/Operations/Forms/FormList'
import { useNetworkStatus } from '../hooks/useNetworkStatus'
import Card from '../components/Shared/Elements/Card'
import ListGroup from '../components/Shared/Elements/ListGroup'
import ListGroupItem from '../components/Shared/Elements/ListGroupItem'
import ListGroupItemHeading from '../components/Shared/Elements/ListGroupItemHeading'

import { DISPATCH_SUB } from '../hooks/subscriptions'

import NWSAlets from '../components/HomeBase/NwsAlerts'
import Spinner from 'src/images/icons/Spinner'
import OfflineDisabledInlineTip from '../components/Shared/OfflineDisabledInlineTip'
import { useNavigate } from 'react-router'
import SubscriptionButton from '../components/Shared/SubscriptionButton'

const StyledListGroupItem = styled(ListGroupItem)`
  ${p => `border-left: 3px solid ${p.theme.colors[p.accent]};`},
`

const HomeBase = () => {
  const isOnline = useNetworkStatus()

  const {
    error: agencyError,
    currentAgency,
    loading
  } = useGetCurrentAgencyContext()

  const e = agencyError && cleanGraphQLError(agencyError.message)

  if (e && isOnline)
    return (
      <CenteredContent>
        Oops, there was an error. Try reloading your browser.
      </CenteredContent>
    )
  if (!currentAgency) return <Spinner centered />

  return (
    <Box>
      <OfflineDisabledInlineTip />
      {!loading && <NWSAlets />}

      <Flex
        sx={{
          flexDirection: 'row',
          justifyContent: 'stretch',
          gap: '20px',
          flexWrap: 'wrap'
        }}
      >
        <PayWallScreen featureLabel="comms" hideContent>
          <HomeBaseLeftPanel />
        </PayWallScreen>
        <HomeBaseRightPanel currentAgency={currentAgency} />
      </Flex>
    </Box>
  )
}

const HomeBaseLeftPanel = () => {
  return (
    <Box
      sx={{
        margin: '0 auto 0 0',
        mb: ['50px', null, null, null, null, '0px'],
        pb: 20,
        flex: 1,
        minWidth: '300px'
      }}
    >
      <Flex
        sx={{
          width: '100%',
          py: '10px',
          height: '50px',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <h5>Bulletins</h5>
        <RolesOnly roles={['ADMIN', 'SUPERVISOR']}>
          <CreateBulletinModalButton />
        </RolesOnly>
      </Flex>

      <BulletinList />
    </Box>
  )
}

const HomeBaseRightPanel = ({ currentAgency }) => {
  const navigate = useNavigate()
  const startDate = moment(new Date())
    .startOf('D')
    .toISOString()
  const endDate = moment(new Date())
    .endOf('D')
    .toISOString()

  const { data, loading, refetch } = useQuery(GET_HOMEBASE_INFO, {
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        incidentTime: {
          gt: startDate,
          lt: endDate
        },
        important: {
          equals: true
        }
      }
    }
  })

  const importantIncidents = get(data, 'myAgenciesSubmissions', [])

  const activeDispatches = get(
    data || [],
    'myAgenciesSubmissionsWithActiveDispatches',
    []
  )

  const docList = useMemo(
    () => (
      <DocumentList
        pinnedOnly
        hideSearch
        hideLoadMore
        hideCreateButton
        hidePin
        hideEdit
      />
    ),
    []
  )

  const formList = useMemo(
    () => (
      <FormList
        pinnedOnly
        hideSearch
        hideLoadMore
        hideEdit
        hidePin
        hid
        hideCreateButton
      />
    ),
    []
  )

  return (
    <Box
      sx={{
        alignItems: 'stretch',
        flexDirection: 'column',
        margin: '0 0 0 auto',
        flex: 1,
        minWidth: '300px',
        mb: ['50px', null, null, null, null, '0px']
      }}
    >
      <Box sx={{ mb: 40, width: '100%' }}>
        <PayWallScreen featureLabel="forms" hideContent>
          <Box
            sx={{ py: '10px', height: '50px', alignItems: 'center' }}
          >
            <h5>Documents</h5>
          </Box>
          {docList}
        </PayWallScreen>
      </Box>
      <Box sx={{ mb: 40, width: '100%' }}>
        <PayWallScreen featureLabel="forms" hideContent>
          <Box
            sx={{ py: '10px', height: '50px', alignItems: 'center' }}
          >
            <h5 sx={{ pb: 10 }}>Forms</h5>
          </Box>
          {formList}
        </PayWallScreen>
      </Box>
      <PayWallScreen hideContent featureLabel="dispatching">
        <Box sx={{ mb: 40, width: '100%' }}>
          <Flex
            sx={{
              justifyContent: 'space-between',
              py: '10px',
              height: '50px',
              alignItems: 'center'
            }}
          >
            <h5
              sx={{
                display: 'flex',
                gap: '4px',
                alignItems: 'center'
              }}
            >
              Active Incidents{' '}
              <SubscriptionButton
                startOn
                minimal
                variables={{
                  agencyId: currentAgency.id
                }}
                query={DISPATCH_SUB}
                onSubscriptionData={() => {
                  // allow for cache to update...
                  setTimeout(() => refetch(), 300)
                }}
                externalLoading={loading}
              />
            </h5>
            <DispatchColorKey />
          </Flex>

          <Card>
            <ListGroup sx={{ padding: '1rem' }}>
              {activeDispatches && activeDispatches.length > 0 ? (
                activeDispatches.map(p => {
                  return (
                    <StyledListGroupItem
                      style={{ cursor: 'pointer' }}
                      onClick={() => navigate('/agency/dispatches')}
                      key={p.id}
                    >
                      <Flex
                        sx={{
                          justifyContent: 'space-between'
                        }}
                      >
                        <ListGroupItemHeading>
                          {prettyDate(p.incidentTime, 'HH:mm')} -{' '}
                          {p.type && p.type.label}{' '}
                        </ListGroupItemHeading>

                        <ListGroupItemHeading>
                          {p.location &&
                            p.location.position &&
                            `${p.location.position.name}`}
                        </ListGroupItemHeading>
                      </Flex>
                      <Flex>
                        {p.dispatches.map(d => (
                          <DispatchStatusColor
                            style={{ marginRight: '3px' }}
                            oScene={d.onScene}
                            completedAt={d.completedAt}
                            enRoute={d.enRoute}
                          >
                            {d.unit ? d.unit.name : 'Unassigned'}
                          </DispatchStatusColor>
                        ))}
                      </Flex>
                    </StyledListGroupItem>
                  )
                })
              ) : (
                <Box>
                  <CenteredContent height={'200px'}>
                    {loading && <Spinner />}
                    No active calls{' '}
                  </CenteredContent>
                </Box>
              )}
            </ListGroup>
          </Card>
        </Box>
      </PayWallScreen>
      <Box sx={{ mb: 40, width: '100%' }}>
        <h5>Today's Notable Incidents</h5>
        <Box variant="card">
          <ImportantSubmissionList
            data={importantIncidents}
            loading={loading}
          />
        </Box>
      </Box>
      <Box sx={{ mb: 40, width: '100%' }}>
        <h5>Weather</h5>
        <Box variant="card">
          <WeatherWidget
            {...currentAgency.settings}
            timezone={currentAgency.timezone}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default HomeBase
