/** @jsxImportSource theme-ui */
import { css, keyframes } from '@emotion/react'
import { Box } from 'theme-ui'
import styled from '@emotion/styled'
import { theme } from 'src/styles'

const pulsingCss = color =>
  keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 ${theme.colors[color] || color};
    opacity: 0.7;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  `

const Pulse = styled(Box)`
  transform: ${props => !props.noPulse && css`scale(1)`};
  animation: ${props => !props.noPulse && pulsingCss(props.color)} 2s
    infinite;
`

export const PulingIndicator = ({
  color,
  large = false,
  noPulse,
  ...props
}) => {
  return (
    <Pulse
      id={props.id}
      key={props.id}
      color={color}
      noPulse={noPulse}
      sx={{
        width: large ? 15 : 10,
        height: large ? 15 : 10,
        bg: color,
        borderRadius: '30px',
        ...props.sx
      }}
    />
  )
}
