import moment from 'moment'

export function optionalChaining(func) {
  try {
    return func()
  } catch (e) {
    return undefined
  }
}

export const sevenDaysAgo = moment()
  .subtract(7, 'days')
  .startOf('day')
export const thirtyDaysAgo = moment()
  .subtract(30, 'days')
  .startOf('day')

export const sortByDate = (field, order) => (a, b) => {
  const aDate = new Date(a[field] || a.createdAt).getTime()
  const bDate = new Date(b[field] || b.createdAt).getTime()

  return order === 'asc' ? aDate - bDate : bDate - aDate
}

export function secondsToDhms(seconds) {
  const d = Math.floor(seconds / (3600 * 24))
  const h = Math.floor((seconds % (3600 * 24)) / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  const s = Math.floor(seconds % 60)
  return [d, h, m, s]
}

export const formatDuration = (secs, opts) => {
  const [d, h, m, s] = secondsToDhms(secs)

  if (opts && opts.long) {
    return `${d} days ${h} hours ${m} minutes ${s} seconds`
  }
  return `${d}d ${h}h ${m}m ${s}s`
}
// .format('H:mm:ss')

export function prettyDate(date, format) {
  return date
    ? prettyDateFromMoment(moment(new Date(date)), format)
    : null
}

export function prettyDateFromMoment(momentDate, format) {
  if (format) {
    return momentDate ? momentDate.format(format) : null
  }

  let f = format

  if (window.navigator.language !== 'en-US') {
    f = 'ddd DD/MM/YYYY HH:mm'
  } else {
    f = 'ddd MM/DD/YYYY HH:mm'
  }

  return momentDate ? momentDate.format(f) : null
}

export const Window = typeof window !== 'undefined' && window

export function formatDur(val, allowUndefined) {
  if (typeof val === 'number' && val < 1) return 'Instant'

  return val
    ? formatDuration(val)
    : allowUndefined
    ? undefined
    : 'No Data'
}

export function stripTimezone(datetime) {
  return datetime
    ? moment(datetime).format('YYYY-MM-DD HH:mm:ss')
    : null
}

export function triggerDownload(assetUrl, title, imageFormat) {
  const link = document.createElement('a')
  link.setAttribute('href', assetUrl)
  const t = `Download-${prettyDate(moment())}`
  switch (imageFormat) {
    case 'toPng':
      link.download = `${t}.png`
      break
    case 'toJpeg':
      link.download = `${t}.jpeg`
      break
    case 'toSvg':
      link.download = `${t}.svg`
      break
  }

  link.click()
}

export function fileNameGenerator(
  assetType,
  assetDescription,
  dateRange
) {
  const prettyDates = dateRange.map(date =>
    moment(date).format(moment.HTML5_FMT.DATE)
  )

  const strippedPrettyDate = prettyDates.every(
    date => date === prettyDates[0]
  )
    ? [prettyDates[0]]
    : prettyDates

  const fileNameForDownload = `${assetType}_${assetDescription}_${strippedPrettyDate.join(
    '_'
  )}`

  return fileNameForDownload
}

export function retry(fn, retriesLeft = 3, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error)
            window.location.reload()
            return
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject)
        }, interval)
      })
  })
}

export function addOrdinalSuffix(number) {
  const lastDigit = number % 10
  const lastTwoDigits = number % 100

  if (lastDigit === 1 && lastTwoDigits !== 11) {
    return number + 'st'
  } else if (lastDigit === 2 && lastTwoDigits !== 12) {
    return number + 'nd'
  } else if (lastDigit === 3 && lastTwoDigits !== 13) {
    return number + 'rd'
  } else {
    return number + 'th'
  }
}

export function removeSearchParams() {
  const url = new URL(window.location.href)
  url.search = ''
  const newUrl = url.toString()
  window.history.replaceState({}, '', newUrl)
}

function getFileExtensionFromUrl(url) {
  const pathArray = url.split('/')
  const lastPath = pathArray[pathArray.length - 1]
  const filenameArray = lastPath.split('?')[0].split('.')

  if (filenameArray.length > 1) {
    return filenameArray.pop().toLowerCase()
  } else {
    return null // No file extension found
  }
}

export function getContentTypeFromFileName(fileName) {
  const contentTypeMap = {
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    gif: 'image/gif',
    pdf: 'application/pdf',
    txt: 'text/plain',
    html: 'text/html',
    json: 'application/json',
    csv: 'text/csv',
    xlsx:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    doc: 'application/msword',
    docx:
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ppt: 'application/vnd.ms-powerpoint',
    pptx:
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    xls: 'application/vnd.ms-excel',
    mp4: 'video/mp4',
    m4a: 'audio/x-m4a',
    webp: 'image/webp',
    zip: 'application/x-zip-compressed',
    ttf: 'font/ttf',
    mov: 'video/quicktime',
    heic: 'image/heic',
    msg: 'application/vnd.ms-outlook'
  }

  const fileExtension = getFileExtensionFromUrl(fileName)

  if (contentTypeMap.hasOwnProperty(fileExtension)) {
    return contentTypeMap[fileExtension]
  } else {
    return null
  }
}
