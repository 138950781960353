/** @jsxImportSource theme-ui */

import { useEffect, useState } from 'react'
import { CenteredContent, Space } from '../components/'

import { gql, useLazyQuery } from '@apollo/client'

import useForm from '../hooks/useForms'
import { useMutation } from '@apollo/client'
import { checkRoleMatch, useHandleAuthTokens } from '../services/auth'
import { Link } from 'react-router-dom'

import FormError from '../components/Forms/FormError'
import { theme } from 'src/styles'

import ForgotPasswordForm from '../components/LoginScreen/ForgotPasswordForm'
import { Box, Flex } from 'theme-ui'
import { useLocation, useNavigate } from 'react-router'

import Button from '../components/Shared/Elements/Button'
import Label from '../components/Shared/Elements/Label'
import Input from '../components/Shared/Elements/Input'
import FormGroup from '../components/Shared/Elements/FormGroup'

import EmailMagicLinkSuccess from '../components/Shared/EmailMagicLinkSuccess'
import { Divider } from '../components/Shared'
import { ConnectedAccountButtons } from '../components/Shared/ConnectedAccountButtons'
import { PasswordInput } from '../components/Shared/Elements/Input'
import { useMFAFlow } from '../hooks/useMFAFlow'
import {
  GET_LOGIN_METHODS_BY_EMAIL,
  useGetCurrentAgencyContext
} from '../hooks/queries'
import { AUTH_PAYLOAD_FRAGMENT } from '../hooks/fragments'
import MFABranch from '../components/LoginScreen/MFABranch'
import config from 'src/config'

const LOGIN = gql`
  mutation login(
    $email: String!
    $password: String!
    $rememberMe: Boolean
  ) {
    login(
      email: $email
      password: $password
      rememberMe: $rememberMe
    ) {
      ...AuthPayloadFragment
    }
  }
  ${AUTH_PAYLOAD_FRAGMENT}
`

export const LOGIN_WITHOUT_PASSWORD = gql`
  mutation loginWP($email: String!) {
    loginWP(email: $email) {
      success
    }
  }
`

const Login = () => {
  const [allowedLogins, setAllowedLogins] = useState()
  const { user } = useGetCurrentAgencyContext()
  const [loading, setLoading] = useState(false)
  const { handleTokens } = useHandleAuthTokens()
  const [attempts, setAttempts] = useState(0)
  const [forgotPassword, setForgotPassword] = useState(false)
  const [magicLinkSent, setMagicLinkSent] = useState(false)
  const { mfa, triggerMfa, mfaOptions } = useMFAFlow(
    afterVerification
  )
  const [defaultPhone, setDefaultPhone] = useState('')
  const navigate = useNavigate()
  const location = useLocation()

  const [login, { error }] = useMutation(LOGIN)

  const [
    getAllowedLogins,
    { loading: allowedLoginsLoading, error: allowedLoginError }
  ] = useLazyQuery(GET_LOGIN_METHODS_BY_EMAIL, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only'
  })

  const [
    loginWP,
    { loading: wpLoading, error: wpError }
  ] = useMutation(LOGIN_WITHOUT_PASSWORD)

  const {
    values,
    handleChange,
    errors: formErrors,
    setValues,
    handleSubmit
  } = useForm(onLogin)

  const {
    values: wpValues,
    handleChange: wpHandleChange,

    setValues: wpSetValues,
    handleSubmit: wpHandleSubmit
  } = useForm(onLoginWP)

  function reset() {
    wpSetValues({})
    setValues({})
    setAllowedLogins(null)
  }

  async function onLogin() {
    setLoading(true)
    const res = await login({
      variables: {
        email: values.email,
        password: values.password,
        rememberMe: values.rememberMe
      }
    })

    if (res?.errors?.[0]?.message?.includes('Incorrect password')) {
      setAttempts(attempts + 1)
    }
    const newToken = res?.data?.login?.token
    const newSession = res?.data?.login?.session
    const agency = res?.data?.login?.user?.agency
    const mfaEnabled = res?.data?.login?.mfaEnabled
    const mfaRequired = res?.data?.login?.mfaRequired
    const role = res?.data?.login?.user?.role

    if (newToken && (mfaEnabled || mfaRequired)) {
      triggerMfa({
        mfaRequired,
        mfaEnabled,
        newToken: newToken,
        organization_id:
          res?.data?.login?.user?.agency?.organizationId,
        user_id: res?.data?.login?.user?.stytchMemberId
      })

      setDefaultPhone(res?.data?.login?.user?.phoneNumber)
    } else if (newToken) {
      await afterVerification(newToken, newSession, agency, role)
    }

    setLoading(false)
  }

  async function onLoginWP() {
    const res = await loginWP({
      variables: {
        email: wpValues.email
      }
    })

    if (res?.data?.loginWP?.success) {
      setMagicLinkSent(true)
    }
  }

  async function afterVerification(
    newToken,
    newSession,
    agency,
    role
  ) {
    await handleTokens(newToken, newSession)
    if (!agency) {
      navigate('/agency/onboarding')
      return
    }

    if (
      location.state &&
      location.state.deepLinkTo &&
      !location.state.deepLinkTo?.includes('expired') &&
      checkRoleMatch(location.state.deepLinkTo, role)
    ) {
      navigate(location.state.deepLinkTo)
    } else {
      navigate(
        role === 'READ_ONLY' ? '/agency' : '/agency/submission'
      )
    }
  }

  async function onEnterEmail(e) {
    try {
      e.preventDefault()
      const res = await getAllowedLogins({
        variables: {
          email: values.email
        }
      })

      if (res?.error) {
        return
      }

      setAllowedLogins(res?.data?.getAllowedLogins || [])
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (user?.agency) {
      navigate('/agency/submission')
    }
  }, [user])

  const ssoConnection = allowedLogins?.find(login =>
    login?.type?.includes('sso')
  )

  const emailPasswordAllowed =
    allowedLogins?.some(login => login.type === 'password') &&
    !ssoConnection

  const magicLinkAllowed =
    allowedLogins?.some(login => login.type === 'magic_link') &&
    !ssoConnection

  const oauthsAllowed =
    !ssoConnection &&
    allowedLogins?.filter(login => login.type?.includes('oauth'))

  return (
    <CenteredContent>
      <Box
        variant="card"
        sx={{
          width: '100%',
          maxWidth: '600px',
          padding: '45px 40px'
        }}
      >
        {mfa ? (
          <MFABranch
            mfa={mfa}
            mfaOptions={mfaOptions}
            defaultPhone={defaultPhone}
          />
        ) : magicLinkSent ? (
          <EmailMagicLinkSuccess
            back={() => setMagicLinkSent(false)}
            email={wpValues.email}
            onRedo={onLoginWP}
            loading={wpLoading}
          />
        ) : forgotPassword ? (
          <ForgotPasswordForm
            setForgotPassword={setForgotPassword}
            back={() => setForgotPassword(false)}
          />
        ) : (
          <>
            <h3>
              Welcome to Watchtower
              {values?.email && allowedLogins ? (
                ''
              ) : (
                <p
                  sx={{
                    mt: 2,
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  Start with your email.
                </p>
              )}
            </h3>
            {location.state && location.state.tokenExpire && (
              <Box sx={{ p: 15, mt: 10, bg: 'gray100' }}>
                Session has expired due to inactivity. Please log back
                in.
              </Box>
            )}
            <Space height={25} />
            <form
              onSubmit={
                values.email && allowedLogins
                  ? handleSubmit
                  : onEnterEmail
              }
            >
              <FormGroup error={formErrors['email']}>
                <Label htmlFor="email">
                  {values?.email && allowedLogins ? (
                    <Flex
                      sx={{
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'center'
                      }}
                    >
                      <p
                        sx={{
                          mb: '0px',
                          fontSize: 2
                        }}
                      >
                        Log in with: <strong>{values.email}</strong>
                      </p>
                      <Button
                        sx={{
                          px: '0px',
                          textDecoration: 'underline'
                        }}
                        variant="link"
                        onClick={() => reset()}
                      >
                        Use a different account
                      </Button>
                    </Flex>
                  ) : (
                    'Email'
                  )}
                </Label>
                <Input
                  id="email"
                  type="email"
                  name="email"
                  sx={{
                    display:
                      values?.email && allowedLogins
                        ? 'none'
                        : 'block'
                  }}
                  onChange={e => {
                    handleChange(e)
                    wpHandleChange(e)
                  }}
                />
                {!emailPasswordAllowed && (
                  <input
                    type="password"
                    name="password"
                    autoComplete="current-password"
                    id="password_hidden"
                    sx={{
                      display: 'none'
                    }}
                  />
                )}
              </FormGroup>

              {!allowedLogins ? (
                <>
                  <Button
                    block
                    variant="primary"
                    size="lg"
                    disabled={!values.email || allowedLoginsLoading}
                    icon={allowedLoginsLoading ? 'spinner' : ''}
                  >
                    Continue with Email
                  </Button>
                  {allowedLoginError && (
                    <FormError error={allowedLoginError.message} />
                  )}
                </>
              ) : (
                <>
                  {ssoConnection && (
                    <Button
                      linkProps={{ target: '_self' }}
                      href={`${config.ssoUrl}?connection_id=${ssoConnection.connectionId}&public_token=${config.STYTCH_PUBLIC_KEY}`}
                      icon="key"
                      block
                      variant="primary"
                      size="lg"
                      type="button"
                    >
                      Sign in with SSO
                    </Button>
                  )}

                  {emailPasswordAllowed && (
                    <>
                      <FormGroup error={formErrors['password']}>
                        <Label htmlFor="password">Password</Label>
                        <PasswordInput
                          type="password"
                          name="password"
                          autoComplete="current-password"
                          id="password"
                          onChange={handleChange}
                          value={values.password}
                        />

                        <Flex
                          sx={{
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            py: '10px'
                          }}
                        >
                          <Input
                            type="checkbox"
                            name="rememberMe"
                            onChange={handleChange}
                            label="Remember Me"
                            value={values.rememberMe}
                          />

                          <Button
                            sx={{
                              textAlign: 'left',
                              color: theme.colors.secondary,
                              paddingRight: '0px'
                            }}
                            type="button"
                            variant="link"
                            onClick={() => setForgotPassword(true)}
                          >
                            Forgot your password?
                          </Button>
                        </Flex>
                      </FormGroup>
                      <Button
                        block
                        variant="primary"
                        size="lg"
                        disabled={
                          !values.email || !values.password || loading
                        }
                        type="submit"
                        icon={loading ? 'spinner' : 'lock'}
                      >
                        Login
                      </Button>
                      {attempts > 5 && (
                        <FormError
                          error={
                            'You may run out of attempts.  Try resetting your password or using the magic link feature.'
                          }
                          warning
                        />
                      )}
                      {error && (
                        <FormError
                          error={error.message}
                          actionText={'Reset here.'}
                          action={
                            error.message?.includes('reset password')
                              ? () => {
                                  setForgotPassword(true)
                                }
                              : false
                          }
                        />
                      )}
                    </>
                  )}

                  {oauthsAllowed && oauthsAllowed?.length && (
                    <Flex
                      sx={{
                        justifyContent: 'space-between',
                        alignItems: 'stretch',
                        pb: '10px',
                        flexDirection: 'column',
                        gap: '10px'
                      }}
                    >
                      {emailPasswordAllowed && <OrLine />}
                      <ConnectedAccountButtons
                        oauths={oauthsAllowed || false}
                        prefix="Sign in with"
                      />
                    </Flex>
                  )}
                  {magicLinkAllowed && (
                    <Flex
                      sx={{
                        justifyContent: 'space-between',
                        alignItems: 'stretch',
                        pb: '10px',
                        flexDirection: 'column',
                        gap: '10px'
                      }}
                    >
                      {(oauthsAllowed?.length > 0 ||
                        emailPasswordAllowed) && <OrLine />}
                      <Box>
                        <Button
                          block
                          variant="primary"
                          onClick={() => {
                            wpHandleSubmit()
                          }}
                          type="button"
                          size="lg"
                          disabled={!wpValues?.email || wpLoading}
                          icon={wpLoading ? 'spinner' : 'lock'}
                        >
                          Securely Login with Magic Link
                        </Button>
                      </Box>
                      {wpError && (
                        <FormError error={wpError.message} />
                      )}
                    </Flex>
                  )}
                </>
              )}
            </form>
          </>
        )}
        <Box sx={{ mt: 3, width: '100%', textAlign: 'center' }}>
          <Link
            style={{ textDecoration: 'none' }}
            to="/agency/onboarding"
          >
            Don't have an account? Click here
          </Link>
        </Box>
      </Box>
    </CenteredContent>
  )
}

const OrLine = () => {
  return (
    <Flex
      sx={{
        mx: 'auto',
        position: 'relative',
        py: 25,
        width: '100%',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Divider
        sx={{
          position: 'absolute',
          top: '0px',
          margin: 'auto',
          left: '0px',
          bottom: '0px',
          right: '0px'
        }}
      />
      <Box
        sx={{
          position: 'relative',
          bg: 'background',
          width: '40px',
          textAlign: 'center',

          margin: 'auto',
          zIndex: 3
        }}
      >
        Or
      </Box>
    </Flex>
  )
}

export default Login
