import React from 'react'

function CompactList(props) {
  const title = props.title || 'Compact List'

  return (
    <svg
      height={props?.height || '48'}
      width={props?.width || '48'}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill={props.color}>
        <path d="M8 28h8v-8H8v8zm0 10h8v-8H8v8zm0-20h8v-8H8v8zm10 10h24v-8H18v8zm0 10h24v-8H18v8zm0-28v8h24v-8H18z" />
      </g>
    </svg>
  )
}

export default CompactList
