/** @jsxImportSource theme-ui */
import { forwardRef } from 'react'
import ReactTooltip from 'react-tooltip'
import styled from '@emotion/styled'

const TooltipStyles = styled.div`
  .react-tooltip {
    text-transform: none;
    max-width: 300px;
    white-space: pre-wrap;
    overflow: hidden;
    text-align: center;
    z-index: 1000000;
  }

  .__react_component_tooltip::before,
  .__react_component_tooltip::after {
    content: none !important;
    pointer-events: auto !important;
  }
`

const ToolTip = ({ children, target, ...p }, ref) => {
  return (
    <TooltipStyles>
      <ReactTooltip
        id={target || 'main'}
        multiline={true}
        place={p.placement || 'top'}
        effect="solid"
        className="react-tooltip"
        {...p}
      >
        {children}
      </ReactTooltip>
    </TooltipStyles>
  )
}

export default forwardRef(ToolTip)
