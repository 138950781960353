/** @jsxImportSource theme-ui */
import { createContext, useEffect, useState } from 'react'
import {
  useCreateForm,
  useUpdateForm
} from '../../../../hooks/mutations'
import useForm from '../../../../hooks/useForms'
import { useToast } from '../../../../../components/toasts'
import { cleanGraphQLError } from '../../../../components/Forms/FormError'

import CustomSelectInput from '../../../../components/Forms/CustomSelectInput'
import {
  GET_FORM,
  useGetCurrentAgencyContext
} from 'src/app/hooks/queries'

import uniqueId from 'lodash/uniqueId'
import DraggableList from '../../../../components/Shared/DraggableList'

import UserContactSelect from '../../../Shared/UserContactSelect'

import {
  fieldItemConfig,
  getDefaultValues,
  mapFieldValuesToCreate,
  mapFieldValuesToUpdateForm,
  relationFieldItemTypes,
  statusFieldItemTypes
} from './dataMapping'
import Field from './Field'
import { NetworkStatus, useQuery } from '@apollo/client'
import Spinner from 'src/images/icons/Spinner'

import { Box, Flex } from 'theme-ui'

import Button from 'src/app/components/Shared/Elements/Button'
import Label from 'src/app/components/Shared/Elements/Label'
import Input from 'src/app/components/Shared/Elements/Input'
import ModalFooter from 'src/app/components/Shared/Elements/ModalFooter'
import FormGroup from 'src/app/components/Shared/Elements/FormGroup'
import Switch from 'src/app/components/Shared/Elements/Switch'
import InputGroupText from 'src/app/components/Shared/Elements/InputGroupText'
import Form from 'src/app/components/Shared/Elements/Form'

import { useGetPermission } from 'src/app/components/PayWalls/PayWallScreen'
import InlineTooltip from 'src/app/components/Shared/InlineTooltip'
import Icon from 'src/app/components/Shared/Icon'
import stripHtml from 'src/utils/stripHtml'
import HexColorInputModal from 'src/app/components/Shared/HexColorInputModal'
import flatten from 'lodash/flatten'
import CollapsablePanel from 'src/app/components/Shared/CollapsablePanel'
import { BetaFeatureBadge } from 'src/app/components/Shared/BetaFeatureBadge'
import updateOrAddToArr from 'src/utils/updateOrAddToArr'
import QuestionTooltip from 'src/app/components/Shared/QuestionTooltip'
import { handleContactInfosSet } from 'src/utils/handleContactInfosSet'
import { Divider } from 'src/app/components/Shared'

export const FormBuilderContext = createContext()

const FormCreateForm = ({
  onFinish,
  form,
  defaultTags,
  formType
}) => {
  const [includeArchived, setIncludeArchived] = useState(false)

  const { currentAgency } = useGetCurrentAgencyContext()

  const { add } = useToast()

  const defaultField = {
    id: uniqueId(),
    fieldItems: []
  }

  const defaultValues = getDefaultValues({
    form: {
      ...(formType === 'supervisor' && {
        supervisorReviewConfig: {
          stateOptions: [],
          enabled: true
        }
      }),
      ...(formType === 'medical' && {
        medicalFormConfig: {
          enabled: true
        }
      }),
      ...form
    },
    defaultTags,
    defaultField
  })
  const hasStatuses = useGetPermission('statuses')
  const {
    values,
    setValues,
    handleChange,
    errors,
    handleErrors,
    warnings,
    handleWarnings,
    handleSubmit,
    isDirty,
    setIsDirty
  } = useForm(form ? updateForm : createNewForm, defaultValues)

  useEffect(() => {
    setIsDirty(false)
    setValues(defaultValues, true)
  }, [form])

  const { createOneForm, loading } = useCreateForm()

  const { updateOneForm, loading: updating } = useUpdateForm({
    awaitRefetchQueries: true,
    refetchQueries: [
      'GetAgencyForms',
      {
        query: GET_FORM,
        variables: {
          where: { id: form?.id }
        }
      }
    ]
  })
  async function createNewForm() {
    if (!values.name) {
      add({
        content: `Please provide a form name.`,
        color: 'danger'
      })
      return
    }

    if (values.fields.some(field => !field.title)) {
      const culprit = values.fields.findIndex(field => !field.title)

      add({
        content: `Questions must have a title. Check question number "${culprit +
          1}".`,
        color: 'danger'
      })
      return
    }

    try {
      let connects = {}

      if (values.fileCategories && values.fileCategories[0]) {
        connects.fileCategories = {
          connect: values.fileCategories.map(fc => ({
            id: fc.id
          }))
        }
      }

      if (values.activityTypes && values.activityTypes[0]) {
        connects.activityTypes = {
          connect: values.activityTypes.map(fc => ({
            id: fc.id
          }))
        }
      }

      if (values.contactInfos && values.contactInfos[0]) {
        const contactInfoArray = values.contactInfos?.filter(fi => fi)

        connects.formAlert = contactInfoArray?.length > 0 && {
          create: {
            contactInfos: {
              set: contactInfoArray?.map(ci => ci.value)
            }
          }
        }
      }

      if (values?.supervisorReviewConfig?.reviewStatusFieldItemId) {
        connects.supervisorReviewConfig = {
          create: {
            reviewStatusFieldItemId:
              values.supervisorReviewConfig.reviewStatusFieldItemId,
            stateOptions: {
              create: values.supervisorReviewConfig.stateOptions.map(
                ({
                  color,
                  state,
                  defaultReviewState,
                  requestReviewState
                }) => ({
                  color,
                  state,
                  defaultReviewState,
                  requestReviewState
                })
              )
            },
            recommendOnImportant:
              values.supervisorReviewConfig?.recommendOnImportant
          }
        }
      }

      if (values?.medicalFormConfig?.enabled) {
        connects.medicalFormConfig = {
          create: {}
        }
      }

      const variables = {
        data: {
          name: values.name,
          autoSave: values.autoSave,
          autoWeather: values.autoWeather,
          fields: mapFieldValuesToCreate(values.fields, true),
          ...connects
        }
      }

      const res = await createOneForm({
        variables
      })

      if (!res.errors) {
        add({
          color: 'success',
          content: 'Form added.'
        })

        onFinish && onFinish()
      } else {
        add({
          content: cleanGraphQLError(res.errors[0]),
          color: 'danger'
        })
      }
    } catch (e) {
      console.log(e)
      add({
        content: cleanGraphQLError(e),
        color: 'danger'
      })
    }
  }
  async function updateForm() {
    if (!values.name) {
      add({
        content: `Please provide a form name.`,
        color: 'danger'
      })
      return
    }

    if (
      values.fields.some(field => !field.archived && !field.title)
    ) {
      const culprit = values.fields.findIndex(field => !field.title)

      add({
        content: `Questions must have a title. Check question number "${culprit +
          1}".`,
        color: 'danger'
      })
      return
    }

    try {
      let connects = {}

      if (values.fileCategories) {
        connects.fileCategories = {
          set: values.fileCategories.map(fc => ({
            id: fc.id
          }))
        }
      }

      if (!values.fileCategories || !values.fileCategories[0]) {
        connects.fileCategories = {
          set: []
        }
      }

      if (values.activityTypes) {
        connects.activityTypes = {
          set: values.activityTypes.map(fc => ({
            id: fc.id
          }))
        }
      }

      if (!values.activityTypes || !values.activityTypes[0]) {
        connects.activityTypes = {
          set: []
        }
      }

      if (values.contactInfos && values.contactInfos[0]) {
        const contactInfoArray = values.contactInfos?.filter(ci => ci)

        connects.formAlert = {
          upsert: {
            create: {
              ...handleContactInfosSet(contactInfoArray)
            },
            update: {
              ...handleContactInfosSet(contactInfoArray)
            }
          }
        }
      } else {
        connects.formAlert = {
          upsert: {
            create: {
              contactInfos: {
                set: []
              }
            },
            update: {
              contactInfos: {
                set: []
              }
            }
          }
        }
      }

      const fields = mapFieldValuesToUpdateForm(
        values.fields,
        values.orderUpdated
      )

      const stateOptionsToUpdate = values?.supervisorReviewConfig?.stateOptions?.filter(
        so => so.id
      )

      const stateOptionsToCreate = values?.supervisorReviewConfig?.stateOptions?.filter(
        so => !so.id
      )

      const res = await updateOneForm({
        variables: {
          where: {
            id: form.id
          },
          data: {
            name: values.name,
            autoSave: values.autoSave,
            autoWeather: values.autoWeather,
            ...(values?.supervisorReviewConfig?.existing &&
            !values?.supervisorReviewConfig?.enabled
              ? {
                  supervisorReviewConfig: {
                    delete: true
                  }
                }
              : values?.supervisorReviewConfig
                  ?.reviewStatusFieldItemId &&
                values?.supervisorReviewConfig?.enabled
              ? {
                  supervisorReviewConfig: {
                    upsert: {
                      create: {
                        reviewStatusFieldItemId:
                          values.supervisorReviewConfig
                            .reviewStatusFieldItemId,
                        stateOptions: {
                          create: stateOptionsToCreate
                        },
                        recommendOnImportant:
                          values.supervisorReviewConfig
                            ?.recommendOnImportant
                      },
                      update: {
                        reviewStatusFieldItemId:
                          values.supervisorReviewConfig
                            .reviewStatusFieldItemId,
                        stateOptions: {
                          ...(stateOptionsToUpdate?.length > 0 && {
                            update:
                              stateOptionsToUpdate?.map(so => ({
                                where: {
                                  id: so.id
                                },
                                data: {
                                  color: so.color,
                                  state: so.state,
                                  defaultReviewState:
                                    so.defaultReviewState,
                                  requestReviewState:
                                    so.requestReviewState
                                }
                              })) || {}
                          }),

                          create: stateOptionsToCreate
                        },
                        recommendOnImportant:
                          values.supervisorReviewConfig
                            ?.recommendOnImportant
                      }
                    }
                  }
                }
              : {}),
            ...connects,
            ...(fields && { fields })
          }
        }
      })

      if (res.errors) {
        add({
          content: cleanGraphQLError(res.errors[0]),
          color: 'danger'
        })
        return
      }

      // onFinish && onFinish()
      add({
        content: 'Form updated.',
        color: 'success'
      })
    } catch (e) {
      console.log(e)
      add({
        content: cleanGraphQLError(e),
        color: 'danger'
      })
    }
  }

  function handleReorder(items) {
    const sortedIdArray = items.map(i => i?.props?.id)

    setValues(prev => ({
      ...prev,
      orderUpdated: true,
      fields: prev.fields.sort((f, k) => {
        return (
          sortedIdArray.indexOf(f.id) - sortedIdArray.indexOf(k.id)
        )
      })
    }))
  }

  function handleFieldDelete(fieldId) {
    const field = values.fields.find(f => f.id === fieldId)
    setValues(prev => ({
      ...prev,
      fields:
        form && field.existing
          ? prev.fields.map((f, k) =>
              f.id === fieldId
                ? {
                    ...f,
                    ...(f.existing && { updated: true }),
                    archived: true
                  }
                : f
            )
          : prev.fields.filter(f => f.id !== fieldId)
    }))

    setIsDirty({
      ...isDirty,
      fields: true
    })
  }
  function handleFieldRestore(fieldId) {
    setIsDirty({
      ...isDirty,
      fields: true
    })
    setValues(prev => ({
      ...prev,
      fields: prev.fields.map((f, k) =>
        f.id === fieldId
          ? {
              ...f,
              ...(f.existing && { updated: true }),
              archived: false
            }
          : f
      )
    }))
  }

  function handleFieldDuplicate(fieldId) {
    const field = values.fields.find(f => f.id === fieldId)
    delete field.updated
    delete field.__typename

    setValues(prev => ({
      ...prev,
      fields: [
        ...prev.fields,
        {
          ...field,
          existing: false,
          fieldItems: field.fieldItems.map(f => ({
            ...f,
            id: uniqueId(),
            // need created at for sorting
            createdAt: f.createdAt || new Date().toISOString(),
            autoUpdates: []
          })),
          id: uniqueId()
        }
      ]
    }))

    setIsDirty({
      ...isDirty,
      fieldItems: true
    })
  }

  function handleFieldItemDuplicate(fieldItem, fieldId) {
    handleCreateItem(
      {
        ...fieldItem,
        autoUpdates: [],
        createdAt: new Date().toISOString(),
        value: fieldItem?.type
      },
      fieldId
    )
  }

  function handleCreateItem(newItem, fieldId) {
    setValues(prev => {
      const newState = {
        ...prev,
        fields: prev.fields.map((f, k) => {
          return f.id === fieldId
            ? {
                ...f,
                ...(f.existing && { updated: true }),
                fieldItems: [
                  ...f.fieldItems,
                  {
                    ...newItem,
                    type: newItem.value,
                    id: uniqueId(),
                    existing: false,
                    checked: true
                  }
                ]
              }
            : f
        })
      }

      return newState
    })

    setIsDirty({
      ...isDirty,
      fieldItems: true
    })
  }

  function handleAddField() {
    setValues(prev => ({
      ...prev,
      fields: [...prev.fields, defaultField]
    }))

    setIsDirty({
      ...isDirty,
      fields: true
    })
  }

  function handleItemChange(input, field) {
    const value = input.value
    const inputName = input.name
    const itemId = input.itemId

    setValues(prev => {
      const newState = {
        ...prev,
        fields: prev.fields.map((f, k) => {
          return f.id === field.id
            ? {
                ...f,
                ...(!itemId && {
                  [inputName]: value,
                  ...(f.existing && { updated: true })
                }),
                fieldItems: f.fieldItems.map((fi, j) => {
                  return itemId === fi.id
                    ? {
                        ...fi,

                        ...(f.existing && {
                          updated: true
                        }),
                        [inputName]: value,
                        ...(inputName === itemId && {
                          checked: value
                        })
                      }
                    : fi
                })
              }
            : f
        })
      }

      return newState
    })

    setIsDirty({
      ...isDirty,
      fieldsChanged: true,
      fieldItemsChanged: true
    })
  }

  const categories =
    currentAgency &&
    currentAgency.fileCategories.filter(fc => fc.name !== 'Map Layer')
  const types =
    currentAgency &&
    currentAgency.activities
      .map(i =>
        i.label.includes('/')
          ? i
          : {
              ...i,
              topLevel: true
            }
      )
      .sort((a, b) => {
        if (a.topLevel && !b.topLevel) {
          return -1
        }
        if (!a.topLevel && b.topLevel) {
          return 1
        }
        return 0
      })

  const fieldsOrFieldItemsAddedOrDeleted =
    isDirty.fields || isDirty.fieldItems

  const formIsEligibleForStatuses =
    hasStatuses &&
    values?.fields?.some(
      fi =>
        !fi?.archived &&
        fi?.fieldItems?.some(
          fii =>
            relationFieldItemTypes.includes(fii.type) && fii?.checked
        )
    ) &&
    values?.fields?.some(
      fi =>
        !fi?.archived &&
        fi?.fieldItems?.some(
          fii =>
            statusFieldItemTypes.includes(fii.type) && fii?.checked
        )
    )

  return (
    <FormBuilderContext.Provider
      value={{
        values,
        setValues,
        handleChange,
        errors,
        handleErrors,
        warnings,
        handleWarnings,
        handleSubmit,
        isDirty,
        setIsDirty,
        handleFieldItemDuplicate,
        allFields: values.fields
      }}
    >
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label>Form Name</Label>
          <Input
            id="name"
            autoComplete="anyrandomstring"
            name="name"
            value={values.name}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label>Tags</Label>
          <CustomSelectInput
            id="fileCategories"
            name="fileCategories"
            isMulti
            getOptionValue={v => v.id}
            getOptionLabel={v => v.name}
            value={values.fileCategories || []}
            isDisabled={defaultTags && defaultTags.length > 0}
            onChange={(selected, action) => {
              setValues(prev => ({
                ...prev,
                fileCategories: selected
              }))
            }}
            options={categories}
          />
        </FormGroup>
        <FormGroup>
          <Label
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px'
            }}
          >
            Who to Notify?{' '}
            <QuestionTooltip
              iconHeight={15}
              tip={
                'Emails and phone numbers entered here will be notified when this form is submitted by a team member.'
              }
            />
          </Label>
          <UserContactSelect
            id="contactInfos"
            name="contactInfos"
            placeholder="Enter an email or phone number. Press enter or click the plus to add multiple."
            value={values.contactInfos}
            onAdd={curArray => {
              setValues(prev => ({
                ...prev,
                contactInfos: curArray
              }))
            }}
          />
        </FormGroup>
        {(values?.contactInfos?.length > 0 ||
          values?.contactInfoInput?.length > 0) && (
          <InlineTooltip
            sx={{
              my: 20,
              mt: 0
            }}
            variant="warning"
            header={'Data Security Reminder'}
            tip="Remember, when sending form alerts via email, you will be sharing form answers outside of Watchtower.  Please consider and understand the importance of the data that could be included."
          />
        )}
        <FormGroup>
          <Label>Suggest on Incident Type</Label>
          <CustomSelectInput
            id="activityTypes"
            name="activityTypes"
            isMulti={true}
            getOptionValue={v => v.id}
            getOptionLabel={v => v.label}
            value={values.activityTypes || []}
            useSummary
            clearable
            clearAll={() => {
              setValues(prev => ({
                ...prev,
                activityTypes: []
              }))
            }}
            clearItem={(item, selected) => {
              const removedLabel = item.label
              // Filter out types that start with the removed label

              const modifiedSelection = selected.filter(s => {
                return !s.label.startsWith(removedLabel)
              })

              setValues(prev => ({
                ...prev,
                activityTypes: modifiedSelection || []
              }))
            }}
            onChange={(selected, action) => {
              let modifiedSelection

              if (action?.action === 'deselect-option') {
                // Find the label of the removed type
                const removedLabel = action.option.label
                // Filter out types that start with the removed label

                modifiedSelection = selected.filter(
                  s => !s.label.startsWith(removedLabel)
                )
              } else {
                const selectedLabels =
                  selected?.map(s => s.label) || []

                modifiedSelection = action?.option?.topLevel
                  ? types.filter(t =>
                      selectedLabels.some(label =>
                        t.label.startsWith(label)
                      )
                    )
                  : selected
              }

              setValues(prev => ({
                ...prev,
                activityTypes: modifiedSelection || []
              }))
            }}
            options={types}
          />
        </FormGroup>

        <InlineTooltip
          variant={formIsEligibleForStatuses ? 'success' : 'warning'}
          header={
            formIsEligibleForStatuses
              ? 'Eligible for Pulse status sync!'
              : 'Not eligible for Pulse status sync.'
          }
          tip={
            formIsEligibleForStatuses
              ? ''
              : hasStatuses
              ? 'In order to sync this form with your Pulse statuses, you will need at least one User, Position or Asset question.'
              : 'Your agency does not have Pulse statuses turned on.  Please reach out to support to enroll.'
          }
          customIcon={
            formIsEligibleForStatuses && (
              <Icon icon={'success'} width={20} height={20} />
            )
          }
          action={
            <a
              href="http://help.yourwatchtower.com/platform/pulse"
              target={'_blank'}
              rel="noopener noreferrer"
            >
              Learn More
            </a>
          }
        />

        <Flex
          sx={{
            width: '100%',
            justifyContent: 'space-between',
            marginTop: '20px',
            alignItems: 'flex-start'
          }}
        >
          <h5 sx={{ mr: '5px' }}>Sections</h5>{' '}
          {values.fields && values.fields.some(f => f.archived) && (
            <Switch
              style={{ cursor: 'pointer' }}
              id={'includeArchived'}
              name="includeArchived"
              checked={includeArchived}
              readOnly
              label={
                <>
                  {includeArchived
                    ? 'Showing Archived'
                    : 'Hiding Archived'}
                </>
              }
              onClick={() => {
                setIncludeArchived(!includeArchived)
              }}
            />
          )}
        </Flex>
        {values.fields && values.fields.length > 0 && (
          <DraggableList
            handleReorder={res => {
              handleReorder(res)
            }}
            items={values.fields
              .filter(f => (includeArchived ? true : !f.archived))
              .map(field => {
                return (
                  <Field
                    {...field}
                    formCreatedAt={form?.createdAt}
                    fields={values.fields?.filter(i => !i?.archived)}
                    key={field.id}
                    hideDelete={values.fields.length === 1}
                    errors={errors}
                    handleErrors={handleErrors}
                    warnings={warnings}
                    disableStatusEditing={
                      form && fieldsOrFieldItemsAddedOrDeleted
                    }
                    formIsEligibleForStatuses={
                      formIsEligibleForStatuses
                    }
                    handleWarnings={handleWarnings}
                    handleChange={input => {
                      handleItemChange(input, field)
                    }}
                    onDelete={() => handleFieldDelete(field.id)}
                    onRestore={() => handleFieldRestore(field.id)}
                    onDuplicate={() => handleFieldDuplicate(field.id)}
                    onCreateItem={input => {
                      handleCreateItem(input, field.id)
                    }}
                    formType={formType}
                  />
                )
              })}
          />
        )}

        <Button
          type="button"
          sx={{ marginTop: '10px' }}
          onClick={handleAddField}
        >
          Add Section +
        </Button>

        {formType === 'supervisor' && (
          <Box
            sx={{
              mt: 20
            }}
          >
            <SupervisorReviewFields
              values={values}
              setValues={setValues}
              createFlow={!form}
            />
          </Box>
        )}

        <CollapsablePanel
          containerStyle={{ marginTop: 20 }}
          buttonOptions={{
            style: {
              justifyContent: 'flex-start'
            },
            openIcon: 'down arrow',
            closeIcon: 'up arrow',
            text: 'Advanced Settings'
          }}
          defaultOpen={
            values?.autoSave || values?.autoWeather || false
          }
        >
          {() => (
            <Box sx={{ mt: 10 }}>
              <FormGroup>
                <Switch
                  style={{ cursor: 'pointer' }}
                  id={'autoSave'}
                  name="autoSave"
                  checked={values?.autoSave}
                  readOnly
                  label={
                    <Flex sx={{ gap: '4px', alignItems: 'center' }}>
                      Auto save while submitting{' '}
                      <BetaFeatureBadge featureName={'auto save'} />
                    </Flex>
                  }
                  onClick={e => {
                    setValues(prev => ({
                      ...prev,
                      autoSave: e.target.checked
                    }))
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Switch
                  style={{ cursor: 'pointer' }}
                  id={'autoWeather'}
                  name="autoWeather"
                  checked={values?.autoWeather}
                  readOnly
                  label={
                    <Flex sx={{ gap: '4px', alignItems: 'center' }}>
                      Auto append weather to response{' '}
                      <BetaFeatureBadge featureName={'auto save'} />
                    </Flex>
                  }
                  onClick={e => {
                    setValues(prev => ({
                      ...prev,
                      autoWeather: e.target.checked
                    }))
                  }}
                />
              </FormGroup>
            </Box>
          )}
        </CollapsablePanel>
        <ModalFooter
          alertBadge={isDirty && 'Unsaved changes...'}
          onClose={onFinish}
          style={{ marginTop: '10px' }}
        >
          <Button
            variant={values.name ? 'primary' : 'secondary'}
            disabled={(!form && !values.name) || updating || loading}
            icon={(loading || updating) && 'spinner'}
          >
            {form ? 'Update Form' : `Create ${formType} Form`}
          </Button>
        </ModalFooter>
      </Form>
    </FormBuilderContext.Provider>
  )
}

const SupervisorReviewFields = ({
  values,
  setValues,
  createFlow
}) => {
  const supervisorReviewConfigFieldItemOptions =
    values?.supervisorReviewConfig &&
    values?.fields
      ?.filter(f => !f.archived)
      .map((field, k) => ({
        id: field.id,
        label: `${k + 1}. ${stripHtml(field.title)}`,
        options: field.fieldItems
          .filter(fi => isEligibleForSupervisorReview(fi))
          .map((fieldItem, i) => {
            return {
              ...fieldItem,
              isDisabled:
                !createFlow &&
                (fieldItem.archived || !fieldItem.existing),
              name: `${k + 1}.${i + 1}. ${
                fieldItemConfig.find(
                  dt => dt.value === fieldItem.type
                ).title
              } ${
                fieldItem.helpText ? `(${fieldItem.helpText})` : ''
              } ${
                fieldItem.existing && !createFlow
                  ? ''
                  : '(Save to use)'
              }`
            }
          })
      }))

  const currentSupervisorReviewConfigFieldItem =
    values.supervisorReviewConfig?.reviewStatusFieldItemId &&
    flatten(
      supervisorReviewConfigFieldItemOptions.map(fi => fi.options)
    )?.find(
      fi =>
        fi.id ===
        values.supervisorReviewConfig?.reviewStatusFieldItemId
    )

  return (
    <FormGroup>
      <h6>Supervisor Review Configuration</h6>
      <Divider margin={'20px 0px'} />

      {values?.supervisorReviewConfig?.enabled && (
        <>
          <FormGroup>
            <Label>Review Status (Multiple Choice Only)</Label>
            <CustomSelectInput
              id="supervisorReviewConfig.fieldItem"
              name="supervisorReviewConfig.fieldItem"
              value={currentSupervisorReviewConfigFieldItem}
              getOptionValue={v => v.id}
              getOptionLabel={v => v.name}
              clearable
              onChange={selected => {
                setValues(prev => ({
                  ...prev,
                  supervisorReviewConfig: {
                    ...prev.supervisorReviewConfig,
                    stateOptions: selected?.allowedValues?.map(
                      av => ({
                        state: av,
                        color: '#ccc'
                      })
                    ),
                    reviewStatusFieldItemId: selected?.id
                  }
                }))
              }}
              options={supervisorReviewConfigFieldItemOptions}
            />
            {currentSupervisorReviewConfigFieldItem?.allowedValues
              ?.length > 0 && (
              <Flex
                sx={{
                  mt: 20,
                  gap: '4px',
                  flexWrap: 'wrap'
                }}
              >
                {currentSupervisorReviewConfigFieldItem?.allowedValues?.map(
                  (av, k) => (
                    <HexColorInputModal
                      key={k}
                      text={av}
                      color={
                        values?.supervisorReviewConfig?.stateOptions?.find(
                          so => so.state === av
                        )?.color || null
                      }
                      handleSelect={val => {
                        setValues(prev => {
                          const curStateOptions =
                            prev.supervisorReviewConfig
                              ?.stateOptions || []

                          let newStateOptions = updateOrAddToArr(
                            curStateOptions,
                            'state',
                            av,
                            {
                              state: av,
                              color: val?.value
                            }
                          )

                          return {
                            ...prev,
                            supervisorReviewConfig: {
                              ...prev.supervisorReviewConfig,
                              stateOptions: newStateOptions
                            }
                          }
                        })
                      }}
                    />
                  )
                )}
              </Flex>
            )}
          </FormGroup>

          <FormGroup>
            <Label>Default Review State</Label>
            <CustomSelectInput
              id="supervisorReviewConfig.defaultReviewState"
              name="supervisorReviewConfig.defaultReviewState"
              value={values?.supervisorReviewConfig?.stateOptions?.find(
                so => so.defaultReviewState
              )}
              getOptionValue={v => v.id || v.state}
              getOptionLabel={v => v.state}
              isClearable
              onChange={selected => {
                setValues(prev => ({
                  ...prev,
                  supervisorReviewConfig: {
                    ...prev.supervisorReviewConfig,
                    stateOptions: prev.supervisorReviewConfig?.stateOptions?.map(
                      so => ({
                        ...so,
                        defaultReviewState: so.id
                          ? so.id === selected?.id
                          : so.state === selected?.state
                      })
                    )
                  }
                }))
              }}
              options={values?.supervisorReviewConfig?.stateOptions}
            />
            <InputGroupText>
              Editing will not change past incident review states.
            </InputGroupText>
          </FormGroup>
          <FormGroup>
            <Label>Request Review State</Label>
            <CustomSelectInput
              id="supervisorReviewConfig.requestReviewState"
              name="supervisorReviewConfig.requestReviewState"
              value={values?.supervisorReviewConfig?.stateOptions?.find(
                so => so.requestReviewState
              )}
              getOptionValue={v => v.id || v.state}
              getOptionLabel={v => v.state}
              isClearable
              onChange={selected => {
                setValues(prev => ({
                  ...prev,
                  supervisorReviewConfig: {
                    ...prev.supervisorReviewConfig,
                    stateOptions: prev.supervisorReviewConfig?.stateOptions?.map(
                      so => ({
                        ...so,
                        requestReviewState: so.id
                          ? so.id === selected?.id
                          : so.state === selected?.state
                      })
                    )
                  }
                }))
              }}
              options={values?.supervisorReviewConfig?.stateOptions}
            />
            <InputGroupText>
              Editing will not change past incident review states.
            </InputGroupText>
          </FormGroup>
          <FormGroup check>
            <Input
              type="checkbox"
              name={'recommendOnImportant'}
              checked={
                values?.supervisorReviewConfig?.recommendOnImportant
              }
              label={'Require on Important Incidents'}
              onChange={e => {
                setValues(prev => ({
                  ...prev,
                  supervisorReviewConfig: {
                    ...prev.supervisorReviewConfig,
                    recommendOnImportant: e.target.checked
                  }
                }))
              }}
            />
          </FormGroup>
        </>
      )}
    </FormGroup>
  )
}

function isEligibleForSupervisorReview(fieldItem) {
  return (
    fieldItem?.type === 'text' &&
    fieldItem?.allowedValues?.length > 0 &&
    !fieldItem?.archived &&
    !fieldItem?.allowMultipleAnswers
  )
}

const FormBuilder = ({ formId, onFinish, ...rest }) => {
  const { data, refetch, networkStatus } = useQuery(GET_FORM, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    skip: !formId,
    notifyOnNetworkStatusChange: true,
    variables: {
      where: { id: formId }
    }
  })
  if ((formId && !data) || networkStatus === NetworkStatus.refetch) {
    return <Spinner centered />
  }

  return (
    <FormCreateForm
      form={data?.form}
      {...rest}
      onFinish={() => {
        formId && refetch()
        onFinish()
      }}
      formType={rest.formType}
    />
  )
}

export default FormBuilder
