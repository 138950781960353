import Drawer from './drawer'

import Icon from '../../Shared/Icon'
import { Box } from 'theme-ui'

import { useWindowSize } from 'src/styles'
import MessageNotificationBadge from 'src/app/components/Shared/MessageNotificationBadge'
import { Overlay } from '../..'

import { useGetCurrentAgencyContext } from 'src/app/hooks/queries'
import { css, Global } from '@emotion/react'
import UptimeWidget from '../../Shared/UptimeWidget'

import { checkRoleMatch } from 'src/app/services/auth'
import { getTruthyPermissionsFromSettings } from '../../PayWalls/PayWallScreen'
import { useLocation } from 'react-router'
import DarkModeToggle from '../../DarkMode'
import GoOfflineSubmitTabButton from '../../QuickSurvey/GoOfflineSubmitTabButton'
import { STATUS_NODE_TYPES } from '../../Statuses/utils'
import capitalize from 'lodash/capitalize'

const NavLinkData = [
  {
    title: 'Home',
    itemId: '/agency',
    elemBefore: () => (
      <Icon icon={'hq'} title="home" width={'20px'} height={'20px'} />
    )
  },
  {
    title: 'Activity Log',
    itemId: '/agency/stats',
    elemBefore: () => (
      <Icon
        icon={'explore'}
        title="explore"
        width={'20px'}
        height={'20px'}
      />
    )
  },
  {
    title: 'Submit',
    itemId: '/agency/submission',
    elemBefore: () => (
      <Icon
        icon={'lightning'}
        title="submit"
        width={'20px'}
        height={'20px'}
      />
    )
  },

  {
    title: 'Dispatch',
    itemId: '/agency/dispatches',
    hideForNonPurchased: 'dispatching',
    elemBefore: () => (
      <Icon
        icon={'dispatch'}
        title="dispatch"
        width={'20px'}
        height={'20px'}
      />
    )
  },
  {
    title: 'Pulse',
    itemId: '/agency/pulse/reports',
    elemBefore: () => (
      <Icon
        icon={'pulse'}
        title="Dashboard"
        width={'20px'}
        height={'20px'}
      />
    ),
    subNav: [
      {
        title: 'Saved Reports',
        itemId: '/agency/pulse/reports',
        elemBefore: () => null
      },
      ...STATUS_NODE_TYPES?.map(nodeType => ({
        title: capitalize(nodeType?.toLowerCase()),
        itemId: `/agency/pulse/${nodeType?.toLowerCase()}`,
        elemBefore: () => null
      }))
    ]
  },
  {
    title: 'Communication',
    itemId: '/agency/communications',
    elemBefore: () => (
      <Icon
        icon={'team'}
        title="communications"
        width={'19px'}
        height={'19px'}
      />
    ),
    elemAfter: ({ open }) => (
      <MessageNotificationBadge
        smallBadge={!open}
        style={{
          marginLeft: !open ? '-5px' : '0px',
          marginTop: !open ? '-10px' : '0px'
        }}
      />
    )
  },

  {
    title: 'Forms & Docs',
    itemId: '/agency/operations/forms',
    elemBefore: () => (
      <Icon
        icon={'paperclip'}
        title="operations"
        width={'20px'}
        height={'20px'}
      />
    ),
    subNav: [
      {
        title: 'General Forms',
        itemId: '/agency/operations/forms',
        elemBefore: () => null
      },
      {
        title: 'Medical Forms',
        itemId: '/agency/operations/forms/medical',
        elemBefore: () => null
      },
      {
        title: 'Supervisor Reviews',
        itemId: '/agency/operations/forms/supervisor',
        elemBefore: () => null
      },
      {
        title: 'Documents',
        itemId: '/agency/operations/documents',
        elemBefore: () => null
      },
      {
        title: 'Tags',
        itemId: '/agency/operations/tags',
        elemBefore: () => null
      }
    ]
  },
  {
    title: 'Reporting',
    itemId: '/agency/reporting/reports',
    elemBefore: () => (
      <Icon
        icon={'stats'}
        title="reporting"
        width={'20px'}
        height={'20px'}
      />
    ),
    subNav: [
      {
        title: 'Saved Reports',
        itemId: '/agency/reporting/reports',
        elemBefore: () => null
      },
      {
        title: 'Incidents',
        itemId: '/agency/reporting/incidents',
        elemBefore: () => null
      },
      {
        title: 'Forms',
        itemId: '/agency/reporting/forms',
        elemBefore: () => null
      }
    ]
  },
  {
    title: 'Agency',
    itemId: '/agency/profile/roster',
    elemBefore: () => (
      <Icon
        icon={'agency'}
        title="agency"
        width={'20px'}
        height={'20px'}
      />
    ),
    subNav: [
      {
        title: 'Roster',
        itemId: '/agency/profile/roster',
        elemBefore: () => null
      },
      {
        title: 'Resources',
        itemId: '/agency/profile/resources',
        elemBefore: () => null
      },
      {
        title: 'Locations',
        itemId: '/agency/profile/locations',
        elemBefore: () => null
      },
      {
        title: 'Assets',
        itemId: '/agency/profile/assets',
        elemBefore: () => null
      },
      {
        title: 'Incident Categories',
        itemId: '/agency/profile/stat-categories',
        elemBefore: () => null
      },
      {
        title: 'Groups',
        itemId: '/agency/profile/groups',
        elemBefore: () => null
      }
    ]
  },
  {
    title: 'Public Safety',
    itemId: '/agency/public/broadcasts',
    elemBefore: () => (
      <Icon
        icon={'dashboard'}
        title="public dashboard"
        width={'20px'}
        height={'20px'}
      />
    ),
    subNav: [
      {
        title: 'Broadcasts',
        itemId: '/agency/public/broadcasts',
        elemBefore: () => null
      },
      {
        title: 'Dashboard',
        itemId: '/agency/public/dashboard',
        elemBefore: () => null
      }
    ]
  }
]

function filterRoutes(user, routes, settings) {
  if (!user) {
    return []
  }

  return routes.filter(route => {
    if (settings && route.hideForNonPurchased) {
      return getTruthyPermissionsFromSettings(settings).includes(
        route.hideForNonPurchased
      )
    }
    return checkRoleMatch(route.itemId, user?.role)
  })
}

export const GlobalStyle = () => (
  <Global
    styles={css`
      body {
        overflow: hidden;
      }
    `}
  />
)

const disableMaxWidth = ['/agency/dispatches', '/agency/onboarding']
const fullHeight = ['/agency/onboarding']

const SideNav = ({
  showSideNav,
  sidebarOpenState,
  mainContent,
  handleOpenState,
  ...props
}) => {
  const { pathname: location } = useLocation()
  const { user, currentAgency } = useGetCurrentAgencyContext()
  const settings = currentAgency && currentAgency.settings

  const windowSize = useWindowSize()
  const openOnSmallScreen = sidebarOpenState && windowSize.smallScreen
  const closedOnDesktop = !sidebarOpenState && !windowSize.smallScreen

  return (
    <>
      <Overlay
        isOpen={openOnSmallScreen}
        onClick={() => {
          handleOpenState(false)
        }}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          maxHeight: '100%',
          minHeight: '100%',
          alignItems: 'stretch',
          position: 'relative'
        }}
        {...props}
      >
        {showSideNav && (
          <Box
            sx={{
              alignSelf: 'stretch',
              position: ['absolute', null, null, null, 'relative'],
              overflowY: 'auto',
              opacity: '1',
              zIndex: 150000,
              // height: '100vh',
              // height: 'calc(100vh - 65px)',
              height: [
                'calc(100vh - 65px)',
                null,
                null,
                null,
                'auto'
              ],
              maxWidth: closedOnDesktop
                ? '60px'
                : ['95vw', null, null, null, '275px'],
              width: closedOnDesktop
                ? '60px'
                : ['300px', null, null, null, '275px'],
              borderRightColor: 'gray200',
              borderRightWidth: '1px',
              borderRightStyle: 'solid',
              flexGrow: 1,
              backgroundColor: 'background',
              '::-webkit-scrollbar': {
                width: '2px'
              },
              '::-webkit-scrollbar-track': {
                background: 'gray200'
              },
              '::-webkit-scrollbar-thumb': {
                background: 'white'
              },

              ...(windowSize.smallScreen && {
                transform: sidebarOpenState
                  ? 'translateX(0px)'
                  : 'translateX(-300px)'
              }),
              transition: 'transform .3s ease-in-out'
            }}
          >
            {openOnSmallScreen && <GlobalStyle />}
            <Drawer
              isOpen={sidebarOpenState}
              items={filterRoutes(user, NavLinkData, settings).map(
                i => ({
                  ...i,
                  subNav:
                    i.subNav && filterRoutes(user, i.subNav, settings)
                })
              )}
              activeItemId={location}
              onFinalRoute={() => {
                if (windowSize.smallScreen) {
                  handleOpenState(false)
                }
              }}
              setOpen={handleOpenState}
            />
            <Box
              sx={{
                marginTop: ['30px', null, null, null, '20px'],
                mb: 60,
                p: 2
              }}
            >
              <Box sx={{ p: '10px 5px', mb: '20px' }}>
                <GoOfflineSubmitTabButton
                  showText={!closedOnDesktop}
                />
              </Box>

              <Box sx={{ p: '10px 5px' }}>
                <DarkModeToggle hideLabel={closedOnDesktop} />
              </Box>
              <Box sx={{ p: '10px 5px' }}>
                <UptimeWidget showText={!closedOnDesktop} />
              </Box>
            </Box>
          </Box>
        )}

        <Box
          sx={{
            width: '100%',

            overflow: 'auto',
            backgroundColor: 'gray100'
          }}
        >
          <Box
            id="main-content"
            variant="container"
            sx={{
              ...(disableMaxWidth.includes(location) && {
                maxWidth: 'none'
              })
            }}
          >
            <Box
              sx={{
                pb: '120px',
                ...(fullHeight.includes(location) && {
                  height: '100%',
                  padding: '0px'
                })
              }}
            >
              {mainContent}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default SideNav
