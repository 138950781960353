import {
  GET_TODAYS_DISPATCHER,
  useGetCurrentAgencyContext,
  useGetTodaysDispatcher
} from 'src/app/hooks/queries'

import CustomSelectInput from './CustomSelectInput'
import FormGroup from '../Shared/Elements/FormGroup'
import Label from '../Shared/Elements/Label'
import { useApolloClientContext } from 'src/app/hooks/useApolloClientProvider'

const DailyDispatcherForm = ({ onFinish }) => {
  const client = useApolloClientContext()
  const { users } = useGetCurrentAgencyContext()
  const { id = null } = useGetTodaysDispatcher()
  const setDispatcher = async d => {
    await client.writeQuery({
      query: GET_TODAYS_DISPATCHER,

      data: {
        todaysDispatcher: d
      }
    })
    onFinish && onFinish()
  }

  return (
    <FormGroup style={{ flex: 2 }}>
      <Label htmlFor="sort">Dispatcher</Label>
      <CustomSelectInput
        value={{
          id: id,
          name: id ? users.find(i => i.id === id).name : null
        }}
        isClearable
        options={users.filter(u => u.rosterStatus !== 'ARCHIVED')}
        getOptionValue={v => v.id}
        getOptionLabel={v => v.name}
        onChange={(suggestion, options) => {
          setDispatcher(
            options.action === 'clear' ? null : suggestion.id
          )
        }}
      />
    </FormGroup>
  )
}

export default DailyDispatcherForm
