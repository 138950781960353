/** @jsxImportSource theme-ui */
import { Flex, Box } from 'theme-ui'
import { useQuery } from '@apollo/client'
import { GET_BULLETIN } from 'src/app/hooks/queries'
import Spinner from 'src/images/icons/Spinner'
import { prettyDate } from 'src/utils'

import { RichTextRender } from '../Shared/RichTextRender'
import ListGroup from '../Shared/Elements/ListGroup'
import Modal from '../Shared/Elements/Modal'
import {
  BulletinActionsDropdown,
  ReadBulletinButton
} from './BulletinActions'
import { RolesOnly } from 'src/app/services/auth'
import { UrgencyIcon } from './BulletinIcons'
import { DocumentItem } from '../Operations/Documents/DocumentList'

export const BulletinRichTextModalContent = p => {
  return (
    <Box sx={{ p: '20px' }} {...p}>
      <Box sx={{ minHeight: '200px' }}>
        <Flex
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap-reverse',
            paddingTop: 20,
            paddingBottom: 10
          }}
        >
          <Box>
            <p
              style={{
                margin: 0,
                marginTop: 10,
                opacity: 0.7
              }}
            >
              {p.author?.name || 'Watchtower Team'} on{' '}
              {prettyDate(p.createdAt, 'MMM Do HH:mm')}
            </p>
          </Box>

          <Flex
            sx={{
              alignItems: 'center',
              mb: 10
            }}
          >
            <RolesOnly roles={['ADMIN', 'SUPERVISOR']}>
              <BulletinActionsDropdown
                dropdown
                bulletin={p}
                blockButton
              />
            </RolesOnly>
          </Flex>
        </Flex>
        <Flex sx={{ alignItems: 'center', mb: 20 }}>
          <h3
            style={{
              fontWeight: '900',
              margin: '0px 0px 0px 0px',
              flex: 1
            }}
          >
            <UrgencyIcon urgency={p.urgency} /> {p.title}{' '}
          </h3>
          <ReadBulletinButton bulletin={p} />
        </Flex>
        <RichTextRender text={p.body} />
        {p?.attachments?.length > 0 && (
          <>
            <h6 sx={{ mt: '20px' }}>Attached Files</h6>
            <ListGroup>
              {p.attachments.map((f, index) => (
                <DocumentItem hideEdit={true} key={index} {...f} />
              ))}
            </ListGroup>
          </>
        )}
      </Box>
    </Box>
  )
}

export const BulletinModalContentContainer = ({
  id,
  onClose,
  noModal
}) => {
  const { data, error } = useQuery(GET_BULLETIN, {
    variables: {
      id: id
    }
  })

  if (error) {
    return <div>Error</div>
  }

  const content = !data ? (
    <Spinner centered />
  ) : (
    <BulletinRichTextModalContent
      onClose={() => onClose()}
      {...data.bulletin}
    />
  )

  return !noModal ? (
    <Modal toggle={() => onClose()} isOpen={true} fade>
      {content}
    </Modal>
  ) : (
    content
  )
}

export default BulletinRichTextModalContent
