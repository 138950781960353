import { Fragment } from 'react'
import styled from '@emotion/styled'
import { Space } from '..'
import Row from '../Shared/Elements/Row'
import { useWindowSize } from 'src/styles'

export default styled.div`
  background: ${p => {
    return p.completedAt
      ? p.theme.colors.danger
      : p.oScene
      ? p.theme.colors.success
      : p.enRoute
      ? p.theme.colors.orange
      : p.theme.colors.warning
  }};
  color: white;
  padding: 2px 5px;
  border-radius: 4px;
  width: auto;
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: none;
  :hover {
    background: ${p =>
      p.completedAt
        ? p.theme.colors.danger
        : p.oScene
        ? p.theme.colors.success
        : p.enRoute
        ? p.theme.colors.orange
        : p.theme.colors.warning};
  }
`

export const ColorCircle = styled.div`
  background-color: ${p => p.theme.colors[p.color]};
  width: ${p => p.width || '10px'};
  height: ${p => p.height || '10px'};
  border-radius: 100%;
  text-align: center;
  color: white;
  font-weight: bold;
`

export const ColorDiv = styled.div`
  background-color: ${p => p.theme.colors[p.color]};
`

export const DispatchColorKey = ({ ...props }) => {
  const { mobile } = useWindowSize()
  return (
    <Row sx={{ alignItems: 'center', mr: '0px', ...props.sx }}>
      <ColorCircle color="warning" />
      <Space width={5} />
      {mobile ? 'P' : 'Pending'}
      <Space width={10} />
      <ColorCircle color="orange" />
      <Space width={5} />
      {mobile ? 'E' : 'En Route'}
      <Space width={10} />
      <ColorCircle color="success" />
      <Space width={5} />
      {mobile ? 'OS' : 'On Scene'}
      <Space width={10} />
      <ColorCircle color="danger" />
      <Space width={5} />
      {mobile ? 'C' : 'Complete'}
    </Row>
  )
}

export const PositionsDispatchStatusDot = ({
  style,
  children,
  status
}) => {
  if (!status) return <Fragment />

  return (
    <ColorCircle
      aria-label={status}
      title={status}
      style={style}
      color={getPositionDispatchStatusColor(status)}
      height={'20px'}
      width={'20px'}
      borderRadius
    >
      {children}
    </ColorCircle>
  )
}

export const getPositionDispatchStatusColor = status => {
  return status === 'PENDING'
    ? 'warning'
    : status === 'ON_SCENE'
    ? 'success'
    : status === 'EN_ROUTE'
    ? 'orange'
    : 'accentBlue'
}

export const getPositionDispatchStatusAbbr = d => {
  return d.completedAt
    ? 'C'
    : d.onScene
    ? 'OS'
    : d.enRoute
    ? 'ER'
    : 'P'
}
