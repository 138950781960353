import { optionalChaining } from 'src/utils'

import uniqBy from 'lodash/uniqBy'
import moment from 'moment'
import { mapValuesToSubmitForm } from 'src/app/components/Operations/Forms/formSubmitRenderer/dataMapping'
import isEmpty from 'lodash/isEmpty'
import flatten from 'lodash/flatten'

import DOMPurify from 'dompurify'
import { generateFormFieldItemAdvancedWhere } from 'src/app/components/Reporting/Forms/utils'

export const parseUniqActivities = (activities, label) =>
  activities && uniqBy(activities, label).map(opt => opt[label])

export const createActivtyTypeLabel = (
  labelOne,
  labelTwo,
  labelThree
) => {
  if (!labelThree && !labelTwo) {
    return `${labelOne}`
  }
  if (!labelThree) {
    return `${labelOne} / ${labelTwo}`
  }
  return `${labelOne} / ${labelTwo} / ${labelThree}`
}

export function parseFloatTwoDecimal(f) {
  if (!f) {
    return null
  }
  return Math.round(parseFloat(f, 10) * 1e2) / 1e2
}

const prepinvolvedPartyInfo = (
  involvedPartyInfo,
  count,
  includeId = false
) => {
  const isIndividuals =
    involvedPartyInfo.length > 1 || count === involvedPartyInfo.length
  return involvedPartyInfo.map(function(v) {
    !includeId && delete v.id

    return {
      ...v,
      isIndividual: isIndividuals
    }
  })
}

const getRightNow = () => moment(new Date()).toISOString()

export const mapValuesToSubmission = async (
  values,
  currentAgency
) => {
  const {
    incidentTime,
    subLabelOne,
    subLabelTwo,
    subLabelThree,
    count,
    note,
    position,
    location,
    vehicles,
    files,
    tideHeight,
    waveHeight,
    waterTemp,
    waveInterval,
    windDirection,
    windSpeed,
    formResponses,
    airTemp,
    dispatches,
    noteWrittenBy = null,
    noteWasDispatch,
    noteWrittenByPosition,
    involvedPartyInfo,
    ...rest
  } = values
  const lng = optionalChaining(() => location.lng)
  const lat = optionalChaining(() => location.lat)
  let weather = {}

  const positionLocation = optionalChaining(
    () => location.position.value
  )
    ? {
        position: {
          connect: {
            id: location.position.value
          }
        }
      }
    : {}
  const prettyAddress = optionalChaining(() => location.prettyAddress)

  if (
    tideHeight ||
    waveHeight ||
    waterTemp ||
    waveInterval ||
    airTemp ||
    windDirection ||
    windSpeed
  ) {
    weather = {
      tideHeight: parseFloatTwoDecimal(tideHeight),
      waveHeight: parseFloatTwoDecimal(waveHeight),
      waterTemp: parseFloatTwoDecimal(waterTemp),
      waveInterval: parseFloatTwoDecimal(waveInterval),
      airTemp: parseFloatTwoDecimal(airTemp),
      windDirection: windDirection,
      windSpeed: parseFloatTwoDecimal(windSpeed)
    }
  }
  const mainTypeId = currentAgency.activities.filter(
    i =>
      i.label ===
      createActivtyTypeLabel(subLabelOne, subLabelTwo, subLabelThree)
  )[0].id

  let additionalIncidents = []

  Object.keys(rest).map(key => {
    if (!rest[key]) {
      return additionalIncidents
    }

    const c = parseInt(rest[key])

    const notDeletedIpI =
      rest[key.replace('quickStats', '') + 'involvedPartyInfo'] &&
      rest[
        key.replace('quickStats', '') + 'involvedPartyInfo'
      ].filter(ip => !ip.deleted)

    const typeId =
      key.startsWith('quickStat') &&
      currentAgency.activities.filter(
        i => i.label === key.slice(10)
      )[0].id

    return (
      key.startsWith('quickStat') &&
      typeId !== mainTypeId &&
      additionalIncidents.push({
        typeId: typeId,
        count: c,
        ...(notDeletedIpI && {
          involvedPartyInfo: {
            create: prepinvolvedPartyInfo(notDeletedIpI, c)
          }
        })
      })
    )
  })

  let dispatchesToCreate = {}

  if (dispatches && dispatches.length > 0) {
    dispatchesToCreate = mapCreateDispatchesToSubmission({
      units: dispatches
        .filter(i => !i.removed)
        .filter(i => i !== undefined),
      destinationId: position ? position.value : null,
      wasDispatched: false
    })
  }

  let involvedPartyInfoArr
  if (involvedPartyInfo && involvedPartyInfo.length > 0) {
    involvedPartyInfoArr = prepinvolvedPartyInfo(
      involvedPartyInfo,
      count
    )
  }

  return {
    createdAt: getRightNow(),
    formResponses:
      formResponses &&
      formResponses.map(fr => mapValuesToSubmitForm(fr)),
    additionalIncidents,

    ...(involvedPartyInfoArr && {
      involvedPartyInfo: involvedPartyInfoArr.filter(
        ip => !ip.deleted
      )
    }),
    dispatches: dispatchesToCreate,
    type: mainTypeId,
    weather,
    location: location && {
      create: {
        lat,
        lng,
        ...positionLocation,
        prettyAddress
      }
    },
    //default to one
    count: parseInt(count, 10),
    //required until bug is fixed in prisma handling defaults https://github.com/prisma/prisma2/issues/457
    incidentTime: incidentTime || new Date().toISOString(),
    note,
    noteWrittenBy: note && noteWrittenBy,
    noteWrittenByPosition: note && noteWrittenByPosition,
    noteWasDispatch: (note && noteWasDispatch) || false,
    vehicleIncidents: vehicles
      ?.filter(v => !v.removed)
      ?.map(v => ({
        type: v?.vehicleType?.value,
        value: parseInt(v?.vehicleValue?.value || v.vehicleValue),
        licenseNumber: v.lNumber,
        passengers: parseInt(v.passengers)
      })),
    files:
      files &&
      files.map(({ userId, agencyId, uploadedAt, ...f }) => ({
        ...f,
        name: f?.name || 'Attachment'
      }))
  }
}

export const mapDispatchFormToSubmission = (
  values,
  currentAgency
) => {
  const {
    incident,
    units = [],
    position,
    location,
    count,
    incidentTime,
    dispatcher,
    trackEnroute
  } = values
  const hasLocation = location || position
  const lng = optionalChaining(() => location.lng)
  const lat = optionalChaining(() => location.lat)
  const prettyAddress = optionalChaining(() => location.prettyAddress)
  let addtLocationInfo = {}
  if (location) {
    addtLocationInfo = {
      prettyAddress,
      lat,
      lng
    }
  }

  const resources = units
  const incidentType =
    incident &&
    currentAgency.activities.filter(i => i.label === incident)
  return {
    //required until bug is fixed in prisma handling defaults https://github.com/prisma/prisma2/issues/457
    incidentTime: incidentTime || new Date().toISOString(),
    count: parseInt(count, 10),
    location: hasLocation && {
      create: {
        ...(position?.value && {
          position: {
            connect: {
              id: position.value
            }
          }
        }),
        ...addtLocationInfo
      }
    },
    dispatches: mapCreateDispatchesToSubmission({
      units: resources,
      destinationId: position ? position.value : null,
      dispatcher,
      wasDispatched: true,
      trackEnroute
    }),
    note: incidentType?.[0]?.noteTemplate,
    noteWrittenBy: 'system',
    type: incident ? incidentType[0].id : undefined
  }
}

export const mapCreateDispatchesToSubmission = ({
  units,
  destinationId,
  dispatcher,
  unassignedDispatch,
  wasDispatched = false,
  addedLater,
  trackEnroute
}) => {
  let connections

  // Moving away from this... but leaving it in for now
  if (destinationId) {
    connections = {
      destination: {
        connect: {
          id: destinationId
        }
      }
    }
  }

  if (dispatcher) {
    connections = {
      dispatchedBy: {
        connect: {
          id: dispatcher
        }
      }
    }
  }
  const hasUnits =
    units &&
    units.filter(u => u.unit || u.value || u.personnel).length > 0

  const defaultDispatchInfo = {
    createdAt: getRightNow(),
    wasDispatched,
    ...(hasUnits && {
      enRoute:
        wasDispatched && !trackEnroute ? getRightNow() : undefined
    }),
    ...connections
  }

  return {
    ...(unassignedDispatch && {
      update: {
        where: {
          id: unassignedDispatch.id
        },
        data: {
          unit: {
            connect: {
              id: units[0].id || units[0].value
            }
          },
          ...defaultDispatchInfo
        }
      }
    }),
    create: hasUnits
      ? [...(unassignedDispatch ? units.slice(1) : units)].map(u => ({
          ...((u?.value || u?.unit?.value || u?.unit?.id) && {
            unit: {
              connect: {
                id: u.value || u.unit.value || u.unit.id
              }
            }
          }),
          ...((u?.personnel?.value || u?.personnel?.id) && {
            personnel: {
              connect: {
                id: u.personnel.value || u.personnel.id
              }
            }
          }),

          ...defaultDispatchInfo,
          ...(wasDispatched &&
            u.isPositionLocation &&
            !addedLater && {
              onScene: getRightNow(),
              enRoute: getRightNow()
            })
        }))
      : {
          ...defaultDispatchInfo
        }
  }
}
export const mapUpdateFormToSubmission = async (
  values,
  currentAgency
) => {
  const {
    incidentTime,
    subLabelOne,
    subLabelTwo,
    subLabelThree,
    count,
    note,
    noteWasDispatch,
    noteWrittenBy,
    noteWrittenByPosition,
    position,
    tideHeight,
    waveHeight,
    waterTemp,
    waveInterval,
    windDirection,
    windSpeed,
    missingUnit,
    airTemp,
    vehicles,
    location,
    involvedPartyInfo,
    files,
    formResponses,
    ...rest
  } = values

  // Initialize an empty object for the submission
  let submission = {}

  // Add fields to the submission object only if they exist in `values`
  if (incidentTime !== undefined)
    submission.incidentTime = incidentTime
  if (count !== undefined) submission.count = parseInt(count, 10)
  if (note !== undefined)
    submission.note = DOMPurify.sanitize(note || '')
  if (noteWasDispatch !== undefined)
    submission.noteWasDispatch = noteWasDispatch
  if (noteWrittenBy !== undefined)
    submission.noteWrittenBy = noteWrittenBy
  if (noteWrittenByPosition !== undefined)
    submission.noteWrittenByPosition = noteWrittenByPosition
  // ... continue for all other destructured fields

  // typeId
  if (
    subLabelOne !== undefined ||
    subLabelTwo !== undefined ||
    subLabelThree !== undefined
  ) {
    const typeId = currentAgency.activities.filter(
      i =>
        i.label ===
        createActivtyTypeLabel(
          subLabelOne,
          subLabelTwo,
          subLabelThree
        )
    )?.[0]?.id
    if (typeId !== undefined) {
      submission.type = {
        connect: {
          id: typeId
        }
      }
    }
  }

  // Weather condition
  if (
    tideHeight ||
    waveHeight ||
    waterTemp ||
    waveInterval ||
    airTemp ||
    windDirection ||
    windSpeed
  ) {
    const weather = {
      tideHeight: tideHeight
        ? parseFloatTwoDecimal(tideHeight)
        : undefined,
      waveHeight: waveHeight
        ? parseFloatTwoDecimal(waveHeight)
        : undefined,
      waterTemp: waterTemp
        ? parseFloatTwoDecimal(waterTemp)
        : undefined,
      waveInterval: waveInterval
        ? parseFloatTwoDecimal(waveInterval)
        : undefined,
      airTemp: airTemp ? parseFloatTwoDecimal(airTemp) : undefined,
      windDirection: windDirection || null,
      windSpeed: windSpeed
        ? parseFloatTwoDecimal(windSpeed)
        : undefined
    }
    submission.weather = {
      upsert: {
        create: weather,
        update: weather
      }
    }
  }

  // Location handling
  if (location !== undefined) {
    const lng = optionalChaining(() => location.lng)
    const lat = optionalChaining(() => location.lat)
    const positionLocation = optionalChaining(
      () => location.position.value
    )
      ? {
          position: {
            connect: {
              id: location.position.value
            }
          }
        }
      : {}
    const clearPositionLocation = location?.position?.cleared
      ? {
          position: {
            disconnect: true
          }
        }
      : {}
    const prettyAddress = optionalChaining(
      () => location.prettyAddress
    )

    submission.location = {
      upsert: {
        create: {
          lat: lat || null,
          lng: lng || null,
          ...positionLocation,
          prettyAddress
        },
        update: {
          lat: lat || null,
          lng: lng || null,
          ...positionLocation,
          ...clearPositionLocation,
          prettyAddress: !lat && !lng ? null : prettyAddress
        }
      }
    }
  }

  // Involved Party Info

  if (involvedPartyInfo && involvedPartyInfo.length > 0) {
    const involvedPartyInfoToAdd = involvedPartyInfo.filter(
      ip => !ip.deleted
    )

    const involvedPartyInfoArr =
      involvedPartyInfoToAdd &&
      prepinvolvedPartyInfo(involvedPartyInfoToAdd, count, true)

    const involvedPartyInfoToRemove = involvedPartyInfo.filter(
      //is deleted and is not a number id but an actual
      i => i.deleted && i.id.length > 5
    )

    submission.involvedPartyData = {
      ...(involvedPartyInfoArr && {
        upsert: upsertInvolvedPartyArr(involvedPartyInfoArr)
      }),
      ...(involvedPartyInfoToRemove && {
        delete: involvedPartyInfoToRemove.map(ip => ({
          id: ip.id
        }))
      })
    }
  }

  // Form responses
  if (formResponses !== undefined) {
    submission.formResponses = formResponses.map(fr =>
      mapValuesToSubmitForm(fr)
    )
  }

  // Files
  if (files !== undefined) {
    submission.files = {
      create: files.map(({ uploadedAt, agencyId, userId, ...f }) => ({
        ...f,
        name: f?.name || 'Attachment'
      }))
    }
  }

  // Additional incidents processing
  let additionalIncidents = []

  Object.keys(rest).map(key => {
    if (!key.includes('quickStats') || !rest[key]) {
      return additionalIncidents
    }

    const c = parseInt(rest[key])
    const involvedPartyInfoArr = prepinvolvedPartyInfo(
      rest[key.replace('quickStats', '') + 'involvedPartyInfo'] || [],
      c
    )

    const involvedPartyArrToUpsert =
      involvedPartyInfoArr &&
      involvedPartyInfoArr.filter(ip => !ip.deleted)

    const involvedPartyInfoToRemove =
      involvedPartyInfo &&
      involvedPartyInfo.filter(
        //is deleted and is not a number id but an actual
        i => i.deleted && i.id.length > 5
      )

    return additionalIncidents.push({
      typeId: currentAgency.activities.filter(
        i => i.label === key.slice(10)
      )?.[0]?.id,
      count: c,
      ...(rest[
        key.replace('quickStats', '') + 'involvedPartyInfo'
      ] && {
        involvedPartyInfo: {
          ...(involvedPartyInfoToRemove && {
            delete: involvedPartyInfoToRemove.map(ip => ({
              id: ip.id
            }))
          }),
          upsert: upsertInvolvedPartyArr(involvedPartyArrToUpsert)
        }
      })
    })
  })

  if (additionalIncidents.length > 0) {
    submission.additionalIncidents = additionalIncidents
  }

  // Vehicles

  if (vehicles !== undefined) {
    const vehilcesToRemove = vehicles?.filter(
      i => i.removed && i.prefilled
    )

    const vehiclesToCreate = vehicles?.filter(
      i => !i.removed && !i.prefilled
    )

    const hasVehicles = vehiclesToCreate?.length > 0
    const hasVehiclesToRemove = vehilcesToRemove?.length > 0

    submission.vehicleIncidents = {
      ...(hasVehicles && {
        create: vehiclesToCreate?.map(v => {
          return {
            type: v.vehicleType && v.vehicleType.value,
            value: parseInt(v?.vehicleValue?.value || v.vehicleValue),
            licenseNumber: v.lNumber,
            passengers: parseInt(v.passengers)
          }
        })
      }),
      ...(hasVehiclesToRemove && {
        delete: vehilcesToRemove.map(v => ({
          id: v.vehicleIncidenId
        }))
      })
    }
  }

  // Dispatches
  if (rest.dispatches !== undefined) {
    submission.dispatches = {}
    const updatedDispatches = rest.dispatches.filter(
      i => i.updated && !i.removed && i.id && !i.id.startsWith('new')
    )

    const toUpdate = updatedDispatches

    const toRemove = rest.dispatches.filter(
      i => i.removed && i.id && !i.id.startsWith('new')
    )
    const toAdd = rest.dispatches.filter(
      i =>
        !i.removed &&
        i.id &&
        i.id.startsWith('new') &&
        (i.unit || i.personnel)
    )

    if (toAdd.length > 0) {
      submission.dispatches = mapCreateDispatchesToSubmission({
        units: toAdd.filter(i => i !== undefined),
        destinationId:
          location?.position && location?.position.value
            ? location.position.value
            : null,
        wasDispatched: false
      })
    }

    if (toRemove.length > 0) {
      submission.dispatches.delete = toRemove.map(i => ({
        id: i.id
      }))
    }

    if (toUpdate.length > 0) {
      const currentlyUpdated = [
        ...(submission.dispatches.update || [])
      ]
      submission.dispatches.update = currentlyUpdated.concat(
        toUpdate.map(i => {
          let connects = {}

          if (i.unit) {
            connects.unit = {
              connect: {
                id: i.unit.id || i.unit.value
              }
            }
          }

          if (i.personnel) {
            connects.personnel = {
              connect: {
                id: i.personnel.id || i.personnel.value
              }
            }
          }

          return {
            where: {
              id: i.id
            },
            data: {
              ...connects
            }
          }
        })
      )
    }
  }

  return submission
}

const upsertInvolvedPartyArr = arr => {
  return arr.map(ivp => ({
    where: { id: ivp.id && ivp.id.length > 3 ? ivp.id : 'undefined' },
    update: {
      age: ivp.age,
      gender: ivp.gender,
      geoOrigin: ivp.geoOrigin,
      isIndividual: ivp.isIndividual
    },
    create: {
      age: ivp.age,
      gender: ivp.gender,
      geoOrigin: ivp.geoOrigin,
      isIndividual: ivp.isIndividual
    }
  }))
}

// use this function to map Reports options.where to submission where args in api
export const mapOptsToSubmissionWhereArgs = (opts, op) => {
  const operator = op || 'AND'

  let where = []

  if (opts.positions && opts.positions.length > 0) {
    const selectedPositions = {
      id: { in: opts.positions.map(f => f.value || f.id) }
    }

    //Submission Filter
    where.push({
      OR: [
        {
          dispatches: {
            some: { unit: selectedPositions }
          }
        },
        {
          location: {
            position: selectedPositions
          }
        }
      ]
    })
  }

  if (opts.users && opts.users.length > 0) {
    //Submission Filter

    where.push({
      OR: [
        {
          dispatches: {
            some: {
              personnel: {
                id: { in: opts.users.map(r => r.value || r.id) }
              }
            }
          }
        }
      ]
    })

    if (opts.includeReporter) {
      where.push({
        reporter: {
          id: { in: opts.users.map(r => r.value || r.id) }
        }
      })

      //Form Response Filter
    }
  }

  if (opts.activities && opts.activities.length) {
    const categoryFilters =
      opts.activities &&
      opts.activities.filter(f => f.value || f.label)
    const unKnownFilter =
      opts.activities &&
      opts.activities.find(f => (f.value || f.label) === null)

    if (categoryFilters || unKnownFilter) {
      const def = unKnownFilter
        ? [
            {
              type: null
            }
          ]
        : []
      //Submission Filter
      where.push({
        type: {
          OR: [
            ...def.concat(categoryFilters).map(f => {
              return {
                label: {
                  startsWith: f.value || f.label
                }
              }
            })
          ]
        }
      })
    }
  }

  return !isEmpty(where) ? { [operator]: where } : {}
}

export const mapOptsToFormFieldItemsWhereArgs = forms => {
  return (
    forms &&
    flatten(
      forms.map(
        f =>
          f.fieldItems &&
          f.fieldItems.map(fi => ({
            id: fi.id,
            groupByFieldItemId: f.groupByFieldItem?.id,
            //Currently a form level object.  but technically could be fieldItem level if needed
            where: f.where
              ? generateFormFieldItemAdvancedWhere(
                  f.where,
                  f.submittedBy
                )
              : {}
          }))
      )
    )
  )
}
