/** @jsxImportSource theme-ui */

import { memo, useState, useEffect, Fragment } from 'react'
import styled from '@emotion/styled'
import { Space } from '..'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import { Box, Flex, Grid, useThemeUI } from 'theme-ui'
import { orderBy } from 'natural-orderby'
import flatten from 'lodash/flatten'
import StickyButton from '../Shared/StickyButton'

import Col from '../Shared/Elements/Col'
import Row from '../Shared/Elements/Row'
import Input from '../Shared/Elements/Input'
import Button from '../Shared/Elements/Button'
import { getContrastColor } from 'src/utils/getContrastColor'
import { useHiddenGroups } from 'src/app/hooks/useHiddenPositionGroups'

const IconContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 10px;
  left: 10px;
  z-index: 3;
`

const TappableSearchInput = memo(
  ({
    options,
    onSelect,
    inputOptions,
    buttonOptions,
    optionColorKey,
    field,
    multiSelect,
    renderChoiceStatus,
    itemsAcross,
    value,
    renderSummary,
    customLabel,
    optionsCategorized = false,
    label,
    fuzzyColorKey,
    onSkip,
    currentLabel
  }) => {
    const [showSearch, setShowSearch] = useState(false)
    const [query, setQuery] = useState('')
    const [shownOptions, setShownOptions] = useState(options)

    const optionsAreObjects =
      typeof options[0] === 'object' ? true : false

    useEffect(() => {
      if (query.length < 1) setShownOptions(options)
      if (query) {
        if (optionsCategorized) {
          setShownOptions(
            options
              .map(o => ({
                ...o,
                options: o.options.filter(opt2 =>
                  opt2.label
                    .toLowerCase()
                    .includes(query.toLowerCase())
                )
              }))
              .filter(o => o.options && o.options.length > 0)
          )

          return
        }

        if (optionsAreObjects) {
          setShownOptions(
            options.length > 0 &&
              options.filter(
                opt =>
                  opt &&
                  opt.label
                    .toLowerCase()
                    .includes(query.toLowerCase())
              )
          )
        } else {
          setShownOptions(
            options.length > 0 &&
              options.filter(
                opt =>
                  opt &&
                  opt.toLowerCase().includes(query.toLowerCase())
              )
          )
        }
      }
    }, [query, options, optionsAreObjects, optionsCategorized])

    function InputButton({
      opt,
      renderIcon,

      optionsAreObjects,
      ...rest
    }) {
      const currentOptionVal = optionsAreObjects ? opt.value : opt
      const currentOptionLabel = optionsAreObjects ? opt.label : opt

      const activeValue =
        value && multiSelect
          ? value.find(v => v.value === currentOptionVal)
          : currentOptionVal ===
            (optionsAreObjects ? get(value, 'value') : value)

      const colorKeyExact = optionColorKey?.find(
        ok => ok.label === currentOptionLabel
      )

      //if fuzzy check for starts with... this is only on sub labels
      const colorKeyFuzzy =
        !colorKeyExact &&
        fuzzyColorKey &&
        optionColorKey?.find(
          ok =>
            ok.label.includes(currentLabel) &&
            ok.label.includes(currentOptionLabel)
        )

      const colorKey = colorKeyExact || colorKeyFuzzy

      return (
        <Col
          sx={{
            padding: '5px',
            justifyContent: 'stretch',
            cursor: 'pointer'
          }}
          name={field}
          {...rest}
        >
          {activeValue?.activeBanner && (
            <IconContainer
              onClick={(e = { target: {} }) => {
                return onSelect(
                  {
                    persist: () => {},
                    ...e,
                    target: {
                      ...e.target,
                      value: currentOptionVal,
                      name: field
                    }
                  },
                  optionsAreObjects ? opt : undefined
                )
              }}
              sx={{
                color: 'white',
                bg: activeValue?.activeColor || 'primary',
                fontSize: '10px',
                p: '2px',
                mt: '2px',
                fontWeight: 'bold',
                textAlign: 'center',
                borderRadius: '5px'
              }}
            >
              {activeValue.activeBanner}
            </IconContainer>
          )}
          <Input
            variant="input.tappableInput"
            type="button"
            sx={{
              backgroundColor: activeValue
                ? 'primary'
                : colorKey?.color,
              height: '100%',
              color:
                colorKey?.color &&
                getContrastColor(colorKey.color, 3.5),
              fontWeight: 'bold',
              border: 'none'
            }}
            name={field}
            {...buttonOptions}
            value={currentOptionLabel}
            onClick={e =>
              onSelect(e, optionsAreObjects ? opt : undefined)
            }
          />

          {renderChoiceStatus && renderChoiceStatus(opt)}
        </Col>
      )
    }

    if (!Array.isArray(shownOptions) && isEmpty(shownOptions)) {
      return <div />
    }
    const defaultInputOptions = {
      placeholder: 'Type to search',
      ...inputOptions
    }

    return (
      <Box sx={{ p: 10 }}>
        {customLabel ? (
          customLabel
        ) : (
          <>
            {label && (
              <Flex
                sx={{
                  pb: 10,
                  alignItems: 'stretch',
                  width: '100%',
                  flexDirection: showSearch ? 'column' : 'row'
                }}
              >
                <Flex
                  sx={{
                    alignItems: 'center',
                    gap: '5px',
                    mb: '10px'
                  }}
                >
                  <h5
                    sx={{
                      margin: '0px'
                    }}
                  >
                    {label}
                  </h5>
                  <Button
                    size="sm"
                    title="Search"
                    variant="linkGray"
                    icon={showSearch ? 'close' : 'search'}
                    onClick={() => {
                      setQuery('')
                      setShowSearch(!showSearch)
                    }}
                  />
                </Flex>
                {showSearch && (
                  <Flex sx={{ width: '100%', mt: '15px' }}>
                    <Input
                      {...defaultInputOptions}
                      value={query}
                      onChange={e => setQuery(e.target.value)}
                    />
                  </Flex>
                )}
              </Flex>
            )}

            {onSkip && (
              <StickyButton
                type="button"
                variant="secondary"
                block
                stickySubmit={true}
                onClick={() => onSkip()}
              >
                Skip
              </StickyButton>
            )}
          </>
        )}

        <Space height={5} />
        <Row>
          {optionsCategorized
            ? shownOptions &&
              orderBy(
                shownOptions,
                v => v.category && v.category.name
              ).map((opt, key) => {
                return (
                  <CategoryBackground
                    key={key}
                    category={opt.category}
                  >
                    <TapInputGridContainer itemsAcross={itemsAcross}>
                      {orderBy(opt.options, v => [
                        v.order,
                        v.label
                      ]).map((opt2, key) => {
                        return (
                          <InputButton
                            opt={opt2}
                            optionsAreObjects={optionsAreObjects}
                            key={key}
                          />
                        )
                      })}
                    </TapInputGridContainer>
                  </CategoryBackground>
                )
              })
            : shownOptions && (
                <TapInputGridContainer itemsAcross={itemsAcross}>
                  {orderBy(shownOptions, v => v.label, 'asc').map(
                    (opt, key) => {
                      if (!opt) return null
                      return (
                        <InputButton
                          opt={opt}
                          optionsAreObjects={optionsAreObjects}
                          key={key}
                        />
                      )
                    }
                  )}
                </TapInputGridContainer>
              )}
          {renderSummary &&
            renderSummary(
              value,
              optionsCategorized
                ? flatten(shownOptions.map(i => i.options))
                : shownOptions
            )}
        </Row>
      </Box>
    )
  }
)

export const TapInputGridContainer = ({
  itemsAcross = 4,
  children
}) => {
  const itemsAcrossIsArray = Array.isArray(itemsAcross)
  return (
    <Grid
      sx={{
        alignItems: 'stretch',
        gridTemplateColumns: itemsAcrossIsArray
          ? itemsAcross.map(ia => `repeat(${ia}, 1fr)`)
          : `repeat(${itemsAcross}, 1fr)`,
        // gap: '2px',
        width: '100%',
        gridGap: '0px'
      }}
    >
      {children}
    </Grid>
  )
}

const CategoryBackground = ({ category, children }) => {
  const { theme } = useThemeUI()
  const { hiddenGroups, hideGroup, showGroup } = useHiddenGroups()

  const categoryId = category?.id || 'noCategory'
  const isHidden = hiddenGroups?.includes(categoryId)

  return (
    <Box
      sx={{
        width: '100%',
        my: '5px',
        p: '10px',
        position: 'relative',
        bg: category ? category.color : 'lightGray',
        color:
          category &&
          category.color &&
          getContrastColor(category.color, 4, theme.rawColors.text)
      }}
    >
      <Flex
        sx={{
          fontWeight: 'bold',
          px: '5px',
          alignItems: 'center',
          gap: '5px',
          width: '100%',
          justifyContent: 'space-between',
          py: '5px',
          fontSize: '16px',
          cursor: 'pointer'
        }}
        onClick={() =>
          isHidden ? showGroup(category?.id) : hideGroup(category?.id)
        }
      >
        <div> {category?.name || ''} </div>
        <ToggleGroupVisibilityButton
          hideGroup={hideGroup}
          showGroup={showGroup}
          groupId={category?.id}
          color={category?.color}
          isHidden={isHidden}
          textcolor={theme.rawColors.text}
        />
      </Flex>

      {isHidden ? (
        <Fragment />
      ) : (
        <Box sx={{ mt: '10px' }}>{children}</Box>
      )}
    </Box>
  )
}

const ToggleGroupVisibilityButton = ({
  groupId,
  showGroup,
  hideGroup,
  isHidden,
  color,
  textcolor
}) => {
  return (
    <Button
      size="sm"
      sx={{
        p: '0px'
      }}
      iconSize={'15px'}
      iconColor={getContrastColor(color, 4, textcolor)}
      variant={'linkGray'}
      icon={isHidden ? 'down arrow' : 'up arrow'}
      onClick={() =>
        isHidden ? showGroup(groupId) : hideGroup(groupId)
      }
    />
  )
}

export default TappableSearchInput
