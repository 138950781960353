import React from 'react'

function Linked(props) {
  const title = props.title || 'url'

  return (
    <svg
      height={props?.height || '48'}
      width={props?.width || '48'}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g>
        <path
          d="M20.543 20.543C22.309 20.563 24.07 21.241 25.415 22.586C28.144 25.315 28.144 29.757 25.415 32.485L19.758 38.142C17.029 40.871 12.588 40.871 9.85902 38.142C7.13002 35.413 7.13002 30.971 9.85902 28.243L15.516 22.586C15.858 22.244 16.227 21.945 16.617 21.688C16.445 20.143 16.602 18.572 17.082 17.08C15.475 17.597 13.961 18.483 12.688 19.757L7.03102 25.414C2.74202 29.703 2.74202 36.681 7.03102 40.97C11.32 45.259 18.298 45.259 22.587 40.97L28.244 35.313C32.533 31.024 32.533 24.046 28.244 19.757C26.411 17.924 24.085 16.881 21.69 16.615C20.909 17.801 20.529 19.173 20.544 20.542L20.543 20.543Z"
          fill={props.color}
        />
        <path
          d="M27.457 27.457C25.691 27.437 23.93 26.759 22.585 25.414C19.856 22.685 19.856 18.243 22.585 15.515L28.242 9.85798C30.971 7.12898 35.412 7.12898 38.141 9.85798C40.87 12.587 40.87 17.029 38.141 19.757L32.484 25.414C32.142 25.756 31.773 26.055 31.383 26.312C31.555 27.857 31.398 29.428 30.918 30.92C32.525 30.403 34.039 29.517 35.312 28.243L40.969 22.586C45.258 18.297 45.258 11.319 40.969 7.02998C36.68 2.74098 29.702 2.74098 25.413 7.02998L19.756 12.687C15.467 16.976 15.467 23.954 19.756 28.243C21.589 30.076 23.915 31.119 26.31 31.385C27.091 30.199 27.471 28.827 27.456 27.458L27.457 27.457Z"
          fill={props.color}
        />
        <path
          d="M21.688 16.615C20.907 17.801 20.527 19.173 20.542 20.542C21.232 20.55 21.92 20.661 22.584 20.871C22.47 19.525 22.891 18.153 23.866 17.091C23.154 16.861 22.426 16.697 21.688 16.615Z"
          fill={props.color}
          opacity="0.15"
        />
        <path
          d="M32.485 25.4139C32.143 25.7559 31.774 26.0549 31.384 26.3119C31.556 27.8569 31.399 29.4279 30.919 30.9199C31.715 30.6639 32.487 30.3159 33.219 29.8769C33.525 28.1869 33.492 26.4549 33.126 24.7739L32.485 25.4149V25.4139Z"
          fill={props.color}
          opacity="0.15"
        />
        <defs>
          <linearGradient
            id="nc-ui-4-0_linear_143_7"
            gradientUnits="userSpaceOnUse"
            x1="17.6375"
            x2="17.6375"
            y1="16.615"
            y2="44.1867"
          >
            <stop stopColor="#E0E0E6" />
            <stop offset="1" stopColor="#C2C3CD" />
          </linearGradient>
          <linearGradient
            id="nc-ui-4-1_linear_143_7"
            gradientUnits="userSpaceOnUse"
            x1="30.3625"
            x2="30.3625"
            y1="3.81323"
            y2="31.385"
          >
            <stop stopColor="#E0E0E6" />
            <stop offset="1" stopColor="#C2C3CD" />
          </linearGradient>
        </defs>
      </g>
    </svg>
  )
}

export default Linked
