import { Box } from 'theme-ui'

import { useSendNotification } from 'src/app/hooks/mutations'
import useForm from 'src/app/hooks/useForms'
import { useToast } from 'src/components/toasts'

import CustomSelectInput from '../../Forms/CustomSelectInput'
import { cleanGraphQLError } from '../../Forms/FormError'

import Button from '../Elements/Button'
import Label from '../Elements/Label'
import Input from '../Elements/Input'
import ModalFooter from '../Elements/ModalFooter'
import FormGroup from '../Elements/FormGroup'
import Form from '../Elements/Form'

import { useGetCurrentAgencyContext } from 'src/app/hooks/queries'
import InlineTooltip from '../InlineTooltip'

import { getTruthyPermissionsFromSettings } from '../../PayWalls/PayWallScreen'
import { gql, useQuery } from '@apollo/client'
import { useEffect } from 'react'

const GET_MY_AGENCIES_MONTHLY_COMMS_SMS_COUNT = gql`
  query GetMyAgenciesMonthlyCommsSmsCount {
    myAgenciesMonthlyCommsSmsCount
  }
`

const NotifyForm = ({
  all,
  whiteList,
  whiteListedGroups,
  url,
  onFinish,
  defaultMessage
}) => {
  const {
    users,
    currentAgency,
    user,
    commsSmsLimit,
    settings
  } = useGetCurrentAgencyContext()
  const {
    data,
    loading: monthlyCommsSmsCountLoading,
    refetch
  } = useQuery(GET_MY_AGENCIES_MONTHLY_COMMS_SMS_COUNT)

  const myAgenciesMonthlyCommsSmsCount =
    data?.myAgenciesMonthlyCommsSmsCount
  const currentUserId = user.id
  const { loading, sendNotifications } = useSendNotification()
  const allowedToSendSms =
    getTruthyPermissionsFromSettings(settings).includes('commsSms') &&
    myAgenciesMonthlyCommsSmsCount < commsSmsLimit
  const { values, setValues, handleSubmit, handleChange } = useForm(
    sendNotifcations,
    {
      userIds: whiteList || [],
      tags: whiteListedGroups || [],
      message: defaultMessage,
      sendToEveryone: all,
      url
    }
  )

  useEffect(() => {
    setValues(v => ({
      ...v,
      email: !allowedToSendSms ? true : false,
      phone: allowedToSendSms ? true : false
    }))
  }, [allowedToSendSms])

  const { add } = useToast()

  async function sendNotifcations() {
    try {
      let channels = []

      if (values.phone) {
        channels.push('phone')
      }

      if (values.email) {
        channels.push('email')
      }

      const recipients = values.sendToEveryone
        ? {
            userIds: users
              .filter(i => i.email || i.phoneNumber)
              .map(u => u.id)
          }
        : {
            userIds: values?.userIds?.map(u => u.id) || [],
            tagIds: values?.tags?.map(u => u.id) || []
          }

      const res = await sendNotifications({
        variables: {
          ...recipients,
          message: values.message || defaultMessage,
          url,
          channels: channels
        }
      })
      if (res && !res.errors) {
        add({
          content: 'Notifications Sent',
          color: 'success'
        })
      } else {
        add({
          content: cleanGraphQLError(res.errors[0]),
          color: 'danger'
        })
        return
      }

      onFinish && onFinish()
    } catch (e) {
      add({
        color: 'danger',
        content: cleanGraphQLError(e)
      })
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      {!values.sendToEveryone && (
        <>
          <FormGroup onClick={e => e.stopPropagation()}>
            <Label>Who to notify?</Label>
            <CustomSelectInput
              id="userIds"
              name="userIds"
              isMulti={true}
              noOptionsMessage={() =>
                'No options. Make sure users have phone numbers.'
              }
              getOptionLabel={v => v.name}
              getOptionValue={v => {
                return v.id
              }}
              itemTitle="Users"
              useSummary={values.userIds?.length > 5}
              isDisabled={values.sendToEveryone}
              isClearable={false}
              value={values.userIds || []}
              onChange={(selected, action) => {
                setValues(prev => ({
                  ...prev,
                  userIds: selected
                }))
              }}
              options={users
                .filter(i => i.email)
                .sort((a, b) =>
                  a.phoneNumber && b.phoneNumber
                    ? a.name < b.name
                      ? -1
                      : 1
                    : a.phoneNumber
                    ? -1
                    : 1
                )
                .map(i => ({
                  id: i.id,
                  name: `${i.name} ${
                    i.id === currentUserId ? '(Yourself)' : ''
                  }`
                }))}
            />
          </FormGroup>
          <FormGroup>
            <Label>Groups to notify?</Label>
            <CustomSelectInput
              id="tags"
              name="tags"
              isMulti={true}
              getOptionValue={v => v.id}
              getOptionLabel={v => v.name}
              isDisabled={values.sendToEveryone}
              isClearable={false}
              loading={loading}
              withColor
              value={values.tags}
              onChange={(selected = []) => {
                setValues(v => ({
                  ...v,
                  tags: selected
                }))
              }}
              options={currentAgency.positionCategories}
            />
          </FormGroup>
        </>
      )}
      <FormGroup check sx={{ mb: 35 }}>
        <Input
          required
          id="sendToEveryone"
          type="checkbox"
          name="sendToEveryone"
          checked={values.sendToEveryone || ''}
          onChange={e => {
            const sendToEveryone = e.target.checked
            setValues(v => ({
              ...v,
              tags: [],
              userIds: [],
              sendToEveryone
            }))
          }}
          label="Send to everyone"
        />
      </FormGroup>
      <FormGroup>
        <Label>Message to send</Label>
        <Input
          required
          id="message"
          type="textarea"
          name="message"
          value={values.message || ''}
          onChange={handleChange}
          placeholder="Custom message"
          showCharacterCount={true}
          maxLength={values.phone ? 1600 : null}
          sx={{ minHeight: 150 }}
        />
      </FormGroup>

      <FormGroup>
        <Label>What communication channels?</Label>
        <Input
          type="checkbox"
          checked={values.phone}
          name={'phone'}
          label={
            monthlyCommsSmsCountLoading
              ? 'checking usage...'
              : `SMS ${
                  allowedToSendSms
                    ? `(${myAgenciesMonthlyCommsSmsCount}/${commsSmsLimit})`
                    : '(Not included in plan)'
                }`
          }
          disabled={monthlyCommsSmsCountLoading || !allowedToSendSms}
          onChange={e => {
            handleChange(e)
          }}
        />
        <Input
          type="checkbox"
          checked={values.email}
          name={'email'}
          label="Email"
          onChange={e => {
            handleChange(e)
          }}
        />
      </FormGroup>
      {values.phone && (
        <InlineTooltip
          variant="warning"
          header={'Your current monthly usage.'}
          tip={
            monthlyCommsSmsCountLoading
              ? 'Checking usage...'
              : `You have sent ${myAgenciesMonthlyCommsSmsCount} SMS this month.  You can send up to ${commsSmsLimit} messages per month.`
          }
          sx={{ mb: '30px' }}
          action={
            <Button
              onClick={() => refetch()}
              variant="linkGray"
              icon={monthlyCommsSmsCountLoading ? 'spinner' : 'sync'}
            />
          }
        />
      )}

      {values.message && (
        <Box sx={{ my: 25 }}>
          <h5>Text Preview:</h5>
          <Box
            sx={{
              bg: 'gray300',
              p: 15,
              borderRadius: 4,
              whiteSpace: 'pre-line',
              wordBreak: 'break-word'
            }}
          >
            {values.message}
            <br />
            <br />
            {url && <>Learn more on Watchtower at {url}</>}
          </Box>
        </Box>
      )}

      <ModalFooter onClose={onFinish}>
        <Button
          disabled={loading}
          sx={{ marginTop: '10px' }}
          type="submit"
        >
          {loading ? 'Sending...' : 'Send'}
        </Button>
      </ModalFooter>
    </Form>
  )
}

export default NotifyForm
