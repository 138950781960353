/** @jsxImportSource theme-ui */

import { Flex } from 'theme-ui'

import Badge from '../Elements/Badge'

import Elipses from '../Elipses'

import flatMap from 'lodash/flatMap'
import stripHtml from 'src/utils/stripHtml'

import ArchiveFormResponseButton from '../../Operations/Forms/ArchiveFormResponseButton'
import { FormSubmitRendererModalButton } from '../../Operations/Forms/formSubmitRenderer/FormSubmitRendererModalButton'

function getSummaryFieldsFromResponse(response) {
  return flatMap(
    response?.fields?.map(field =>
      field.fieldItems.filter(fi => fi.summaryAnswer)
    )
  )
}

const AttachedFormResponseItem = ({
  response,
  onUpdate,
  onDetach,
  includeFormName,
  fetchFormResponse,
  onFinish,
  onDraftFinish,
  ...props
}) => {
  const summary =
    response?.summary || getSummaryFieldsFromResponse(response)

  const summaryTexts = summary?.filter(s => {
    return (
      typeof s === 'string' ||
      s.value?.name !== undefined ||
      s.value !== undefined
    )
  })

  const hasSummary = summaryTexts?.length > 0

  const CustomButton = {
    blockButton: true,
    buttonProps: {
      variant: 'naked',
      icon: 'pencil',
      sx: {
        padding: '0px',
        cursor: 'pointer',
        textAlign: 'left',
        alignItems: 'flex-start',
        minWidth: '100%'
      }
    },
    buttonLabel: (
      <Flex
        sx={{
          gap: '4px',
          width: '100%',
          alignItems: 'center'
        }}
      >
        {response.draft && (
          <Badge sx={{ p: '3px', mr: '5px' }} variant="warning">
            Draft
          </Badge>
        )}
        <Flex sx={{ alignItems: 'center' }}>
          <Elipses mw={'250px'}>
            {(includeFormName || !hasSummary) &&
              `${response?.form?.name || response.formName} ${
                hasSummary ? ' - ' : ''
              } `}
            {hasSummary &&
              stripHtml(
                summaryTexts
                  ?.map(item =>
                    typeof item === 'string'
                      ? item
                      : item.value?.name || item.value
                  )
                  .join(', ')
              )}
          </Elipses>
        </Flex>
      </Flex>
    )
  }

  return (
    <Flex
      sx={{
        p: '5px',
        px: '10px',
        bg: 'gray300',
        borderRadius: '4px',
        justifyContent: 'space-between',
        marginTop: '10px',
        BoxgnItems: 'center',
        fontWeight: 'bold',
        alignItems: 'center'
      }}
      {...props}
    >
      <FormSubmitRendererModalButton
        disableAutoSave
        submitToastText="Attached Form Updated"
        onFormSubmit={
          onUpdate &&
          (val => {
            return onUpdate(val)
          })
        }
        modalButtonProps={CustomButton}
        formResponse={response}
        formResponseId={response.id}
        onFinish={onFinish}
        onDraftFinish={onDraftFinish}
        {...response}
        fetchFormResponse={fetchFormResponse}
      />

      <Flex sx={{ gap: '2px' }}>
        <ArchiveFormResponseButton
          hideToolTip
          id={response.id}
          isArchived={response.archived}
          onFinish={onFinish}
          onDraftFinish={onDraftFinish}
          cacheQuery={null}
          onArchive={
            onDetach &&
            (async () => {
              onDetach(response.id)
            })
          }
          buttonProps={{
            size: 'sm',
            variant: 'linkGray',
            icon: 'trash'
          }}
        />
      </Flex>
    </Flex>
  )
}

export default AttachedFormResponseItem
