import { theme } from 'src/styles'

function Help(props) {
  const title = props.title || ''
  const { height = '64px', width = '64px' } = props

  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{title}</title>
      <g fill={props.color || theme.colors.gray800}>
        <path
          d="M32,2A30,30,0,1,0,62,32,30.034,30.034,0,0,0,32,2ZM30,51a3,3,0,1,1,3-3A3,3,0,0,1,30,51ZM40.1,27.907a34.832,34.832,0,0,1-4.126,4.013,15.533,15.533,0,0,0-3.359,3.6,8.87,8.87,0,0,0-.818,4.534H28.553a13.574,13.574,0,0,1,.906-6.081,12.973,12.973,0,0,1,3.371-4.013c3.878-3.279,5.208-4.722,5.208-7.875,0-3.718-2.627-5.359-6.39-5.359a17.034,17.034,0,0,0-8.2,2.164l-1.484-3.4A21.12,21.12,0,0,1,31.9,13q4.8,0,7.472,2.365a8.448,8.448,0,0,1,2.667,6.667A9.021,9.021,0,0,1,40.1,27.907Z"
          fill={props.color || theme.colors.gray800}
        />
      </g>
    </svg>
  )
}

export default Help
