import { useUpdateForm } from '../../../../hooks/mutations'

import { useToast } from '../../../../../components/toasts'
import { cleanGraphQLError } from '../../../../components/Forms/FormError'

import {
  GET_CURRENT_AGENCY,
  GET_MY_AGENCIES_FORMS
} from 'src/app/hooks/queries'

import { cloneDeep } from '@apollo/client/utilities'
import ModalButton from 'src/app/components/Shared/ModalButton'
import Spinner from 'src/images/icons/Spinner'
import Button from 'src/app/components/Shared/Elements/Button'

const FormPinButton = ({
  formId,
  isPinned,
  isPinnedToSubmitScreen,
  style,
  ...rest
}) => {
  const { add } = useToast()

  const { updateOneForm, loading } = useUpdateForm()

  async function pinFile(pinLocation) {
    const homePin = pinLocation === 'home'
    const pinMessage =
      pinLocation === 'home' ? 'Homebase' : 'Submit Incidents'
    const dataKey = homePin ? 'pinned' : 'pinnedToSubmitScreen'

    try {
      await updateOneForm({
        variables: {
          where: {
            id: formId
          },
          data: {
            [dataKey]: homePin ? !isPinned : !isPinnedToSubmitScreen
          }
        },
        //Update cache...
        update: (store, { data: { updateOneForm } }) => {
          // Read the data from our cache for this query.

          if (dataKey === 'pinnedToSubmitScreen') {
            let data = cloneDeep(
              store.readQuery({
                query: GET_CURRENT_AGENCY
              })
            )

            data.myAgency.tappableForms =
              data.myAgency.tappableForms && updateOneForm[dataKey]
                ? [updateOneForm, ...data.myAgency.tappableForms]
                : data.myAgency.tappableForms.filter(
                    tf => tf.id !== updateOneForm.id
                  )

            // Write our data back to the cache.
            store.writeQuery({
              query: GET_CURRENT_AGENCY,
              data: { ...data }
            })
          } else {
            let data = cloneDeep(
              store.readQuery({
                query: GET_MY_AGENCIES_FORMS,
                variables: {
                  take: 10,
                  where: {
                    pinned: {
                      equals: true
                    }
                  }
                }
              })
            )

            if (data) {
              data.myAgenciesForms =
                data.myAgenciesForms && updateOneForm[dataKey]
                  ? [
                      updateOneForm,
                      ...((data && data.myAgenciesForms) || [])
                    ]
                  : data.myAgenciesForms.filter(
                      tf => tf.id !== updateOneForm.id
                    )
            } else {
              data = {
                myAgenciesForms: [updateOneForm]
              }
            }

            // Write our data back to the cache.
            store.writeQuery({
              query: GET_MY_AGENCIES_FORMS,
              data: { ...data },
              variables: {
                take: 10,
                where: {
                  pinned: {
                    equals: true
                  }
                }
              }
            })
          }
        }
      })

      const currentPinState =
        dataKey === 'pinnedToSubmitScreen'
          ? isPinnedToSubmitScreen
          : isPinned

      add({
        content: currentPinState
          ? `Form removed from  ${pinMessage}.`
          : `Form pinned to ${pinMessage}.`,
        color: 'success'
      })
    } catch (e) {
      add({
        content: cleanGraphQLError(e),
        color: 'danger'
      })
    }
  }

  return (
    <ModalButton
      buttonLabel={`Pin To (${isPinned + isPinnedToSubmitScreen})`}
      buttonProps={{
        variant:
          isPinned || isPinnedToSubmitScreen
            ? 'success'
            : 'secondary',
        icon: 'pin'
      }}
      modalHeader={'Where would you like to pin this form?'}
      renderModalActions={toggle => [
        loading && (
          <Spinner
            key={0}
            width={20}
            style={{ marginRight: '5px' }}
          />
        ),
        <Button
          key={1}
          variant={isPinned ? 'success' : 'secondary'}
          onClick={async e => {
            e.stopPropagation()
            await pinFile('home')
            toggle()
          }}
          icon="pin"
          {...rest}
        >
          {isPinned ? 'Unpin From Home' : 'Pin To Home'}
        </Button>,
        <Button
          key={2}
          icon="pin"
          variant={isPinnedToSubmitScreen ? 'success' : 'secondary'}
          onClick={async e => {
            e.stopPropagation()
            await pinFile()
            toggle()
          }}
          {...rest}
        >
          {isPinnedToSubmitScreen
            ? 'Unpin From Submit Screen'
            : 'Pin To Submit Screen'}
        </Button>
      ]}
    />
  )
}

export default FormPinButton
