/** @jsxImportSource theme-ui */

import { useLogout } from '../services/auth'
import Button from '../components/Shared/Elements/Button'

import { Box, Flex, Heading, Image, Link } from 'theme-ui'
import image from '../../images/MainImage.png'

import BackgroundImage from '../components/Shared/BackgroundImage'
import FaviconWhite from '../../images/FaviconWhite.png'

import { useGetCurrentAgencyContext } from '../hooks/queries'
import { useSmallChat } from 'src/components/SupportButton'

const Onboarding = () => {
  const { currentAgency } = useGetCurrentAgencyContext()
  const { openChat } = useSmallChat()
  const { logout } = useLogout()

  return (
    <Flex sx={{ justifyContent: 'center', height: '100%' }}>
      <Box sx={{ flex: [1, 1, 1, 0.75] }}>
        <Flex
          sx={{
            alignItems: 'center',
            flexDirection: 'column',
            p: 4,
            textAlign: 'center',
            pt: '10%',
            maxWidth: '500px',
            margin: 'auto'
          }}
        >
          <Image
            sx={{
              borderRadius: '50%',
              width: '100px',
              height: '100px',
              mb: 3
            }}
            src={FaviconWhite}
          />

          {currentAgency ? (
            <>
              <Heading>
                Next Generation Digital Command Center
              </Heading>
              <p>All the software you need to save lives</p>
              <Button href="/agency" sx={{ mt: 5 }} block size="lg">
                {`Go to ${currentAgency?.name}`}
              </Button>
            </>
          ) : (
            <>
              <Heading>Welcome to Watchtower</Heading>
              <p>
                Looking to get started? We have a few options for you.
              </p>
              <ol
                sx={{
                  fontSize: 2,

                  margin: 20,
                  padding: 0,
                  textAlign: 'left',
                  maxWidth: '500px',
                  mt: 5
                }}
              >
                <li
                  sx={{
                    mb: 5
                  }}
                >
                  <h5>Join an existing agency</h5>{' '}
                  <p>
                    Reach out to your supervisor and ask them to
                    invite you to the agency.
                  </p>
                </li>
                <li>
                  <h5>Create a new agency</h5>
                  <p>
                    Use the chat bubble to message our support team or
                    click
                    <Button
                      sx={{
                        p: '0px',
                        textTransform: 'lowercase',
                        display: 'inline-block'
                      }}
                      variant="link"
                      onClick={openChat}
                    >
                      here.
                    </Button>
                  </p>
                </li>
              </ol>
            </>
          )}
          <Flex sx={{ mt: 3, alignItems: 'center', gap: '5px' }}>
            Have an account?{' '}
            <Link sx={{ p: '0px' }} variant="link" onClick={logout}>
              Login here
            </Link>
          </Flex>
        </Flex>
      </Box>
      <Box sx={{ flex: 1, display: ['none', null, null, 'block'] }}>
        <BackgroundImage src={image} fullHeight />
      </Box>
    </Flex>
  )
}

export default Onboarding
