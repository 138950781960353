import { Flex, useColorMode } from 'theme-ui'
import { BetaFeatureBadge } from './Shared/BetaFeatureBadge'
import Switch from './Shared/Elements/Switch'

const DarkModeToggle = ({ hideLabel, props }) => {
  const [colorMode, setColorMode] = useColorMode()

  return (
    <Switch
      readOnly
      label={
        !hideLabel && (
          <Flex sx={{ alignItems: 'center', gap: '4px' }}>
            Dark Mode{' '}
          </Flex>
        )
      }
      checked={colorMode === 'dark'}
      onClick={() =>
        setColorMode(colorMode === 'default' ? 'dark' : 'default')
      }
      {...props}
    />
  )
}

export default DarkModeToggle
