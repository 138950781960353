import { cloneDeep } from '@apollo/client/utilities'
import { useMutation } from '@apollo/client'
import { ARCHIVE_ONE_FORM_RESPONSE } from 'src/app/hooks/mutations'
import { GET_MY_AGENCIES_FORM_RESPONSES } from 'src/app/hooks/queries'

import { useToast } from 'src/components/toasts'
import ModalButton from '../../Shared/ModalButton'
import Button from '../../Shared/Elements/Button'
import InlineTooltip from '../../Shared/InlineTooltip'
import set from 'lodash/set'
import get from 'lodash/get'

export const ArchiveFormResponseButton = ({
  id,
  variables,
  cacheQuery = GET_MY_AGENCIES_FORM_RESPONSES,
  isArchived,
  cacheKey = 'myAgenciesFormResponses',
  options,
  buttonProps,
  onArchive,
  hideToolTip,
  onFinish
}) => {
  const { add } = useToast()

  const [archiveOneFormResponse, { loading: updating }] = useMutation(
    ARCHIVE_ONE_FORM_RESPONSE,
    {
      update: (store, { data: { archiveOneFormResponse } }) => {
        try {
          if (!cacheQuery) return
          // Read the data from our cache for this query.
          const data = cloneDeep(
            store.readQuery({
              query: cacheQuery,
              variables: variables
            })
          )

          // Using lodash's get to handle dot notation in cacheKey
          let cacheData = get(data, cacheKey)

          if (!cacheData) return

          if (cacheData?.feed) {
            // Update the cache data by filtering out the archived response

            cacheData = {
              ...cacheData,
              feed:
                cacheData?.feed?.filter(
                  d => d.id !== archiveOneFormResponse.id
                ) || []
            }
          } else {
            cacheData =
              cacheData?.filter(
                d => d.id !== archiveOneFormResponse.id
              ) || []
          }

          // Using lodash's set to update the data object with the new array
          set(data, cacheKey, cacheData)

          // Write our data back to the cache.
          store.writeQuery({
            query: cacheQuery,
            data: data,
            variables: variables
          })
        } catch (e) {
          console.log(e)
        }
      },
      ...options
    }
  )
  return (
    <ModalButton
      modalHeader={`Are you sure you want to ${
        isArchived ? 'restore' : 'archive'
      } this form response?`}
      buttonProps={{
        variant: 'link',
        icon: isArchived ? 'restore' : 'trash',
        iconColor: isArchived ? 'success' : 'danger',
        sx: {
          color: isArchived ? 'success' : 'danger'
        },
        ...buttonProps
      }}
      renderModalActions={toggle => (
        <Button
          onClick={async () => {
            if (onArchive) {
              onArchive()
            } else {
              const res = await archiveOneFormResponse({
                variables: {
                  id,
                  cascadeStatuses: true
                }
              })
              if (res && !res.errors) {
                onFinish && onFinish()
                add({
                  content: `Form response ${
                    isArchived ? 'restored' : 'archived'
                  }.`,
                  color: 'success'
                })
              }
            }
            toggle()
          }}
          variant="danger"
          sx={{ marginRight: '5px' }}
          disabled={updating}
          icon={updating && 'spinner'}
        >
          {isArchived ? 'Yes, restore.' : 'Yes, archive'}
        </Button>
      )}
    >
      {() =>
        !hideToolTip && (
          <InlineTooltip
            variant={'warning'}
            tip={
              'This will also archive any Pulse status updates associated with this form response.'
            }
            isCloseable={false}
          />
        )
      }
    </ModalButton>
  )
}

export default ArchiveFormResponseButton
