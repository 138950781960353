import React, { useState } from 'react'
import { useApolloClient } from '@apollo/client'

import {
  GET_FORM,
  useGetCurrentAgencyContext
} from 'src/app/hooks/queries'

import Icon from '../Shared/Icon'
import { Flex } from 'theme-ui'
import {
  chunkArray,
  sequenceCallsWithProgress
} from 'src/utils/chunkAndSequenceWithProgress'

export const GoOfflineSubmitTabButton = p => {
  const { currentAgency } = useGetCurrentAgencyContext()
  const formIds =
    currentAgency?.offlineEligibleFormIds?.map(oef => oef.id) || []
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)
  const client = useApolloClient()

  async function goOffline() {
    setLoading(true)

    if (formIds?.length) {
      const chunkSize = 5
      const chunks = chunkArray(formIds, chunkSize)
      const numChunks = chunks.length

      await sequenceCallsWithProgress(
        chunks,
        async (chunk, index) => {
          await Promise.all(
            chunk.map(formId => {
              return client.query({
                query: GET_FORM,
                fetchPolicy: 'network-only',
                variables: {
                  where: { id: formId },
                  fieldsWhere: {
                    archived: {
                      equals: false
                    }
                  },
                  fieldItemsWhere: {
                    archived: {
                      equals: false
                    }
                  }
                }
              })
            })
          )

          const chunkProgress = ((index + 1) / numChunks) * 100
          setProgress(chunkProgress)
        }
      )
    } else {
      setProgress(100)
    }

    setLoading(false)
  }

  return (
    <Flex
      sx={{
        cursor: 'pointer',
        gap: '4px',
        paddingLeft: '5px',
        ...p.style
      }}
      onClick={goOffline}
    >
      <Flex
        sx={{
          bg: progress === 100 ? 'success' : 'secondary',
          alignItems: 'center',
          justifyContent: 'center',
          width: '20px',
          height: '20px',
          borderRadius: '40px',
          mr: p.showText && '10px'
        }}
      >
        <Icon
          width={10}
          height={10}
          title={
            progress === 100
              ? 'Offline Ready'
              : 'Download Offline Data'
          }
          icon={progress === 100 ? 'checkmark' : 'download'}
        />
      </Flex>

      {p.showText && (
        <div>
          {loading
            ? `Downloading.. (${Math.round(progress)}%)`
            : progress === 100
            ? 'Offline Ready'
            : 'Prep Offline'}
        </div>
      )}
    </Flex>
  )
}

export default GoOfflineSubmitTabButton
