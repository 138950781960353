/** @jsxImportSource theme-ui */
import { forwardRef } from 'react'
import { Flex } from 'theme-ui'

const Row = ({ children, variant, sx, ...props }, ref) => {
  return (
    <Flex
      variant={variant ? variant : 'row'}
      sx={{ ...sx }}
      {...props}
    >
      {children}
    </Flex>
  )
}

export default forwardRef(Row)
