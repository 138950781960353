/** @jsxImportSource theme-ui */
import { forwardRef } from 'react'
import { Flex } from 'theme-ui'
import Badge from './Badge'
import Button from './Button'

const ModalFooter = (
  {
    children,
    variant = 'ModalFooter',
    onClose,
    onCloseText,
    alertBadge,
    alertBadgeVariant,
    ...props
  },
  ref
) => {
  return (
    <Flex variant={variant} {...props}>
      {alertBadge && (
        <Badge variant={alertBadgeVariant || 'successReverse'}>
          {alertBadge}
        </Badge>
      )}
      {onClose && (
        <Button onClick={onClose} variant="linkGray">
          {onCloseText || 'Cancel'}
        </Button>
      )}
      {children}
    </Flex>
  )
}

export default forwardRef(ModalFooter)
