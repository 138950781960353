const Map = ({ height, width, color }) => {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>map pin</title>
      <g fill={color || '#111111'}>
        <path
          d="M21,29.622c-3.189-4.503-6-9.688-6-13.986c0-0.67,0.038-1.322,0.108-1.956L1.629,19.071 C1.249,19.224,1,19.591,1,20v40c0,0.332,0.165,0.642,0.439,0.828C1.607,60.942,1.803,61,2,61c0.126,0,0.252-0.023,0.371-0.071 L21,53.477V29.622z"
          fill={color || '#111111'}
        />
        <path
          d="M23,53.477l18,7.2V32.308c-3.158,4.046-6.201,7.138-6.882,7.817C33.551,40.69,32.799,41,32,41 s-1.551-0.31-2.117-0.874c-0.681-0.68-3.724-3.772-6.883-7.818V53.477z"
          fill={color || '#111111'}
        />
        <path
          d="M62.597,11.198c-0.254-0.189-0.584-0.247-0.884-0.156L48.96,14.868c0.011,0.257,0.04,0.506,0.04,0.768 c0,4.299-2.811,9.484-6,13.986v31.055l19.371-7.748C62.751,52.776,63,52.409,63,52V12C63,11.684,62.851,11.386,62.597,11.198z"
          fill={color || '#111111'}
        />
        <path d="M32,1c-7.373,0-15,5.475-15,14.636c0,8.731,13.711,22.491,14.294,23.072 C31.489,38.903,31.745,39,32,39s0.511-0.097,0.706-0.292C33.289,38.127,47,24.368,47,15.636C47,6.475,39.373,1,32,1z M32,21 c-2.761,0-5-2.239-5-5s2.239-5,5-5s5,2.239,5,5S34.761,21,32,21z" />
      </g>
    </svg>
  )
}

export default Map
