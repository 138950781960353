import { gql, useMutation } from '@apollo/client'
import { useState } from 'react'
import { useToast } from 'src/components/toasts'
import Button from '../../Shared/Elements/Button'
import FormGroup from '../../Shared/Elements/FormGroup'
import Input from '../../Shared/Elements/Input'

import ModalButton from '../../Shared/ModalButton'

const DUPLICATE_FORM = gql`
  mutation DuplicateForm($id: ID!, $skipPulse: Boolean) {
    duplicateForm(id: $id, skipPulse: $skipPulse) {
      id
    }
  }
`

export const DuplicateFormButton = ({
  formId,
  hasPulse,
  formNamme
}) => {
  const { add } = useToast()
  const [skipPulse, setSkipPulse] = useState(false)
  const [duplicateForm, { loading }] = useMutation(DUPLICATE_FORM, {
    refetchQueries: ['GetAgencyForms']
  })

  const handleDuplicateForm = async () => {
    try {
      const res = await duplicateForm({
        variables: {
          id: formId,
          ...(skipPulse && { skipPulse })
        }
      })

      if (res.errors) {
        add({
          color: 'danger',
          content: 'An error occurred while duplicating the form'
        })
        return
      }
      add({
        color: 'success',
        content: 'Form duplicated successfully'
      })
    } catch (error) {
      console.log(error)
      add({
        color: 'danger',
        content: 'An error occurred while duplicating the form'
      })
    }
  }
  return (
    <ModalButton
      modalHeader={`Are you sure you want to duplicate ${formNamme}?`}
      buttonProps={{
        variant: 'linkGray',

        icon: 'duplicate'
      }}
      renderModalActions={toggle => (
        <Button
          disabled={loading}
          icon={loading && 'spinner'}
          onClick={async () => {
            await handleDuplicateForm()
            toggle()
          }}
        >
          Duplicate Form
        </Button>
      )}
    >
      {() => {
        return (
          <>
            {hasPulse && (
              <>
                <p>
                  By default, all Pulse status connections will be
                  included. If you would like to exclude Pulse
                  connections, uncheck the box below.
                </p>
                <FormGroup
                  sx={{
                    maxWidth: '300px'
                  }}
                >
                  <Input
                    type="checkbox"
                    style={{ cursor: 'pointer' }}
                    id={'skipPulse'}
                    name={'skipPulse'}
                    checked={!skipPulse}
                    readOnly
                    label={'Include Pulse Connections'}
                    onClick={() => {
                      setSkipPulse(!skipPulse)
                    }}
                  />
                </FormGroup>
              </>
            )}
          </>
        )
      }}
    </ModalButton>
  )
}
