import { Box, Flex } from 'theme-ui'
import moment from 'moment'
import { prettyDate } from 'src/utils'
import ModalButton from './ModalButton'
import { RichTextRender } from './RichTextRender'
import ListGroupItem from './Elements/ListGroupItem'
import UpdateNoteModalForm from '../Forms/UpdateNoteModalForm'

export const NoteList = ({ notes, props }) => (
  <Box {...props}>
    {notes &&
      notes.map((d, i) => {
        const noteIsNotToday =
          moment(d.createdAt).diff(new Date(), 'd') < 0

        return (
          <Flex
            key={i}
            sx={{
              justifyContent: 'flex-start',
              alignItems: 'center',
              flexWrap: 'wrap',
              py: '5px',
              pt: '10px',
              whiteSpace: 'normal',
              wordWrap: 'break-word',
              borderBottom:
                notes.length !== i + 1 && '1px solid lightGray'
            }}
          >
            <Flex
              sx={{
                px: 1,
                flexDirection: 'column'
              }}
            >
              <Flex
                sx={{
                  alignItems: 'center',
                  gap: '5px'
                }}
              >
                {d.createdAt && (
                  <strong>
                    {prettyDate(
                      d.createdAt,
                      noteIsNotToday ? null : 'HH:mm'
                    )}
                    {` ${
                      d.wasDispatch
                        ? '| Dispatch'
                        : d.writtenByPosition
                        ? `| ${d.writtenByPosition.name}`
                        : ''
                    } ${d.writtenBy ? `| ${d.writtenBy.name}` : ''}`}
                  </strong>
                )}
                <UpdateNoteModalForm noteId={d.id} text={d.text} />
              </Flex>
              <RichTextRender text={d.text} />
            </Flex>
          </Flex>
        )
      })}
  </Box>
)

const Notes = ({ notes }) => {
  return (
    <ListGroupItem
      sx={{
        maxHeight: '200px',
        overflowY: 'auto',
        position: 'relative'
        // boxShadow: 'inset 0 -10px 10px -10px lightGray'
      }}
    >
      <MoreNotesModal
        notes={notes}
        style={{ position: 'absolute', top: 10, right: 10 }}
      />
      <NoteList notes={notes} />
    </ListGroupItem>
  )
}

export const MoreNotesModal = ({ notes, ...props }) => {
  return (
    <ModalButton
      buttonLabel={'Show Notes'}
      buttonProps={{
        variant: 'link',
        style: props.style
      }}
      modalHeader={'All Notes'}
      {...props}
    >
      {() => <NoteList notes={notes} />}
    </ModalButton>
  )
}

export default Notes
