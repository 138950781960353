/** @jsxImportSource theme-ui */

import { useCallback, useEffect, useState } from 'react'

import { gql, useLazyQuery } from '@apollo/client'

import Icon from '../components/Shared/Icon'
import { Box, Flex } from 'theme-ui'

import { checkPWStrength } from 'src/utils/checkPasswordStrength'

import throttle from 'lodash/throttle'

const PW_CHECK = gql`
  query PWCheck($password: String) {
    pwStrengthCheck(password: $password)
  }
`

const PasswordError = ({ input, onSuccess }) => {
  const {
    lengthValid,
    upperCaseValid,
    numberValid,
    specialCharValid
  } = checkPWStrength(input)
  const [strengthCheck, setStrengthCheck] = useState(false)

  const [checkStrength, { loading, data }] = useLazyQuery(PW_CHECK, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',

    onCompleted: () => {
      setStrengthCheck(data?.pwStrengthCheck)
    }
  })

  const ready =
    strengthCheck &&
    lengthValid &&
    upperCaseValid &&
    numberValid &&
    specialCharValid

  useEffect(() => {
    onSuccess(ready)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready])

  const throttledCheckStrength = useCallback(
    throttle(
      input => {
        checkStrength({
          variables: {
            password: input
          }
        })
      },
      1200,
      {
        leading: false,
        trailing: true
      }
    ),
    []
  ) // Dependencies array is empty to ensure the throttle function is not recreated

  useEffect(() => {
    throttledCheckStrength(input)
  }, [input, throttledCheckStrength])

  const strong = data?.pwStrengthCheck?.valid_password
  const suggestions = data?.pwStrengthCheck?.feedback?.warning

  return (
    <Box>
      <Flex
        sx={{
          my: 2,
          alignItems: 'center',
          gap: '3px',
          color: lengthValid && 'success'
        }}
      >
        <Icon
          width={15}
          height={15}
          color={lengthValid ? 'success' : 'danger'}
          icon={lengthValid ? 'success' : 'close'}
        />{' '}
        At least 8 characters
      </Flex>
      <Flex
        sx={{
          my: 2,
          alignItems: 'center',
          gap: '3px',
          color: upperCaseValid && 'success'
        }}
      >
        <Icon
          width={15}
          height={15}
          color={upperCaseValid ? 'success' : 'danger'}
          icon={upperCaseValid ? 'success' : 'close'}
        />{' '}
        At least 1 uppercase letter
      </Flex>
      <Flex
        sx={{
          my: 2,
          alignItems: 'center',
          gap: '3px',
          color: numberValid && 'success'
        }}
      >
        <Icon
          width={15}
          height={15}
          color={numberValid ? 'success' : 'danger'}
          icon={numberValid ? 'success' : 'close'}
        />{' '}
        At least 1 number
      </Flex>
      <Flex
        sx={{
          my: 2,
          alignItems: 'center',
          gap: '3px',
          color: specialCharValid && 'success'
        }}
      >
        <Icon
          width={15}
          height={15}
          color={specialCharValid ? 'success' : 'danger'}
          icon={specialCharValid ? 'success' : 'close'}
        />{' '}
        At least 1 special character (e.g. @#$%^?!&*+=)
      </Flex>
      <Flex
        sx={{
          my: 2,
          alignItems: 'center',
          gap: '3px',
          color: strong && 'success'
        }}
      >
        <Icon
          width={15}
          height={15}
          color={strong ? 'success' : 'danger'}
          icon={loading ? 'spinner' : strong ? 'success' : 'close'}
        />{' '}
        Meets strength requirments.{' '}
        {strong
          ? 'Great work!'
          : suggestions && `Tip: ${suggestions}`}
      </Flex>
    </Box>
  )
}

export default PasswordError
