import React from 'react'

function ResizeY(props) {
  const title = props.title || 'resize y'

  return (
    <svg
      height={props.height || '48'}
      width={props.width || '48'}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill={props.color || '#f7f7f7'}>
        <path d="M15.219,31.625l8,10a1,1,0,0,0,1.562,0l8-10A1,1,0,0,0,32,30H16a1,1,0,0,0-.781,1.625Z" />
        <path d="M16,18H32a1,1,0,0,0,.781-1.625l-8-10a1.036,1.036,0,0,0-1.562,0l-8,10A1,1,0,0,0,16,18Z" />
        <path
          d="M44,1H4A1,1,0,0,0,4,3H44a1,1,0,0,0,0-2Z"
          fill={props.color || '#f7f7f7'}
        />
        <path
          d="M44,45H4a1,1,0,0,0,0,2H44a1,1,0,0,0,0-2Z"
          fill={props.color || '#f7f7f7'}
        />
      </g>
    </svg>
  )
}

export default ResizeY
