export default ({ height, width, color }) => {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>star</title>
      <g>
        <path
          d="M46,18H30.19L24.952,1.694a1,1,0,0,0-1.9,0L17.81,18H2a1,1,0,0,0-.612,1.791L14,29.554,8.752,45.691a1,1,0,0,0,1.539,1.118L24,36.85l13.709,9.959a1,1,0,0,0,1.539-1.118L34,29.554l12.608-9.763A1,1,0,0,0,46,18Z"
          fill={color}
        />
      </g>
    </svg>
  )
};
