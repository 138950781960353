import { useState } from 'react'
import { useUpdateManySubmissions } from 'src/app/hooks/mutations'

import { useToast } from 'src/components/toasts'
import { cleanGraphQLError } from '../Forms/FormError'

import ModalButton from '../Shared/ModalButton'

import Button from '../Shared/Elements/Button'
import { Box } from 'theme-ui'
import ModalFooter from '../Shared/Elements/ModalFooter'
import RichTextInput from '../Shared/RichTextInput'
import DOMPurify from 'dompurify'

export const BulkSubmissionNoteForm = ({
  onFinish,
  submissionIds,
  note,
  mutationOptions
}) => {
  const { add } = useToast()

  const {
    updateManySubmissions,
    loading: loadingMany
  } = useUpdateManySubmissions(mutationOptions)
  const [content, setValues] = useState(note)

  async function onSave() {
    const res = await updateManySubmissions({
      variables: {
        data: {
          note: DOMPurify.sanitize(content || '')
        },
        where: {
          ids: submissionIds
        }
      }
    })

    if (!res.errors) {
      onFinish()
    } else {
      add({
        variant: 'danger',
        content: cleanGraphQLError(res.errors)
      })
    }
  }

  return (
    <Box>
      <RichTextInput
        minHeight={'300px'}
        onChange={content => {
          setValues(content)
        }}
        value={content || ''}
      />
      <ModalFooter onClose={onFinish}>
        <Button
          onClick={onSave}
          disabled={loadingMany}
          icon={loadingMany && 'spinner'}
        >
          Update
        </Button>
      </ModalFooter>
    </Box>
  )
}

const BulkeSubmissionNoteModal = ({
  note,
  submissionIds,
  cacheQuery,

  variables
}) => {
  return (
    <ModalButton
      buttonProps={{
        icon: 'pencil',
        variant: 'link',
        size: 'sm',
        sx: {
          p: '0px'
        }
      }}
    >
      {({ toggle }) => {
        return (
          <BulkSubmissionNoteForm
            submissionIds={submissionIds}
            onFinish={toggle}
            note={note}
            mutationOptions={{
              refetchQueries: [
                {
                  query: cacheQuery,
                  variables
                }
              ]
            }}
          />
        )
      }}
    </ModalButton>
  )
}

export default BulkeSubmissionNoteModal
