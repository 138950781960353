/** @jsxImportSource theme-ui */
import { forwardRef, Fragment } from 'react'
import {
  Button as ThemeUIButton,
  Link as ExternalLink,
  useThemeUI
} from 'theme-ui'
import { Link as InternalLink } from 'react-router-dom'
import styled from '@emotion/styled'
import Icon from '../Icon'

// color => variant
// size => padding
// block => width

const StyledButton = styled(ThemeUIButton)`
  text-decoration: none;
`
const Button = ({
  children,
  outline,
  size,
  block,
  customIcon,
  variant = 'primary',
  disabled = false,
  href = null,
  download = false,
  icon,
  iconColor,
  iconPosition = 'left',
  iconSize,
  title,
  noPadding,
  ...props
}) => {
  const { colorMode, theme } = useThemeUI()
  const IconComponent =
    customIcon || icon ? (
      customIcon || (
        <Icon
          icon={icon}
          width={iconSize === 'lg' ? 20 : iconSize || 15}
          height={iconSize === 'lg' ? 20 : iconSize || 15}
          color={
            outline
              ? iconColor || theme.buttons[variant]?.bg
              : disabled
              ? 'gray600'
              : iconColor || theme.buttons[variant]?.color || 'white'
          }
          title={title}
        />
      )
    ) : (
      <Fragment />
    )

  // Handle block prop and set button to be 100% width, block-level element
  if (block) {
    props.sx = {
      ...props.sx,

      width: '100%'
    }
  }

  // Handle size prop and map size prop to pre-sets
  if (size) {
    switch (size) {
      case 'xs':
        props.sx = {
          ...props.sx,
          fontSize: '.575rem',
          padding: '0.5rem 0.1rem',
          lineHeight: 1,
          borderRadius: '0.2rem'
        }
        break
      case 'sm':
        props.sx = {
          ...props.sx,
          fontSize: '.675rem',
          padding: '0.25rem 0.5rem',
          lineHeight: 1.5,
          borderRadius: '0.2rem'
        }
        break
      case 'md':
        break
      case 'lg':
        props.sx = {
          ...props.sx,
          fontSize: '1.1rem',
          padding: '0.5rem 1rem',
          lineHeight: 1.5,
          borderRadius: '0.3rem'
        }
        break
      default:
        break
    }
  }

  if (noPadding) {
    props.sx = {
      ...props.sx,
      padding: '0px'
    }
  }

  // Handle outline prop, make bg transparent and borders, text same color
  if (outline) {
    props.sx = {
      ...props.sx,
      backgroundColor: 'transparent',
      borderColor: colorMode === 'dark' ? 'white' : `${variant}`,
      color: colorMode === 'dark' ? 'white' : `${variant}`,
      '&:hover': {
        bg: 'background',
        // color: colorMode === 'dark' ? 'black' : `${variant}`
        opacity: 0.8,
        textDecoration: 'none',
        color: 'white'
      }
    }
  }

  const ButtonLink = ({ href, sx, linkProps, ...props }) => {
    // Check link type based on href value
    const linkType =
      href.startsWith('http://') ||
      href.startsWith('https://') ||
      href.startsWith('data') ||
      href.startsWith('mailto:')
        ? 'external'
        : 'internal'

    // Return internal or external link based on link type
    if (linkType === 'external') {
      return (
        <StyledButton variant={variant} {...props}>
          <ExternalLink
            href={href}
            rel="noopener noreferrer"
            target="_blank"
            download={download}
            {...linkProps}
            sx={{
              width: block && '100%',
              textDecoration: 'none',
              padding: '0px',
              color: 'inherit'
            }}
          >
            {iconPosition === 'left' && IconComponent}
            {children}
            {iconPosition === 'right' && IconComponent}
          </ExternalLink>
        </StyledButton>
      )
    } else if (linkType === 'internal') {
      return (
        <StyledButton
          variant={variant}
          disabled={disabled}
          {...props}
        >
          <InternalLink
            {...linkProps}
            sx={{
              width: block && '100%',
              textDecoration: 'none',
              padding: '0px',
              color: 'inherit'
            }}
            to={href}
          >
            {iconPosition === 'left' && IconComponent}
            {children}
            {iconPosition === 'right' && IconComponent}
          </InternalLink>
        </StyledButton>
      )
    }
  }

  return (
    <>
      {href ? (
        <ButtonLink href={href} {...props} />
      ) : (
        <>
          <StyledButton
            variant={variant}
            disabled={disabled}
            type={props.onClick ? 'button' : 'submit'}
            {...props}
          >
            {iconPosition === 'left' && IconComponent}
            {children}
            {iconPosition === 'right' && IconComponent}
          </StyledButton>
        </>
      )}
    </>
  )
}

export default Button
