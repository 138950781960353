import { useEffect, useState } from 'react'
import { Loader } from '@googlemaps/js-api-loader'
import config from 'src/config'

const apiKey = config.GOOGLE_MAPS_API_KEY

export const mapsOpts = {
  apiKey: apiKey,
  version: 'weekly',
  libraries: ['places', 'visualization']
}
const loader = new Loader(mapsOpts)

export default function useGoogleMapsLoader() {
  const [googleMaps, setGoogleMaps] = useState()
  const [loading, setLoading] = useState(true)
  const [e, setError] = useState(false)

  useEffect(() => {
    loader
      .load()
      .then(google => {
        setGoogleMaps(google)
        setLoading(false)
      })
      .catch(e => {
        console.log('Error loading maps', e)
        setError(
          'Error loading maps.  Please check for adblock or other script blockers.'
        )
      })
  }, [googleMaps])

  const getAddressFromLatLng = async ({ lat, lng }) => {
    const geoCoder = new googleMaps.maps.Geocoder()

    const geocoded = await geoCoder.geocode({
      location: {
        lat,
        lng
      }
    })

    return geocoded && geocoded.results[0].formatted_address
  }

  return { googleMaps, getAddressFromLatLng, loading, error: e }
}
