import { Fragment, useState } from 'react'

import CustomSelectInput from '../../Forms/CustomSelectInput'
import { Box, Flex } from 'theme-ui'

import Icon from '../../Shared/Icon'
import { useGetCurrentAgencyContext } from 'src/app/hooks/queries'
import FieldItemSelect from './FieldItemSelect'

import { getFieldConfig } from '../../Operations/Forms/formBuilder/dataMapping'
import operators, {
  getOperator
} from '../../Operations/Forms/formBuilder/FormWhereOperators'
import isEmpty from 'lodash/isEmpty'
import Button from '../../Shared/Elements/Button'
import ModalBody from '../../Shared/Elements/ModalBody'
import ModalFooter from '../../Shared/Elements/ModalFooter'
import Label from '../../Shared/Elements/Label'

export const FormFieldItemWhereForm = ({
  value,
  formId,
  handleFinish,
  handleCancel,
  hideSubmitterFilter,
  onClear
}) => {
  const defaultFilter = {}
  const valuesWithOperator = value
    ? {
        where: value?.where?.map(v => ({
          ...v,
          operator: getOperator(v.operator?.value)
        })) || [defaultFilter],
        submittedBy: value?.submittedBy
      }
    : null

  const { currentAgency } = useGetCurrentAgencyContext()

  const [where, setWhere] = useState(valuesWithOperator)

  const fieldItemsAreReady = where?.where?.some(
    w => w.fieldItem || Object.keys(w).length > 2
  )
    ? where?.where?.every(w => {
        const operator = getOperator(w.operator?.value)

        if (!operator) return false

        return operator?.requiresValue
          ? !isEmpty(w[w.key]) || w[w.key]?.length
          : true
      })
    : true
  const readyToSave = isEmpty(where) || fieldItemsAreReady

  function handleNewBlankFilter() {
    setWhere({ where: [...where.where, defaultFilter] })
  }

  function removeFilter(index) {
    setWhere(prev => ({
      ...prev,
      where: where.where.filter((w, i) => i !== index)
    }))
  }

  function clearFilters() {
    setWhere({ where: [defaultFilter] })
    onClear && onClear()
  }

  if (!currentAgency) return null
  return (
    <>
      <ModalBody>
        <h4>Form Filters</h4>
        <Box sx={{ mb: 10 }}>
          Use form filters to only show responses that match certain
          rules. Example: Find responses where the "location" question
          was a specific answer.
        </Box>
        <Box sx={{ mb: 10, mt: 40 }}>
          <Flex
            sx={{
              flexWrap: 'wrap',
              alignItems: 'flex-start',
              gap: '5px'
            }}
          >
            <Label>Question Filters</Label>
            {where?.where?.map((w, l) => {
              const fieldItemConfig =
                w.fieldItem && getFieldConfig(w.fieldItem)
              const operator = getOperator(w.operator?.value)

              return (
                <>
                  <Flex
                    key={l}
                    sx={{
                      width: '100%',
                      gap: '20px',
                      flexWrap: 'wrap',
                      alignItems: 'flex-start'
                    }}
                  >
                    <Box
                      sx={{
                        width: '250px',
                        maxWidth: '250px',
                        flex: 1
                      }}
                    >
                      <FieldItemSelect
                        value={w.fieldItem || null}
                        formId={formId}
                        isMulti={false}
                        filtersOnly
                        onFieldItemSelect={selected => {
                          const fieldItemConfig = getFieldConfig(
                            selected
                          )

                          setWhere({
                            ...where,
                            where: where.where.map((w, k) =>
                              k === l
                                ? {
                                    ...defaultFilter,
                                    key:
                                      fieldItemConfig &&
                                      fieldItemConfig.value,
                                    fieldItem: selected
                                  }
                                : w
                            )
                          })
                        }}
                      />
                    </Box>

                    {w?.fieldItem && (
                      <Box
                        sx={{
                          width: '250px',
                          maxWidth: '250px',
                          flex: 1
                        }}
                      >
                        <CustomSelectInput
                          value={operator}
                          options={operators.filter(o =>
                            fieldItemConfig.operators.includes(
                              o.value
                            )
                          )}
                          placeholder="Select how to compare.."
                          defaultValue={null}
                          isClearable
                          onChange={(selected, action) => {
                            setWhere({
                              ...where,
                              where: where.where.map((w2m, k) => {
                                if (k === l) {
                                  return {
                                    ...w2m,
                                    [fieldItemConfig.value]: null,
                                    operator:
                                      action?.action === 'clear'
                                        ? null
                                        : {
                                            value: selected.value,
                                            label: selected.label
                                          }
                                  }
                                }
                                return w2m
                              })
                            })
                          }}
                        />
                        <Box sx={{ p: '3px' }}>
                          {operator && operator.tip}
                        </Box>
                      </Box>
                    )}
                    {fieldItemConfig &&
                      operator?.renderWhereValueComponent && (
                        <Box>
                          {operator.renderWhereValueComponent({
                            value: w[fieldItemConfig.value],
                            type: fieldItemConfig.value,
                            allowOther: w.fieldItem?.allowOther,
                            isMulti:
                              w.fieldItem?.allowMultipleAnswers,
                            allowedValues: w.fieldItem?.allowedValues,
                            onChange: selected => {
                              setWhere({
                                ...where,
                                where: where.where.map((w2, k) => {
                                  if (k === l) {
                                    return {
                                      ...w2,
                                      [fieldItemConfig.value]: selected
                                    }
                                  }
                                  return w2
                                })
                              })
                            }
                          })}
                        </Box>
                      )}
                    {where.where.length > 1 && (
                      <Button
                        variant="secondary"
                        onClick={() => {
                          removeFilter(l)
                        }}
                        sx={{ width: '40px' }}
                      >
                        <Icon
                          color="white"
                          width="15px"
                          height="15px"
                          icon="close"
                        />
                      </Button>
                    )}
                  </Flex>
                  {where.where.length > l + 1 && (
                    <Box
                      key={l}
                      sx={{
                        flex: 1,
                        display: 'block',
                        minWidth: '100%',
                        textAlign: 'left'
                      }}
                    >
                      AND
                    </Box>
                  )}
                </>
              )
            })}
          </Flex>

          {where?.where?.[0].fieldItem && (
            <Flex>
              <Button
                variant="link"
                onClick={handleNewBlankFilter}
                sx={{ marginTop: '5px' }}
              >
                + Add another filter
              </Button>
              <Button
                variant="link"
                onClick={clearFilters}
                sx={{ marginTop: '5px' }}
              >
                Clear All
              </Button>
            </Flex>
          )}
        </Box>
        {hideSubmitterFilter ? (
          <Fragment />
        ) : (
          <Box sx={{ mt: '20px', maxWidth: '250px' }}>
            <Label>Submitted By</Label>
            <CustomSelectInput
              id="submitter"
              name="submitter"
              placeholder="Select a user"
              isMulti={true}
              getOptionValue={v => v.id}
              getOptionLabel={v => v.name}
              value={where?.submittedBy}
              onChange={selected => {
                setWhere({
                  ...where,
                  submittedBy: selected
                })
              }}
              options={currentAgency.users}
            />
          </Box>
        )}
      </ModalBody>
      <ModalFooter onClose={handleCancel}>
        <Button
          variant="primary"
          onClick={() => {
            handleFinish(where)
          }}
          disabled={!readyToSave}
        >
          Save Filters
        </Button>
      </ModalFooter>
    </>
  )
}
