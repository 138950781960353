import Input from '../Shared/Elements/Input'
import FormGroup from '../Shared/Elements/FormGroup'
import CustomSelectInput from './CustomSelectInput'

export const SubmissionLocationEditFields = ({
  options,
  onSelect,
  values = {},
  onCheck,
  showCheck
}) => {
  return (
    <div>
      <CustomSelectInput
        getOptionValue={v => v.id}
        getOptionLabel={v => v.name || v.label}
        options={options}
        onChange={onSelect}
        value={values.locationPosition}
        name={'locationPosition'}
        label={`Change Location`}
      />
      {showCheck && (
        <FormGroup check style={{ marginTop: '20px' }}>
          <Input
            name={'changeLatLng'}
            value={values.changeLatLng}
            type="checkbox"
            onChange={onCheck}
            label="Use the new location's latitude and longitude"
          />
        </FormGroup>
      )}
    </div>
  )
}
