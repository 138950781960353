import { theme } from 'src/styles'

function Phone(props) {
  const title = props.title || 'headphones mic'

  return (
    <svg
      height={props.height}
      width={props.width}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill={props.color || theme.colors.gray800}>
        <path d="M40,55H24c-0.552,0-1,0.448-1,1v2h-3c-4.744,0-8.634-3.693-8.967-8.354 C10.321,49.309,9.64,48.92,9,48.473V49c0,6.065,4.935,11,11,11h3v2c0,0.552,0.448,1,1,1h16c0.552,0,1-0.448,1-1v-6 C41,55.448,40.552,55,40,55z" />
        <path
          d="M57,30.381V20C57,9.523,48.477,1,38,1H26C15.523,1,7,9.523,7,20v10.381C5.739,32.28,5,34.555,5,37 c0,6.617,5.383,12,12,12c1.088,0,2.189-0.156,3.272-0.463C20.703,48.416,21,48.022,21,47.575v-21.15c0-0.447-0.297-0.84-0.728-0.962 C19.189,25.156,18.088,25,17,25c-3.075,0-5.874,1.173-8,3.081V20c0-9.374,7.626-17,17-17h12c9.374,0,17,7.626,17,17v8.081 C52.874,26.173,50.075,25,47,25c-1.088,0-2.189,0.156-3.272,0.463C43.297,25.584,43,25.978,43,26.425v21.15 c0,0.447,0.297,0.84,0.728,0.962C44.812,48.844,45.913,49,47,49c6.617,0,12-5.383,12-12C59,34.555,58.261,32.28,57,30.381z"
          fill={props.color || theme.colors.gray800}
        />
      </g>
    </svg>
  )
}

export default Phone
