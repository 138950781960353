import { useState } from 'react'
import { HexColorPicker } from 'react-colorful'
import 'react-colorful/dist/index.css'
import Button from './Elements/Button'
import { Flex } from 'theme-ui'

export const presetColors = [
  '#28a745',
  '#ffc107',
  '#e91d1d',
  '#c0392b',
  '#e74c3c',
  '#9b59b6',
  '#8e44ad',
  '#3498db',
  '#2980b9',
  '#1abc9c',
  '#16a085',
  '#27ae60',
  '#2ecc71',
  '#f1c40f',
  '#f39c12',
  '#e67e22',
  '#d35400',
  '#34495e',
  '#2c3e50',
  '#95a5a6',
  '#7f8c8d',
  '#ecf0f1'
]

const HexColorPickerCustom = props => {
  const [showCustom, setShowCustom] = useState(false)
  return (
    <Flex style={{ marginBottom: '20px' }}>
      <div
        style={{
          flex: 1,
          marginTop: '10px',
          flexWrap: 'wrap',

          maxWidth: '300px'
        }}
      >
        {presetColors.map(presetColor => (
          <Button
            sx={{
              width: '24px',
              height: '24px',
              margin: '4px',
              display: 'inline-block',
              border: '2px solid',
              borderColor:
                presetColor === props.color ? 'text' : 'transparent',
              padding: '0px',
              borderRadius: '4px',
              cursor: 'pointer',
              outline: 'none',
              background: presetColor,
              ':hover': {
                background: presetColor,
                opacity: 0.8
              }
            }}
            type="button"
            key={presetColor}
            onClick={() => props.onChange(presetColor)}
          />
        ))}
        <Flex>
          <Button
            variant="primary"
            size="sm"
            sx={{ height: '24px', m: '4px' }}
            type="button"
            onClick={() => {
              setShowCustom(!showCustom)
            }}
          >
            Custom
          </Button>
          <Button
            variant="secondary"
            size="sm"
            sx={{ height: '24px', m: '4px' }}
            type="button"
            onClick={() => {
              props.onChange(null)
            }}
          >
            Clear
          </Button>
        </Flex>
      </div>
      {showCustom && <HexColorPicker {...props} />}
    </Flex>
  )
}

export default HexColorPickerCustom
