import { css } from '@emotion/react'
import { useState, useEffect } from 'react'

export * from './theme'

export const useWindowSize = () => {
  const isClient = typeof window === 'object'

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    }
  }

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    if (!isClient) {
      return false
    }

    function handleResize() {
      setWindowSize(getSize())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }) // Empty array ensures that effect is only run on mount and unmount

  return {
    mobile: windowSize.width < 768,
    tablet: windowSize.width > 768 && windowSize.width < 992,
    desktop: windowSize.width > 992,
    smallScreen: windowSize.width < 992,
    windowSize
  }
}

export const mobile = body => css`
  @media (max-width: 768px) {
    ${body};
  }
`

export const tablet = body => css`
  @media (max-width: 992px) {
    ${body};
  }
`
