import { useState, useEffect } from 'react'
import { unstable_usePrompt } from 'react-router-dom'

export const usePromptOnNavigate = ({ message, path, skip }) => {
  const [prompted, setPrompted] = useState(false)

  // Block in-app navigation
  unstable_usePrompt({
    message,
    when: ({ nextLocation }) => {
      if (!skip && path !== nextLocation.pathname) {
        setPrompted(true)
        return true // Block navigation
      }
      return false // Don't block navigation
    }
  })

  // Block browser refresh and tab close
  useEffect(() => {
    const handler = e => {
      if (!skip) {
        setPrompted(true)
        e.preventDefault()
        e.returnValue = message // Chrome requires returnValue to be set for the prompt to work
      }
    }

    window.addEventListener('beforeunload', handler)

    return () => {
      window.removeEventListener('beforeunload', handler)
    }
  }, [prompted, skip, message])

  return { prompted }
}
