import { theme } from 'src/styles'
import Icon from './Icon'

export const ThumbsUpDownIcon = ({ up }) => {
  return up ? (
    <Icon
      icon="like"
      color={theme.colors.success}
      width={15}
      height={15}
      style={{
        marginLeft: '10px'
      }}
    />
  ) : (
    <Icon
      icon="like"
      color={theme.colors.danger}
      width={15}
      height={15}
      style={{
        marginLeft: '10px',
        transform: 'scaleY(-1)'
      }}
    />
  )
}
