import { Flex } from 'theme-ui'

export const renderRowExpanderComponent = ({ row }) => {
  return (
    <Expander
      open={row.isExpanded}
      {...row.getToggleRowExpandedProps()}
    />
  )
}

export const Expander = ({ open, style, ...rest }) => (
  <Flex
    sx={{
      alignItems: 'center',
      fontWeight: 'bold',
      color: 'text',
      fontSize: 30,
      cursor: 'pointer',
      maxWidth: 20,
      margin: 'auto',

      ...style
    }}
    {...rest}
  >
    {open ? '-' : '+'}
  </Flex>
)
