/** @jsxImportSource theme-ui */
import { forwardRef } from 'react'
import { Box } from 'theme-ui'

const NavItem = (
  { children, variant = 'NavItem', sx, ...props },
  ref
) => {
  return (
    <Box as="li" variant={variant} sx={{ ...sx }} {...props}>
      {children}
    </Box>
  )
}

export default forwardRef(NavItem)
