import React from 'react'

function Pin(props) {
  const title = props.title || 'pin'

  return (
    <svg
      height={props.height || '48'}
      width={props.width || '48'}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{title}</title>
      <g fill={props.color || '#f7f7f7'}>
        <path d="M14.172,32.414L3.293,43.293c-0.391,0.391-0.391,1.023,0,1.414C3.488,44.902,3.744,45,4,45 s0.512-0.098,0.707-0.293l10.879-10.879L14.172,32.414z" />
        <path
          d="M44.707,17.293l-14-14c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414l2.752,2.752L8.189,20.775 l-3.482-3.482c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414l26,26C29.488,44.902,29.744,45,30,45s0.512-0.098,0.707-0.293 c0.391-0.391,0.391-1.023,0-1.414l-3.482-3.482l13.316-23.856l2.752,2.752C43.488,18.902,43.744,19,44,19s0.512-0.098,0.707-0.293 C45.098,18.316,45.098,17.684,44.707,17.293z"
          fill={props.color || '#f7f7f7'}
        />
      </g>
    </svg>
  )
}

export default Pin
