/** @jsxImportSource theme-ui */
import { useRef, useCallback, useEffect, useState } from 'react'

export function useScrollToFormSection(defActiveSectionId) {
  const sectionRefs = useRef({})
  const [activeSectionId, setActiveSectionId] = useState(
    sectionRefs.current[defActiveSectionId] || null
  )

  const setRef = useCallback(
    sectionName => element => {
      if (element) {
        sectionRefs.current[sectionName] = element
      } else {
        delete sectionRefs.current[sectionName]
      }
    },
    []
  )

  useEffect(() => {
    const handleUserScroll = () => {
      setActiveSectionId(null)
    }

    // Listen for both wheel (mouse scroll) and touchmove (swipe on touch devices)
    window.addEventListener('wheel', handleUserScroll, {
      passive: true
    })
    window.addEventListener('touchmove', handleUserScroll, {
      passive: true
    })

    // Cleanup function to remove event listeners
    return () => {
      window.removeEventListener('wheel', handleUserScroll)
      window.removeEventListener('touchmove', handleUserScroll)
    }
  }, [])

  const scrollToSection = useCallback(
    sectionName => {
      const section = sectionRefs.current[sectionName]
      if (section) {
        section.scrollIntoView(true)
        setActiveSectionId(sectionName)
      }
    },
    [sectionRefs]
  )

  return { setRef, scrollToSection, activeSectionId }
}
