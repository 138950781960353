import styled from '@emotion/styled'

import { Link } from 'react-router-dom'

import { prettyDate } from 'src/utils'

import { CenteredContent } from '../../components'

import { RolesOnly } from '../../services/auth'
import { Box, Flex } from 'theme-ui'
import ListGroup from '../Shared/Elements/ListGroup'
import ListGroupItem from '../Shared/Elements/ListGroupItem'
import ListGroupItemHeading from '../Shared/Elements/ListGroupItemHeading'

import Spinner from 'src/images/icons/Spinner'
import Icon from '../Shared/Icon'

// import moment from 'moment'

const StyledListGroupItem = styled(ListGroupItem)`
  ${p => `border-left: 3px solid ${p.theme.colors[p.accent]};`}
`

const ImportantSubmissionList = ({ data, loading }) => {
  return (
    <ListGroup>
      {data && data.length > 0 ? (
        data.map((p, k) => {
          return (
            <StyledListGroupItem key={k}>
              <ListGroupItemHeading>
                <Flex sx={{ justifyContent: 'space-between' }}>
                  <Flex
                    sx={{
                      alignItems: 'center',
                      gap: '10px'
                    }}
                  >
                    <Icon
                      icon="star"
                      width={15}
                      height={15}
                      color="primary"
                    />
                    {prettyDate(p.incidentTime, 'HH:mm')} -{' '}
                    {p.type && p.type.label}{' '}
                  </Flex>
                  {p.location &&
                    p.location.position &&
                    ` ${p.location.position.name}`}
                </Flex>
              </ListGroupItemHeading>

              <RolesOnly
                roles={['ADMIN', 'DISPATCHER', 'SUPERVISOR']}
              >
                <Link to={`/agency/stats?incidentId=${p.id}`}>
                  Learn More
                </Link>
              </RolesOnly>
            </StyledListGroupItem>
          )
        })
      ) : (
        <CenteredContent height={'200px'}>
          {loading && <Spinner />}
          No notable incidents
        </CenteredContent>
      )}
    </ListGroup>
  )
}

export default ImportantSubmissionList
