/** @jsxImportSource theme-ui */
import { forwardRef } from 'react'
import { Flex } from 'theme-ui'

const Col = ({ children, variant = 'column', sx, ...props }, ref) => {
  return (
    <Flex variant={variant} sx={{ ...sx }} {...props}>
      {children}
    </Flex>
  )
}

export default forwardRef(Col)
