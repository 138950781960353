function Pie(props) {
  const fill = props.fill || 'currentColor'
  const secondaryfill = props.secondaryfill || fill
  const title = props.title || 'Pie'

  return (
    <svg
      height={props.height || '64'}
      width={props.width || '64'}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{title}</title>
      <g fill={secondaryfill}>
        <path
          d="M44.949,33C44.435,39.701,38.831,45,32,45c-3.395,0-6.479-1.318-8.797-3.457L9.988,53.803 C15.609,59.478,23.4,63,32,63c16.758,0,30.444-13.369,30.975-30H44.949z"
          fill={fill}
        />
        <path d="M33,19.051C39.37,19.539,44.461,24.63,44.949,31h18.025C62.454,14.702,49.299,1.546,33,1.025 V19.051z" />
        <path
          d="M21.837,40.084C20.066,37.862,19,35.055,19,32c0-6.831,5.299-12.435,12-12.949V1.025 C14.368,1.556,1,15.242,1,32c0,7.776,2.885,14.887,7.631,20.335L21.837,40.084z"
          fill={fill}
        />
      </g>
    </svg>
  )
}

export default Pie
