function CloseBurger(props) {
  const title = props.title || "menu close";

  return (
    <svg
      height={props.height}
      width={props.width}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg">
      <title>{title}</title>
      <g fill={props.color || '#111111'}>
        <path d="M3 18h13v-2H3v2zm0-5h10v-2H3v2zm0-7v2h13V6H3zm18 9.59L17.42 12 21 8.41 19.59 7l-5 5 5 5L21 15.59z" fill={props.color || "#ffffff"} />
      </g>
    </svg>
  );
}

export default CloseBurger;