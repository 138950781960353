import ModalButton from '../Shared/ModalButton'
import AddVehicleInputs from './AddVehicleInputs'
import { useCreateVehicleIncident } from 'src/app/hooks/mutations'
import { cleanGraphQLError } from './FormError'
import get from 'lodash/get'
import { RolesOnly } from 'src/app/services/auth'
import { useToast } from 'src/components/toasts'

const AddVehicleModalForm = ({ submissionId }) => {
  const { loading, createVehicleIncident } = useCreateVehicleIncident(
    submissionId
  )

  const { add } = useToast()

  async function onSubmit({
    vehicleValue,
    vehicleType,
    lNumber = '',
    passengers = 1
  }) {
    if (!vehicleType) {
      add({
        content: 'Please add a vehicle type.',
        color: 'danger'
      })
      return
    }
    return await createVehicleIncident({
      variables: {
        data: {
          value: parseInt(get(vehicleValue, 'value') || vehicleValue),
          licenseNumber: lNumber || null,
          type: get(vehicleType, 'value'),
          passengers: parseInt(passengers),
          submissionId
        }
      }
    })
  }
  const label = `Add Vehicle`

  return (
    <RolesOnly roles={['USER', 'ADMIN', 'DISPATCHER', 'SUPERVISOR']}>
      <ModalButton
        buttonLabel="+ Add"
        buttonProps={{ variant: 'link' }}
        modalHeader="Vehicle Incident"
      >
        {({ toggle }) => (
          <>
            <AddVehicleInputs
              buttonOptions={{ label, icon: loading && 'spinner' }}
              onClose={toggle}
              onAdd={async newVal => {
                const res = await onSubmit(newVal)

                if (!res) return
                if (!res.errors) {
                  toggle()
                } else {
                  add({
                    content: cleanGraphQLError(res.errors[0]),
                    color: 'danger'
                  })
                }
              }}
            />
          </>
        )}
      </ModalButton>
    </RolesOnly>
  )
}

export default AddVehicleModalForm
