/** @jsxImportSource theme-ui */

import get from 'lodash/get'
import { Flex } from 'theme-ui'

const LocationRenderer = ({ location, destination, ...rest }) => {
  const positionName = get(location, 'position.name', '')
  const prettyAddress = get(location, 'prettyAddress', '')

  return (
    <Flex
      sx={{
        gap: '10px',
        width: '100%',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignItems: 'flex-start'
      }}
      {...rest}
    >
      {positionName && (
        <div sx={{ minWidth: '60px' }}>
          {location.lat && location.lng && !prettyAddress ? (
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                `${location.lat},${location.lng}`
              )}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textAlign: 'left',
                padding: 0,

                flex: 1
              }}
            >
              {positionName}
            </a>
          ) : (
            positionName || ''
          )}
        </div>
      )}
      {prettyAddress && location.lat && location.lng && (
        <a
          href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
            `${location.lat},${location.lng}`
          )}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textAlign: 'left'
          }}
        >
          {prettyAddress}
        </a>
      )}
    </Flex>
  )
}

export default LocationRenderer
