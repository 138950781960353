import { Fragment } from 'react'
import Modal from 'react-modal'
import { useConfirm } from 'src/app/hooks/useConfirm'
import { useThemeUI } from 'theme-ui'

import ModalHeader from './ModalHeader'

Modal.setAppElement('body')

Modal.defaultStyles.content.overflow = 'unset'

Modal.defaultStyles.overlay.overflow = 'auto'

const CustomModal = ({
  isOpen,
  height,
  width,
  size,
  children,
  toggle = () => {},
  customCloseButton,
  modalHeader,
  checkOnClose,
  contentStyles = {},
  overlayStyles = {},
  ...props
}) => {
  const { theme } = useThemeUI()
  const { isConfirmed } = useConfirm()

  const getModalSize = size => {
    switch (size) {
      case 'sm':
        return '450px'
      case 'md':
        return '750px'
      case 'lg':
        return '1400px'
      default:
        return '750px'
    }
  }
  //Don't append portal unless open
  if (!isOpen) {
    return <Fragment />
  }

  async function onToggle() {
    let validClose = true

    if (checkOnClose) {
      validClose = await isConfirmed(
        'Are you sure you want to leave?  You may lose your progress.'
      )
    }

    if (!validClose) {
      return
    }

    toggle()
  }

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={true}
      shouldReturnFocusAfterClose={false}
      onRequestClose={async e => {
        onToggle(e)
      }}
      {...props}
      style={{
        overlay: {
          zIndex: 1000000,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',

          ...overlayStyles
        },
        content: {
          display: 'flex',
          flexDirection: 'column',
          width: width || getModalSize(size),
          maxWidth: '98vw',
          height: height || 'auto',
          top: '40px',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translateX(-50%)',
          oveflow: 'visible',
          border: 'none',
          background: theme.colors.background,
          ...contentStyles
        }
      }}
    >
      <ModalHeader
        customCloseButton={customCloseButton}
        toggle={onToggle}
      >
        {modalHeader}
      </ModalHeader>
      {children}
    </Modal>
  )
}

export default CustomModal
