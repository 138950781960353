function User(props) {
  const title = props.title || 'user'

  return (
    <svg
      height={props.height || '12'}
      width={props.width || '12'}
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{title}</title>
      <g fill={props.color || '#303035'}>
        <circle cx="6" cy="3" r="3" />
        <path
          d="M6,7C2.625,7,1,9.106,1,10.333V11a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1v-.667C11,9.106,9.375,7,6,7Z"
          fill={props.color || '#303035'}
        />
      </g>
    </svg>
  )
}

export default User
