export default ({ height, width, color, style, ...props }) => {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 24 24"
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      transform="scale(2,2)"
      {...props}
    >
      <title>ic keyboard arrow up 24px</title>
      <g fill={color || '#111111'}>
        <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" />
      </g>
    </svg>
  )
};
