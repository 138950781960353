import { useState } from 'react'
import Spinner from 'src/images/icons/Spinner'

import ModalButton from '../../components/Shared/ModalButton'

import {
  GET_BULLETIN_READ_BY,
  useGetCurrentAgencyContext
} from '../../hooks/queries'

import {
  useCloseChat,
  useOpenChat,
  useMarkBulletinRead
} from '../../hooks/mutations'

import UpdateBulletin from '../../components/Forms/UpdateBulletin'
import Button from '../../components/Shared/Elements/Button'
import ListGroupItem from '../../components/Shared/Elements/ListGroupItem'
import Dropdown from '../../components/Shared/Elements/Dropdown'
import Input from '../../components/Shared/Elements/Input'

import Icon from '../../components/Shared/Icon'

import NotifyForm from '../../components/Shared/Notify/NotifyForm'
import config from 'src/config'

import { Box, Flex } from 'theme-ui'
import CustomTabs from '../Shared/CustomTabs'
import CopyBox from '../Shared/CopyBox'
import { useQuery } from '@apollo/client'
import { ThumbsUpDownIcon } from '../Shared/ThumbsUpDownIcon'

const ReadByModal = ({ bulletin }) => {
  const { users } = useGetCurrentAgencyContext()
  const { loading, data } = useQuery(GET_BULLETIN_READ_BY, {
    variables: { id: bulletin.id }
  })

  if (loading) return <Spinner centered />

  const usersList = data?.bulletin?.permittedUsers || users

  return (
    <>
      {usersList.map(({ id, name }, k) => (
        <ListGroupItem key={k}>
          {name}
          <ThumbsUpDownIcon
            up={data.bulletin.readBy.some(rb => rb.id === id)}
          />
        </ListGroupItem>
      ))}
    </>
  )
}

const BulletinActions = ({
  bulletin,
  buttonStyles = [],
  dropdown,
  ...props
}) => {
  const [days, setDays] = useState()
  const { loading: closeLoading, closeChat } = useCloseChat()
  const { loading: openLoading, openChat } = useOpenChat()

  const loading = closeLoading || openLoading
  const url = `${config.domain}/agency/communications?bulletinId=${bulletin.id}&deep=true`
  return [
    <ModalButton
      allowPropagation
      buttonProps={{
        variant: buttonStyles[0] || 'dropdown',
        style: {
          width: '100%'
        }
      }}
      buttonLabel={`Read by (${bulletin.readByCount})`}
      modalHeader="Read By"
      {...props}
    >
      {() => {
        return <ReadByModal bulletin={bulletin} />
      }}
    </ModalButton>,
    <ModalButton
      allowPropagation
      buttonProps={{
        variant: buttonStyles[1] || 'dropdown',
        icon: 'share',
        sx: {
          width: '100%'
        }
      }}
      buttonLabel={'Share'}
      modalHeader="Share with Participants"
      {...props}
    >
      {({ toggle }) => (
        <CustomTabs
          tabs={[
            {
              linkTitle: 'Text or Email',
              component: (
                <Box sx={{ p: 20 }}>
                  <NotifyForm
                    all={
                      bulletin.whiteList?.length === 0 &&
                      bulletin?.whiteListedGroups?.length === 0
                    }
                    whiteList={bulletin.whiteList}
                    whiteListedGroups={bulletin.whiteListedGroups}
                    defaultMessage={`${bulletin.title}`}
                    onFinish={toggle}
                    url={url}
                  />
                </Box>
              )
            },
            {
              linkTitle: 'Link Sharing',
              component: (
                <Flex sx={{ p: 30 }}>
                  <CopyBox value={url} />
                </Flex>
              )
            }
          ]}
        />
      )}
    </ModalButton>,
    <ModalButton
      allowPropagation
      buttonProps={{
        variant: buttonStyles[2] || 'dropdown',
        sx: {
          width: '100%'
        }
      }}
      modalProps={{ checkOnClose: true }}
      buttonLabel={`Edit`}
      modalHeader="Edit Bulletin"
      {...props}
    >
      {({ toggle }) => (
        <UpdateBulletin onFinish={toggle} bulletin={bulletin} />
      )}
    </ModalButton>,
    <ModalButton
      allowPropagation
      buttonProps={{
        variant: dropdown
          ? 'dropdown'
          : bulletin.isActive
          ? 'linkGray'
          : 'linkSuccess',

        sx: {
          width: '100%'
        },
        icon: 'pin'
      }}
      buttonLabel={bulletin.isActive ? 'Unpin' : 'Pin'}
      modalHeader={
        bulletin.isActive
          ? 'Are you sure you want to unpin this bulletin?  It will still be available on your communications page.'
          : 'Are you sure you want to pin this bulletin? How many more days should we keep it on the Homebase.'
      }
      renderModalActions={toggle => (
        <Button
          variant={bulletin.isActive ? 'danger' : 'success'}
          onClick={async () => {
            if (bulletin.isActive) {
              await closeChat({
                variables: {
                  bulletinId: bulletin.id
                }
              })
            } else {
              await openChat({
                variables: {
                  bulletinId: bulletin.id,
                  days: days || 3
                }
              })
            }
            toggle()
          }}
          disabled={loading || (!bulletin.isActive && !days)}
          icon={loading && 'spinner'}
        >
          {!bulletin.isActive ? 'Yes, Pin' : 'Yes, unpin'}
        </Button>
      )}
      {...props}
    >
      {() => {
        return (
          !bulletin.isActive && (
            <Input
              onChange={e => setDays(parseInt(e.target.value))}
              value={days}
              type="number"
              placeholder="Enter number of days..."
            />
          )
        )
      }}
    </ModalButton>
  ]
}

export const BulletinActionsDropdown = p => {
  return (
    <Dropdown
      dropdownTitle={<Icon color="text" icon="dots" />}
      dropdownButtonStyles={{
        py: '0px',
        width: '100%'
      }}
      dropdownItems={BulletinActions(p)}
    />
  )
}

export const ReadBulletinButton = ({ bulletin, onFinish }) => {
  const {
    markBulletinRead,
    loading: updateLoading
  } = useMarkBulletinRead()

  const isRead = bulletin.hasRead

  return (
    <Button
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        gap: '10px',
        marginLeft: 10,
        marginRight: 10,
        cursor: isRead && 'not-allowed'
      }}
      variant={isRead ? 'success' : 'secondary'}
      onClick={async () => {
        if (!isRead) {
          await markBulletinRead({
            variables: {
              where: {
                id: bulletin.id
              }
            },
            refetchQueries: [
              'GetBulletins',
              'GetBulletinNotificationsCount'
            ]
          })
          onFinish && onFinish()
        }
      }}
    >
      {updateLoading ? (
        <Spinner white />
      ) : (
        [
          <Icon icon="like" height={10} width={10} color="white" />,
          isRead ? ' Read' : ' Mark as read'
        ]
      )}
    </Button>
  )
}

export default BulletinActions
