import HeadwayWidget from '@headwayapp/react-widget'
import Bell from 'src/images/icons/Bell'
import { css, Global } from '@emotion/react'

export const GlobalStyle = () => (
  <Global
    styles={css`
      .HW_frame_cont.HW_visible.HW_bottom {
        top: 60px !important;
      }
    `}
  />
)

const NotificationDropdown = () => {
  return (
    <HeadwayWidget
      widgetPosition="bottom-left"
      badgePosition="top-right"
      account="yBNOMy"
      translations={{
        title: 'Recent Announcements'
      }}
    >
      <GlobalStyle />
      <Bell style={{ marginRight: '5px' }} height="15" width="15" />
    </HeadwayWidget>
  )
}

export default NotificationDropdown
