import QueryString from 'qs'
import { createContext } from 'react'

export const STATUS_NODE_TYPES = [
  'USER',
  'RESOURCE',
  'LOCATION',
  'ASSET'
]

export const StatusValueModalContext = createContext()

export function getGroupStatus(
  groupIsCritical,
  groupNeedsAttention,
  groupHasOneStatus
) {
  return groupIsCritical
    ? 'Critical'
    : groupNeedsAttention
    ? 'Attention'
    : groupHasOneStatus
    ? 'OK'
    : 'N/A'
}

export function getGroupStatusColor(
  groupIsCritical,
  groupNeedsAttention,
  groupHasOneStatus
) {
  return groupIsCritical
    ? groupIsCritical?.statusValue?.color || 'danger'
    : groupNeedsAttention
    ? groupNeedsAttention?.statusValue?.color || 'yellow'
    : groupHasOneStatus
    ? 'success'
    : 'secondary'
}

export function getExpGroupStatusColor(
  groupIsCritical,
  groupNeedsAttention,
  groupHasOneStatus
) {
  return groupIsCritical
    ? groupIsCritical?.statusValue?.expirationColor || 'danger'
    : groupNeedsAttention
    ? groupNeedsAttention?.statusValue?.expirationColor || 'yellow'
    : groupHasOneStatus
    ? 'success'
    : 'secondary'
}

export const statusFilterJSONToURL = json => {
  const params = QueryString.stringify(json)

  const type = json.nodeType?.toLowerCase()

  return `/agency/pulse/${type}?${params}`
}

export const statusFilterURLToJSON = url => {
  const params = new URLSearchParams(url)
  return Object.fromEntries(params.entries())
}

export function formatDashboardFilters(filters) {
  return {
    nodeType: filters.nodeType,
    statusTemplates: pluckIdAndName(filters.statusTemplates),
    statusTemplateCategories: pluckIdAndName(
      filters.statusTemplateCategories
    ),
    tableView: filters.tableView,
    focusMode: filters.focusMode,
    date: filters.date,
    groups: pluckIdAndName(filters.groups),
    search: filters.search,
    expandAll: filters.expandAll,
    allActive: filters.allActive
  }
}

function pluckIdAndName(arr) {
  if (!arr) return null

  return arr.map(a => ({
    id: a.id,
    name: a.name,
    title: a.title
  }))
}
