import { useToast } from 'src/components/toasts'
import useForm from 'src/app/hooks/useForms'

import Button from '../Shared/Elements/Button'
import Input from '../Shared/Elements/Input'
import Label from '../Shared/Elements/Label'
import ModalFooter from '../Shared/Elements/ModalFooter'
import FormGroup from '../Shared/Elements/FormGroup'
import Form from '../Shared/Elements/Form'
import Switch from '../Shared/Elements/Switch'

import { useUpdateBulletin } from 'src/app/hooks/mutations'

import RichTextInput, {
  CustomRichText
} from '../Shared/RichTextInput'
import { cleanGraphQLError } from './FormError'
import { useGetCurrentAgencyContext } from 'src/app/hooks/queries'

import CustomSelectInput from './CustomSelectInput'

import InlineTooltip from '../Shared/InlineTooltip'
import DatePickerInput from './DatePickerInput'
import { BulletinUrgency } from '../Bulletins/BulletinIcons'
import ModalButton from '../Shared/ModalButton'
import DocumentList from '../Operations/Documents/DocumentList'
import DOMPurify from 'dompurify'

const UpdateBulletin = ({ bulletin, onFinish }) => {
  const { updateBulletin, loading } = useUpdateBulletin()
  const { add } = useToast()

  const {
    values,
    handleChange,
    handleSelect,
    setValues,
    mergeValues,
    handleSubmit
  } = useForm(UpdateBulletin, {
    isPrivate: bulletin.isPrivate,
    title: bulletin.title || '',
    body: bulletin.body || '',
    users: bulletin.whiteList || [],
    tags: bulletin.whiteListedGroups || [],
    liveUntil: bulletin.liveUntil,
    attachments: bulletin.attachments || [],
    urgency: BulletinUrgency.find(bu => bu.value === bulletin.urgency)
  })

  async function UpdateBulletin() {
    try {
      const {
        users = [],
        tags = [],
        urgency,
        attachments = [],
        ...vals
      } = values

      const res = await updateBulletin({
        variables: {
          where: {
            id: bulletin.id
          },
          data: {
            whiteList: {
              set: users.map(w => ({ id: w.id }))
            },
            whiteListedGroups: {
              set: tags.map(w => ({ id: w.id }))
            },
            attachments: {
              set: attachments.map(w => ({ id: w.id }))
            },
            urgency: urgency && urgency.value,
            ...vals,
            body: DOMPurify.sanitize(vals.body || ''),
            isPrivate:
              values.isPrivate && (tags?.length || users?.length)
                ? true
                : false
          }
        }
      })

      if (res && !res.errors) {
        add({
          color: 'success',
          content: 'Bulletin updated'
        })
        setValues({})
      } else {
        add({
          color: 'danger',
          content: cleanGraphQLError(res.errors[0])
        })
        return
      }

      onFinish && onFinish()
    } catch (e) {
      console.log(e)
      add({
        color: 'danger',
        content: e
      })
    }
  }

  const hasAttachments =
    values.attachments && values.attachments.length

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <Label>Subject</Label>
        <Input
          required
          id="title"
          name="title"
          value={values.title || ''}
          onChange={handleChange}
          placeholder="No name"
        />
      </FormGroup>

      <FormGroup>
        <Label>Details</Label>
        <CustomRichText>
          <RichTextInput
            required
            id="message"
            name="message"
            minHeight="300px"
            value={values.body}
            placeholder="Bulletin details"
            type="textarea"
            onChange={content => {
              mergeValues({
                body: content
              })
            }}
          />
        </CustomRichText>
      </FormGroup>
      <FormGroup>
        <Label>Documents</Label>
        <ModalButton
          contentSize={'lg'}
          modalHeader="Upload or Attach Files"
          buttonLabel={
            hasAttachments
              ? `${values.attachments.length} attached`
              : 'Click here to attach'
          }
          buttonProps={{
            variant: hasAttachments ? 'warning' : 'secondary',
            sx: {
              marginLeft: '5px',
              boxShadow: 'none'
            }
          }}
          renderModalActions={toggle => (
            <Button
              variant="primary"
              onClick={() => {
                toggle()
              }}
            >
              Done Attaching
            </Button>
          )}
        >
          {() => {
            return (
              <>
                <DocumentList
                  take={10}
                  selectedFiles={values.attachments}
                  hideEdit={true}
                  onSelect={(e, file) => {
                    mergeValues({
                      attachments: [
                        ...(values?.attachments || []),
                        file
                      ]
                    })
                  }}
                  onDeselect={(e, file) => {
                    mergeValues({
                      attachments:
                        values?.attachments?.filter(
                          i => i.id !== file.id
                        ) || []
                    })
                  }}
                />
              </>
            )
          }}
        </ModalButton>
      </FormGroup>
      <FormGroup>
        <Label>Pin until</Label>
        <DatePickerInput
          id="liveUntil"
          name="liveUntil"
          value={values.liveUntil}
          timeIntervals={5}
          minDate={new Date()}
          onChange={date => {
            handleSelect({
              name: 'liveUntil',
              value: date
            })
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label>Urgency</Label>
        <CustomSelectInput
          id="urgency"
          name="urgency"
          loading={loading}
          style={{ width: '100%' }}
          value={values.urgency}
          withColor
          onChange={selected => {
            mergeValues({
              urgency: selected
            })
          }}
          options={BulletinUrgency}
        />
      </FormGroup>
      <FormGroup>
        <Switch
          id={`isPrivate`}
          name="isPrivate"
          label={'Share with specific team members only'}
          checked={values.isPrivate}
          readOnly
          onClick={handleChange}
        />{' '}
      </FormGroup>

      {values.isPrivate && (
        <PrivateBulletinFields
          mergeValues={mergeValues}
          values={values}
        />
      )}

      <ModalFooter onClose={onFinish}>
        <Button
          type="submit"
          disabled={loading}
          icon={loading && 'spinner'}
        >
          Update
        </Button>
      </ModalFooter>
    </Form>
  )
}

export const PrivateBulletinFields = ({ values, mergeValues }) => {
  const { currentAgency, users, user } = useGetCurrentAgencyContext()
  return (
    <>
      <InlineTooltip
        variant="warning"
        tip={
          'Bulletin will ONLY show to users and groups selected below.'
        }
      />
      <FormGroup
        sx={{ mt: '20px' }}
        onClick={e => e.stopPropagation()}
      >
        <Label>Allowed Users</Label>
        <CustomSelectInput
          id="users"
          name="users"
          isMulti={true}
          noOptionsMessage={() =>
            'No options. Make sure users have phone numbers.'
          }
          getOptionLabel={v => v.name}
          getOptionValue={v => {
            return v.id
          }}
          isClearable={false}
          value={values.users || []}
          onChange={(selected, action) => {
            mergeValues({
              users: selected || []
            })
          }}
          options={users.filter(i => i.id !== user.id)}
        />
      </FormGroup>
      <FormGroup>
        <Label>Allowed Groups</Label>
        <CustomSelectInput
          id="tags"
          name="tags"
          isMulti={true}
          getOptionValue={v => v.id}
          getOptionLabel={v => v.name}
          isClearable={false}
          withColor
          styles={{
            container: base => ({
              ...base
            })
          }}
          value={values.tags}
          onChange={(selected = []) => {
            mergeValues({
              tags: selected || []
            })
          }}
          options={currentAgency.positionCategories}
        />
      </FormGroup>
    </>
  )
}

export default UpdateBulletin
