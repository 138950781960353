import { useMutation } from '@apollo/client'
import { optionalChaining } from 'src/utils'
import { SUBMIT_FORM } from './mutations'
import { useNetworkStatus } from './useNetworkStatus'
import { useOfflineMutation } from './useOffline'

export const useSubmitForm = (options, offlineOptions) => {
  const isOnline = useNetworkStatus()

  const defaultOptions = {
    update(cache, { data: { submitForm } }) {
      cache.modify({
        id: cache.identify({
          __typename: 'Form',
          id: submitForm?.formResponse?.form?.id
        }),
        fields: {
          responseCount(existingResponseCount = 0) {
            return existingResponseCount + 1
          }
        }
      })
    },
    ...options
  }
  const {
    mutate: submitOffline,
    loading: offlineLoading,
    error
  } = useOfflineMutation('form')

  const [
    submitFormOnline,
    { data: onlineData, error: onlineError, loading: onlineLoading }
  ] = useMutation(SUBMIT_FORM, defaultOptions)

  const data = optionalChaining(() => onlineData.submitFormOnline)

  async function submitForm(opts) {
    try {
      return isOnline
        ? await submitFormOnline(opts)
        : await submitOffline(opts.variables, offlineOptions)
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  return {
    submitForm,
    error: error || onlineError,
    loading: offlineLoading || onlineLoading,
    data
  }
}
