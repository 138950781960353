import ModalButton from '../Shared/ModalButton'

import { useAddDispatches } from 'src/app/hooks/mutations'

import { AddResourceInputs } from './AddResourceInputs'
import useForm from 'src/app/hooks/useForms'
import { mapCreateDispatchesToSubmission } from 'src/utils/dataMapping'
import {
  GET_SUBMISSION,
  useGetCurrentAgencyContext
} from 'src/app/hooks/queries'
import { useToast } from 'src/components/toasts'
import { cleanGraphQLError } from './FormError'
import { cloneDeep } from '@apollo/client/utilities'
import Button from 'src/app/components/Shared/Elements/Button'
import { RolesOnly } from 'src/app/services/auth'

const AddResourceModalForm = ({
  submissionId,
  locationId,
  onFinish
}) => {
  const { add } = useToast()
  const { currentAgency } = useGetCurrentAgencyContext()
  const { addDispatches, loading } = useAddDispatches({
    update: (store, { data: { updateOneSubmission } }) => {
      // Read the data from our cache for this query.
      const data = cloneDeep(
        store.readQuery({
          query: GET_SUBMISSION,
          variables: {
            where: {
              id: submissionId
            }
          }
        })
      )

      data.submission = { ...data.submission, ...updateOneSubmission }

      // Write our data back to the cache.
      store.writeQuery({
        query: GET_SUBMISSION,
        variables: {
          where: {
            id: submissionId
          }
        },
        data: { ...data }
      })
    }
  })
  const { values, mergeValues } = useForm()

  async function onSubmit(closeModal) {
    try {
      const res = await addDispatches({
        variables: {
          where: { id: submissionId },
          data: {
            dispatches: mapCreateDispatchesToSubmission({
              currentAgencyId: currentAgency.id,
              units: values.dispatches,
              wasDispatched: false,
              destinationId: locationId
            })
          }
        }
      })

      if (!res.errors) {
        add({ content: 'Resources added to call.', color: 'success' })
        onFinish && onFinish()
        closeModal()
      } else {
        add({
          content: cleanGraphQLError(res.errors[0]),
          color: 'danger'
        })
      }
    } catch (e) {
      add({ content: cleanGraphQLError(e), color: 'danger' })
    }
  }
  const label = loading ? 'Saving...' : `Save Changes`

  return (
    <RolesOnly roles={['USER', 'ADMIN', 'DISPATCHER', 'SUPERVISOR']}>
      <ModalButton
        buttonLabel="+ Add"
        buttonProps={{ variant: 'link' }}
        modalHeader="Resources"
        renderModalActions={toggle => (
          <Button variant="primary" onClick={() => onSubmit(toggle)}>
            {label}
          </Button>
        )}
      >
        {({ toggle }) => (
          <>
            <AddResourceInputs
              dispatches={values.dispatches}
              mergeValues={mergeValues}
              values={values}
              useTodaysPosition={false}
            />
          </>
        )}
      </ModalButton>
    </RolesOnly>
  )
}

export default AddResourceModalForm
