function DeleteX(props) {
  const title = props.title || 'delete x'

  return (
    <svg
      height={props.height}
      width={props.width}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill={props.color || '#111111'}>
        <path
          d="M60,13H18c-0.285,0-0.558,0.122-0.747,0.335l-16,18c-0.337,0.379-0.337,0.95,0,1.329l16,18 C17.442,50.878,17.715,51,18,51h42c0.553,0,1-0.448,1-1V14C61,13.448,60.553,13,60,13z M42.707,37.293 c0.391,0.391,0.391,1.023,0,1.414C42.512,38.902,42.256,39,42,39s-0.512-0.098-0.707-0.293L36,33.414l-5.293,5.293 C30.512,38.902,30.256,39,30,39s-0.512-0.098-0.707-0.293c-0.391-0.391-0.391-1.023,0-1.414L34.586,32l-5.293-5.293 c-0.391-0.391-0.391-1.023,0-1.414s1.023-0.391,1.414,0L36,30.586l5.293-5.293c0.391-0.391,1.023-0.391,1.414,0s0.391,1.023,0,1.414 L37.414,32L42.707,37.293z"
          fill={props.color || '#111111'}
        />
      </g>
    </svg>
  )
}

export default DeleteX
