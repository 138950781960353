import React from 'react'
import { Box, Flex } from 'theme-ui'
import FormGroup from '../Shared/Elements/FormGroup'
import Label from '../Shared/Elements/Label'
import CustomSelectInput from './CustomSelectInput'
import { recurringFrequencyOptions } from './RecurringFrequencySelectInputs/utils'

export const RecurringFrequencySelectInputs = ({
  setValues,
  values
}) => {
  const handleFrequencyReset = () =>
    setValues({
      ...values,
      dayOfWeek: null,
      dayOfMonth: null,
      monthOfYear: null
    })

  function frequencyRendererSwitch(frequencySelection) {
    switch (frequencySelection) {
      case 'WEEKLY':
        return (
          <FormGroup>
            <Label>Repeat On Day of Week:</Label>
            <CustomSelectInput
              name={'dayOfWeek'}
              id={'dayOfWeek'}
              value={values?.dayOfWeek}
              options={recurringFrequencyOptions.dayOfWeekOptions}
              onChange={selected => {
                setValues(val => ({
                  ...val,
                  dayOfWeek: selected
                }))
              }}
            />
          </FormGroup>
        )
      case 'MONTHLY':
        return (
          <FormGroup>
            <Label>Repeat On Day of Month:</Label>
            <CustomSelectInput
              name={'dayOfMonth'}
              id={'dayOfMonth'}
              value={values?.dayOfMonth}
              options={recurringFrequencyOptions.dayOfMonthOptions.filter(
                d => !d.label.includes('Last')
              )}
              onChange={selected => {
                setValues(val => ({
                  ...val,
                  dayOfMonth: selected
                }))
              }}
            />
          </FormGroup>
        )
      case 'ANNUALLY':
        return (
          <Flex sx={{ flexDirection: 'row', gap: 2 }}>
            <FormGroup sx={{ flex: '0 1 50%' }}>
              <Label>Select Month:</Label>
              <CustomSelectInput
                name={'selectMonth'}
                id={'selectMonth'}
                options={recurringFrequencyOptions.monthOptions}
                value={values?.monthOfYear}
                onChange={selected => {
                  setValues(val => ({
                    ...val,
                    dayOfMonth: null,
                    monthOfYear: selected
                  }))
                }}
              />
            </FormGroup>
            <FormGroup sx={{ flex: '0 1 50%' }}>
              <Label>Select Day Of Month:</Label>
              <CustomSelectInput
                name={'selectDayOfMonth'}
                id={'selectDayOfMonth'}
                isDisabled={!values?.monthOfYear}
                options={recurringFrequencyOptions.dayOfMonthOptions}
                value={values?.dayOfMonth}
                onChange={selected => {
                  setValues(val => ({
                    ...val,
                    dayOfMonth: selected
                  }))
                }}
              />
            </FormGroup>
          </Flex>
        )

      default:
        return null
    }
  }

  return (
    <>
      <FormGroup>
        <Label>Time of Day</Label>
        <Flex sx={{ gap: 2 }}>
          <CustomSelectInput
            sx={{ flex: '0 1 20%', minWidth: '120px' }}
            placeholder={'Hour'}
            options={recurringFrequencyOptions.hourOptions}
            value={values?.hour}
            onChange={selected => {
              setValues(val => ({
                ...val,
                hour: selected
              }))
            }}
          />
          <CustomSelectInput
            sx={{ flex: '0 1 20%', minWidth: '120px' }}
            placeholder={'Minutes'}
            options={recurringFrequencyOptions.minuteOptions}
            value={values?.minute}
            onChange={selected => {
              setValues(val => ({
                ...val,
                minute: selected
              }))
            }}
          />
        </Flex>
      </FormGroup>
      <Box
        sx={{
          backgroundColor: 'gray200',
          padding: 20
        }}
      >
        <Flex
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 2
          }}
        >
          <Label sx={{ fontWeight: 'bold', marginBottom: '0px' }}>
            Schedule/Frequency
          </Label>
        </Flex>
        <FormGroup>
          <CustomSelectInput
            name={'frequency'}
            id={'frequency'}
            value={values?.frequency}
            options={recurringFrequencyOptions.frequencyOptions}
            onChange={selected => {
              handleFrequencyReset()
              setValues(val => ({
                ...val,
                frequency: selected
              }))
            }}
          />
        </FormGroup>
        {frequencyRendererSwitch(values?.frequency?.value)}
      </Box>
    </>
  )
}
