/** @jsxImportSource theme-ui */
import { useRef, forwardRef } from 'react'
import { Flex, Box } from 'theme-ui'
import { useDetectOutsideClick } from 'src/app/hooks/useDetectOutsideClick'
import Button from './Button'
import Icon from '../Icon'

const Dropdown = (
  {
    children,
    variant = null,
    dropdownTitle,
    dropdownItems = [],
    dropdownMenuHeading,
    dropdownImage,
    dropdownIcon,
    dropdownButtonStyles,
    caret = null,
    caretColor,
    sx,
    buttonVariant,
    disabled,
    ...props
  },
  ref
) => {
  const dropdownRef = useRef(null)
  const [isActive, setIsActive] = useDetectOutsideClick(
    dropdownRef,
    false
  )
  const toggleDropdown = () => setIsActive(!isActive)
  const closeDropdown = () => setIsActive(false)

  return (
    <Flex
      variant={variant}
      sx={{
        position: 'relative',
        alignItems: 'center',
        display: 'inline-block',
        '&:last-of-type': {
          marginRight: '0px'
        },
        ...sx
      }}
      {...props}
    >
      <Button
        variant={buttonVariant || 'link'}
        onClick={toggleDropdown}
        sx={{
          px: '5px',
          display: 'flex',
          flexWrap: 'nowrap',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
          ...dropdownButtonStyles
        }}
        disabled={disabled}
      >
        {dropdownImage && (
          <Box sx={{ marginRight: ['0px', null, null, '5px'] }}>
            {dropdownImage}
          </Box>
        )}
        {dropdownIcon}
        <span
          sx={{
            // fontWeight: '900',

            '&:hover': {
              textDecoration: 'none'
            }
          }}
        >
          {dropdownTitle}
          {caret && (
            <Icon
              icon="down arrow"
              height={10}
              width={10}
              color={caretColor || 'gray600'}
              style={{ marginLeft: '5px' }}
            />
          )}
        </span>
      </Button>
      <nav
        ref={dropdownRef}
        sx={{
          backgroundColor: 'background',
          borderRadius: '0.2rem',
          position: 'absolute',
          top: '110%',
          right: '0px',
          color: 'text',
          minWidth: '150px',
          boxShadow: '0 1px 8px rgba(0, 0, 0, 0.3)',
          opacity: isActive ? 1 : 0,
          visibility: isActive ? 'visible' : 'hidden',
          transform: isActive ? 'translateY(0)' : 'translateY(-20px)',
          transition:
            'opacity 0.4s ease, transform 0.4s ease, visibility 0.4s',
          zIndex: 1
        }}
      >
        {dropdownMenuHeading && (
          <Box
            sx={{
              padding: 3,
              borderBottomWidth: '1px',
              borderBottomStyle: 'solid',
              borderBottomColor: 'gray400'
            }}
          >
            {Array.isArray(dropdownMenuHeading)
              ? dropdownMenuHeading.map((menuHeadingItem, index) => {
                  return (
                    <Box
                      sx={{
                        pb: '5px'
                      }}
                      key={index}
                    >
                      {menuHeadingItem}
                    </Box>
                  )
                })
              : dropdownMenuHeading}
          </Box>
        )}
        <Box sx={{ padding: 2 }}>
          <ul sx={{ padding: '0px' }}>
            {dropdownItems.map((dropdownItem, index) => {
              return (
                <li
                  key={index}
                  onClick={closeDropdown}
                  sx={{
                    display: 'flex',
                    justifyContent: 'stretch',
                    listStyle: 'none',
                    margin: '0px',
                    borderRadius: '0.2rem',
                    flexWrap: 'nowrap',
                    width: '100%',
                    my: '2px',
                    '& a': {
                      textDecoration: 'none'
                    },
                    '&:hover': {
                      backgroundColor: 'gray200'
                    }
                  }}
                >
                  {dropdownItem}
                </li>
              )
            })}
          </ul>
        </Box>
      </nav>
    </Flex>
  )
}

export default forwardRef(Dropdown)
