import { theme } from 'src/styles'
// eslint-disable-next-line
export default ({ width, height, color, title, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width={width}
      height={height}
      {...props}
    >
      <title>{title || 'edit curves'}</title>
      <g
        className="nc-icon-wrapper"
        fill={color || theme.colors.gray800}
      >
        <path
          d="M61,53H7a1,1,0,0,1-.117-1.993C23.347,49.07,28.087,39.59,33.1,29.553,38.141,19.482,43.347,9.07,60.883,7.007a.983.983,0,0,1,.781.246A1,1,0,0,1,62,8V52A1,1,0,0,1,61,53Z"
          data-color="color-2"
        />
        <path
          d="M61,57H3a1,1,0,0,1-1-1V8A1,1,0,0,1,4,8V55H61a1,1,0,0,1,0,2Z"
          fill={color || '#000000'}
        />
      </g>
    </svg>
  )
}
