export function validateEmail(email = '') {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}
export function validatePhone(phone = '') {
  // Remove any extraneous spaces or dashes for easier validation
  phone = phone?.replace(/\D/g, '')

  // US pattern: Optional + optionally followed by 1, then XXXXXXXXXX
  const usPattern = /^\+?1?[0-9]{10}$/

  // AUS pattern (landline with international code): Optional + followed by 61 X XXXXXXXX
  const ausLandlineIntlPattern = /^\+?61?[-.\s]?(4\d{2}[-.\s]?\d{3}[-.\s]?\d{3}|\d{1}[-.\s]?\d{4}[-.\s]?\d{4})$/

  // UK pattern: Optional + followed by 44 X XXXXXXXXXX
  const ukPattern = /^\+?44?[-.\s]?(7\d{3}[-.\s]?\d{3}[-.\s]?\d{3}|\d{1}[-.\s]?\d{4}[-.\s]?\d{4})$/

  // International: Ensuring a minimum and maximum length
  const intlPattern = /^\+?[0-9]{5,20}$/

  // If the number matches US or AUS patterns, validate it against them
  if (
    usPattern.test(phone) ||
    ausLandlineIntlPattern.test(phone) ||
    ukPattern.test(phone)
  ) {
    return true
  }

  // If the number doesn't validate the above, check it against the international pattern
  if (intlPattern.test(phone)) {
    return true
  }

  return false
}

export function validatePhoneorEmail(val) {
  return validateEmail(val) || validatePhone(val)
}

export function validateUrl(str) {
  const pattern = new RegExp(
    '^(https:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  )

  return !!pattern.test(str)
}
