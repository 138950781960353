function filterNodesByType(nodes, type) {
  if (!type) return nodes

  return nodes?.filter(n => {
    if (type === 'no_landmarks') {
      return !n.locationOnly
    }

    if (type === 'locations') {
      return !n.dispatchable
    }

    if (type === 'resources') {
      return n.dispatchable
    }

    if (type === 'landmarks') {
      return !n.dispatchable && n.locationOnly
    }
    return nodes
  })
}

export default filterNodesByType
