import { gql } from '@apollo/client'

export const typeDefs = gql`
  extend type Query {
    todaysResponders: [TodaysResponder]
    todaysDispatcher: String
    TodaysLocation: TodaysLocation
    hiddenGroups: [String]
  }

  type TodaysResponder {
    positionId: ID
    positionName: String
    responderId: ID
    responderName: String
    primary: Boolean
    isLocation: Boolean
  }

  type TodaysLocation {
    locationId: ID
    locationName: String
  }
`
