/** @jsxImportSource theme-ui */

import { Flex } from 'theme-ui'
import Button from '../Shared/Elements/Button'

import { PulingIndicator } from '../Shared/PulsingIndicator'
import Icon from './Icon'
import usePusher from '../../hooks/usePusher'
import { useNetworkStatus } from 'src/app/hooks/useNetworkStatus'
import { Fragment, useContext, useState } from 'react'
import { __PusherContext } from 'src/app/services/pusher'

const SubscriptionButton = ({
  variables,
  query,
  turnOffPolling,
  minimal,
  externalLoading,
  onSubscriptionData
}) => {
  const channel = query
  const agencyId = variables.agencyId
  const channelName = channel + agencyId
  const eventName = 'update'
  const cb = onSubscriptionData
  const c = usePusher({
    channelName,
    eventName,
    cb
  })

  const isPolling = c?.subscribed && onSubscriptionData
  const loading = externalLoading || c?.loading
  const error = c?.error

  return (
    <SubButton
      subscribe={c?.subscribe}
      isPolling={isPolling}
      loading={loading}
      error={error}
      minimal={minimal}
      turnOffPolling={turnOffPolling}
    />
  )
}

function SubButton({
  loading,
  error,
  turnOffPolling,
  isPolling,
  subscribe,
  minimal
}) {
  const isOnline = useNetworkStatus()
  return (
    <Flex sx={{ alignItems: 'center', gap: '10px' }}>
      {!minimal &&
        (!isOnline
          ? 'Offline'
          : error
          ? 'Error'
          : isPolling
          ? ''
          : '')}
      {loading ? (
        <Icon icon="spinner" color="success" width="10" height="15" />
      ) : (
        <PulingIndicator
          noPulse={!isPolling || error || !isOnline}
          color={
            error || !isOnline
              ? 'danger'
              : isPolling
              ? 'success'
              : 'secondary'
          }
        />
      )}
      {!minimal && (
        <Button
          sx={{
            display: 'flex',
            textDecoration: 'none',
            alignItems: 'center',
            cursor: !loading && 'pointer',
            marginLeft: '10px'
          }}
          size="sm"
          disabled={loading || !isOnline}
          onClick={() => {
            if (loading) return
            if (error || !isPolling) {
              subscribe()
            }
            if (isPolling) {
              turnOffPolling()
            }
          }}
          variant={
            isPolling && isOnline && !error ? 'success' : 'secondary'
          }
        >
          {!isOnline
            ? 'offline'
            : error
            ? 'Resync'
            : loading
            ? 'syncing...'
            : isPolling
            ? 'Live'
            : 'Go Live'}
        </Button>
      )}
    </Flex>
  )
}

export default ({ startOn, onTurnOn, ...props }) => {
  const { client } = useContext(__PusherContext)
  const [isPolling, setIsPolling] = useState(startOn)
  if (!isPolling)
    return (
      <Button
        variant="secondary"
        size="sm"
        onClick={() => {
          setIsPolling(true)
          onTurnOn && onTurnOn()
        }}
      >
        Go Live
      </Button>
    )

  return !client ? (
    <Fragment />
  ) : (
    <SubscriptionButton turnOffPolling={setIsPolling} {...props} />
  )
}
