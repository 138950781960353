import { theme } from 'src/styles'

function Email(props) {
  const title = props.title || 'email'

  return (
    <svg
      height={props.height}
      width={props.width}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill={props.color || theme.colors.gray800}>
        <path
          d="M56,7H8a6.006,6.006,0,0,0-6,6V51a6.006,6.006,0,0,0,6,6H56a6.006,6.006,0,0,0,6-6V13A6.006,6.006,0,0,0,56,7ZM20.793,34.609l-10,13a1,1,0,1,1-1.586-1.218l10-13a1,1,0,0,1,1.586,1.218ZM54.609,47.793a1,1,0,0,1-1.4-.184l-10-13a1,1,0,1,1,1.586-1.218l10,13A1,1,0,0,1,54.609,47.793Zm.081-32.069-22,21a1,1,0,0,1-1.38,0l-22-21a1,1,0,1,1,1.38-1.448L32,34.617,53.31,14.276a1,1,0,0,1,1.38,1.448Z"
          fill={props.color || theme.colors.gray800}
        />
      </g>
      {props.active && (
        <g className="nc-dot_right">
          <circle
            cx="10"
            cy="10"
            fill={theme.colors.danger}
            r="10"
            stroke="none"
            strokeLinecap="butt"
          />
          <circle
            cx="10"
            cy="10"
            fill="none"
            r="1"
            stroke={theme.colors.dangerLight}
          />
        </g>
      )}
    </svg>
  )
}

export default Email
