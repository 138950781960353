import { useEffect, useState } from 'react'
import { Window } from 'src/utils'

export const useLocalStorage = (key, initialValue, watch) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = Window && Window.localStorage.getItem(key)
      // Parse stored json or if none return initialValue
      return item || initialValue
    } catch (error) {
      // If error also return initialValue

      return initialValue
    }
  })

  const clearValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = null

      // Save state
      setStoredValue(valueToStore)
      // Save to local storage
      window.localStorage.removeItem(key)
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error)
    }
  }

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value

      // Save state
      setStoredValue(valueToStore)
      // Save to local storage
      window.localStorage.setItem(key, valueToStore)
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error)
    }
  }

  function checkStoredValue() {
    return Window && Window.localStorage.getItem(key)
  }

  useEffect(() => {
    if (watch) {
      function updateValue() {
        const item = Window && Window.localStorage.getItem(key)

        item ? setValue(item) : clearValue()
      }

      window.addEventListener('storage', updateValue)

      return () => {
        window.removeEventListener('storage', updateValue)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [storedValue, setValue, checkStoredValue]
}
