import React from 'react'

function Lost(props) {
  const title = props.title || 'route alert'

  return (
    <svg
      height={props?.height || '64'}
      width={props?.width || '64'}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill={props.color || '#f7f7f7'}>
        <path
          d="M26,3h-3c-0.552,0-1-0.448-1-1s0.448-1,1-1h3c0.552,0,1,0.448,1,1S26.552,3,26,3z"
          fill={props.color || '#f7f7f7'}
        />
        <path
          d="M41,63h-3c-0.552,0-1-0.448-1-1s0.448-1,1-1h3c0.552,0,1,0.448,1,1S41.552,63,41,63z"
          fill={props.color || '#f7f7f7'}
        />
        <path d="M32,18c-7.732,0-14,6.268-14,14s6.268,14,14,14s14-6.268,14-14S39.732,18,32,18z M31,25 c0-0.552,0.448-1,1-1s1,0.448,1,1v8c0,0.552-0.448,1-1,1s-1-0.448-1-1V25z M32,41c-1.103,0-2-0.897-2-2s0.897-2,2-2s2,0.897,2,2 S33.103,41,32,41z" />
        <path
          d="M47,1H33c-0.552,0-1,0.448-1,1s0.448,1,1,1h14c7.72,0,14,6.28,14,14c0,7.382-5.747,13.433-13,13.949v2 c8.356-0.52,15-7.465,15-15.949C63,8.178,55.822,1,47,1z"
          fill={props.color || '#f7f7f7'}
        />
        <path
          d="M31,61H17C9.28,61,3,54.72,3,47c0-7.382,5.747-13.433,13-13.949v-2C7.644,31.571,1,38.515,1,47 c0,8.822,7.178,16,16,16h14c0.552,0,1-0.448,1-1S31.552,61,31,61z"
          fill={props.color || '#f7f7f7'}
        />
      </g>
    </svg>
  )
}

export default Lost
