/** @jsxImportSource theme-ui */

import { Fragment } from 'react'
import { Box } from 'theme-ui'

import { Space } from '..'

export const cleanGraphQLError = error => {
  if (!error) {
    return 'Oops! Something went wrong. Please refresh or try again later.'
  }
  if (
    typeof error.message === 'string' &&
    error.message.includes(':')
  ) {
    return error.message.split(':')[1]
  }
  if (typeof error === 'string' && error.includes(':')) {
    return error.split(':')[1]
  }
  return typeof error.message === 'string' ? error.message : error
}

const FormError = ({
  error,
  customError,
  warning,
  action,
  actionText
}) => {
  if (!error && !customError) return <Fragment />
  return (
    <Box
      invalid
      role="alert"
      aria-live="assertive"
      sx={{
        display: 'block',
        color: warning ? 'primary' : 'danger',
        fontWeight: 'bold'
      }}
    >
      <Space height={10} />
      {customError
        ? customError
        : error && cleanGraphQLError(error)}{' '}
      {action && (
        <span
          sx={{ color: 'warning', cursor: 'pointer' }}
          onClick={action}
        >
          {actionText}
        </span>
      )}
    </Box>
  )
}

export default FormError
