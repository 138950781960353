function UserFrame31(props) {
  const title = props.title || "agency home";

  return (
    <svg height={props.height || 64} width={props.width || "64"} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <title>{title}</title>
      <g fill={props.color || "#212529"}>
        <path d="M61,1H3C1.895,1,1,1.895,1,3v58c0,1.105,0.895,2,2,2h58c1.105,0,2-0.895,2-2V3C63,1.895,62.105,1,61,1z M20,21c0-6.617,5.383-12,12-12s12,5.383,12,12v3c0,6.617-5.383,12-12,12s-12-5.383-12-12V21z M52,58c0,0.553-0.448,1-1,1H13 c-0.552,0-1-0.447-1-1v-2c0-8.822,7.178-16,16-16h8c8.822,0,16,7.178,16,16V58z" fill={props.color || "#212529"} />
      </g>
    </svg >
  );
}

export default UserFrame31