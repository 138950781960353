function MapLayer(props) {
  const title = props.title || 'MapLayer'

  return (
    <svg
      height={props.height || '64'}
      width={props.width || '64'}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill={props.color || '#F7F7F7'}>
        <path d="M60.5,43.132l-5.957-3.4L33.984,51.473a4,4,0,0,1-3.968,0L9.462,39.727,3.5,43.132a1,1,0,0,0,0,1.736l28,16a1,1,0,0,0,.992,0l28-16a1,1,0,0,0,0-1.736Z" />
        <path d="M60.5,31.132l-5.957-3.4L33.984,39.473a4,4,0,0,1-3.968,0L9.462,27.727,3.5,31.132a1,1,0,0,0,0,1.736l28,16a1,1,0,0,0,.992,0l28-16a1,1,0,0,0,0-1.736Z" />
        <path
          d="M60.5,19.132l-28-16a1,1,0,0,0-.992,0l-28,16a1,1,0,0,0,0,1.736l28,16a1,1,0,0,0,.992,0l28-16a1,1,0,0,0,0-1.736Z"
          fill={props.color || '#F7F7F7'}
        />
      </g>
    </svg>
  )
}

export default MapLayer
