import ClipLoader from 'react-spinners/ClipLoader'

import { CenteredContent } from 'src/app/components'
// eslint-disable-next-line
export default ({
  centered,
  containerHeight,
  white,
  height,
  containerProps,
  ...props
}) => {
  const color = props.color || white ? 'background' : 'primary'

  if (centered) {
    return (
      <CenteredContent height={containerHeight} {...containerProps}>
        <ClipLoader
          sizeUnit={'px'}
          size={height || 20}
          color={color}
          loading={true}
        />
      </CenteredContent>
    )
  }
  return (
    <ClipLoader
      sizeUnit={'px'}
      size={height || 15}
      color={color}
      loading={true}
      {...props}
    />
  )
}
