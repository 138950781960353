/** @jsxImportSource theme-ui */
import { Box } from 'theme-ui'

const Jumbotron = ({
  children,
  variant = 'Jumbotron',
  color,
  outline,
  ...props
}) => {
  return (
    <Box variant={variant} {...props}>
      {children}
    </Box>
  )
}

export default Jumbotron
