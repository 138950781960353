/** @jsxImportSource theme-ui */
import { forwardRef } from 'react'
import { Box } from 'theme-ui'

const FormGroup = (
  { children, sx, check, error, inline, ...props },
  ref
) => {
  return (
    <>
      <Box
        sx={{
          marginBottom: '1.5rem',
          ...sx
        }}
        {...props}
      >
        {children}
        {error && (
          <Box sx={{ mt: '2px', color: 'danger', width: '100%' }}>
            {error}
          </Box>
        )}
      </Box>
    </>
  )
}

export default forwardRef(FormGroup)
