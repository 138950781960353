function Upload(props) {
  const title = props.title || 'Upload'

  return (
    <svg
      {...props}
      height={props.height || '48'}
      width={props.width || '48'}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill={props.color || '#f7f7f7'}>
        <path d="M18 32h12V20h8L24 6 10 20h8zm-8 4h28v4H10z" />
      </g>
    </svg>
  )
}

export default Upload
