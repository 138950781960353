import { Box, Flex } from 'theme-ui'

import ModalButton from './ModalButton'
import Button from './Elements/Button'
import SupportButton from 'src/components/SupportButton'

export const BetaFeatureBadge = ({ featureName, size, ...p }) => {
  return (
    <ModalButton
      modalHeader={`${featureName} Is In Beta`}
      buttonLabel={
        <Flex
          sx={{
            alignItems: 'center',
            backgroundColor: 'successLight',
            fontWeight: 'bold',
            borderRadius: 10,
            padding: '8px',
            py: '4px',
            ...p.style
          }}
        >
          BETA
        </Flex>
      }
      buttonProps={{
        variant: 'naked',
        size: size || 'sm',
        style: {
          margin: '0px',
          padding: '0px',
          borderRadius: 10
        }
      }}
      renderModalActions={() => {
        return [
          <Button variant="primary">
            <SupportButton
              buttonProps={{
                sx: { p: '0px', width: '100%' }
              }}
              metadata={{ betaFeature: featureName }}
            >
              Submit Feedback
            </SupportButton>
          </Button>
        ]
      }}
    >
      {() => (
        <Box>
          This feature is in beta. Beta means the functionality could
          change based on user feedback and you may run into small
          bugs. If you find a bug, please let us know with the button
          below.
        </Box>
      )}
    </ModalButton>
  )
}
