import { useState } from 'react'
import { Box, Flex } from 'theme-ui'
import styled from '@emotion/styled'
import DraggableIcon from 'src/images/icons/Draggable'
import { theme } from 'src/styles'

const initialDnDState = {
  draggedFrom: null,
  draggedTo: null,
  isDragging: false,
  originalOrder: [],
  updatedOrder: []
}

const Container = styled.div``

const DraggableList = ({ items, handleReorder }) => {
  const [dragAndDrop, setDragAndDrop] = useState(initialDnDState)

  // onDragStart fires when an element
  // starts being dragged
  const onDragStart = event => {
    const initialPosition = Number(
      event.currentTarget.dataset.position
    )

    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: initialPosition,
      isDragging: true,
      originalOrder: items
    })

    // Note: this is only for Firefox.
    // Without it, the DnD won't work.
    // But we are not using it.
    event.dataTransfer.setData('text/html', '')
  }

  // onDragOver fires when an element being dragged
  // enters a droppable area.
  // In this case, any of the items on the list
  const onDragOver = event => {
    // in order for the onDrop
    // event to fire, we have
    // to cancel out this one
    event.preventDefault()

    let newList = dragAndDrop.originalOrder

    // index of the item being dragged
    const draggedFrom = dragAndDrop.draggedFrom

    // index of the droppable area being hovered
    const draggedTo = Number(event.currentTarget.dataset.position)

    const itemDragged = newList[draggedFrom]
    const remainingItems = newList.filter(
      (item, index) => index !== draggedFrom
    )

    newList = [
      ...remainingItems.slice(0, draggedTo),
      itemDragged,
      ...remainingItems.slice(draggedTo)
    ]

    if (draggedTo !== dragAndDrop.draggedTo) {
      setDragAndDrop({
        ...dragAndDrop,
        updatedOrder: newList,
        draggedTo: draggedTo
      })
    }
  }

  const onDrop = event => {
    handleReorder(dragAndDrop.updatedOrder)

    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: null,
      draggedTo: null,
      isDragging: false
    })
  }

  const onDragLeave = () => {
    setDragAndDrop({
      ...dragAndDrop,
      draggedTo: null
    })
  }

  return (
    <Container>
      {items.map((item, index) => {
        const isDraggedItem = dragAndDrop.draggedFrom === index
        const isDraggedHovered = dragAndDrop.draggedTo === index
        return (
          <Flex
            sx={{
              borderRadius: '5px',
              bg: isDraggedItem ? 'gray100' : 'background',
              alignItems: 'center',
              border: isDraggedHovered
                ? '1px dashed lightgray'
                : '1px solid #e6e6e6'
            }}
            key={index}
            className={
              dragAndDrop && dragAndDrop.draggedTo === Number(index)
                ? 'dropArea'
                : ''
            }
            onDrop={onDrop}
            data-position={index}
            onDragOver={onDragOver}
          >
            <Box
              key={index}
              onDrop={onDrop}
              data-position={index}
              onDragStart={onDragStart}
              draggable
              onDragOver={onDragOver}
              onDragLeave={onDragLeave}
            >
              <DraggableIcon
                height="24px"
                width="24px"
                color={isDraggedItem && theme.colors.success}
                style={{ margin: '10px' }}
              />
            </Box>
            {item}
          </Flex>
        )
      })}
    </Container>
  )
}

export default DraggableList
