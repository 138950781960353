import ToolTip from './Elements/ToolTip'
import Icon from './Icon'

const QuestionTooltip = ({
  tip,
  customIndex = '0',
  iconStyles,
  alert,
  toolTipProps,
  iconHeight,
  iconWidth,
  ...props
}) => {
  return (
    <div {...props}>
      <ToolTip
        placement="top"
        target={'questionTip' + tip.length + customIndex}
        {...toolTipProps}
      >
        {tip}
      </ToolTip>

      <Icon
        icon={alert ? 'Attention' : 'help'}
        height={iconHeight || 20}
        width={iconWidth || 20}
        title=""
        style={{
          textDecoration: 'underline',
          color: 'blue'
        }}
        {...iconStyles}
        data-tip
        data-for={'questionTip' + tip.length + customIndex}
      />
    </div>
  )
}

export default QuestionTooltip
