import { useState } from 'react'
import { SubmissionLocationEditFields } from './SubmissionLocationEditFields'
import Button from '../Shared/Elements/Button'

import ModalFooter from '../Shared/Elements/ModalFooter'

import { useGetCurrentAgencyContext } from 'src/app/hooks/queries'

const EditLocationInputs = ({ values, mergeValues, toggle }) => {
  const { locations } = useGetCurrentAgencyContext()
  const [edits, setEdits] = useState()
  const onContinue = () => {
    if (!edits || !edits.locationPosition) {
      return
    }

    const { locationPosition, ...rest } = edits

    mergeValues({
      location: {
        ...values.location,
        ...rest,
        position: {
          value: locationPosition.id,
          ...locationPosition
        }
      }
    })
  }
  return (
    <>
      <SubmissionLocationEditFields
        values={{
          locationPosition:
            (edits && edits.locationPosition) ||
            (values.location && values.location.position),
          changeLatLng: edits && edits.lat && edits.lng
        }}
        options={locations}
        onSelect={i => {
          setEdits(v => ({
            ...v,
            locationPosition: i
          }))
        }}
        showCheck={
          edits &&
          edits.locationPosition?.lat &&
          edits.locationPosition?.lng
        }
        onCheck={e => {
          setEdits(v => ({
            ...v,
            ...(e.target && e.target.checked
              ? {
                  prettyAddress: undefined,
                  lat: edits.locationPosition.lat,
                  lng: edits.locationPosition.lng
                }
              : {})
          }))
        }}
      />
      {(values?.location?.position?.value ||
        values?.location?.position?.id) && (
        <Button
          variant="link"
          onClick={() => {
            setEdits(undefined)
            mergeValues({
              location: {
                ...values.location,
                position: {
                  cleared: true
                }
              }
            })
            toggle()
          }}
        >
          Clear
        </Button>
      )}

      <ModalFooter
        onClose={() => {
          setEdits(undefined)
          toggle()
        }}
      >
        <Button
          variant="primary"
          onClick={() => {
            onContinue()
            toggle()
          }}
        >
          Continue
        </Button>
      </ModalFooter>
    </>
  )
}

export default EditLocationInputs
