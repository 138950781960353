import { useState, useEffect } from 'react'
import OtpInput from 'react-otp-input'
import Button from '../../components/Shared/Elements/Button'
import Label from '../../components/Shared/Elements/Label'
const inputStyle = {
  height: '3rem',
  flex: 1,
  maxWidth: '3rem',
  margin: '0 .5rem',
  fontSize: '14px',
  borderRadius: '4px',
  border: '1px solid rgba(0, 0, 0, 0.3)'
}
const containerStyle = {
  margin: '1rem 0px 1rem -.5rem'
}

export const OtpVerificationField = ({
  phone,
  setCode,
  resendCode,
  requestLoading,
  code
}) => {
  const [isResendDisabled, setIsResendDisabled] = useState(false)
  const [timer, setTimer] = useState(60)

  const resend = async () => {
    await resendCode()
    setIsResendDisabled(true)
    startTimer()
  }

  const startTimer = () => {
    let timeLeft = 60
    setTimer(timeLeft)
    const timerId = setInterval(() => {
      timeLeft--
      setTimer(timeLeft)
    }, 1000)

    setTimeout(() => {
      setIsResendDisabled(false)
      setTimer(null)
      clearInterval(timerId)
    }, 60000)
    return timerId
  }

  useEffect(() => {
    if (isResendDisabled) {
      const timerId = startTimer()
      return () => {
        clearInterval(timerId)
      }
    }
  }, [isResendDisabled])

  return (
    <>
      {phone && <Label>We've sent 6-digit code to {phone}</Label>}
      <OtpInput
        value={code}
        shouldAutoFocus
        onChange={setCode}
        numInputs={6}
        inputType="number"
        renderSeparator={<span>-</span>}
        renderInput={props => <input {...props} />}
        inputStyle={inputStyle}
        containerStyle={containerStyle}
      />
      <Label>
        Didn't get a code?
        <Button
          disabled={isResendDisabled}
          variant="link"
          onClick={resend}
          icon={requestLoading && 'spinner'}
        >
          {' '}
          {isResendDisabled
            ? `Resend in (${timer}s)`
            : 'Click to resend'}
        </Button>
      </Label>
    </>
  )
}
