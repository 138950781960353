import { useState, useEffect } from 'react'
import { Window } from 'src/utils'

const defaultSettings = {
  enableHighAccuracy: true,
  timeout: 10000,
  maximumAge: 0
}

const useGeoPosition = (
  watch = false,
  settings = defaultSettings
) => {
  const [watcher, setWatcher] = useState(null)
  const [position, setPosition] = useState({})
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const geo = Window ? navigator.geolocation : {}

  const onChange = ({ coords, timestamp }) => {
    setPosition({
      latitude: coords.latitude,
      longitude: coords.longitude,
      accuracy: coords.accuracy,
      timestamp
    })
  }

  const onError = error => {
    setError(error.message)
  }

  useEffect(() => {
    if (!geo) {
      setError('Geolocation is not supported')
      return
    }

    return () => geo?.clearWatch(watcher)

    // eslint-disable-next-line
  }, [settings])

  async function requestPosition() {
    // additionally supplying options for fine tuning, if you want to

    return new Promise(function(resolve, reject) {
      geo.getCurrentPosition(
        pos => {
          resolve(pos)
        },
        err => {
          reject(err)
        },
        defaultSettings
      )
    })
  }

  const grabLocation = async () => {
    try {
      let res = null
      setLoading(true)
      if (watch) {
        res = geo.watchPosition(onChange, onError, settings)
        setWatcher(res)
      } else {
        res = await requestPosition()
      }

      if (res?.coords) {
        onChange(res)
      }

      setLoading(false)
      return res
    } catch (error) {
      console.log(error)
      setError(error.message)
      setLoading(false)
    }
  }

  return {
    ...position,
    grabLocation,
    error,
    loading
  }
}

export default useGeoPosition
