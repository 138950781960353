import { getFieldConfig } from '../../Operations/Forms/formBuilder/dataMapping'
import { getOperator } from '../../Operations/Forms/formBuilder/FormWhereOperators'

export function generateFormFieldItemAdvancedWhere(w, submittedBy) {
  return {
    formFieldResponse: {
      response: {
        ...(submittedBy?.length > 0 && {
          OR: submittedBy.map(sb => ({
            submittedBy: {
              id: {
                equals: sb.id
              }
            }
          }))
        }),
        ...generateFormResponseAdvancedWhere(w)
      }
    }
  }
}

export function generateFormResponseAdvancedWhere(filters) {
  const removeNoOps = filters.filter(fi => fi.operator)

  return {
    AND: removeNoOps.map(curr => {
      const fieldInformation = getFieldConfig(curr.fieldItem)

      const operatorInfo = {
        ...getOperator(curr.operator?.value),
        ...curr.operator
      }

      if (!curr.key) {
        return null
      }

      return operatorInfo.requiresValue && !curr[curr.key]
        ? {}
        : {
            fields: {
              some: {
                fieldItems: {
                  some: {
                    formFieldItem: {
                      id: {
                        equals: curr.fieldItem.id
                      }
                    },
                    ...(fieldInformation.treatAsRelation ||
                    fieldInformation.isFileType
                      ? operatorInfo.relationMapToWhereArg(
                          curr[curr.key],
                          curr.key
                        )
                      : operatorInfo.mapToWhereArg(
                          curr[curr.key],
                          curr.key
                        ))
                  }
                }
              }
            }
          }
    })
  }
}
