import React, { useEffect, useState } from 'react'
import Spinner from 'src/images/icons/Spinner'

const SpinnerWithTimeoutWarning = ({ warning }) => {
  const [showWarning, setShowWarning] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowWarning(true)
    }, 15000) // After 15 seconds

    return () => clearTimeout(timeout) // Clear timeout if the component is unmounted
  }, [])

  return (
    <>
      {!showWarning ? (
        // Replace with your spinner component
        <Spinner />
      ) : (
        <div>
          {warning ||
            'Sorry, this query is taking longer than expected. Try using filters or dates to narrow down your search.'}
        </div>
      )}
    </>
  )
}

export default SpinnerWithTimeoutWarning
