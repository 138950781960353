/** @jsxImportSource theme-ui */
import { forwardRef } from 'react'
import { Card as ThemeUICard } from 'theme-ui'

const Card = (
  { children, variant, color, outline, ...props },
  ref
) => {
  if (outline) {
    props.sx = {
      ...props.sx,
      borderColor: outline
    }
  }

  // Sets background color if there is a "color" prop passed to Card
  // Please note that this may cause accessibility issues based on font color contrast against bg color
  if (color) {
    props.sx = {
      backgroundColor: color
    }
  }

  if (props.onClick) {
    props.sx = {
      cursor: 'pointer',
      '&:hover': {
        boxShadow: '0 4px 10px -8px rgba(0, 0, 0, 0.2)',
        outline: 'none',
        transition: 'all 0.15s ease-in-out',
        transform: 'translateY(-2px)'
      },
      ...props.sx
    }
  }
  return (
    <ThemeUICard
      variant={variant ? variant : 'default'}
      {...props}
      sx={{ ...props.sx }}
    >
      {children}
    </ThemeUICard>
  )
}

export default forwardRef(Card)
