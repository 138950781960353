import { theme } from 'src/styles'

function Bell(props) {
  const title = props.title || 'Bell'

  return (
    <svg
      height={props.height || '32'}
      width={props.width || '32'}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill={props.color || theme.colors.gray800}>
        <path
          d="M30.8,25.4S27,20.7,27,16V11A11,11,0,0,0,16,0h0A11,11,0,0,0,5,11v5c0,4.7-3.7,9.3-3.8,9.4a1.073,1.073,0,0,0-.1,1.1A1.05,1.05,0,0,0,2,27H30a.961.961,0,0,0,.9-.6A.92.92,0,0,0,30.8,25.4Z"
          fill={props.color || theme.colors.gray800}
        />
        <path d="M12.1,29A3.99,3.99,0,0,0,16,32a4.079,4.079,0,0,0,3.9-3Z" />
      </g>
      {props.active && (
        <g className="nc-dot_right">
          <circle
            cx="6"
            cy="6"
            fill={theme.colors.danger}
            r="6"
            stroke="none"
            strokeLinecap="butt"
          />
          <circle
            cx="6"
            cy="6"
            fill="none"
            r="1"
            stroke={theme.colors.dangerLight}
          />
        </g>
      )}
    </svg>
  )
}

export default Bell
