import { theme } from 'src/styles'

function CheckMark(props) {
  const title = props.title || 'f check'

  return (
    <svg
      height={props.height || '64'}
      width={props.width || '64'}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{title}</title>
      <g fill={props.color || theme.colors.gray800}>
        <path
          d="M24,52c-0.512,0-1.023-0.195-1.414-0.586l-20-20l2.828-2.828L24,47.171l34.586-34.585l2.828,2.828l-36,36 C25.023,51.805,24.512,52,24,52z"
          fill={props.color || theme.colors.gray800}
        />
      </g>
    </svg>
  )
}

export default CheckMark
