import { useMutation } from '@apollo/client'
import { useState } from 'react'
import { MATCH_FIELD_ITEM_TO_STATUS_ALLOWED } from 'src/app/hooks/mutations'
import { useToast } from 'src/components/toasts'
import Button from '../Shared/Elements/Button'

const SyncFormQuestionAndStatus = ({
  fieldItemId,
  statusTemplateId
}) => {
  const { add } = useToast()
  const [synced, setSynced] = useState(false)
  const [update, { loading }] = useMutation(
    MATCH_FIELD_ITEM_TO_STATUS_ALLOWED,
    {
      variables: {
        fieldItemId,
        statusTemplateId
      }
    }
  )

  return (
    <Button
      disabled={loading}
      variant={synced ? 'success' : 'primary'}
      onClick={async () => {
        const res = await update()

        if (!res.errors) {
          setSynced(true)
        } else {
          console.error(res.errors)
          add({
            content: "Couldn't sync",
            variant: 'danger'
          })
        }
      }}
      icon={synced ? 'checkmark' : loading && 'spinner'}
    >
      Sync
    </Button>
  )
}

export default SyncFormQuestionAndStatus
