function List(props) {
  const title = props.title || 'bullet list 69'

  return (
    <svg
      height={props.height || '64'}
      width={props.width || '64'}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g
        fill={props.color || '#F7F7F7'}
        stroke={props.color || '#F7F7F7'}
        strokeLinecap="square"
        strokeLinejoin="miter"
        strokeMiterlimit="10"
        strokeWidth="3"
        transform="translate(0.5 0.5)"
      >
        <line fill="none" x1="26" x2="60" y1="10" y2="10" />
        <line fill="none" x1="26" x2="60" y1="32" y2="32" />
        <line fill="none" x1="26" x2="60" y1="54" y2="54" />
        <rect
          height="12"
          width="12"
          fill="none"
          stroke={props.color || '#F7F7F7'}
          x="4"
          y="4"
        />
        <rect
          height="12"
          width="12"
          fill="none"
          stroke={props.color || '#F7F7F7'}
          x="4"
          y="26"
        />
        <rect
          height="12"
          width="12"
          fill="none"
          stroke={props.color || '#F7F7F7'}
          x="4"
          y="48"
        />
      </g>
    </svg>
  )
}

export default List
