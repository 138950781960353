function Calendar(props) {
  const title = props.title || 'calendar date 2'

  return (
    <svg
      height={props.height || '64'}
      width={props.width || '64'}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{title}</title>
      <g fill={props.color || '#F7F7F7'}>
        <path d="M11,52H21a1,1,0,0,0,1-1V43a1,1,0,0,0-1-1H11a1,1,0,0,0-1,1v8A1,1,0,0,0,11,52Z" />
        <path d="M11,38H21a1,1,0,0,0,1-1V29a1,1,0,0,0-1-1H11a1,1,0,0,0-1,1v8A1,1,0,0,0,11,38Z" />
        <path d="M27,52H37a1,1,0,0,0,1-1V43a1,1,0,0,0-1-1H27a1,1,0,0,0-1,1v8A1,1,0,0,0,27,52Z" />
        <path d="M27,38H37a1,1,0,0,0,1-1V29a1,1,0,0,0-1-1H27a1,1,0,0,0-1,1v8A1,1,0,0,0,27,38Z" />
        <path d="M43,38H53a1,1,0,0,0,1-1V29a1,1,0,0,0-1-1H43a1,1,0,0,0-1,1v8A1,1,0,0,0,43,38Z" />
        <path
          d="M56,10H8a6.006,6.006,0,0,0-6,6V53a6.006,6.006,0,0,0,6,6H56a6.006,6.006,0,0,0,6-6V16A6.006,6.006,0,0,0,56,10Zm0,47H8a4,4,0,0,1-4-4V23H60V53A4,4,0,0,1,56,57Z"
          fill={props.color || '#F7F7F7'}
        />
        <path d="M49,8V3a2,2,0,0,0-4,0V8Z" />
        <path d="M19,8V3a2,2,0,0,0-4,0V8Z" />
      </g>
    </svg>
  )
}

export default Calendar
