/** @jsxImportSource theme-ui */

import { useState } from 'react'

import { useMutation } from '@apollo/client'

import {
  ENABLE_MFA,
  RESEND_MFA_LOGIN,
  VERIFY_MFA_LOGIN
} from '../hooks/mutations'

export const useMFAFlow = cb => {
  const [mfa, setMFA] = useState(false)
  const [sessionToken, setSessionToken] = useState('')
  const [stytchMemberId, setStytchMemberId] = useState('')
  const [stytchOrganizationId, setStytchOrganizationId] = useState('')

  const [verifyMFALogin, { loading: verifyLoader }] = useMutation(
    VERIFY_MFA_LOGIN
  )
  const [resendMFALogin, { loading: resendLoader }] = useMutation(
    RESEND_MFA_LOGIN
  )

  const [enableMfa, { loading: enablingMfa }] = useMutation(
    ENABLE_MFA
  )

  function triggerMfa({
    newToken,
    user_id,
    organization_id,
    mfaRequired,
    mfaEnabled
  }) {
    const needsMFA = mfaRequired && !mfaEnabled

    setMFA(needsMFA ? 'ENABLE' : mfaEnabled)
    setSessionToken(newToken)
    setStytchMemberId(user_id)
    setStytchOrganizationId(organization_id)
  }

  async function verifyCb(res) {
    const newToken = res?.data?.verifyMFALogin?.data?.token
    const newSession = res?.data?.verifyMFALogin?.data?.session
    const agency = res?.data?.verifyMFALogin?.data?.user?.agency
    const role = res?.data?.verifyMFALogin?.data?.user?.role

    if (!newToken) return

    await cb(newToken, newSession, agency, role)
  }

  const mfaOptions = {
    verify: {
      fn: verifyMFALogin,
      loader: verifyLoader,
      cb: verifyCb,
      variables: {
        stytchMemberId: stytchMemberId,
        sessionJwt: sessionToken
      }
    },
    setupMfa: {
      fn: enableMfa,
      loader: enablingMfa,
      cb: async () => {
        setMFA(true)
      },
      variables: {
        sessionJwt: sessionToken,
        stytchMemberId: stytchMemberId,
        organizationId: stytchOrganizationId
      }
    },
    resend: {
      fn: resendMFALogin,
      loader: resendLoader,
      cb: async () => {},
      variables: {
        stytchMemberId: stytchMemberId
      }
    }
  }

  return {
    mfa,
    triggerMfa,
    mfaOptions
  }
}
