/** @jsxImportSource theme-ui */
import { forwardRef } from 'react'
import { Box } from 'theme-ui'

const ModalBody = (
  { children, variant = 'ModalBody', style, ...props },
  ref
) => {
  return (
    <Box variant={variant} sx={{ ...props.sx, ...style }}>
      {children}
    </Box>
  )
}

export default forwardRef(ModalBody)
