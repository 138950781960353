import { gql, useQuery } from '@apollo/client'
import { Box } from 'theme-ui'

const BULLETIN_NOTIFICATIONS_COUNT = gql`
  query GetBulletinNotificationsCount {
    myAgenciesBulletinsUnreadCount
  }
`

const ChatIndicator = ({ smallBadge, children, ...rest }) => {
  const { data } = useQuery(BULLETIN_NOTIFICATIONS_COUNT)

  const newMessageCount = data?.myAgenciesBulletinsUnreadCount
  return (
    <>
      {children}
      {newMessageCount >= 1 && (
        <Box
          sx={{
            height: smallBadge ? '6px' : '18px',
            width: smallBadge ? '6px' : '18px',
            borderRadius: '100%',
            backgroundColor: 'red',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            border: 'none',
            color: 'white',
            fontSize: '.75rem'
          }}
          {...rest}
        >
          {!smallBadge && newMessageCount}
        </Box>
      )}
    </>
  )
}

export default ChatIndicator
