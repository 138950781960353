function Lock(props) {
  const title = !props.noTitle && (props.title || 'lock')

  return (
    <svg
      height={props.height || '64'}
      width={props.width || '64'}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{title}</title>
      <g fill={props.color || '#F7F7F7'}>
        <path d="M45,25H43V14a11,11,0,0,0-22,0V25H19V14a13,13,0,0,1,26,0Z" />
        <path
          d="M53,27H11a4,4,0,0,0-4,4V59a4,4,0,0,0,4,4H53a4,4,0,0,0,4-4V31A4,4,0,0,0,53,27ZM33,47.91V54a1,1,0,0,1-2,0V47.91a6,6,0,1,1,2,0Z"
          fill={props.color || '#F7F7F7'}
        />
      </g>
    </svg>
  )
}

export default Lock
