const HQ = ({ height, width, color, title, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={height}
      height={width}
      viewBox="0 0 64 64"
      {...props}
    >
      <title>{title || 'home'}</title>
      <g fill={color || '#111111'}>
        <path
          d="M62,57H2a1,1,0,0,0,0,2H62a1,1,0,0,0,0-2Z"
          data-color="color-2"
        ></path>{' '}
        <polygon
          points="35 4 35 12 43 8 35 4"
          data-color="color-2"
        ></polygon>{' '}
        <path
          d="M26,41a1,1,0,0,1,1-1H37a1,1,0,0,1,1,1V55H59V22a6,6,0,0,0-6-6H33V3a1,1,0,0,0-2,0V16H11a6,6,0,0,0-6,6V55H26ZM42,27a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1v4a1,1,0,0,1-1,1H43a1,1,0,0,1-1-1ZM29,27a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1v4a1,1,0,0,1-1,1H30a1,1,0,0,1-1-1Zm-7,4a1,1,0,0,1-1,1H17a1,1,0,0,1-1-1V27a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1Z"
          fill={color || '#111111'}
        ></path>
      </g>
    </svg>
  )
}

export default HQ
