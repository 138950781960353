import { useEffect, useState } from 'react'
import { Flex, Box } from 'theme-ui'
import DeleteX from 'src/images/icons/Delete'
import { theme } from 'src/styles'
import Button from '../Shared/Elements/Button'
import Input from '../Shared/Elements/Input'
import ModalButton from '../Shared/ModalButton'

const numberPadOptions = [
  { value: 1 },
  { value: 2 },
  { value: 3 },
  { value: 4 },
  { value: 5 },
  { value: 6 },
  { value: 7 },
  { value: 8 },
  { value: 9 },
  { value: 0 }
]

const CustomRadioButtonsInput = ({
  id,
  type,
  name,
  placeholder,
  value: curVal,
  onChange,
  handleSelect,
  options,
  withInput,
  containerStyles,
  noZero,
  buttonStyles,
  actionButton,
  disabled,
  withNumberPad,
  withNumberPadButtonProps,
  minValue,
  maxValue
}) => {
  const isNumberPad = options === 'numberPad'

  const value =
    type === 'number' && curVal !== undefined && curVal !== null
      ? parseInt(curVal)
      : curVal
  const isNumber = typeof value === 'number'
  const handleClick = value => {
    handleSelect({
      name,
      value
    })
  }

  return (
    <Flex
      style={{
        maxWidth: isNumberPad && '150px',
        flexWrap: 'wrap',
        alignItems: 'center',
        ...containerStyles
      }}
      // ref={ref}
    >
      {!isNumberPad
        ? options.map((o, k) => {
            return (
              <Button
                type="button"
                disabled={disabled}
                variant={
                  value === o.value ||
                  (!value && !isNumber && o.default)
                    ? 'primary'
                    : 'secondary'
                }
                size="lg"
                onClick={() => handleClick(o.value)}
                style={{ margin: '5px 5px', ...buttonStyles }}
                key={k}
              >
                {o.label || o.value}
              </Button>
            )
          })
        : numberPadOptions.map((o, k) => {
            return (
              <Box sx={{ width: '33%' }} key={k}>
                <Button
                  type="button"
                  variant="secondary"
                  size="lg"
                  disabled={
                    disabled || (noZero && !value && o.value === 0)
                  }
                  onClick={() =>
                    handleClick(parseInt(`${value || ''}${o.value}`))
                  }
                  sx={{
                    margin: '5px 5px',
                    '&:active': {
                      backgroundColor: 'black'
                    },
                    ...buttonStyles
                  }}
                >
                  {o.value}
                </Button>
              </Box>
            )
          })}

      <>
        {isNumberPad && (
          <Box sx={{ width: '33%' }}>
            <Button
              disabled={disabled}
              onClick={() => {
                const valString =
                  value && `${value.toString().slice(0, -1)}`
                !value
                  ? handleClick(null)
                  : handleClick(
                      valString ? parseInt(valString) : null
                    )
              }}
              type="button"
              variant="secondary"
              size="lg"
              sx={{
                margin: '5px 5px',
                width: '100%',
                padding: '8px',
                '&:active': {
                  backgroundColor: 'black'
                }
              }}
            >
              <DeleteX
                color={theme.colors.white}
                height={20}
                width={20}
              />
            </Button>
          </Box>
        )}

        {withInput && (
          <Input
            disabled={disabled}
            id={id}
            type={type}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            value={isNumber ? value : value || ''}
            min={minValue}
            max={maxValue}
            style={{
              fontSize: '20px',
              maxWidth: 100,
              height: 47,
              margin: '5px 10px',
              borderWidth: (value || isNumber) && '4px',
              borderColor: (value || isNumber) && 'primary',
              borderStyle: (value || isNumber) && 'solid'
            }}
          />
        )}

        {withNumberPad && (
          <CustomValueNumberPad
            defaultVal={value}
            options={options}
            onAdd={val => handleClick(val)}
            buttonStyles={withNumberPadButtonProps}
          />
        )}

        {actionButton && actionButton}
      </>
    </Flex>
  )
}

const CustomValueNumberPad = ({
  onAdd,
  name,
  id,
  defaultVal,
  options,
  buttonStyles
}) => {
  const [value, setValue] = useState(defaultVal)

  useEffect(() => {
    if (defaultVal !== value) {
      setValue()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultVal])

  function handleClick(val) {
    setValue(val)
  }

  return (
    <ModalButton
      buttonProps={{
        variant:
          value && !options.some(o => o.value === value)
            ? 'primary'
            : 'secondary',
        ...buttonStyles
      }}
      contentSize="sm"
      modalHeader="Custom Value"
      modalProps={{
        style: {
          width: '250px',
          margin: 'auto'
        }
      }}
      buttonLabel={
        value && !options.some(o => o.value === value)
          ? value
          : 'Other..'
      }
      renderModalActions={toggle => (
        <Button
          variant="primary"
          onClick={() => {
            onAdd(value)
            toggle()
          }}
          type="button"
        >
          Update
        </Button>
      )}
    >
      {() => {
        return (
          <Flex
            sx={{
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <h3
              style={{
                minHeight: '32px',
                textDecoration: 'underline'
              }}
            >
              {value || ''}
            </h3>
            <CustomRadioButtonsInput
              id={id}
              type="number"
              name={name}
              placeholder="Number"
              value={value}
              handleSelect={e => handleClick(e.value)}
              options="numberPad"
              noZero={true}
              withInput={false}
              withNumberPad={false}
            />
          </Flex>
        )
      }}
    </ModalButton>
  )
}

export default CustomRadioButtonsInput
