import { addOrdinalSuffix } from 'src/utils'
import capitalize from 'lodash/capitalize'
import moment from 'moment'

export const frequencyOptions = [
  { label: 'Daily', value: 'DAILY', required: ['hour', 'minute'] },
  {
    label: 'Weekly',
    value: 'WEEKLY',
    required: ['hour', 'minute', 'dayOfWeek']
  },
  {
    label: 'Monthly',
    value: 'MONTHLY',
    required: ['hour', 'minute', 'dayOfMonth']
  },
  {
    label: 'Annually',
    value: 'ANNUALLY',
    required: ['hour', 'minute', 'dayOfMonth', 'monthOfYear']
  }
]

// day of week options
export const dayOfWeekOptions = [
  { label: 'Sunday', value: 0 },
  { label: 'Monday', value: 1 },
  { label: 'Tuesday', value: 2 },
  { label: 'Wednesday', value: 3 },
  { label: 'Thursday', value: 4 },
  { label: 'Friday', value: 5 },
  { label: 'Saturday', value: 6 }
]

// Day options
export const dayOfMonthOptions = [
  { label: 'First Day of Month', value: 1 },
  {
    label: 'Last Day of Month',
    value: v => v
    // get last day of month using moment
  },
  ...Array.from({ length: 27 }, (_, index) => {
    return {
      label: (index + 2).toString(),
      value: index + 2
    }
  })
]

// Month options
const FIXED_YEAR = 2001
export const monthOptions = Array.from({ length: 12 }, (_, index) => {
  const month = index + 1
  const daysInMonth = new Date(FIXED_YEAR, month, 0).getDate()

  return {
    label: new Date(0, index).toLocaleString('en-US', {
      month: 'long'
    }),
    daysInMonth,
    value: index + 1
  }
})

// hour of day options
export const hourOptions = Array.from({ length: 24 }, (_, index) => ({
  label:
    index.toString().length < 2
      ? index.toString().padStart(2, '0')
      : index.toString(),
  value: index
}))

// minute of hour options
export const minuteOptions = Array.from(
  { length: 12 },
  (_, index) => {
    return {
      label:
        (index * 5).toString().length < 2
          ? (index * 5).toString().padStart(2, '0')
          : (index * 5).toString(),
      value: index * 5
    }
  }
)

export const recurringFrequencyOptions = {
  frequencyOptions,
  dayOfWeekOptions,
  dayOfMonthOptions,
  monthOptions,
  hourOptions,
  minuteOptions
}

export function mapRecurringFrequencyToState(recurringFrequency) {
  return {
    hour: {
      ...(recurringFrequencyOptions.hourOptions.find(
        hourOption => hourOption.value === recurringFrequency.hour
      ) || null)
    },
    minute: {
      ...(recurringFrequencyOptions.minuteOptions.find(
        minuteOption =>
          minuteOption.value === recurringFrequency.minute
      ) || null)
    },
    frequency:
      recurringFrequencyOptions.frequencyOptions.find(
        frequencyOption =>
          frequencyOption.value ===
          recurringFrequency.frequency.toUpperCase()
      ) || null,
    dayOfWeek:
      (recurringFrequency.dayOfWeek &&
        recurringFrequencyOptions.dayOfWeekOptions.find(
          dayOfWeekOption =>
            dayOfWeekOption.value === recurringFrequency?.dayOfWeek
        )) ||
      null,
    dayOfMonth:
      (recurringFrequency.dayOfMonth &&
        recurringFrequencyOptions.dayOfMonthOptions.find(
          dayOfMonthOption =>
            dayOfMonthOption.value === recurringFrequency?.dayOfMonth
        )) ||
      null,
    monthOfYear:
      recurringFrequencyOptions.monthOptions.find(
        monthOption =>
          monthOption.value === recurringFrequency?.monthOfYear
      ) || null
  }
}

export function generateRecurringFrequencyDescription({
  frequency,
  hour,
  minute,
  dayOfWeek,
  dayOfMonth,
  monthOfYear
}) {
  let description = `${capitalize(frequency)} at ${hour}:${
    minute < 10 ? `0${minute}` : minute
  }`

  if (dayOfWeek >= 0 || dayOfMonth || monthOfYear) {
    if (frequency !== 'DAILY') description += ' on '

    if (dayOfWeek !== null && frequency === 'WEEKLY') {
      description += `${moment()
        .day(dayOfWeek)
        .format('dddd')} `
    }
    if (
      dayOfMonth &&
      (frequency === 'MONTHLY' || frequency === 'ANNUALLY')
    ) {
      description += `${addOrdinalSuffix(dayOfMonth)} day `
    }
    if (monthOfYear) {
      description += `of ${moment()
        .month(monthOfYear - 1)
        .format('MMMM')}`
    }
  }

  return description
}
