/** @jsxImportSource theme-ui */
import { forwardRef } from 'react'
import { Flex } from 'theme-ui'

const Nav = ({ children, variant = 'Nav', sx, ...props }, ref) => {
  return (
    <Flex as="ul" variant={variant} sx={{ ...sx }} {...props}>
      {children}
    </Flex>
  )
}

export default forwardRef(Nav)
