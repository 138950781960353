/** @jsxImportSource theme-ui */
import { forwardRef } from 'react'
import { Label as ThemeUILabel } from 'theme-ui'

const Label = (
  { children, labelFor, variant = 'label', sx, ...props },
  ref
) => {
  return (
    <ThemeUILabel
      variant={variant}
      htmlFor={labelFor}
      sx={{ ...sx }}
      {...props}
    >
      {children}
    </ThemeUILabel>
  )
}

export default forwardRef(Label)
