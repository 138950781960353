import { Fragment, useMemo, useState } from 'react'
import SubmissionUpdateForm from '../Forms/SubmissionUpdateForm'
import { Flex, Box } from 'theme-ui'

import {
  GET_SUBMISSION,
  useGetCurrentAgencyContext,
  useGetSubmission
} from 'src/app/hooks/queries'
import { Divider, Empty, Overline } from '../Shared'
import { prettyDate, prettyDateFromMoment } from 'src/utils'
import { Space } from '..'
import get from 'lodash/get'
import styled from '@emotion/styled'
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'

import AddVehicleModalForm from '../Forms/AddVehicleModalForm'
import AddMedicalReportCountButton from '../Forms/AddMedicalReportCountButton'
import ModalButton from '../Shared/ModalButton'
import DispatchEditForm, {
  DispatchEditPersonnelForm,
  DispatchEditUnitForm
} from '../Forms/DispatchEditForm'
import { Link } from 'react-router-dom'
import AddNoteModalForm from '../Forms/AddNoteModalForm'
import Notes from '../Shared/Notes'
import DocumentList from '../Operations/Documents/DocumentList'

import { FormResponseListItems } from '../Operations/Forms/FormResponseList'
import FormList from '../Operations/Forms/FormList'
import AddResourceModalForm from '../Forms/AddResourcesModalForm'
import { ArchiveDispatchButton } from './DispatchTableActions'

import Spinner from 'src/images/icons/Spinner'
import Button from '../Shared/Elements/Button'
import Label from '../Shared/Elements/Label'
import ListGroup from '../Shared/Elements/ListGroup'
import ListGroupItem from '../Shared/Elements/ListGroupItem'
import Badge from '../Shared/Elements/Badge'

import { RolesOnly } from 'src/app/services/auth'
import Icon from '../Shared/Icon'
import cloneDeep from 'lodash/cloneDeep'
import UserActionAuditModal from '../UserActionAuditModal'
import SurflineWeatherIcons from '../Shared/SurflineWeatherIcons'
import FormAttachmentButtonList from '../Shared/Forms/FormAttachmentButton'
import { UppyFileUploadButton } from '../Shared/Uppy/AuthenticatedUppyFilePicker'
import { UPDATE_SUBMISSION } from 'src/app/hooks/mutations'
import { useMutation } from '@apollo/client'

const WrappedBadge = styled(Badge)`
  word-break: break-word;
  max-width: 100%;
  white-space: normal;
  text-align: left;
  cursor: pointer;
`

const SubmissionTableSubcomponent = ({ submissionId, row }) => {
  const [formSubmitted, setFormSubmitted] = useState(0)

  const { currentAgency, user } = useGetCurrentAgencyContext()
  const { submission, refetch, loading } = useGetSubmission(
    submissionId,
    {
      fetchPolicy: 'cache-and-network'
    }
  )

  const dispatchedUnits = get(submission, 'dispatches')
  const relatedSubmissions = get(submission, 'relatedSubmissions')
  const notes = get(submission, 'notes')

  const sub = useMemo(() => ({ ...submission }), [submission])

  if (!submission) return <Spinner centered />

  const dispatchContextData =
    dispatchedUnits?.map((d, k) => ({
      ...d,
      name: 'Dispatch' + ' ' + (d?.unit?.name || k + 1)
    })) || []

  const notesContextdata =
    notes?.map((d, k) => ({
      ...d,
      name: 'Notes' + ' ' + (k + 1)
    })) || []

  const currentType = currentAgency.activities.find(
    a => a.id === sub?.type?.id
  )

  return (
    <div>
      <Flex
        sx={{ alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Box>
          <Flex sx={{ alignItems: 'center' }}>
            <h4 style={{ margin: 0 }}>Incident Details</h4>
            <UserActionAuditModal
              includeFieldNameWithContext
              contextData={[
                ...dispatchContextData,
                ...notesContextdata
              ]}
              propNodes={[
                ...dispatchContextData,
                ...notesContextdata
              ]}
              rowId={sub.id}
            />
            {loading && <Spinner />}
          </Flex>
          <p style={{ marginLeft: 5, margin: 0 }}>
            {submission.reporter
              ? `Submitted by ${
                  submission.reporter.name
                } on ${prettyDate(submission.createdAt)}`
              : `Submitted on ${prettyDate(submission.createdAt)}`}
          </p>
        </Box>
      </Flex>

      <Divider margin="20px 0px" />
      <Flex
        sx={{
          flexDirection: ['column', null, null, null, 'row'],
          flexWrap: 'wrap'
        }}
      >
        <Box
          sx={{
            minWidth: '300px',
            flex: 0.75,
            maxWidth: '100%',
            p: '.5rem'
          }}
        >
          <SubmissionUpdateForm
            onCancel={() => row.toggleRowExpanded()}
            submission={sub}
          />
        </Box>
        <Flex
          sx={{
            p: '.5rem',
            maxWidth: '100%',
            minWidth: '300px',
            flex: 1,
            flexDirection: 'column'
          }}
        >
          <Box
            sx={{
              // flex: 1,
              pb: 20,
              width: '100%'
            }}
          >
            <Flex
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Label sx={{ marginBottom: '0px' }}>Resources</Label>
              <AddResourceModalForm submissionId={submission.id} />
            </Flex>
            {dispatchedUnits && dispatchedUnits.length < 1 ? (
              <Empty>No Dispatches</Empty>
            ) : (
              <>
                <ListGroup>
                  {dispatchedUnits &&
                    dispatchedUnits.map((d, i) => (
                      <ListGroupItem key={i}>
                        <EditResourceItem
                          includeCallTimes
                          incidentTime={sub.incidentTime}
                          allowArchive
                          dispatch={d}
                          refetch={refetch}
                          updateQueryOnArchive={(
                            store,
                            { data: { archiveOneDispatch } }
                          ) => {
                            // Read the data from our cache for this query.
                            const data = cloneDeep(
                              store.readQuery({
                                query: GET_SUBMISSION,
                                variables: {
                                  where: {
                                    id: submissionId
                                  }
                                }
                              })
                            )

                            data.submission = {
                              ...data.submission,
                              dispatches: data.submission.dispatches?.filter(
                                d => d.id !== archiveOneDispatch?.id
                              )
                            }

                            // Write our data back to the cache.
                            store.writeQuery({
                              query: GET_SUBMISSION,
                              variables: {
                                where: {
                                  id: submissionId
                                }
                              },
                              data: { ...data }
                            })
                          }}
                        />
                      </ListGroupItem>
                    ))}
                </ListGroup>
              </>
            )}
          </Box>
          <Box
            sx={{
              width: '100%',
              // flex: 1,

              pb: 35
            }}
          >
            <Flex
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Label sx={{ marginBottom: '0px' }}>
                Notes / Narrative
              </Label>
              <RolesOnly
                roles={['USER', 'ADMIN', 'DISPATCHER', 'SUPERVISOR']}
              >
                <AddNoteModalForm submissionId={submissionId} />
              </RolesOnly>
            </Flex>
            {notes && notes.length < 1 ? (
              <Empty>
                No Notes <br />
                {submission.relatedSubmissions &&
                  submission.relatedSubmissions.length > 0 &&
                  '(may be on related submissions)'}
              </Empty>
            ) : (
              <>
                <ListGroup style={{ margin: 0 }}>
                  <Notes notes={notes} />
                </ListGroup>
              </>
            )}
          </Box>
          <Box
            sx={{
              width: '100%',
              // flex: 1,
              pb: 35
            }}
          >
            <Label>Related</Label>
            {relatedSubmissions && relatedSubmissions.length < 1 ? (
              <Empty>No Related Submissions</Empty>
            ) : (
              <>
                <ListGroup>
                  {relatedSubmissions &&
                    relatedSubmissions.map((d, i) => {
                      return (
                        <ListGroupItem key={i}>
                          <Flex
                            sx={{
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              flexWrap: 'wrap'
                            }}
                          >
                            <Flex
                              sx={{
                                width: '100%',
                                px: 1,
                                justifyContent: 'space-between'
                              }}
                            >
                              <strong>{d.type.label}</strong>
                              <Link
                                to={{
                                  pathname: '/agency/stats',
                                  search: `incidentId=${d.id}`
                                }}
                              >
                                <Button
                                  sx={{ padding: 0 }}
                                  variant="link"
                                >
                                  Learn More
                                </Button>
                              </Link>
                            </Flex>
                          </Flex>
                        </ListGroupItem>
                      )
                    })}
                </ListGroup>
              </>
            )}
          </Box>
          {/* <Box flex={1} width={[1]} pb={20} sx={{border: '200px solid blue'}}></Box> */}
        </Flex>
        <Flex
          sx={{
            flexDirection: 'column',
            justifyContent: 'flex-start',
            maxWidth: '100%',
            minWidth: '300px',
            flex: 1,

            p: '.5rem'
          }}
        >
          <Box
            sx={{
              width: '100%',
              // flex: 1,
              pb: 35
            }}
          >
            <Flex
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Label sx={{ marginBottom: '0px' }}>Files</Label>
              <RolesOnly
                roles={['USER', 'ADMIN', 'DISPATCHER', 'SUPERVISOR']}
              >
                <ModalButton
                  modalHeader="Upload Files"
                  buttonProps={{
                    variant: 'link'
                  }}
                  buttonLabel="+ Add"
                >
                  {({ toggle }) => {
                    return (
                      <SubmissionFileUploadForm
                        submissionId={submission.id}
                        onFileUploadFinish={() => {
                          toggle()
                          refetch()
                        }}
                      />
                    )
                  }}
                </ModalButton>
              </RolesOnly>
            </Flex>
            <DocumentList
              hideSearch
              hideCreateButton
              compactView
              submissionId={submission.id}
              emptyState={
                <Empty>
                  No Files
                  <br />
                  {submission.relatedSubmissions &&
                    submission.relatedSubmissions.length > 0 &&
                    '(may be on related submissions)'}
                </Empty>
              }
              hidePin
            />
          </Box>

          <Box
            sx={{
              // flex: 1,
              width: '100%',
              pb: 35
            }}
          >
            <Flex
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Label sx={{ marginBottom: '0px' }}>Forms</Label>
              <RolesOnly
                roles={['USER', 'ADMIN', 'DISPATCHER', 'SUPERVISOR']}
              >
                <ModalButton
                  modalHeader="New Form Response"
                  buttonProps={{
                    variant: 'link'
                  }}
                  buttonLabel="+ Add"
                >
                  {({ toggle }) => {
                    return (
                      <FormList
                        onFormSubmitFinish={() => {
                          toggle()
                          // Need this to trigger refetch :(
                          setFormSubmitted(formSubmitted + 1)
                        }}
                        formType={'incidentAttachedForms'}
                        onDraftFinish={refetch}
                        hideCreateButton
                        hideEdit
                        hideResponseModal
                        submissionId={submission.id}
                      />
                    )
                  }}
                </ModalButton>
              </RolesOnly>
            </Flex>

            <FormResponseListItems
              key={formSubmitted}
              submissionId={submission.id}
              hideSearch
              includeSummaryTitles={false}
              includeFormName
              hideCreateButton
              emptyState={
                <Empty>
                  No Form Responses
                  <br />
                  {submission.relatedSubmissions &&
                    submission.relatedSubmissions.length > 0 &&
                    '(may be on related submissions)'}
                </Empty>
              }
              customComponent={({
                data,
                called,
                refetch,
                ...props
              }) => {
                return (
                  <FormAttachmentButtonList
                    featuredForms={
                      currentType && currentType.suggestedForms
                    }
                    formResponses={data.myAgenciesFormResponses?.map(
                      fr => ({
                        ...fr,
                        formId: fr.form?.id,
                        formName: fr.form?.name
                      })
                    )}
                    onFinish={() => {
                      refetch()
                    }}
                    onDraftFinish={() => {
                      refetch()
                    }}
                    {...props}
                    text=""
                    fetchFormResponse
                  />
                )
              }}
              suggestedForms={
                currentAgency.activities.find(
                  a => a.label === submission.type?.label
                )?.suggestedForms
              }
              hidePin
            />
          </Box>
          <Box
            sx={{
              // flex: 1,
              pb: 20,
              width: '100%'
            }}
          >
            <Flex
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Label sx={{ marginBottom: '0px' }}>Vehicles</Label>
              <AddVehicleModalForm submissionId={submissionId} />
            </Flex>
            {isEmpty(submission.vehicleIncidents) ? (
              <Empty>
                No Vehicles Added <br />
                {submission.relatedSubmissions &&
                  submission.relatedSubmissions.length > 0 &&
                  '(may be on related submissions)'}
              </Empty>
            ) : (
              <Box>
                <ListGroup>
                  {submission.vehicleIncidents.map(vi => (
                    <ListGroupItem>
                      <Flex sx={{ justifyContent: 'space-between' }}>
                        <Box>
                          <p>
                            {vi.vehicle.licenseNumber} -{' '}
                            {vi.vehicle.type}
                          </p>
                          ${vi.vehicle.value}
                        </Box>

                        <Box>
                          Passengers: <Badge>{vi.passengers}</Badge>
                        </Box>
                      </Flex>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </Box>
            )}
          </Box>

          <Box
            sx={{
              // flex: 1,
              pb: 20,
              width: '100%'
            }}
          >
            <Flex
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Label sx={{ marginBottom: '0px' }}>
                External Reports
              </Label>
              <AddMedicalReportCountButton
                submissionId={submissionId}
              />
            </Flex>
            <Empty>
              {submission.medicalRecordCount ? (
                <>
                  <h3>
                    {submission.medicalReportId} (
                    {submission.callForService.callNumber})
                  </h3>
                  <Badge>
                    {submission.medicalRecordCount > 1
                      ? `${submission.medicalRecordCount} Reports`
                      : `${submission.medicalRecordCount} Report`}
                  </Badge>
                  <p
                    style={{
                      textAlign: 'center',
                      marginTop: 5
                    }}
                  >
                    Use this to keep track of external reports stored
                    in other systems.
                  </p>
                </>
              ) : (
                'No External Reports Connected'
              )}
            </Empty>
          </Box>
        </Flex>
      </Flex>
      <Box sx={{ p: 10, borderTop: 'default' }}>
        <Overline>Weather</Overline>
        <SurflineWeatherIcons
          weather={submission?.weather}
          addUnits
        />
      </Box>
    </div>
  )
}

const SubmissionFileUploadForm = ({
  submissionId,
  onFileUploadFinish
}) => {
  const [updateOneSubmission, { loading }] = useMutation(
    UPDATE_SUBMISSION,
    {
      refetchQueries: ['GetAgencyFilesPList']
    }
  )
  const [files, setFiles] = useState([])

  function mapFileToCreate(val) {
    return {
      name: val.name || `attachment`,
      filestackId: val.filestackId,
      fileType: val.fileType
    }
  }

  async function onUpload() {
    await updateOneSubmission({
      variables: {
        where: {
          id: submissionId
        },
        data: {
          files: {
            create: files
              .filter(f => f.filestackId)
              ?.map(f => mapFileToCreate(f))
          }
        }
      }
    })

    onFileUploadFinish && onFileUploadFinish()
  }

  return (
    <>
      <UppyFileUploadButton
        multipleFiles={true}
        onDetach={({ filestackId }) => {
          setFiles(files.filter(f => f.filestackId !== filestackId))
        }}
        onAttach={value => {
          setFiles(value)
        }}
        files={files}
      />
      <Flex
        sx={{
          justifyContent: 'flex-end',
          marginTop: 10
        }}
      >
        <Button
          onClick={onUpload}
          disabled={files.length < 1 || loading}
          icon={loading && 'spinner'}
        >
          Upload
        </Button>
      </Flex>
    </>
  )
}

export const EditResourceItem = ({
  dispatch: d,
  updateQueryOnArchive,
  includeCallTimes,
  allowArchive,
  incidentTime,
  refetch,
  ...rest
}) => {
  const { user } = useGetCurrentAgencyContext()

  const ca = d.createdAt
  const os = d.onScene
  const er = d.enRoute
  const c = d.completedAt
  const resp = d.personnel

  const validateDispatch = time => {
    const diff = moment(time).diff(incidentTime, 'second')

    return diff < 0 ? 'Must be after incident time.' : false
  }

  return (
    <>
      <Flex
        sx={{
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexWrap: 'wrap'
        }}
      >
        <Flex
          sx={{
            width: '100%',
            alignItems: 'flex-start',
            justifyContent: 'space-between'
          }}
        >
          <Box sx={{ width: '100%' }}>
            <Flex
              sx={{
                fontWeight: 'bold',
                width: '100%'
              }}
            >
              {d.unit ? d.unit.name : 'Unassigned'}
              <Space width={5} />
              <RolesOnly
                roles={['USER', 'ADMIN', 'DISPATCHER', 'SUPERVISOR']}
              >
                <ModalButton
                  buttonProps={{
                    sx: {
                      padding: '30px'
                    },
                    variant: 'link'
                  }}
                  modalHeader={'Edit Resource'}
                  CustomButtonComponent={({ onClick }) => (
                    <Button
                      variant="naked"
                      sx={{
                        padding: '0px',
                        fontWeight: 'bold',
                        fontSize: '12px',
                        textTransform: 'lowercase'
                      }}
                      onClick={user?.role !== 'READ_ONLY' && onClick}
                    >
                      <Icon
                        icon="pencil"
                        width="10px"
                        height="10px"
                      />
                    </Button>
                  )}
                >
                  {({ toggle }) => (
                    <DispatchEditUnitForm
                      onFinish={toggle}
                      dispatchId={d.id}
                      unit={d.unit}
                    />
                  )}
                </ModalButton>
              </RolesOnly>
            </Flex>

            <Space width={7} height={3} />
            <Flex>
              {resp && resp.name}
              <Space width={5} />
              <RolesOnly
                roles={['USER', 'ADMIN', 'DISPATCHER', 'SUPERVISOR']}
              >
                <ModalButton
                  modalHeader="Edit Operator"
                  CustomButtonComponent={({ onClick }) => (
                    <Button
                      variant="link"
                      sx={{
                        padding: '0px',
                        fontWeight: 'bold',
                        fontSize: '12px',
                        textTransform: 'lowercase'
                      }}
                      onClick={user?.role !== 'READ_ONLY' && onClick}
                    >
                      {resp ? (
                        <Icon
                          icon="pencil"
                          width="10px"
                          height="10px"
                        />
                      ) : (
                        'Add Operator'
                      )}
                    </Button>
                  )}
                >
                  {({ toggle }) => (
                    <DispatchEditPersonnelForm
                      onFinish={() => {
                        toggle()
                        refetch()
                      }}
                      dispatchId={d.id}
                      personnel={d.personnel}
                    />
                  )}
                </ModalButton>
              </RolesOnly>
            </Flex>
          </Box>
          {allowArchive && (
            <RolesOnly
              roles={['USER', 'ADMIN', 'DISPATCHER', 'SUPERVISOR']}
            >
              <ArchiveDispatchButton
                dispatchId={d.id}
                update={updateQueryOnArchive}
              />
            </RolesOnly>
          )}
        </Flex>
        <Space block height={10} />
        {includeCallTimes ? (
          <Flex sx={{ flexWrap: 'wrap' }}>
            <Flex
              sx={{
                alignItems: 'flex-end',
                gap: 1
              }}
            >
              <ModalButton
                CustomButtonComponent={({ onClick }) => (
                  <WrappedBadge
                    onClick={user?.role !== 'READ_ONLY' && onClick}
                  >
                    {prettyDateFromMoment(moment(ca), 'HH:mm:ss')}
                  </WrappedBadge>
                )}
                modalHeader={'Edit Call Started'}
              >
                {({ toggle }) => (
                  <DispatchEditForm
                    onFinish={toggle}
                    dispatchTime={ca || ''}
                    fieldKey="createdAt"
                    dispatchId={d.id}
                    validator={validateDispatch}
                  />
                )}
              </ModalButton>
              <ModalButton
                CustomButtonComponent={({ onClick }) => (
                  <WrappedBadge
                    onClick={user?.role !== 'READ_ONLY' && onClick}
                    variant="orange"
                  >
                    ER: {er ? prettyDate(er, 'HH:mm:ss') : 'Not Set'}
                  </WrappedBadge>
                )}
                modalHeader={'Edit En Route'}
              >
                {({ toggle }) => (
                  <DispatchEditForm
                    onFinish={toggle}
                    dispatchTime={er || ''}
                    fieldKey="enRoute"
                    dispatchId={d.id}
                    validator={validateDispatch}
                  />
                )}
              </ModalButton>
              <ModalButton
                CustomButtonComponent={({ onClick }) => (
                  <WrappedBadge
                    onClick={user?.role !== 'READ_ONLY' && onClick}
                    variant="success"
                  >
                    OS: {os ? prettyDate(os, 'HH:mm:ss') : 'Not Set'}
                  </WrappedBadge>
                )}
                modalHeader={'Edit On Scene'}
              >
                {({ toggle }) => (
                  <DispatchEditForm
                    onFinish={toggle}
                    dispatchTime={os || ''}
                    fieldKey="onScene"
                    validator={validateDispatch}
                    dispatchId={d.id}
                  />
                )}
              </ModalButton>
              <ModalButton
                CustomButtonComponent={({ onClick }) => (
                  <WrappedBadge
                    onClick={user?.role !== 'READ_ONLY' && onClick}
                    variant="danger"
                  >
                    C: {c ? prettyDate(c, 'HH:mm:ss') : 'Not Set'}
                  </WrappedBadge>
                )}
                modalHeader={'Edit Completed At'}
              >
                {({ toggle }) => (
                  <DispatchEditForm
                    onFinish={toggle}
                    dispatchTime={c}
                    fieldKey="completedAt"
                    dispatchId={d.id}
                    validator={validateDispatch}
                  />
                )}
              </ModalButton>
            </Flex>
          </Flex>
        ) : (
          <Fragment />
        )}
      </Flex>
      {d.dispatchedBy && d.wasDispatched && (
        <Box sx={{ mt: 10 }}>
          {`Dispatched by: ${(d.dispatchedBy &&
            d.dispatchedBy.name) ||
            'Unassigned'}`}
        </Box>
      )}
    </>
  )
}

export default SubmissionTableSubcomponent
