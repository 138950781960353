export const checkPWStrength = v => {
  const lengthValid = v && /^.{8,}$/.test(v)
  const upperCaseValid = /^.*[A-Z].*$/.test(v)
  const numberValid = /^.*\d.*$/.test(v)
  const specialCharValid = /^.*[@#$%^!?&*+=].*$/.test(v)

  return {
    lengthValid,
    upperCaseValid,
    numberValid,
    specialCharValid
  }
}
