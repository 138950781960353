function Success(props) {
  const title = props.title || 'progress 2'

  return (
    <svg
      height={props.height || '48'}
      width={props.width || '48'}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{title}</title>
      <g>
        <path
          d="M24,47a1,1,0,0,1,0-2A21,21,0,0,0,24,3a1,1,0,0,1,0-2,23,23,0,0,1,0,46Z"
          fill={props.color || '#9ee09e'}
        />
        <path
          d="M2.091,26.826a1,1,0,0,1-1-.919C1.049,25.339,1,24.676,1,24s.049-1.338.095-1.906a1,1,0,0,1,1.994.162C3.046,22.785,3,23.4,3,24s.046,1.216.089,1.745a1,1,0,0,1-.916,1.078C2.146,26.825,2.118,26.826,2.091,26.826Z"
          fill="#c5edc5"
        />
        <path
          d="M12.485,6.281a1,1,0,0,1-.526-1.85,22.944,22.944,0,0,1,3.4-1.739,1,1,0,1,1,.753,1.854,20.851,20.851,0,0,0-3.1,1.586A.994.994,0,0,1,12.485,6.281Z"
          fill="#c5edc5"
        />
        <path
          d="M4.377,15.089a.976.976,0,0,1-.451-.109,1,1,0,0,1-.44-1.343,23.144,23.144,0,0,1,2-3.248A1,1,0,1,1,7.1,11.574a21.119,21.119,0,0,0-1.83,2.967A1,1,0,0,1,4.377,15.089Z"
          fill="#c5edc5"
        />
        <path
          d="M6.255,37.966a1,1,0,0,1-.809-.41,23.319,23.319,0,0,1-1.994-3.262,1,1,0,1,1,1.787-.9,21.1,21.1,0,0,0,1.823,2.98,1,1,0,0,1-.807,1.59Z"
          fill="#c5edc5"
        />
        <path
          d="M15.706,45.37a.99.99,0,0,1-.378-.074,23.027,23.027,0,0,1-3.408-1.75,1,1,0,1,1,1.053-1.7,21.09,21.09,0,0,0,3.111,1.6,1,1,0,0,1-.378,1.926Z"
          fill="#c5edc5"
        />
        <path
          d="M19.293,34.121l-8.414-8.414a1,1,0,0,1,0-1.414l1.414-1.414a1,1,0,0,1,1.414,0L20,29.172,34.293,14.879a1,1,0,0,1,1.414,0l1.414,1.414a1,1,0,0,1,0,1.414L20.707,34.121A1,1,0,0,1,19.293,34.121Z"
          fill="#49c549"
        />
      </g>
    </svg>
  )
}

export default Success
