import React from 'react'

function SettingsGear(props) {
  const title = props.title || 'settings gear'

  return (
    <svg
      height={props.height || '64'}
      width={props.width || '64'}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill={props.color || '#f7f7f7'}>
        <path
          d="M60.354,41.139,53.706,36.1a22.285,22.285,0,0,0,.385-4.1v0a22.267,22.267,0,0,0-.393-4.1l6.652-5.037a1,1,0,0,0,.262-1.3L55.34,12.434a1,1,0,0,0-1.255-.421l-7.7,3.254A22.241,22.241,0,0,0,39.3,11.153L38.265,2.876A1,1,0,0,0,37.273,2H26.727a1,1,0,0,0-.992.876L24.7,11.153a22.225,22.225,0,0,0-7.086,4.112l-7.7-3.254a1,1,0,0,0-1.255.421L3.384,21.566a1,1,0,0,0,.262,1.3L10.3,27.9a22,22,0,0,0,0,8.2L3.646,41.137a1,1,0,0,0-.262,1.3l5.272,9.132a1,1,0,0,0,1.255.421l7.7-3.254A22.212,22.212,0,0,0,24.7,52.847l1.034,8.277a1,1,0,0,0,.992.876H37.273a1,1,0,0,0,.992-.876L39.3,52.847a22.225,22.225,0,0,0,7.086-4.112l7.7,3.254a1,1,0,0,0,1.255-.421l5.272-9.132A1,1,0,0,0,60.354,41.139ZM32,43A11,11,0,1,1,43,32,11,11,0,0,1,32,43Z"
          fill={props.color || '#f7f7f7'}
        />
      </g>
    </svg>
  )
}

export default SettingsGear
