import React from 'react'

function Time(props) {
  const title = props.title || 'times'

  return (
    <svg
      height={props.height || '64'}
      width={props.width || '64'}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{title}</title>

      <g fill={props.color || '#f7f7f7'}>
        <rect x="0" y="0" width="64" height="64" fill="white" />
        <path
          fill={props.color || '#f7f7f7'}
          d="M32,12c-.552,0-1-.448-1-1V2c0-.552,.448-1,1-1s1,.448,1,1V11c0,.552-.448,1-1,1Z"
        />
        <path
          fill={props.color || '#f7f7f7'}
          d="M15,12c-.552,0-1-.448-1-1V2c0-.552,.448-1,1-1s1,.448,1,1V11c0,.552-.448,1-1,1Z"
        />
        <path
          fill={props.color || '#f7f7f7'}
          d="M49,12c-.552,0-1-.448-1-1V2c0-.552,.448-1,1-1s1,.448,1,1V11c0,.552-.448,1-1,1Z"
        />
        <path
          d="M34.558,55H5V20H59v19.851c.737,.661,1.406,1.393,2,2.187V8c0-.552-.448-1-1-1h-8v3.83c0,1.624-1.216,3.081-2.839,3.165-1.73,.091-3.161-1.285-3.161-2.996V7h-11v3.83c0,1.624-1.216,3.081-2.839,3.165-1.73,.091-3.161-1.285-3.161-2.996V7h-11v3.83c0,1.624-1.216,3.081-2.839,3.165-1.73,.091-3.161-1.285-3.161-2.996V7H4c-.552,0-1,.448-1,1V56c0,.552,.448,1,1,1h31.261c-.282-.644-.513-1.313-.703-2Z"
          fill={props.color || '#f7f7f7'}
        />
        <path
          fill={props.color || '#f7f7f7'}
          d="M37,51c0,6.627,5.373,12,12,12s12-5.373,12-12-5.373-12-12-12-12,5.373-12,12Zm13-7v6h6c.552,0,1,.448,1,1s-.448,1-1,1h-7c-.552,0-1-.448-1-1v-7c0-.552,.448-1,1-1s1,.448,1,1Z"
        />
      </g>
    </svg>
  )
}

export default Time
