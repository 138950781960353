// User to handle : notation in hidden fields string... this is used when an agency wants to
// hide a field based on the category of the incident
function generateCustomHideFieldsBasedOnCategory(
  settings = [],
  currentLabel
) {
  return settings
    ?.filter(hisf => {
      if (hisf?.includes(':')) {
        const categoriesToHideOn = hisf.split(':')?.slice(1)

        const shouldBeHidden = categoriesToHideOn?.some(ctho =>
          currentLabel?.startsWith(ctho)
        )

        return shouldBeHidden
      }
      return true
    })
    ?.map(hisf => hisf.split(':')[0])
}

export default generateCustomHideFieldsBasedOnCategory
