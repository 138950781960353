import ModalBody from 'src/app/components/Shared/Elements/ModalBody'
import ModalButton from 'src/app/components/Shared/ModalButton'
import Unauthorized from 'src/app/containers/Unauthorized'
import { RolesOnly } from 'src/app/services/auth'
import FormSubmitRenderer from '.'
import { FormResponseSubmitRenderer } from './FormResponseSubmitRenderer'

export const FormSubmitRendererModalButton = ({
  modalButtonProps,
  onFinish,
  formId,
  preFormRenderer,
  fetchFormResponse,
  ...p
}) => {
  return (
    <ModalButton
      contentSize={'lg'}
      modalProps={{
        checkOnClose: true
      }}
      {...modalButtonProps}
    >
      {({ toggle }) => {
        return (
          <RolesOnly
            fallback={<Unauthorized />}
            roles={['USER', 'ADMIN', 'DISPATCHER', 'SUPERVISOR']}
          >
            {preFormRenderer}
            {!formId ? (
              'This incident does not require supervisor review at this time.'
            ) : fetchFormResponse ? (
              <FormResponseSubmitRenderer
                formId={formId}
                {...p}
                onFinish={() => {
                  toggle()
                  onFinish && onFinish()
                }}
              />
            ) : (
              <FormSubmitRenderer
                formId={formId}
                {...p}
                onFinish={() => {
                  toggle()
                  onFinish && onFinish()
                }}
              />
            )}
          </RolesOnly>
        )
      }}
    </ModalButton>
  )
}
