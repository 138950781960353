import React from 'react'
import config from 'src/config'
import Button from './Elements/Button'

export const ConnectedAccountButtons = ({ prefix, oauths = [] }) => {
  const google = oauths.find(oauth => oauth.type?.includes('google'))
  const microsoft = oauths.find(oauth =>
    oauth.type?.includes('microsoft')
  )

  return (
    <>
      {microsoft && (
        <Button
          type="button"
          variant="primary"
          outline
          block
          linkProps={{ target: '_self' }}
          href={`${config.MICROSOFT_LOGIN_URL}`}
          icon="microsoft"
          size="lg"
          iconSize="lg"
        >
          {prefix || 'Connect'} Microsoft
        </Button>
      )}

      {google && (
        <Button
          type="button"
          variant="primary"
          outline
          block
          linkProps={{ target: '_self' }}
          href={`${config.GOOGLE_LOGIN_URL}`}
          icon="google"
          size="lg"
          iconSize="lg"
        >
          {prefix || 'Connect'} Google
        </Button>
      )}
    </>
  )
}
