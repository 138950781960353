function handleSortOrder(arr, descending) {
  return [...arr].sort(function(a, b) {
    // equal items sort equally
    if (a.order === b.order) {
      return 0
    }
    // nulls sort after anything else
    else if (a.order === null) {
      return 1
    } else if (b.order === null) {
      return -1
    }
    // otherwise, if we're ascending, lowest sorts first
    else if (descending) {
      return a.order < b.order ? 1 : -1
    }
    // if descending, highest sorts first
    else {
      return a.order < b.order ? -1 : 1
    }
  })
}

export const handleSortByCategoryLevel = categories => {
  return [...categories].sort((a, b) =>
    a.label && a.label.includes('/') ? 1 : -1
  )
}

export default handleSortOrder
