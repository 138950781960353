import { useContext, useEffect, useState } from 'react'
import {
  __ChannelsContext,
  __PusherContext
} from '../services/pusher'

export function usePusherContext() {
  const context = useContext(__PusherContext)
  useEffect(() => {
    if (!Object.keys(context).length)
      console.warn(
        'No Pusher context. Did you forget to wrap your app in a <PusherProvider />?'
      )
  }, [context])
  return context
}

export function useEvent(channel, eventName, callback) {
  // bind and unbind events whenever the channel, eventName or callback changes.
  useEffect(() => {
    if (channel === undefined) {
      return
    } else channel.bind(eventName, callback)
    return () => {
      channel.unbind(eventName, callback)
    }
  }, [channel, eventName, callback])
}

export function useChannels() {
  const context = useContext(__ChannelsContext)
  useEffect(() => {
    if (!context || !Object.keys(context).length)
      console.warn(
        'No Channels context. Did you forget to wrap your app in a <ChannelsProvider />?'
      )
  }, [context])
  return context
}

export function useChannel(channelName) {
  const [channel, setChannel] = useState()
  const [error, setError] = useState()
  const [subscribed, setSubscribed] = useState(false)
  const { subscribe, unsubscribe } = useChannels()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!channelName || !subscribe || !unsubscribe) return

    setLoading(true)

    const _channel = subscribe(channelName)
    setChannel(_channel)

    _channel?.bind('pusher:subscription_error', e => {
      console.log('Subscription Error', e)
      setError(e)
      setLoading(false)
    })

    _channel?.bind('pusher:subscription_succeeded', () => {
      console.log('Subscription Succeeded')
      setChannel(_channel)
      setLoading(false)
      setSubscribed(true)
    })

    return () => {
      unsubscribe(channelName)
      _channel?.unbind('pusher:subscription_error')
      _channel?.unbind('pusher:subscription_succeeded')
    }
  }, [channelName, subscribe, unsubscribe])

  /** Return the channel for use. */
  return { channel, error, loading, subscribed, subscribe }
}

const usePusher = ({ channelName, eventName, cb }) => {
  const { channel, subscribed, loading, error } = useChannel(
    channelName
  )
  useEvent(channel, eventName, cb)

  return {
    loading,
    subscribed,
    error,
    channel
  }
}

export default usePusher
