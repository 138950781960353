import { useState } from 'react'
import { useRegisterSW } from 'virtual:pwa-register/react'

const intervalMS = 60 * 60 * 1000

export const useSWUpdate = () => {
  const [installing, setInstalling] = useState(false)
  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker
  } = useRegisterSW({
    immediate: true,
    onRegisteredSW(swUrl, r) {
      console.log(`Service Worker at: ${swUrl}`, r)

      if (r?.installing?.state === 'installing') {
        setInstalling(true)
      } else {
        setInstalling(false)
      }

      r &&
        setInterval(async () => {
          if (!(!r.installing && navigator)) return

          console.log('Checking for service worker update')

          if ('connection' in navigator && !navigator.onLine) return

          const resp = await fetch(swUrl, {
            cache: 'no-store',
            headers: {
              cache: 'no-store',
              'cache-control': 'no-cache'
            }
          })

          if (resp?.status === 200) {
            await r.update()
          }
        }, intervalMS)
    }
  })

  async function update(reload) {
    await updateServiceWorker()

    if (reload) {
      const url = new URL(window.location.href)
      url.searchParams.set('updated', 'true') // Set your query parameter
      window.history.pushState({ path: url.href }, '', url.href) // Update the URL without reloading
      window.location.reload() // Now reload the page
    }
  }
  return {
    updateServiceWorker: update,
    needRefresh,
    setNeedRefresh,
    offlineReady,
    setOfflineReady,
    installing,
    setInstalling
  }
}
