import { theme } from 'src/styles'

function CheckboxChecked(props) {
  const title = props.title || 'c check'

  return (
    <svg
      height={props.height || '64'}
      width={props.width || '64'}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill={props.color || theme.colors.gray800}>
        <path
          d="M32,2A30,30,0,1,0,62,32,30.026,30.026,0,0,0,32,2ZM46.7,22.7l-20,20a.967.967,0,0,1-1.4,0l-8-8a.99.99,0,0,1,1.4-1.4L26,40.6,45.3,21.3a.99.99,0,1,1,1.4,1.4Z"
          fill={props.color || theme.colors.gray800}
        />
      </g>
    </svg>
  )
}

export default CheckboxChecked
