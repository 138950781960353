import { useState, useEffect } from 'react'

export const useNetworkStatus = () => {
  const [isOnline, setIsOnline] = useState(() =>
    typeof navigator !== 'undefined' &&
    typeof navigator.onLine === 'boolean'
      ? navigator.onLine
      : true
  )

  useEffect(() => {
    function setOnline() {
      setIsOnline(true)
    }

    function setOffline() {
      setIsOnline(false)
    }

    window.addEventListener('online', setOnline)
    window.addEventListener('offline', setOffline)

    return () => {
      window.removeEventListener('online', setOnline)
      window.removeEventListener('offline', setOffline)
    }
  }, [])

  return isOnline
}
