function Alert(props) {
  const title = props.title || 'o warning'

  return (
    <svg
      height={props.height || '64'}
      width={props.width || '64'}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{title}</title>
      <g fill={props.color || '#303035'}>
        <path
          d="M60.707,19.7l-16.4-16.4A1,1,0,0,0,43.6,3H20.4a1,1,0,0,0-.707.293L3.293,19.7A1,1,0,0,0,3,20.4V43.6a1,1,0,0,0,.293.707l16.4,16.4A1,1,0,0,0,20.4,61H43.6a1,1,0,0,0,.707-.293l16.4-16.4A1,1,0,0,0,61,43.6V20.4A1,1,0,0,0,60.707,19.7ZM34.621,13l-1.31,26.862H30.69L29.379,13ZM32,51a3,3,0,1,1,3-3A3,3,0,0,1,32,51Z"
          fill={props.color || '#303035'}
        />
      </g>
    </svg>
  )
}

export default Alert
