import { useMutation } from '@apollo/client'
import { optionalChaining } from 'src/utils'
import { SUBMIT_BULK_INCIDENT } from './mutations'
import { useNetworkStatus } from './useNetworkStatus'
import { useOfflineMutation } from './useOffline'

export const useSubmitBulkSubmission = options => {
  const isOnline = useNetworkStatus()

  const defaultOptions = {
    ...options
  }
  const {
    mutate: submitOffline,
    loading: offlineLoading,
    error
  } = useOfflineMutation('bulkIncident')

  const [
    submitBulkIncidentOnline,
    { data: onlineData, error: onlineError, loading: onlineLoading }
  ] = useMutation(SUBMIT_BULK_INCIDENT, defaultOptions)

  const data = optionalChaining(() => onlineData.submitBulkIncident)

  async function submitBulkIncident(opts) {
    try {
      return isOnline
        ? await submitBulkIncidentOnline(opts)
        : await submitOffline(opts.variables)
    } catch (e) {
      return e
    }
  }

  return {
    submitBulkIncident,
    error: error || onlineError,
    loading: offlineLoading || onlineLoading,
    data
  }
}
