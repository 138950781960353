import { useEffect, useState, Fragment } from 'react'
import useForm from '../../hooks/useForms'

import SubmissionReviewFields from './SubmissionReviewFields'
import { useUpdateSubmission } from 'src/app/hooks/mutations'

import { useGetCurrentAgencyContext } from 'src/app/hooks/queries'
import { mapUpdateFormToSubmission } from 'src/utils/dataMapping'
import FormError, { cleanGraphQLError } from './FormError'

import { Box, Flex } from 'theme-ui'
import { useToast } from 'src/components/toasts'
import Button from '../Shared/Elements/Button'
import Form from '../Shared/Elements/Form'
import { RolesOnly } from 'src/app/services/auth'
import generateCustomHideFieldsBasedOnCategory from 'src/utils/generateCustomHideFieldsBasedOnCategory'
import pick from 'lodash/pick'
import InlineTooltip from '../Shared/InlineTooltip'
import { Overline } from '../Shared'

const SubmissionUpdateForm = ({ submission, onCancel }) => {
  const { add } = useToast()
  const [render, setRender] = useState(false)
  const {
    updateOneSubmission,
    error: mutationError,
    loading: mutationLoading
  } = useUpdateSubmission()
  const defaultValues = {
    ...submission,
    ...submission.type,
    ...submission.weather,
    dispatches: submission.dispatches,
    involvedPartyInfo: submission.involvedPartyInfo,
    note: ''
  }

  const {
    values,
    isDirty,
    setValues,
    mergeValues,
    handleChange,
    handleSubmit,
    handleSelect
  } = useForm(onSubmit, defaultValues)

  useEffect(() => {
    setValues(defaultValues, true)
    // eslint-disable-next-line
    setRender(true)
  }, [submission])

  const { currentAgency } = useGetCurrentAgencyContext()
  async function onSubmit() {
    try {
      const data = await mapUpdateFormToSubmission(
        pick(values, Object.keys(isDirty)),
        currentAgency
      )
      const res = await updateOneSubmission({
        variables: {
          data,
          where: {
            id: submission.id
          }
        },
        refetchQueries:
          data?.additionalIncidents?.length > 0
            ? ['GetSubmission', 'GetEvents']
            : ['GetSubmission']
      })

      if (!res.errors) {
        add({ content: 'Incident updated!', color: 'success' })
      } else {
        add({
          content: cleanGraphQLError(res.errors[0]),
          color: 'danger'
        })
      }
    } catch (e) {
      add({ color: 'danger', content: cleanGraphQLError(e) })
      console.error(e)
      throw new Error(e)
    }
  }

  if (!render) {
    return <Fragment />
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Flex
        sx={{
          flexWrap: 'wrap',
          justifyContent: 'stretch',
          width: '100%',
          padding: '0px',
          mb: '20px'
        }}
      >
        {isDirty && (
          <Flex
            sx={{
              alignItems: 'center',
              gap: '8px',
              justifyContent: 'flex-end',
              width: '100%',
              bg: 'secondaryLight',
              p: '5px',
              borderRadius: '4px'
            }}
          >
            <Box
              sx={{
                mb: '0px',
                fontSize: '12px',
                opacity: '0.8'
              }}
            >
              Unsaved changes...
            </Box>
            <Button
              type="submit"
              size="sm"
              variant={isDirty ? 'danger' : 'secondary'}
              disabled={mutationLoading || !isDirty}
              icon={mutationLoading && 'spinner'}
            >
              Save
            </Button>
          </Flex>
        )}
        <SubmissionReviewFields
          values={values || {}}
          handleChange={handleChange}
          currentAgency={currentAgency}
          handleSelect={handleSelect}
          alwaysShowAssignmentInputs={true}
          hideFields={[
            'vehicles',
            'note',
            'files',
            'forms',
            'missingUnit',
            'position'
          ].concat(
            generateCustomHideFieldsBasedOnCategory(
              currentAgency.settings.hideIncidentSubmitFields,
              values?.type?.label
            )
          )}
          mergeValues={mergeValues}
          useTodaysPosition={false}
        />
        <RolesOnly
          roles={['USER', 'ADMIN', 'DISPATCHER', 'SUPERVISOR']}
        >
          <Flex
            sx={{
              width: '100%',
              justifyContent: 'flex-end',
              flexWrap: 'wrap'
            }}
          >
            {onCancel && (
              <Button
                variant="linkGray"
                onClick={onCancel}
                style={{ marginRight: 5 }}
              >
                Cancel
              </Button>
            )}
            <Button
              type="submit"
              variant={isDirty ? 'danger' : 'secondary'}
              disabled={mutationLoading || !isDirty}
              icon={mutationLoading && 'spinner'}
            >
              Save Changes
            </Button>

            <FormError error={mutationError} />
          </Flex>
        </RolesOnly>
      </Flex>
    </Form>
  )
}

export default SubmissionUpdateForm
