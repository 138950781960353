import React, { Fragment } from 'react'
import { useNetworkStatus } from 'src/app/hooks/useNetworkStatus'
import InlineTooltip from './InlineTooltip'

const OfflineDisabledInlineTip = () => {
  const isOnline = useNetworkStatus()
  return isOnline ? (
    <Fragment />
  ) : (
    <InlineTooltip
      sx={{
        mb: '35px'
      }}
      variant="danger"
      header={'Not Offline Compatible'}
      tip="This page will only include data that was loaded prior to going offline.  Actions on this page will not be saved."
      placement="top"
    >
      <span>Offline Disabled</span>
    </InlineTooltip>
  )
}

export default OfflineDisabledInlineTip
