/** @jsxImportSource theme-ui */
import { css, Global } from '@emotion/react'

import { Box, Flex, useThemeUI } from 'theme-ui'

const UptimeWidget = p => {
  const { colorMode } = useThemeUI()
  return (
    <Flex sx={{ position: 'relative' }}>
      <iframe
        src={`https://watchtower.betteruptime.com/badge?theme=${colorMode}`}
        width={p.showText ? '250' : '25'}
        height="30"
        frameBorder="0"
        scrolling="no"
      ></iframe>
    </Flex>
  )
}

export default UptimeWidget
