/** @jsxImportSource theme-ui */
import DOMPurify from 'dompurify'
import Elipses from './Elipses'
import ReactHtmlParser, {
  convertNodeToElement
} from 'react-html-parser'

const transform = node => {
  if (node.name === 'a') {
    node.attribs.target = '_blank'
    node.attribs.rel = 'noopener noreferrer'
    return convertNodeToElement(node)
  }
}

export const RichTextRender = ({
  mw,
  maxLines,
  noHover,
  text,
  ...p
}) => {
  if (mw || maxLines) {
    return (
      <Elipses noHover={noHover} maxLines={maxLines} mw={mw}>
        <div
          // Quill handles this with classnames
          className="ql-editor"
          {...p}
        >
          {ReactHtmlParser(DOMPurify.sanitize(text), { transform })}
        </div>
      </Elipses>
    )
  }
  return (
    <div
      // Quill handles this with classnames
      className="ql-editor"
      {...p}
    >
      {ReactHtmlParser(DOMPurify.sanitize(text), { transform })}
    </div>
  )
}
