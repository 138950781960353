export const FormPageTypes = {
  supervisor: {
    title: 'Supervisor Review',
    description:
      'Manage, create and edit your supervisor review forms.',
    moduleName: 'forms',
    color: 'info',
    accent: 'infoLight',
    icon: '',
    buttonText: 'Submit Review/Response',
    isBeta: true
  },
  medical: {
    title: 'Medical Form',
    description: 'Manage, create and edit your medical forms.',
    moduleName: 'medicalForms',
    color: 'danger',
    accent: 'dangerLight',
    icon: 'heart',
    buttonText: 'Submit Medical Form',
    isBeta: true
  },
  general: {
    title: 'General Form',
    description: 'Manage, create and edit your general forms.',
    moduleName: 'forms'
  }
}
