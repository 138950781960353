function Incidents(props) {
  const title = props.title || 'medical clipboard'

  return (
    <svg
      height={props.height || '48'}
      width={props.width || '48'}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g>
        <path
          d="M43,47H5c-0.552,0-1-0.448-1-1V4c0-0.552,0.448-1,1-1h38c0.552,0,1,0.448,1,1v42C44,46.552,43.552,47,43,47z "
          fill="#C6A279"
        />
        <path
          d="M39,43H9c-0.552,0-1-0.448-1-1V8c0-0.552,0.448-1,1-1h30c0.552,0,1,0.448,1,1v34C40,42.552,39.552,43,39,43z "
          fill="#E6E6E6"
        />
        <path
          d="M32,11h-4c-0.552,0-1-0.448-1-1c0-1.654-1.346-3-3-3s-3,1.346-3,3c0,0.552-0.448,1-1,1h-4 c-0.552,0-1-0.448-1-1V2c0-0.552,0.448-1,1-1h16c0.552,0,1,0.448,1,1v8C33,10.552,32.552,11,32,11z"
          fill="#B3B3B3"
        />
        <path
          d="M29,24h-3v-3c0-0.552-0.448-1-1-1h-2c-0.552,0-1,0.448-1,1v3h-3c-0.552,0-1,0.448-1,1v2c0,0.552,0.448,1,1,1 h3v3c0,0.552,0.448,1,1,1h2c0.552,0,1-0.448,1-1v-3h3c0.552,0,1-0.448,1-1v-2C30,24.448,29.552,24,29,24z"
          fill="#E86C60"
        />
      </g>
    </svg>
  )
}

export default Incidents
