/** @jsxImportSource theme-ui */

import { Flex, Box } from 'theme-ui'
import ListGroupItem from '../Elements/ListGroupItem'

import OtherFormListButton from './OtherFormButtonList'
import { FormSubmitRendererModalButton } from '../../Operations/Forms/formSubmitRenderer/FormSubmitRendererModalButton'
import AttachedFormResponseItem from './AttachedFormResponseItem'

const FormAttachmentButton = ({
  onAttach,
  containerProps,
  featuredForms,
  form,
  onFinish,
  ...rest
}) => {
  return (
    <>
      <Flex
        sx={{
          position: 'relative',
          justifyContent: 'space-between',

          alignItems: 'center'
        }}
        {...containerProps}
      >
        <p sx={{ maxWidth: '50%', fontWeight: '600', margin: '0px' }}>
          {form?.name || ' Additional Forms'}
        </p>
        {form ? (
          <FormSubmitRendererModalButton
            formId={form.id}
            submitToastText="Form Attached"
            onFormSubmit={onAttach}
            modalButtonProps={{
              buttonProps: {
                sx: {
                  minWidth: '100px'
                }
              },

              buttonLabel: 'Add +'
            }}
            onFinish={onFinish}
            {...rest}
          />
        ) : (
          <OtherFormListButton
            onFinish={onFinish}
            onAttach={onAttach}
            featuredForms={featuredForms}
            variant="primary"
            text="Add Form +"
            {...rest}
          />
        )}
      </Flex>
    </>
  )
}

const FormAttachmentButtonList = ({
  formResponses,
  featuredForms,
  onUpdate,
  onDetach,
  onAttach,
  fetchFormResponse,

  ...props
}) => {
  const otherFormResponses = formResponses?.filter(response => {
    const formId = response?.form?.id || response?.formId
    return !featuredForms?.map(form => form.id).includes(formId)
  })

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'stretch',
        gap: '10px'
      }}
    >
      {featuredForms?.map((form, i) => {
        const resps = formResponses?.filter(response => {
          const formId = response?.form?.id || response?.formId
          return formId === form.id
        })
        return (
          <ListGroupItem
            sx={{
              border: '1px dashed',
              borderColor: 'gray400',
              borderRadius: '10px',
              py: '20px'
            }}
          >
            <FormAttachmentButton
              key={i}
              form={form}
              onAttach={onAttach}
              {...props}
            />
            {resps?.length > 0 && (
              <Box sx={{ mt: '15px' }}>
                {resps.map((response, i) => (
                  <AttachedFormResponseItem
                    key={i}
                    fetchFormResponse={fetchFormResponse}
                    response={response}
                    onDetach={onDetach}
                    onUpdate={onUpdate}
                    onFinish={props?.onFinish}
                  />
                ))}
              </Box>
            )}
          </ListGroupItem>
        )
      })}

      {(otherFormResponses?.length > 0 ||
        featuredForms?.length < 1) && (
        <ListGroupItem>
          <FormAttachmentButton
            featuredForms={featuredForms?.map(ff => ff.id)}
            onAttach={onAttach}
            {...props}
          />
          {otherFormResponses?.map((response, i) => (
            <AttachedFormResponseItem
              key={i}
              fetchFormResponse={fetchFormResponse}
              response={response}
              onDetach={onDetach}
              onUpdate={onUpdate}
              includeFormName
            />
          ))}
        </ListGroupItem>
      )}
      {featuredForms?.length > 0 && !otherFormResponses?.length && (
        <OtherFormListButton onAttach={onAttach} {...props} />
      )}
    </Flex>
  )
}

export default FormAttachmentButtonList
