import { useState, useEffect, Fragment, useMemo } from 'react'
import { useToast } from 'src/components/toasts'
import useForm from 'src/app/hooks/useForms'
import { useUpdateSubmission } from 'src/app/hooks/mutations'
import {
  mapUpdateFormToSubmission,
  mapValuesToSubmission,
  createActivtyTypeLabel
} from 'src/utils/dataMapping'
import uniqWith from 'lodash/uniqWith'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import uniq from 'lodash/uniq'

import SubmissionReviewFields from '../Forms/SubmissionReviewFields'
import TappableSearchInput from '../Forms/TappableSearchInput'
import BreadCrumb from '../Shared/BreadCrumb'
import FormError, { cleanGraphQLError } from '../Forms/FormError'
import isEmpty from 'lodash/isEmpty'
import { Box, Flex } from 'theme-ui'

import ModalButton from '../Shared/ModalButton'
import {
  useGetCurrentAgencyContext,
  useGetTodaysAssignment,
  useGetTodaysDispatcher
} from 'src/app/hooks/queries'
import {
  RevertAllDispatchButton,
  RevertDispatchButton
} from '../Dispatch/RevertDispatchCloseButtons'

import Button from '../Shared/Elements/Button'
import Label from '../Shared/Elements/Label'
import Form from '../Shared/Elements/Form'

import handleSortOrder from 'src/utils/handleSortOrder'
import RelatedIncidentPad from './RelatedIncidentPad'
import StickyButton from '../Shared/StickyButton'
import { usePromptOnNavigate } from 'src/app/hooks/usePromptOnNavigate'
import generateCustomHideFieldsBasedOnCategory from 'src/utils/generateCustomHideFieldsBasedOnCategory'
import { useNavigate } from 'react-router'
import { useSubmitNewSubmission } from 'src/app/hooks/useSubmitNewSubmission'

export const QuickSurvey = ({
  preFilledSubmission,
  onFinish,
  stickySubmit = true,
  typeOnly: typeOnlyProp,
  typeOnlyWithPad,
  buttonOptions,
  extendedQuickSurvey,
  includeResponseInputs,
  useTodaysPosition,
  isDispatchSub,
  hideInvolvedParty,
  numPadTitle,
  hideResetForm,
  dispatchId,
  dispatchSubmissionId,
  customOnSubmit,
  submitButtonText,
  hideFields = ['missingUnit'],
  includeHiddenStats,
  hideStat,
  mutationOptions = {}
}) => {
  const {
    currentAgency,
    topLevelCategories,
    positions,
    locations
  } = useGetCurrentAgencyContext()
  const typeOnly = typeOnlyProp || typeOnlyWithPad
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = useState(0)
  const [skippedSteps, setSkipedSteps] = useState([])
  const [currentOptions, setCurrentOptions] = useState([])
  const { add } = useToast()
  const { data: todaysAssignment } = useGetTodaysAssignment()
  const { id } = useGetTodaysDispatcher()
  const [responderIsLocation, setResponderIsLocation] = useState(
    false
  )

  const cachedLocation = get(todaysAssignment, 'todaysLocation', null)

  const isUpdatingSub = preFilledSubmission && preFilledSubmission.id

  const {
    values,
    setValues,
    handleChange,
    handleSelect,
    handleSubmit,
    mergeValues,
    isDirty,
    setIsDirty
  } = useForm(
    customOnSubmit
      ? customOnSubmit
      : isUpdatingSub
      ? onSubmitUpdateSubmission
      : onSubmitCreateSubmission,
    { ...preFilledSubmission }
  )

  usePromptOnNavigate({
    message:
      'Are you sure you want to leave this page? You may lose unsaved data.',
    path: '/agency/submission',
    skip: !isDirty
  })
  const {
    createSubmission,
    submission: newSubmission,
    loading: mutationLoading
  } = useSubmitNewSubmission({ ...mutationOptions })

  const {
    updateOneSubmission,
    submission: updatedSubmission,
    loading: updateMutationLoading
  } = useUpdateSubmission({ ...mutationOptions })

  function clearForm({ all, values, resetQuickStats }) {
    if (all) {
      setIsDirty(false)
      setValues(() => ({}), true)
      setSkipedSteps([])
    } else {
      setValues(prev => {
        let newPrev

        if (resetQuickStats) {
          //parse out keys that incldue quick stat info
          newPrev = Object.keys(prev)
            .filter(
              key =>
                !key.includes('quickStats') &&
                !key.includes('involvedPartyInfo')
            )
            .reduce((obj, key) => {
              obj[key] = prev[key]
              return obj
            }, {})
        }

        const actualPrev = newPrev || prev

        return { ...actualPrev, ...values }
      })
    }

    setActiveStep(0)
    setResponderIsLocation(false)
  }
  async function onSubmitUpdateSubmission() {
    const responders = get(todaysAssignment, 'todaysResponders', [])
    const primaryResponder = responders.find(i => i.primary)
    const noteWriter = primaryResponder || responders[0] || {}
    const noteWriterResponderId = get(noteWriter, 'responderId')
    const noteWriterPositionId = get(noteWriter, 'positionId')

    const args = await mapUpdateFormToSubmission(
      {
        noteWrittenBy:
          isDispatchSub && id ? id : noteWriterResponderId || id,
        noteWrittenByPosition: noteWriterPositionId,
        noteWasDispatch: isDispatchSub,
        ...values
      },
      currentAgency
    )
    const updateSubmissionRes = await updateOneSubmission({
      variables: {
        where: {
          id: preFilledSubmission.id
        },
        data: args
      }
    })

    if (updateSubmissionRes?.errors) {
      add({
        content: cleanGraphQLError(updateSubmissionRes.errors[0]),
        color: 'danger'
      })
      return
    }

    if (updateSubmissionRes?.data) {
      clearForm({ all: true })

      add({ content: 'Incident updated!', color: 'success' })
      onFinish && onFinish()
    }
  }

  async function onSubmitCreateSubmission() {
    const responders = get(todaysAssignment, 'todaysResponders', [])
    const primaryResponder = responders.find(i => i.primary)
    const noteWriter = primaryResponder || responders[0] || {}
    const noteWriterResponderId = get(noteWriter, 'responderId')
    const noteWriterPositionId = get(noteWriter, 'positionId')

    try {
      const submissionVariables = await mapValuesToSubmission(
        {
          ...values,
          noteWrittenBy:
            isDispatchSub && id ? id : noteWriterResponderId || id,
          noteWrittenByPosition: noteWriterPositionId,
          noteWasDispatch: isDispatchSub
        },
        currentAgency
      )

      const res = await createSubmission({
        variables: {
          data: submissionVariables
        }
      })

      // CREATE SUBMISSION ERROR LOGIC
      if (res && res.errors) {
        add({
          content: cleanGraphQLError(res.errors[0]),
          color: 'danger'
        })
        return
      }

      if (res.offline) {
        clearForm({ all: true })
        add({
          content: 'Stored Offline Successfully',
          color: 'success'
        })
        onFinish && onFinish()
      } else if (res.data) {
        clearForm({ all: true })
        add({ content: 'Incident submitted!', color: 'success' })
        onFinish && onFinish()
      }
    } catch (e) {
      console.error(e)
      add({
        content:
          'Error submitting incident. Please refresh and try again.  If the problem persists, please contact support.',
        color: 'danger'
      })
      throw new Error(e)
    }
  }

  const withoutHiddenActivities = useMemo(
    () =>
      currentAgency.activities.filter(i => {
        if (i.subLabelOne === hideStat) {
          return false
        }

        if (includeHiddenStats) {
          return true
        }

        return !i.hideFromQuickSurvey
      }),

    [currentAgency.activities, hideStat, includeHiddenStats]
  )

  const uniqLabelOneActivities = topLevelCategories

  useEffect(() => {
    if (!currentAgency) {
      return
    }

    let activityTypesMinusHidden = withoutHiddenActivities
    let uniqActivities = uniqLabelOneActivities

    function checkAndHandleFinalOptions(options = []) {
      const cleanOptions =
        options && options.filter(opt => opt != null || undefined)

      const categoryFullySelected =
        values['subLabelThree'] ||
        ((values['subLabelTwo'] || values['subLabelOne']) &&
          isEmpty(cleanOptions))
      return categoryFullySelected
    }

    const categoryFullySelected = checkAndHandleFinalOptions(
      currentOptions
    )

    if (
      !cachedLocation &&
      includeResponseInputs &&
      !get(values, 'location.position') &&
      !skippedSteps.includes('locationInput') &&
      categoryFullySelected &&
      !responderIsLocation
    ) {
      setActiveStep('locationInput')
      return
    }

    if (values['subLabelOne']) {
      setActiveStep(1)
      setCurrentOptions(
        uniq(
          handleSortOrder(
            activityTypesMinusHidden.filter(
              opt =>
                opt.subLabelTwo &&
                opt.subLabelOne === values.subLabelOne
            )
          ).map(opt => opt.subLabelTwo)
        )
      )
    }

    if (values['subLabelTwo']) {
      setActiveStep(2)
      setCurrentOptions(
        uniq(
          handleSortOrder(
            activityTypesMinusHidden.filter(
              opt =>
                opt.subLabelThree &&
                opt.subLabelOne === values.subLabelOne &&
                opt.subLabelTwo === values.subLabelTwo
            )
          ).map(opt => opt.subLabelThree)
        )
      )
    }

    if (categoryFullySelected) {
      setActiveStep(typeOnlyWithPad ? 'numPad' : 3)
      return
    }

    const hasAddedDispatch =
      values['dispatches'] && values['dispatches'].some(d => d.unit)

    if (isEmpty(values.subLabelOne)) {
      if (
        !skippedSteps.includes('responderInput') &&
        includeResponseInputs &&
        !hasAddedDispatch &&
        !get(todaysAssignment, 'todaysResponders[0].positionId')
      ) {
        setActiveStep('responderInput')
      } else {
        setActiveStep(0)
      }
      setCurrentOptions(uniqActivities)

      return
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values,
    activeStep,
    currentAgency,
    newSubmission,
    updatedSubmission,
    skippedSteps,
    todaysAssignment
  ])

  const currentLabel = createActivtyTypeLabel(
    values['subLabelOne'],
    values['subLabelTwo'],
    values['subLabelThree']
  )

  const { hasQuickStats, quickStatsToShow } = extendedQuickSurvey
    ? calculateQuickStats(currentLabel, values, currentAgency)
    : {}

  const activePositions = positions
  const activeLocations = locations

  const colorKey = currentAgency.activities
    .map(o => ({
      label: o.label,
      color: o.color
    }))
    .sort((a, b) => {
      const countA = (a.label?.match(/\//g) || []).length // count number of '/'
      const countB = (b.label?.match(/\//g) || []).length
      return countA - countB // sort by ascending order of count
    })

  const formId = values && values.id ? values.id : 'newSubmissionForm'

  const onFormSubmit = e => {
    if (e.target.keyCode === 13) {
      return
    }

    handleSubmit(e)
  }

  return (
    <Form
      id={formId}
      sx={{
        pb: 120,
        maxWidth: !hasQuickStats ? '700px' : '',
        margin: 'auto'
      }}
    >
      <Flex
        sx={{
          position: 'relative',
          flexWrap: 'wrap'
        }}
      >
        <Box
          sx={{
            width: !extendedQuickSurvey
              ? '100%'
              : [
                  '100%',
                  null,
                  null,
                  null,
                  null,
                  hasQuickStats ? '33.33%' : '100%'
                ]
          }}
        >
          <BreadCrumb
            items={Object.entries(values)
              .map(
                (val, key) => val[0].includes('subLabel') && val[1]
              )
              .filter(i => i)}
            activeItem={activeStep}
            clearLabels={() => {
              clearForm({
                all: false,
                resetQuickStats: !isUpdatingSub && true,
                values: {
                  subLabelOne: null,
                  subLabelTwo: null,
                  subLabelThree: null
                }
              })
              setActiveStep(0)
            }}
            clearForm={
              !isUpdatingSub && !hideResetForm
                ? () => {
                    clearForm({ all: true })
                  }
                : false
            }
            actionButton={
              <>
                {dispatchId ? (
                  <RevertDispatchButton
                    dispatchId={dispatchId}
                    onFinish={onFinish}
                    style={{
                      marginRight: '5px'
                    }}
                  />
                ) : dispatchSubmissionId ? (
                  <RevertAllDispatchButton
                    submissionId={dispatchSubmissionId}
                    onFinish={onFinish}
                  />
                ) : (
                  <Fragment />
                )}
                {values['subLabelOne'] && extendedQuickSurvey && (
                  <ModalButton
                    buttonProps={{
                      variant: 'primary'
                    }}
                    buttonLabel="Add Related +"
                  >
                    {({ toggle }) => (
                      <RelatedQuickSurvey
                        currentAgency={currentAgency}
                        onFinish={toggle}
                        setValues={setValues}
                        currentLabel={currentLabel}
                      />
                    )}
                  </ModalButton>
                )}
              </>
            }
          />

          {activeStep === 'responderInput' && (
            <TappableSearchInput
              label="Which resource/location responded?"
              field="responderInput"
              multiSelect={true}
              onSkip={() =>
                setSkipedSteps([...skippedSteps, 'responderInput'])
              }
              onSelect={(e, i) => {
                setResponderIsLocation(i.isLocation)
                setValues(values => ({
                  ...values,
                  ...(i.isLocation && {
                    location: {
                      position: {
                        ...i
                      }
                    }
                  }),
                  dispatches: [
                    {
                      unit: {
                        id: i.value,
                        name: i.label
                      }
                    }
                  ]
                }))
              }}
              {...categorizeOptionsProps(
                activePositions.filter(i => !i.locationOnly),
                currentAgency.positionCategories
              )}
            />
          )}

          {activeStep === 0 && (
            <TappableSearchInput
              label="What happened?"
              field="subLabelOne"
              onSelect={handleChange}
              options={currentOptions}
              optionColorKey={colorKey}
            />
          )}

          {activeStep === 1 && (
            <TappableSearchInput
              label="What happened?"
              field="subLabelTwo"
              optionColorKey={colorKey}
              fuzzyColorKey={true}
              onSelect={handleChange}
              options={currentOptions}
              currentLabel={currentLabel}
            />
          )}
          {activeStep === 2 && (
            <>
              <TappableSearchInput
                label="What happened?"
                field="subLabelThree"
                optionColorKey={colorKey}
                fuzzyColorKey={true}
                onSelect={handleChange}
                options={currentOptions}
                currentLabel={currentLabel}
              />
            </>
          )}

          {activeStep === 'numPad' && (
            <Flex sx={{ mb: 15, justifyContent: 'center' }}>
              <RelatedIncidentPad
                quickStatInfo={{
                  label: currentLabel
                }}
                hideInvolvedParty={hideInvolvedParty}
                handleChange={handleChange}
                values={values}
                numPadTitle={numPadTitle}
                handleSelect={handleSelect}
                nonDeletedIP={
                  values[currentLabel + 'involvedPartyInfo'] &&
                  values[currentLabel + 'involvedPartyInfo'].filter(
                    ip => !ip.deleted
                  )
                }
                involvedPartyLabel={
                  currentLabel + 'involvedPartyInfo'
                }
                setValues={setValues}
              />
            </Flex>
          )}

          {typeOnly &&
            (typeOnlyWithPad
              ? activeStep === 'numPad'
              : activeStep === 3) && (
              <StickyButton
                stickySubmit={typeOnly ? false : stickySubmit}
                type="button"
                onClick={onFormSubmit}
                icon={
                  (mutationLoading || updateMutationLoading) &&
                  'spinner'
                }
                {...buttonOptions}
              >
                {submitButtonText || 'Submit'}
              </StickyButton>
            )}
          {activeStep === 'locationInput' && (
            <TappableSearchInput
              label="Where did it happen?"
              field="locationInput"
              onSkip={() =>
                setSkipedSteps([...skippedSteps, 'locationInput'])
              }
              onSelect={(e, i) => {
                setValues(values => ({
                  ...values,
                  location: {
                    position: {
                      ...i
                    }
                  }
                }))
              }}
              {...categorizeOptionsProps(
                activeLocations,
                currentAgency.positionCategories
              )}
            />
          )}
          {!typeOnly && activeStep === 3 && (
            <SubmissionReviewFields
              values={values}
              handleChange={handleChange}
              handleSelect={handleSelect}
              mergeValues={mergeValues}
              dispatchId={dispatchId}
              hideFields={hideFields.concat(
                generateCustomHideFieldsBasedOnCategory(
                  currentAgency.settings.hideIncidentSubmitFields,
                  currentLabel
                )
              )}
              submittable
              currentAgency={currentAgency}
              useTodaysPosition={useTodaysPosition}
              loading={mutationLoading || updateMutationLoading}
              useNoteTemplate={!isUpdatingSub}
              buttonOptions={{
                block: true,
                stickySubmit,
                onClick: onFormSubmit
              }}
            />
          )}
        </Box>

        {extendedQuickSurvey && hasQuickStats && (
          <Box
            sx={{
              width: ['100', null, null, '66.66%'],
              bg: 'gray100',
              p: ['0px', '10px'],
              pb: '30px'
            }}
          >
            <Flex
              sx={{
                p: 10,
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Box sx={{ flexBasis: ['100', null, null, '66.66%'] }}>
                <h5>Other Incidents On This Call</h5>
                <p>
                  All additional incidents submitted here will be
                  filed as a single call for service. Each incident
                  can be edited individually after submission.
                </p>
              </Box>
              <ModalButton buttonLabel="Add Related +">
                {({ toggle }) => (
                  <RelatedQuickSurvey
                    currentAgency={currentAgency}
                    onFinish={toggle}
                    setValues={setValues}
                    currentLabel={currentLabel}
                  />
                )}
              </ModalButton>
            </Flex>
            <Flex
              sx={{
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                flexDirection: 'row',
                flexWrap: 'wrap'
              }}
            >
              {quickStatsToShow.map((i, k) => {
                const quickStatInfo = currentAgency.activities.find(
                  a => a.id === i.id
                )

                if (!quickStatInfo.quickStat && !i.quickStat) {
                  return <Fragment />
                }

                const involvedPartyLabel =
                  quickStatInfo.label + 'involvedPartyInfo'
                const nonDeletedIP =
                  values[involvedPartyLabel] &&
                  values[involvedPartyLabel].filter(ip => !ip.deleted)

                return (
                  <Box sx={{ p: 10, maxWidth: '200px' }} key={k}>
                    <Box
                      sx={{
                        bg: quickStatInfo?.color,
                        height: '3px',
                        width: '20px',
                        mb: 2
                      }}
                    />
                    <Label
                      sx={{
                        minHeight: '42px',
                        fontSize: '0.875rem',
                        display: 'flex',
                        alignItems: 'flex-start'
                      }}
                    >
                      {i.existing ? (
                        <ModalButton
                          type="button"
                          buttonProps={{
                            variant: 'link',
                            sx: {
                              padding: '0px'
                            }
                          }}
                          buttonLabel={quickStatInfo.label}
                          modalHeader="Are you sure you want to leave this incident?"
                          renderModalActions={() => (
                            <Button
                              onClick={() =>
                                navigate(
                                  `/agency/stats?incidentId=${i.existing}`
                                )
                              }
                            >
                              Yes
                            </Button>
                          )}
                        />
                      ) : (
                        quickStatInfo.label
                      )}
                    </Label>
                    <RelatedIncidentPad
                      quickStatInfo={quickStatInfo}
                      handleChange={handleChange}
                      values={values}
                      handleSelect={handleSelect}
                      nonDeletedIP={nonDeletedIP}
                      involvedPartyLabel={involvedPartyLabel}
                      setValues={setValues}
                    />
                    {i.existing &&
                      !values['quickStats' + quickStatInfo.label] && (
                        <FormError customError="To remove this incident you must archive it by clicking the blue link above." />
                      )}
                  </Box>
                )
              })}
            </Flex>
          </Box>
        )}
      </Flex>
    </Form>
  )
}

const RelatedQuickSurvey = ({
  currentAgency,
  setValues,
  onFinish,
  currentLabel,
  ...props
}) => {
  return (
    <QuickSurvey
      stickySubmit={false}
      typeOnlyWithPad
      hideResetForm
      includeHiddenStats
      extendedQuickSurvey={false}
      submitButtonText={'Add to Submission'}
      customOnSubmit={e => {
        const { subLabelOne, subLabelTwo, subLabelThree, ...rest } = e
        const label = createActivtyTypeLabel(
          subLabelOne,
          subLabelTwo,
          subLabelThree
        )

        // Don't add quick stat if already being submitted as main category
        if (label === currentLabel) {
          onFinish && onFinish()
          return
        }

        setValues(prev => {
          const quickStatInfo = currentAgency.activities.find(
            a => a.label === label
          )

          return {
            ...prev,
            ...rest,
            relatedSubmissions: [
              {
                existing: false,
                type: { ...quickStatInfo },
                count: 1
              },
              ...(prev.relatedSubmissions
                ? prev.relatedSubmissions
                : [])
            ]
          }
        })
        onFinish && onFinish()
      }}
      {...props}
    />
  )
}

// // //
//  Utility Functions Specific to QuickSurvey
// // //

const categorizeOptionsProps = (opts, positionCategories) => {
  if (!positionCategories[0]) {
    return {
      options: opts.map(i => ({
        label: i.name,
        value: i.id,
        isLocation: !i.dispatchable
      })),
      optionsCategorized: false
    }
  }

  return {
    options: Object.entries(
      groupBy(opts, opt => opt.category && opt.category.id)
    ).map(function([key, items]) {
      const currentCat = positionCategories.filter(
        pc => pc.id === key
      )[0]

      return {
        category: currentCat,
        options: items.map(opt => ({
          label: opt.name,
          value: opt.id,
          isLocation: !opt.dispatchable
        }))
      }
    }),
    optionsCategorized: true
  }
}

function calculateQuickStats(currentLabel, values, currentAgency) {
  const exactType = values.subLabelOne
    ? currentAgency.activities.find(
        i =>
          i.label ===
          createActivtyTypeLabel(
            values.subLabelOne,
            values.subLabelTwo,
            values.subLabelThree
          )
      )
    : null

  const parentType = values.subLabelOne
    ? currentAgency.activities.find(
        i => i.label === values.subLabelOne
      )
    : null

  const quickStatChildren = [
    ...(exactType?.quickStatChildren || []),
    ...(parentType?.quickStatChildren || [])
  ]

  if (
    (!quickStatChildren || quickStatChildren.length === 0) &&
    (!values?.relatedSubmissions ||
      values.relatedSubmissions.length === 0)
  ) {
    return {}
  }

  const extendedStatsToShow = values.relatedSubmissions
    ? values.relatedSubmissions.map(i => {
        return {
          ...i,
          ...i.type,
          quickStat: true,
          existing: i.existing === false ? false : i.id
        }
      })
    : []

  const quickStatsToShow = uniqWith(
    [...extendedStatsToShow].concat(quickStatChildren || []),
    (optA, optB) => optA.id === optB.id
  ).filter(i => {
    const quickStatInfo = currentAgency.activities.find(
      a => a.id === i.id
    )

    return i.quickStat && currentLabel !== quickStatInfo.label
  })
  const hasQuickStats = quickStatsToShow.length > 0

  return {
    hasQuickStats,
    quickStatsToShow
  }
}
