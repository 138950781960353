/** @jsxImportSource theme-ui */

import { useEffect, useState } from 'react'
import { CenteredContent, Space } from '../components/'
import qs from 'qs'

import { gql, useQuery } from '@apollo/client'

import useForm from '../hooks/useForms'
import { useMutation } from '@apollo/client'

import FormError from '../components/Forms/FormError'
import get from 'lodash/get'
import { useHandleAuthTokens } from '../services/auth'

import { Window } from 'src/utils'
import {
  GET_LOGIN_METHODS_BY_EMAIL,
  useGetCurrentAgencyContext
} from '../hooks/queries'
import Button from '../components/Shared/Elements/Button'
import Label from '../components/Shared/Elements/Label'
import FormGroup from '../components/Shared/Elements/FormGroup'
import Form from '../components/Shared/Elements/Form'
import InputGroupText from '../components/Shared/Elements/InputGroupText'

import Icon from '../components/Shared/Icon'
import { Box } from 'theme-ui'
import { MAGIC_LINKS } from './OauthScreen'
import Spinner from 'src/images/icons/Spinner'
import { PasswordInput } from '../components/Shared/Elements/Input'
import PhoneInput from '../components/Shared/Forms/PhoneInput'
import { Link, useNavigate } from 'react-router-dom'
import { useMFAFlow } from '../hooks/useMFAFlow'
import MFABranch from '../components/LoginScreen/MFABranch'
import isEmpty from 'lodash/isEmpty'

import { useToast } from 'src/components/toasts'
import { AUTH_PAYLOAD_FRAGMENT } from '../hooks/fragments'

const CONFIRM_ACCOUNT = gql`
  mutation SetupAccount($password: String, $phoneNumber: String) {
    completeInvite(password: $password, phoneNumber: $phoneNumber) {
      ...AuthPayloadFragment
    }
  }
  ${AUTH_PAYLOAD_FRAGMENT}
`

const SetupAccount = () => {
  const { add } = useToast()
  const navigate = useNavigate()
  const { token } = qs.parse(get(Window.location, 'search', ''), {
    ignoreQueryPrefix: true
  })
  const { mfa, triggerMfa, mfaOptions } = useMFAFlow(
    async (newToken, newSession, agency) => {
      return await afterVerification(
        newToken,
        newSession,
        agency,
        true
      )
    }
  )
  const [allowedLogins, setAllowedLogins] = useState()
  const {
    getCurrentAgency,
    loading: loadingUser,
    user
  } = useGetCurrentAgencyContext()

  const { loading: loadingLoginMethods } = useQuery(
    GET_LOGIN_METHODS_BY_EMAIL,
    {
      variables: {
        email: user?.email
      },
      skip: !user?.email,
      onCompleted: data => {
        setAllowedLogins(data.getAllowedLogins)
      }
    }
  )
  const [
    magicLink,
    {
      data: magicLinkData,
      loading: loadingMagicLink,
      error: magicLinkDataError
    }
  ] = useMutation(MAGIC_LINKS)
  const [completeInvite, { loading, error }] = useMutation(
    CONFIRM_ACCOUNT
  )
  const { handleTokens } = useHandleAuthTokens()
  const {
    values,
    handleErrors,
    errors,
    handleChange,
    setValues,
    handleSubmit
  } = useForm(onSubmit)

  async function onSubmit() {
    if (
      !values.newPassword &&
      (!values.phoneNumber || values.phoneNumber === 'skip')
    ) {
      return
    }

    const res = await completeInvite({
      variables: {
        ...(values.phoneNumber !== 'skip' && {
          phoneNumber: values.phoneNumber
        }),
        password: values.newPassword,
        verificationCode: token
      }
    })

    const newToken = res?.data?.completeInvite?.token
    const newSession = res?.data?.completeInvite?.session

    if (newToken) {
      await handleTokens(newToken, newSession)

      navigate(
        res?.data?.user?.role === 'READ_ONLY'
          ? '/agency'
          : '/agency/submission'
      )
    } else {
      add({
        color: 'danger',
        message: 'Error setting up account'
      })
    }
  }

  async function afterVerification(
    newToken,
    newSession,
    agency,
    phoneEntered
  ) {
    await handleTokens(newToken, newSession)
    getCurrentAgency()
    if (!agency) {
      navigate('/agency/onboarding')
    }
    if (phoneEntered) {
      setValues({
        ...values,
        phoneNumber: 'skip'
      })
    }
  }

  useEffect(() => {
    async function authenticateInviteToken() {
      const res = await magicLink({
        variables: {
          token
        }
      })
      const data = res?.data?.authenticateMagicLink
      const newToken = data?.token
      const newSession = data?.session
      const agency = data?.user?.agency
      const mfaEnabled = data?.mfaEnabled
      const mfaRequired = data?.mfaRequired
      const user_id = data?.user?.stytchMemberId
      const role = data?.user?.role
      const organization_id = data?.user?.agency?.organizationId

      if (newToken && (mfaEnabled || mfaRequired)) {
        triggerMfa({
          newToken,
          user_id,
          mfaRequired,
          mfaEnabled,
          organization_id
        })
      } else if (newToken) {
        await afterVerification(newToken, newSession, agency)
      }
    }
    if (!magicLinkData && token) {
      authenticateInviteToken()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  useEffect(() => {
    if (user && magicLinkDataError) {
      navigate('/agency')
    }

    if (!loadingUser && !user?.id && magicLinkDataError) {
      navigate(
        `/agency/auth-error?message=${magicLinkDataError.message}`
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, magicLinkDataError])

  const emailPasswordAllowed = allowedLogins?.some(
    login => login.type === 'password'
  )

  return (
    <CenteredContent>
      <Box
        variant="card"
        sx={{
          width: '100%',
          maxWidth: '600px',
          padding: '45px 20px'
        }}
      >
        {!token ? (
          <CenteredContent height={300}>
            <Icon icon="lost" color="warning" />
            <h3 sx={{ mt: '20px' }}>
              Looks like your invite is expired
            </h3>
            <p>
              Please reach out to your admin and ask for a new email
              invite.
            </p>
            <Space height={25} />

            <Link block color="link" size="md" to="/agency/login">
              Have an account? Login here.
            </Link>
          </CenteredContent>
        ) : !magicLinkData ||
          loadingUser ||
          loadingLoginMethods ||
          loadingMagicLink ? (
          <Spinner centered />
        ) : mfa && !values?.phoneNumber ? (
          <MFABranch mfa={mfa} mfaOptions={mfaOptions} />
        ) : !user ? (
          <Spinner centered />
        ) : (
          <>
            <h3
              sx={{
                mt: '20px'
              }}
            >
              Welcome to{' '}
              {magicLinkData?.authenticateMagicLink?.user?.agency
                ?.name || 'Watchtower'}
              !
            </h3>
            <p>Enter your information to complete set up.</p>
            <Space height={25} />
            <Form onSubmit={handleSubmit}>
              {emailPasswordAllowed && (
                <>
                  <FormGroup>
                    <Label>Add a password?</Label>
                    <PasswordInput
                      type="password"
                      passwordStrengthCheck
                      autoComplete="new-password"
                      name="newPassword"
                      onChange={handleChange}
                      value={values.newPassword}
                      onSuccess={res => {
                        if (values.newPassword) {
                          handleErrors(
                            'newPassword',
                            res,
                            'Does not meet requirements'
                          )
                        }
                      }}
                    />
                    <InputGroupText>
                      Without a password, you will can use a magic
                      link or Social account to login.
                    </InputGroupText>
                    {errors.newPassword && (
                      <FormError customError={errors.newPassword} />
                    )}
                  </FormGroup>
                  <Space height={10} />
                  {values?.phoneNumber !== 'skip' && (
                    <FormGroup>
                      <Label>Phone Number</Label>
                      <PhoneInput
                        id="phoneNumber"
                        name="phoneNumber"
                        defaultValue={values.phoneNumber}
                        value={values.phoneNumber}
                        onChange={val => {
                          setValues(prev => ({
                            ...prev,
                            phoneNumber: val
                          }))
                        }}
                      />
                      {errors.phoneNumber && (
                        <FormError customError={errors.phoneNumber} />
                      )}
                    </FormGroup>
                  )}
                  <Space height={10} />

                  <Button
                    disabled={
                      (values.newPassword && !isEmpty(errors)) ||
                      loading
                    }
                    href={
                      !values.newPassword &&
                      (!values.phoneNumber ||
                        values.phoneNumber === 'skip')
                        ? '/agency'
                        : null
                    }
                    block
                    variant="primary"
                    type="submit"
                    size="lg"
                    icon={loading && 'spinner'}
                  >
                    Continue to agency
                  </Button>
                </>
              )}

              {error && (
                <FormError
                  actionText={'Or skip and go to account'}
                  action={
                    error?.message?.includes(
                      'Looks like you took to long'
                    ) &&
                    (() => {
                      navigate('/agency/profile')
                    })
                  }
                  error={error.message}
                />
              )}
            </Form>
            <div
              sx={{
                my: '20px',
                fontSize: '12px',
                color: 'textSecondary',
                textAlign: 'center'
              }}
            >
              By signing up, I accept the{' '}
              <a
                href="https://www.yourwatchtower.com/watchtower-platform/terms-of-service"
                target={'_blank'}
                rel={'noopener noreferrer'}
              >
                Watchtower Terms of Service
              </a>{' '}
              and acknowledge the{' '}
              <a
                href="https://www.yourwatchtower.com/watchtower-platform/privacy-policy"
                target={'_blank'}
                rel={'noopener noreferrer'}
              >
                Privacy Policy
              </a>
              .
            </div>
            <Link
              sx={{
                display: 'block',
                textAlign: 'center',
                margin: 'auto'
              }}
              block
              color="link"
              size="md"
              to="/agency/login"
            >
              Have an account? Login here.
            </Link>
          </>
        )}
        <Space height={5} />
      </Box>
    </CenteredContent>
  )
}

export default SetupAccount
