function GChart(props) {
  const fill = props.fill || 'currentColor'
  const secondaryfill = props.secondaryfill || fill
  const title = props.title || 'g chart'

  return (
    <svg
      height={props.height || '64'}
      width={props.width || '64'}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{title}</title>
      <g fill={secondaryfill}>
        <rect
          height="18"
          width="14"
          fill={fill}
          rx="1"
          x="4"
          y="42"
        />
        <rect height="37" width="14" rx="1" x="24" y="23" />
        <rect
          height="56"
          width="14"
          fill={fill}
          rx="1"
          x="46"
          y="4"
        />
      </g>
    </svg>
  )
}

export default GChart
