import { useStytchMemberSession } from '@stytch/react/b2b'
import moment from 'moment'

const useSessionHoursRemaining = () => {
  const { session } = useStytchMemberSession()

  const sessionExpiringHours = moment(session?.expires_at)?.diff(
    moment(),
    'hours'
  )

  const sessionExpiringSoon =
    sessionExpiringHours < 15 && sessionExpiringHours > 0

  return {
    sessionExpiringHours,
    sessionExpiringSoon
  }
}

export default useSessionHoursRemaining
