/** @jsxImportSource theme-ui */

import { Fragment } from 'react'

import get from 'lodash/get'
import {
  useGetCurrentAgencyContext,
  useGetWeather
} from 'src/app/hooks/queries'
import { Box, Flex } from 'theme-ui'
import Button from '../Shared/Elements/Button'
import Input from '../Shared/Elements/Input'
import Label from '../Shared/Elements/Label'
import InlineTooltip from '../Shared/InlineTooltip'
import ModalButton from '../Shared/ModalButton'
import SurflineWeatherIcons, {
  weatherDataPoints
} from '../Shared/SurflineWeatherIcons'

export const EditWeatherLogModalForm = ({
  handleChange,
  values,
  showPreviewWeather,
  ...rest
}) => {
  const { currentAgency } = useGetCurrentAgencyContext()
  const timezone = currentAgency.timezone
  const {
    latitude,
    longitude,
    noaaTidesStation,
    surflineSpotId,
    useMeters,
    useCelsius
  } = currentAgency?.settings || {}
  const hasWeatherValues = Object.keys(values || {}).some(k =>
    weatherDataPoints.some(({ name }) => name === k)
  )
  const { data, loading, error } = useGetWeather(
    {
      latitude,
      longitude,
      noaaTidesStation,
      agencyTz: timezone,
      surflineSpotId,
      useMeters,
      useCelsius
    },
    {
      skip: hasWeatherValues || !showPreviewWeather
    }
  )

  return (
    <Flex sx={{ flexDirection: 'column', flexWrap: 'wrap' }}>
      {showPreviewWeather && loading ? (
        <Box sx={{ p: 1 }}>Checking conditions...</Box>
      ) : error ? (
        ''
      ) : (
        [
          showPreviewWeather ? (
            <SurflineWeatherIcons
              key={1}
              extended
              sx={{ mb: '20px' }}
              weather={weatherDataPoints?.reduce((acc, wdp) => {
                return {
                  ...acc,
                  [wdp.name]: get(data, wdp.weatherApiKey)
                }
              }, {})}
            />
          ) : (
            <Fragment key={1} />
          ),
          <ModalButton
            key={2}
            buttonLabel={
              hasWeatherValues
                ? 'Edit Weather'
                : showPreviewWeather
                ? 'Edit Weather'
                : 'Add Weather'
            }
            modalHeader="Weather"
            buttonProps={{
              variant: hasWeatherValues ? 'primary' : 'secondary',
              icon: hasWeatherValues ? 'pencil' : 'plus'
            }}
            hideCancel
            renderModalActions={toggle => (
              <Button onClick={toggle}>Done</Button>
            )}
            {...rest}
          >
            {() => (
              <EditWeatherLogForm
                handleChange={handleChange}
                values={values}
                hasWeatherValues={hasWeatherValues}
              />
            )}
          </ModalButton>
        ]
      )}
    </Flex>
  )
}

export const EditWeatherLogForm = ({
  hasWeatherValues,
  handleChange,
  values
}) => {
  return (
    <Box>
      {!hasWeatherValues && (
        <InlineTooltip
          tip={
            'Weather input here will override automated weather on submit.'
          }
        />
      )}

      <Flex
        sx={{ justifyContent: 'space-between', flexWrap: 'wrap' }}
      >
        {weatherDataPoints.map(({ label, name, subItem }) => (
          <Flex
            key={name}
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            <Label sx={{ flex: '1 1 auto' }} htmlFor={name}>
              {label}:
            </Label>
            <Flex sxxx={{ alignItems: 'center' }}>
              <Input
                name={name}
                id={name}
                type="number"
                step="0.01"
                onChange={handleChange}
                placeholder={label}
                value={values?.[name]}
                sx={{
                  width: '105px',
                  margin: '5px',
                  flex: '0 .25 auto'
                }}
                maxlength="6"
              />
              {subItem && (
                <Input
                  name={subItem?.name}
                  placeholder={subItem?.label}
                  id={subItem?.name}
                  onChange={handleChange}
                  value={values?.[subItem?.name]}
                  sx={{
                    width: '125px',
                    margin: '5px',
                    flex: '0 .25 auto'
                  }}
                  maxlength="6"
                />
              )}
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Box>
  )
}
