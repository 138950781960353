import { Fragment } from 'react'
import { useQuery } from '@apollo/client'

import { Box } from 'theme-ui'
import { GET_NWS_ALERTS } from '../../hooks/queries'
import Button from '../../components/Shared/Elements/Button'
import CustomCarousel from '../../components/Shared/CustomCarousel'

import InlineTooltip from '../../components/Shared/InlineTooltip'
import ModalButton from '../../components/Shared/ModalButton'
import { RichTextRender } from '../Shared/RichTextRender'
import { getContrastColor } from 'src/utils/getContrastColor'

const NWSAlets = () => {
  const { data } = useQuery(GET_NWS_ALERTS)
  const alerts = data?.nwsAlerts

  return !alerts || alerts.length < 1 ? (
    <Fragment />
  ) : (
    <CustomCarousel arrows={true} slidesToShow={1} infinite>
      {alerts.map((n, k) => {
        const properties = n.properties
        return (
          <Box key={k} sx={{ minWidth: 'auto', mb: 30 }}>
            <ModalButton
              buttonProps={{
                sx: {
                  p: '0px'
                },
                variant: 'naked'
              }}
              renderModalActions={() => {
                return (
                  <Button
                    href={'https://alerts.weather.gov'}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Read More
                  </Button>
                )
              }}
              modalHeader={properties.headline}
              buttonLabel={
                <InlineTooltip
                  isCloseable={false}
                  icon={'attention'}
                  iconColor={'white'}
                  forceNewLine
                  header={properties.headline}
                  tip={properties.areaDesc}
                  sx={{
                    textAlign: 'left',
                    color: getContrastColor(n?.color || 'lightGray'),
                    borderWidth: '0px',
                    margin: '0px',
                    background: n?.color || 'lightGray'
                  }}
                />
              }
            >
              {() => {
                return (
                  <>
                    <RichTextRender
                      sx={{ py: 20, pt: '0px' }}
                      text={properties.description?.replaceAll(
                        '*',
                        '<br /><br />'
                      )}
                    />
                    <strong>Severity:</strong>
                    <Box sx={{ pb: 20, pt: '5px' }}>
                      {properties.severity}
                    </Box>
                    <strong> Area Description:</strong>
                    <Box sx={{ pb: 20, pt: '5px' }}>
                      {properties.areaDesc}
                    </Box>
                    <strong> Instructions</strong>
                    <Box sx={{ pb: 20, pt: '5px' }}>
                      {properties.instruction}
                    </Box>
                  </>
                )
              }}
            </ModalButton>
          </Box>
        )
      })}
    </CustomCarousel>
  )
}

export default NWSAlets
