function UploadSuccess(props) {
  const title = props.title || 'archive check'

  return (
    <svg
      height={props.height || '64'}
      width={props.width || '64'}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill={props.color || '#212121'}>
        <path
          d="M54,4H10a6.006,6.006,0,0,0-6,6V54a6.006,6.006,0,0,0,6,6H54a6.006,6.006,0,0,0,6-6V10A6.006,6.006,0,0,0,54,4ZM10,6H54a4,4,0,0,1,4,4V38H42a1,1,0,0,0-1,1v7H23V39a1,1,0,0,0-1-1H6V10A4,4,0,0,1,10,6Z"
          fill={props.color || '#212121'}
        />
        <polygon points="28 32 18 22 20 20 28 27 44 12 46 14 28 32" />
      </g>
    </svg>
  )
}

export default UploadSuccess
