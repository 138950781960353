/** @jsxImportSource theme-ui */

import { CenteredContent } from '../../components'
import { Flex, Link } from 'theme-ui'

import Icon from '../../components/Shared/Icon'
import Button from './Elements/Button'

const EmailMagicLinkSuccess = ({ loading, email, onRedo, back }) => {
  return (
    <CenteredContent
      sx={{ maxWidth: '350px', margin: 'auto', textAlign: 'center' }}
      height={300}
    >
      <Icon
        width={40}
        height={40}
        icon={loading ? 'spinner' : 'email'}
        color="success"
      />
      <h3 sx={{ my: '20px' }}>Check your email</h3>
      <p>
        We sent an email to <strong>{email}</strong>
      </p>

      <Flex sx={{ my: '20px', gap: '5px' }}>
        Didn't recieve the email?
        <Button
          variant="link"
          noPadding
          onClick={onRedo}
          disabled={loading}
        >
          Click to Resend
        </Button>
      </Flex>
      <Link onClick={back}>Back to login</Link>
    </CenteredContent>
  )
}

export default EmailMagicLinkSuccess
