/** @jsxImportSource theme-ui */

const CharacterCount = ({ maxCharacters, currentLength }) => {
  return (
    <p
      sx={{
        fontSize: '0.65rem',
        fontStyle: 'italic',
        position: 'absolute',
        bottom: '-27px',
        right: '0px'
      }}
    >
      Remaining Characters: {maxCharacters - currentLength}
    </p>
  )
}

export default CharacterCount
