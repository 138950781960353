const Download = ({ height, width, color, title = 'download' }) => {
  return (
    <svg
      height={height || '48'}
      width={width || '48'}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill={color || "#303035"}>
        <path d="M38 18h-8V6H18v12h-8l14 14 14-14zM10 36v4h28v-4H10z" />
      </g>
    </svg>
  )
}

export default Download
