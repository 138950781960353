import { useMutation } from '@apollo/client'

import React, { Fragment } from 'react'
import { useToast } from 'src/components/toasts'
import { LOGIN_WITHOUT_PASSWORD } from '../containers/LoginScreen'
import { useGetCurrentAgencyContext } from '../hooks/queries'

import Modal from './Shared/Elements/Modal'
import ModalBody from './Shared/Elements/ModalBody'
import Button from './Shared/Elements/Button'
import Icon from './Shared/Icon'
import { useLocation, useNavigate } from 'react-router'

export const VerifyEmailModal = () => {
  const { user } = useGetCurrentAgencyContext()
  const { search, pathname } = useLocation()
  const navigate = useNavigate()
  const { add } = useToast()

  const [loginWP, { loading: wpLoading }] = useMutation(
    LOGIN_WITHOUT_PASSWORD,
    {
      onCompleted: data => {
        navigate(pathname)
        add({
          content:
            "We've sent you an email! Please check your inbox.",
          color: 'success'
        })
      }
    }
  )

  if (!user?.email || !search.includes('verifyEmail=true'))
    return <Fragment />
  return (
    <Modal
      toggle={() => {
        navigate(pathname)
      }}
      isOpen={true}
      width={450}
      size={350}
    >
      <ModalBody
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '20px'
        }}
      >
        <Icon icon="email" width={40} height={40} color="success" />
        <h5>
          Click the button below, and we will send your verification
          email!
        </h5>

        <Button
          icon={wpLoading && 'spinner'}
          onClick={() =>
            loginWP({
              variables: {
                email: user?.email
              }
            })
          }
        >
          Send Email Verification
        </Button>
      </ModalBody>
    </Modal>
  )
}
