import { theme } from 'src/styles'
// eslint-disable-next-line
export default ({ active, height, width }) => {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Connected</title>
      <g fill={active ? theme.colors.success : theme.colors.gray600}>
        <circle cx="32" cy="32" r="7" />
        <path
          d="M54.137,10.298c-0.387-0.395-1.02-0.401-1.414-0.014c-0.394,0.386-0.4,1.02-0.014,1.414 C58.056,17.152,61,24.362,61,32c0,7.638-2.944,14.847-8.29,20.3c-0.387,0.395-0.38,1.028,0.014,1.414 c0.195,0.191,0.448,0.286,0.7,0.286c0.259,0,0.518-0.1,0.714-0.3C59.853,47.871,63,40.165,63,32 C63,23.835,59.853,16.128,54.137,10.298z"
          fill={active ? theme.colors.success : theme.colors.gray600}
        />
        <path
          d="M44.31,18.83c-0.398,0.382-0.412,1.015-0.03,1.414C47.323,23.422,49,27.597,49,32 c0,4.404-1.677,8.58-4.722,11.758c-0.382,0.399-0.369,1.032,0.03,1.414c0.194,0.186,0.443,0.278,0.692,0.278 c0.263,0,0.526-0.103,0.722-0.308C49.125,41.589,51,36.922,51,32c0-4.921-1.874-9.587-5.276-13.14 C45.342,18.461,44.708,18.448,44.31,18.83z"
          fill={active ? theme.colors.success : theme.colors.gray600}
        />
        <path
          d="M11.277,10.285c-0.395-0.387-1.027-0.38-1.414,0.014C4.147,16.128,1,23.835,1,32 c0,8.165,3.147,15.871,8.862,21.7c0.196,0.2,0.455,0.3,0.714,0.3c0.252,0,0.505-0.095,0.7-0.286c0.394-0.386,0.4-1.02,0.014-1.414 C5.944,46.847,3,39.638,3,32c0-7.638,2.944-14.848,8.291-20.301C11.678,11.304,11.671,10.671,11.277,10.285z"
          fill={active ? theme.colors.success : theme.colors.gray600}
        />
        <path
          d="M15,32c0-4.403,1.677-8.578,4.721-11.756c0.382-0.399,0.368-1.032-0.03-1.414 c-0.398-0.381-1.032-0.369-1.414,0.03C14.874,22.413,13,27.079,13,32c0,4.922,1.875,9.589,5.278,13.142 c0.196,0.205,0.459,0.308,0.722,0.308c0.249,0,0.498-0.092,0.692-0.278c0.399-0.382,0.412-1.015,0.03-1.414 C16.677,40.58,15,36.404,15,32z"
          fill={active ? theme.colors.success : theme.colors.gray600}
        />
      </g>
    </svg>
  )
}
