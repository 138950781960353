import { gql } from '@apollo/client'

export const NOTES_FRAGMENT = gql`
  fragment NotesFragment on Note {
    id
    text
    createdAt
    wasDispatch
    writtenBy {
      id
      name
    }
    writtenByPosition {
      id
      name
    }
  }
`

export const FORM_FIELD_FRAGMENT = gql`
  fragment FormFieldFragment on FormField {
    id
    title
    archived
  }
`

export const FORM_FIELD_ITEM_FRAGMENT = gql`
  fragment FormFieldItemFragment on FormFieldItem {
    id
    required
    createdAt
    helpText
    summaryAnswer
    allowedValues
    allowOther
    allowMultipleAnswers
    valueTypeFormat
    type
    archived
    formAlert {
      id
      check
      contactInfos
    }
  }
`

export const SUBMISSION_FRAGMENT = gql`
  fragment SubmissionFragment on Submission {
    id
    notes(orderBy: { createdAt: desc }) {
      ...NotesFragment
    }
    supervisorReviews(take: 1) {
      id
      form {
        id
      }
    }
    reviewForm {
      id
    }

    reviewStatus
    reviewStatusColor
    createdAt
    incidentTime
    archived
    wasBulkIncident
    important
    count
    wasIncludedForGroups
    reporter {
      id
      name
    }
    dispatches {
      id
      archived
      wasDispatched
      completedAt
      personnel {
        id
        name
      }
      unit {
        id
        name
      }
    }
    callForService {
      id
      hasMultipleSubmissions
      callNumber
    }
    location {
      id
      prettyAddress
      position {
        id
        name
      }
    }
    weather {
      id
      airTemp
      waveInterval
      waveHeight
      createdAt
      tideHeight
      waterTemp
      windDirection
      windSpeed
    }
    type {
      id
      label
      color
    }
  }
  ${NOTES_FRAGMENT}
`

export const DISPATCH_SUBMISSION_FRAGMENT = gql`
  fragment DispatchSubmissionFragment on Submission {
    id
    reporter {
      id
    }
    callForService {
      id
      callNumber
    }
    incidentTime
    important
    type {
      id
      label
      color
    }
    location {
      id
      lat
      lng
      prettyAddress
      position {
        id
        name
      }
    }

    notes(orderBy: { createdAt: desc }) {
      ...NotesFragment
    }
    dispatches(
      where: {
        archived: { equals: false }
        completedAt: { equals: null }
        wasDispatched: { equals: true }
      }
      orderBy: { createdAt: asc }
    ) {
      id
      createdAt
      onScene
      enRoute
      completedAt
      # isFinalActiveDispatchOnSubmission
      unit {
        id
        name
      }
    }
  }
  ${NOTES_FRAGMENT}
`

export const FILE_FRAGMENT = gql`
  fragment FileFragment on File {
    id
    name
    url
    pinned
    createdAt
    fileType
    secureFileUrl
    uploadedBy {
      id
      name
    }
    fileCategories {
      id
      name
      color
    }
  }
`

export const FORM_RESPONSE_FRAGMENT = gql`
  fragment FormResponseFragment on FormResponse {
    id
    createdAt
    draft
    weather {
      id
      airTemp
      waveInterval
      waveHeight
      createdAt
      tideHeight
      waterTemp
      windDirection
      windSpeed
    }
    submission {
      id
      reviewStatus
      reviewStatusColor
    }
    form {
      id
      name
      fileCategories {
        id
        name
        color
      }
    }
    submittedBy {
      id
      name
    }

    fields {
      id
      title
      formField {
        id
      }
      fieldItems {
        id
        type
        prettyType
        createdAt
        value
        prettyValue
        dateTime
        helpText
        valueTypeFormat
        allowedValues
        allowOther
        formFieldItem {
          id
        }
        locations {
          id
          lat
          lng
          prettyAddress
        }
        file {
          id
          createdAt
          fileType
          filestackId
          name
        }
        users {
          id
          name
        }
        positions {
          id
          name
        }
        assets {
          id
          name
        }
      }
    }
  }
`

export const FORM_RESPONSE_OVERVIEW_FRAGMENT = gql`
  fragment FormResponseOverviewFragment on FormResponse {
    id
    createdAt
    archived
    summary(includeTitle: $includeSummaryTitles)
    draft
    submissionId
    form {
      id
      name
      fileCategories {
        id
        name
        color
      }
    }
    submittedBy {
      id
      name
    }
  }
`

export const STATUS_TEMPLATE_FRAGMENT = gql`
  fragment StatusTemplateFragment on StatusTemplate {
    id
    createdAt
    title
    statusOnNodeType
    dbStatusOnNodeType
    archived
    type
    isPrimary
    valueTypeFormat
    alertLevel
    alertLevelRules {
      id
      operator
      value
      units
      alertLevel
      contactInfos
      alertOwner
      isExpirationRule
    }
    allowedValues {
      id
      value
      color
      alertLevel
      contactInfos
      alertOwner
    }
    category {
      id
      name
      color
    }
    onlyForGroups {
      id
      name
      color
    }
  }
`

export const SUPERVISOR_REVIEW_CONFIG_FRAGMENT = gql`
  fragment SupervisorReviewConfigFragment on SupervisorReviewConfig {
    id
    createdAt
    stateOptions {
      id
      color
      state
      defaultReviewState
      requestReviewState
    }
    recommendOnImportant
    reviewStatusFieldItemId
  }
`

export const AUTH_PAYLOAD_FRAGMENT = gql`
  fragment AuthPayloadFragment on AuthPayload {
    token
    session
    mfaEnabled
    mfaRequired
    user {
      id
      email
      stytchMemberId
      role
      agency {
        id
        name
        organizationId
      }
    }
  }
`
