import { useState, useEffect, useCallback, useRef } from 'react'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import isDate from 'lodash/isDate'
import moment from 'moment'
import { prettyDate } from 'src/utils'

export const upsert = function(arr, newVal) {
  let newArr = [...arr]
  const index = newArr.findIndex(i => {
    const equal = isEqual(i, newVal) || i === newVal
    return equal
  })

  if (index !== -1) {
    newArr.splice(index, 1)
    return newArr
  } else {
    newArr.push(newVal)
    return newArr
  }
}

const useForm = (callback, defaultValues, options) => {
  const [lastSave, setLastSave] = useState(null)
  const [errors, setErrors] = useState({})
  const [warnings, setWarnings] = useState({})
  const [values, setValues] = useState(defaultValues || {})
  const [isDirty, setIsDirty] = useState(false)

  useEffect(() => {
    if (defaultValues) {
      setValues(defaultValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const autoSave = useAutoSave(handleSubmit, options, setLastSave)

  function setValuesWithDirty(newVals, skip) {
    setValues(newVals)

    if (!skip) {
      setIsDirty(true)
    }
  }

  function mergeValuesWithDirty(newVals, skip) {
    setValues(prev => ({ ...prev, ...newVals }))

    if (!skip) {
      setIsDirty({
        ...isDirty,
        ...Object.keys(newVals).reduce(
          (acc, key) => ({
            ...acc,
            [key]: true
          }),
          {}
        )
      })
    }
  }

  async function handleSubmit(event, v) {
    if (event) event.preventDefault()
    if (event) event.stopPropagation()

    const newVals = v || values

    const requiredFields = options?.required
    if (!isEmpty(requiredFields)) {
      const needValue = requiredFields.filter(r => {
        return isDate(newVals[r]) ? false : isEmpty(newVals[r])
      })

      if (needValue.length > 0) {
        setErrors({
          ...errors,
          ...needValue.reduce(
            (acc, r) => ({
              ...acc,
              [r]: `${r} is required`
            }),
            {}
          )
        })
        return
      } else {
        setErrors({})
      }
    }

    if (!isEmpty(errors)) {
      return
    }

    const res = await callback(newVals)

    setIsDirty(false)

    return res
  }

  const handleErrors = (event, isValid, message) => {
    const name = event.target ? event.target.name : event
    setErrors(errors => {
      if (isValid) {
        delete errors[name]
      }

      return {
        ...errors,
        ...(!isValid && { [name]: message })
      }
    })
  }

  const removeRequiredErrors = name => {
    if (options?.required) {
      delete errors[name] // remove error if exists

      setErrors({
        ...errors
      })
    }
  }

  const handleWarnings = (event, isValid, message) => {
    const name = event.target ? event.target.name : event
    setWarnings(values => {
      if (isValid) {
        delete values[name]
      }

      return {
        ...values,
        ...(!isValid && { [name]: message })
      }
    })
    setIsDirty({
      ...isDirty,
      [name]: true
    })
  }

  const handleChange = (event, input) => {
    const name = event ? event.target.name : input.name

    if (input) {
      setValues(values => ({
        ...values,
        [name]: input
      }))
    } else {
      event && event.persist()
      const value =
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value

      setValues(values => ({
        ...values,
        [event.target.name]: value
      }))
    }

    if (options?.required) {
      removeRequiredErrors(name)
    }

    setIsDirty({
      ...isDirty,
      [name]: true
    })
  }

  const handleMultiSelectChange = (event, obj) => {
    event && event.persist()
    if (options?.required) {
      removeRequiredErrors(event.target.name)
    }

    setValues(values => {
      const oldValues = values[event.target.name]
        ? [...values[event.target.name]]
        : []

      return {
        ...values,
        [event.target.name]: [
          ...upsert(oldValues, obj || event.target.value)
        ]
      }
    })

    setIsDirty({
      ...isDirty,
      [event.target.name]: true
    })
  }

  const handleSelect = input => {
    const { value, name } = input
    if (options?.required) {
      removeRequiredErrors(name)
    }
    setValues(values => ({
      ...values,
      [name]: value
    }))

    setIsDirty({
      ...isDirty,
      [name]: true
    })
  }

  const getValue = name => values[name]

  return {
    autoSaveEnabled: options?.autoSave,
    autoSave,
    handleChange,
    handleMultiSelectChange,
    handleSubmit,
    setValues: setValuesWithDirty,
    mergeValues: mergeValuesWithDirty,
    getValue,
    handleSelect,
    lastSave,
    handleWarnings,
    errors,
    handleErrors,
    warnings,
    setErrors,
    isDirty,
    setIsDirty,
    values
  }
}

const useAutoSave = (handleSubmit, options, setLastSave) => {
  const timeoutId = useRef(null)

  const autoSave = useCallback(
    v => {
      if (options?.autoSave) {
        if (timeoutId.current) {
          clearTimeout(timeoutId.current)
        }

        timeoutId.current = setTimeout(async () => {
          console.log(`Auto save is enabled. Executing save. `)
          await handleSubmit(null, v)
          setLastSave(prettyDate(moment()))
          timeoutId.current = null
        }, 600)
      } else {
      }
    },
    [handleSubmit, options]
  )

  return autoSave
}

export default useForm
