function Duplicate(props) {
  const title = props.title || 'duplicate'

  return (
    <svg
      height={props.height || '16'}
      width={props.width || '16'}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill={props.color}>
        <path d="M15,5H8C7.4,5,7,5.4,7,6v9c0,0.6,0.4,1,1,1h7c0.6,0,1-0.4,1-1V6C16,5.4,15.6,5,15,5z" />
        <path
          d="M5,4c0-0.6,0.4-1,1-1h6V1c0-0.6-0.4-1-1-1H1C0.4,0,0,0.4,0,1v12c0,0.6,0.4,1,1,1h4V4z"
          fill={props.color}
        />
      </g>
    </svg>
  )
}

export default Duplicate
