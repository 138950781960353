function updateOrAddToArr(arr, key, keyValue, newValue) {
  const index = arr.findIndex(item => item[key] === keyValue)

  if (index !== -1) {
    // If a matching element is found, update it
    const updatedElement = { ...arr[index], ...newValue }
    return [
      ...arr.slice(0, index),
      updatedElement,
      ...arr.slice(index + 1)
    ]
  } else {
    // If no matching element is found, add a new one
    const newElement = { ...newValue }
    return [...arr, newElement]
  }
}

export default updateOrAddToArr
