import { useGetCurrentAgencyContext } from 'src/app/hooks/queries'
import DefaultAvatarImg from 'src/images/WatchtowerOpenGraph.png'
import styled from '@emotion/styled'

const StyledAvatarDiv = styled.div`
  border-radius: 100%;
  width: ${p => (p.width ? p.width : p.height)};
  height: ${p => (p.height ? p.height : p.width)};
  margin: auto;
  border: 1px solid ${p => p.theme.colors.gray300};
  overflow: hidden;
`

const Avatar = props => {
  const { currentAgency } = useGetCurrentAgencyContext()
  const avatarUrl =
    currentAgency.settings.logo &&
    currentAgency.settings.logo.secureFileUrl
  let avatarImage = avatarUrl ? avatarUrl : DefaultAvatarImg

  return (
    <StyledAvatarDiv {...props}>
      <img height="100%" alt="profile" src={avatarImage} />
    </StyledAvatarDiv>
  )
}

export default Avatar
