function RowTable(props) {
  const title = props.title || 'row table'

  return (
    <svg
      height={props.height || '24'}
      width={props.width || '24'}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g
        fill={props.color || '#F7F7F7'}
        stroke={props.color || '#F7F7F7'}
        strokeLinecap="square"
        strokeLinejoin="miter"
        strokeMiterlimit="10"
        strokeWidth="2"
      >
        <line
          fill="none"
          strokeLinecap="butt"
          x1="2"
          x2="22"
          y1="8"
          y2="8"
        />
        <line
          fill="none"
          strokeLinecap="butt"
          x1="2"
          x2="22"
          y1="15"
          y2="15"
        />
        <line
          fill="none"
          strokeLinecap="butt"
          x1="8"
          x2="8"
          y1="2"
          y2="22"
        />
        <path
          d="M20,22H4a2,2,0,0,1-2-2V4A2,2,0,0,1,4,2H20a2,2,0,0,1,2,2V20A2,2,0,0,1,20,22Z"
          fill="none"
          stroke={props.color || '#F7F7F7'}
        />
      </g>
    </svg>
  )
}

export default RowTable
