import React from 'react'

function Microsoft(props) {
  const title = props.title || 'microsoft'

  return (
    <svg
      height={props.height || '48'}
      width={props.width || '48'}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{title}</title>
      <g>
        <rect height="20" width="20" fill="#E86C60" x="3" y="3" />
        <rect height="20" width="20" fill="#72C472" x="25" y="3" />
        <rect height="20" width="20" fill="#43A6DD" x="3" y="25" />
        <rect height="20" width="20" fill="#EFD358" x="25" y="25" />
      </g>
    </svg>
  )
}

export default Microsoft
