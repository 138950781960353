/** @jsxImportSource theme-ui */

import { memo, useState } from 'react'

import { Box, Flex, useThemeUI } from 'theme-ui'
import { CenteredContent, Space } from '..'

import flatMap from 'lodash/flatMap'
import uniqBy from 'lodash/uniqBy'

import FilterByTag from '../Shared/FilterByTag'

import Col from '../Shared/Elements/Col'
import Button from '../Shared/Elements/Button'
import Input from '../Shared/Elements/Input'
import InputGroup from '../Shared/Elements/InputGroup'

import { Link } from 'react-router-dom'
import { getContrastColor } from 'src/utils/getContrastColor'
import { TapInputGridContainer } from '../Forms/TappableSearchInput'
import { FormSubmitRendererModalButton } from '../Operations/Forms/formSubmitRenderer/FormSubmitRendererModalButton'

const TappableFormList = memo(({ label, options }) => {
  const [showSearch, setShowSearch] = useState(false)
  const [query, setQuery] = useState(null)
  const [categoryFilter, setCategoryFilter] = useState([])

  const defaultInputOptions = {
    placeholder: 'Type to search'
  }

  return !options || options?.length < 1 ? (
    <CenteredContent sx={{ textAlign: 'center' }} height="200px">
      <h4>No operational forms configured.</h4>
      To add operational forms to this screen go to your operations
      tab, create a new form and pin it to the submit screen.
      <Link to="/agency/operations/forms">
        <Button variant="primary" sx={{ marginTop: '20px' }}>
          Click Here to Edit
        </Button>
      </Link>
    </CenteredContent>
  ) : (
    <>
      <Col
        sx={{
          pt: 40
        }}
      >
        {label && (
          <Flex sx={{ alignItems: 'center', mb: '10px' }}>
            <h5
              sx={{
                margin: '0px'
              }}
            >
              {label}
            </h5>
            <Button
              size="sm"
              title="Search"
              variant="linkGray"
              icon={showSearch ? 'close' : 'search'}
              onClick={() => {
                setQuery('')
                setShowSearch(!showSearch)
              }}
            />
          </Flex>
        )}
        {showSearch && (
          <>
            <InputGroup sx={{ mb: 10 }}>
              <Input
                {...defaultInputOptions}
                value={query}
                onChange={e => setQuery(e.target.value)}
              />
            </InputGroup>
            <FilterByTag
              currentFilter={categoryFilter}
              onFilter={res => setCategoryFilter(res || [])}
              tags={uniqBy(
                flatMap(
                  options,
                  ({ fileCategories }) => fileCategories
                ),
                'id'
              )}
            />
          </>
        )}

        <Space height={15} />

        <TapInputGridContainer itemsAcross={[2, 2, 4]}>
          {options
            .filter(
              opt1 =>
                (!query ||
                  opt1.name
                    .toLowerCase()
                    .includes(query.toLowerCase())) &&
                (!categoryFilter.length ||
                  opt1.fileCategories.some(fc =>
                    categoryFilter.includes(fc)
                  ))
            )
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((opt2, key) => {
              return (
                <Box
                  key={key}
                  sx={{
                    flex: 1,
                    padding: '5px',
                    position: 'relative'
                  }}
                >
                  <FormSubmitRendererModalButton
                    formId={opt2.id}
                    modalButtonProps={{
                      buttonLabel: opt2.name,
                      buttonProps: {
                        // variant: 'secondary',
                        sx: {
                          ...(opt2?.fileCategories?.[0]?.color && {
                            bg: opt2?.fileCategories?.[0]?.color,
                            color: getContrastColor(
                              opt2?.fileCategories?.[0]?.color,
                              4
                            )
                          }),
                          fontWeight: 'bold',
                          width: '100%',
                          border: 'none',
                          flex: 1,
                          height: '90px',
                          position: 'relative',
                          padding: '20px'
                        }
                      }
                    }}
                  />

                  <Flex
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,

                      gap: '3px'
                    }}
                  >
                    {opt2?.fileCategories?.slice(1).map((fc, key) => (
                      <Box
                        key={key}
                        sx={{
                          padding: '5px',
                          borderRadius: '30px',
                          backgroundColor: fc.color,
                          fontSize: '10px',
                          fontWeight: 'bold',
                          textTransform: 'uppercase'
                        }}
                      />
                    ))}
                  </Flex>
                </Box>
              )
            })}
        </TapInputGridContainer>
      </Col>
    </>
  )
})

export default TappableFormList
