import { useLogout } from 'src/app/services/auth'

import Button from './Elements/Button'

const LogoutButton = ({ ...props }) => {
  const { logout, loading } = useLogout()

  return (
    <Button
      {...props}
      onClick={event => {
        event.preventDefault()

        logout()
      }}
      variant={props.variant ? props.variant : 'primary'}
      icon={loading && 'spinner'}
    >
      Logout
    </Button>
  )
}

export default LogoutButton
