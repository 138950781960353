import { useState, useEffect } from 'react'

function useKeybind(key, callback, ctrl) {
  const [isPressed, setIsPressed] = useState(false)

  useEffect(() => {
    const handleKeyDown = event => {
      const currentKey = event.key === key

      if (
        (ctrl ? event.ctrlKey && currentKey : currentKey) &&
        !isPressed
      ) {
        setIsPressed(true)
        callback()
      }
    }
    const handleKeyUp = event => {
      if (event.key === key) {
        setIsPressed(false)
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [callback, isPressed, key, ctrl])

  return isPressed
}

export default useKeybind
