import { Space } from '..'
import { useMutation } from '@apollo/client'
import useForm from 'src/app/hooks/useForms'
import FormError, { cleanGraphQLError } from '../Forms/FormError'
import Button from 'src/app/components/Shared/Elements/Button'
import Input from 'src/app/components/Shared/Elements/Input'
import FormGroup from 'src/app/components/Shared/Elements/FormGroup'
import Form from 'src/app/components/Shared/Elements/Form'

import { REQUEST_PASSWORD } from 'src/app/hooks/mutations'
import EmailMagicLinkSuccess from '../Shared/EmailMagicLinkSuccess'
import { useState } from 'react'
import { Flex } from 'theme-ui'

const PasswordForm = ({ back }) => {
  const [magicLinkSent, setMagicLinkSent] = useState(false)
  const [requestPassword, { loading, error }] = useMutation(
    REQUEST_PASSWORD
  )
  const { values, handleChange, handleSubmit, errors } = useForm(
    onRequest,
    {},
    {
      required: ['email']
    }
  )

  async function onRequest() {
    const res = await requestPassword({
      variables: { email: values.email }
    })

    if (!res.errors) {
      setMagicLinkSent(true)
    }
  }

  return (
    <>
      {magicLinkSent ? (
        <EmailMagicLinkSuccess
          email={values.email}
          onRedo={onRequest}
          back={() => {
            back()
            setMagicLinkSent(false)
          }}
          loading={loading}
        />
      ) : (
        <>
          <h3>Forgot password?</h3>
          <p>Enter the email address associated with your account.</p>
          <Space height={15} />
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Input
                placeholder="Enter email..."
                type="email"
                name="email"
                id="email"
                required
                values={values.email || ''}
                onChange={handleChange}
              />
              <FormError error={errors['email']} />
            </FormGroup>
            <Space height={10} />
            <Flex sx={{ alignItems: 'center' }}>
              <Button onClick={back} variant="linkGray">
                cancel
              </Button>{' '}
              <Button icon={loading && 'spinner'} disabled={loading}>
                Send reset email
              </Button>
            </Flex>
            {error && <FormError error={cleanGraphQLError(error)} />}
          </Form>
        </>
      )}
    </>
  )
}

export default PasswordForm
