import { CenteredContent, Space } from '../components/'

import { gql } from '@apollo/client'

import useForm from '../hooks/useForms'
import { useMutation } from '@apollo/client'

import FormError from '../components/Forms/FormError'
import { useHandleAuthTokens } from '../services/auth'

import Button from '../components/Shared/Elements/Button'
import Label from '../components/Shared/Elements/Label'
import Card from '../components/Shared/Elements/Card'
import FormGroup from '../components/Shared/Elements/FormGroup'
import Form from '../components/Shared/Elements/Form'

import isEmpty from 'lodash/isEmpty'
import { PasswordInput } from '../components/Shared/Elements/Input'
import { useMFAFlow } from '../hooks/useMFAFlow'

import { useNavigate } from 'react-router'
import useGetAllSearchParams from '../hooks/useGetAllSearchParams'
import { Link } from 'react-router-dom'
import MFABranch from '../components/LoginScreen/MFABranch'
import { AUTH_PAYLOAD_FRAGMENT } from '../hooks/fragments'

const UPDATE_PASSWORD = gql`
  mutation UpdatePasswordWithCode(
    $newPassword: String!
    $verificationCode: String
  ) {
    updatePassword(
      verificationCode: $verificationCode
      newPassword: $newPassword
    ) {
      ...AuthPayloadFragment
    }
  }
  ${AUTH_PAYLOAD_FRAGMENT}
`

const UpdatePassword = () => {
  const { token } = useGetAllSearchParams()
  const navigate = useNavigate()
  const { mfa, triggerMfa, mfaOptions } = useMFAFlow(
    afterVerification
  )
  const [updatePassword, { loading, error }] = useMutation(
    UPDATE_PASSWORD
  )
  const { handleTokens } = useHandleAuthTokens()
  const {
    values,
    handleChange,
    errors,
    handleErrors,
    handleSubmit
  } = useForm(onSubmit)

  async function onSubmit() {
    const res = await updatePassword({
      variables: {
        newPassword: values.newPassword,
        verificationCode: token
      }
    })
    const ress = res?.data?.updatePassword

    const newToken = ress?.token
    const newSession = ress?.session
    const agency = ress?.user?.agency
    const mfaEnabled = ress?.mfaEnabled
    const mfaRequired = ress?.mfaRequired
    const user_id = ress?.user?.stytchMemberId
    const role = ress?.user?.role
    const organization_id = ress?.user?.agency?.organizationId
    if (mfaEnabled) {
      triggerMfa({
        newToken,
        user_id,
        mfaRequired,
        mfaEnabled,
        organization_id
      })
    } else if (newToken) {
      afterVerification(newToken, newSession, agency, role)
    }
  }

  async function afterVerification(
    newToken,
    newSession,
    agency,
    role
  ) {
    await handleTokens(newToken, newSession)

    if (!agency) {
      navigate('/agency/onboarding')
    } else {
      navigate(
        role === 'READ_ONLY' ? '/agency' : '/agency/submission'
      )
    }
  }

  return (
    <CenteredContent>
      {mfa ? (
        <MFABranch mfa={mfa} mfaOptions={mfaOptions} />
      ) : (
        <Card
          sx={{
            width: '100%',
            maxWidth: '600px',
            padding: '45px 20px'
          }}
        >
          <h3>Update your password.</h3>
          <Space height={25} />
          <Form autoComplete="off" onSubmit={handleSubmit}>
            <FormGroup>
              <Label>New Password</Label>
              <PasswordInput
                type="password"
                name="newPassword"
                autoComplete="new-password"
                onChange={handleChange}
                passwordStrengthCheck
                onSuccess={res => {
                  handleErrors('newPassword', res, '')
                }}
                value={values.newPassword}
                required
              />
              {errors.newPassword && (
                <FormError customError={errors.newPassword} />
              )}
            </FormGroup>
            <Space height={10} />
            <FormGroup>
              <Label>Confirm Password</Label>
              <PasswordInput
                type="password"
                name="confirmPassword"
                autoComplete="new-password"
                onChange={e => {
                  const match = e.target.value === values.newPassword

                  handleErrors(e, match, 'Password does not match.')

                  handleChange(e)
                }}
                value={values.confirmPassword}
                required
              />
              {errors.confirmPassword && (
                <FormError customError={errors.confirmPassword} />
              )}
            </FormGroup>
            <Button
              disabled={
                !isEmpty(errors) ||
                !values.newPassword ||
                !values.confirmPassword ||
                loading
              }
              block
              variant="primary"
              type="submit"
              icon={loading && 'spinner'}
            >
              Update password
            </Button>

            {error && <FormError error={error.message} />}
            <Space height={25} />
            <Link block color="link" size="md" to="/agency/login">
              Back to login.
            </Link>
          </Form>
        </Card>
      )}
    </CenteredContent>
  )
}

export default UpdatePassword
