import UserContactSelect from '../../../Shared/UserContactSelect'
import ModalButton from '../../../Shared/ModalButton'
import CustomSelectInput from 'src/app/components/Forms/CustomSelectInput'

import Button from 'src/app/components/Shared/Elements/Button'
import Label from 'src/app/components/Shared/Elements/Label'
import FormGroup from 'src/app/components/Shared/Elements/FormGroup'
import InputGroupText from 'src/app/components/Shared/Elements/InputGroupText'

import { checkContactInfoHasValue } from 'src/utils/handleContactInfosSet'
import { formAlertCheckOptions } from './dataMapping'
import Icon from 'src/app/components/Shared/Icon'
import InlineTooltip from 'src/app/components/Shared/InlineTooltip'

const FormFieldItemFormAlertModal = ({
  fieldItem,
  warnings,
  handleChange
}) => {
  const fieldKey = 'contactInfos'

  return (
    <ModalButton
      renderModalActions={toggle => (
        <Button onClick={toggle}>Done</Button>
      )}
      modalHeader={'Notify On Submit'}
      buttonLabel={
        <Icon
          icon="bell"
          height={15}
          width={15}
          title="Add Alert"
          color={
            checkContactInfoHasValue(
              fieldItem.contactInfos,
              fieldItem?.[fieldKey]
            )
              ? 'success'
              : 'text'
          }
        />
      }
      buttonProps={{
        variant: 'link',
        sx: { padding: '0px' }
      }}
    >
      {() => {
        return (
          <>
            <p>
              Emails and phone numbers entered here will be notified
              when {fieldItem?.helpText || 'this question'} is submit.
            </p>
            <FormGroup>
              <UserContactSelect
                id={fieldKey}
                name={fieldKey}
                placeholder="Enter an email or phone number. Press enter or click the plus to add multiple."
                value={fieldItem[fieldKey]}
                errorMessage={warnings[fieldKey]}
                onAdd={curArray => {
                  if (warnings[fieldKey]) {
                    return
                  }

                  handleChange({
                    value: curArray,
                    name: 'contactInfos'
                  })
                }}
              />
            </FormGroup>

            {fieldItem.type === 'boolean' && (
              <FormGroup>
                <Label>Condition</Label>
                <CustomSelectInput
                  id="check"
                  name="check"
                  value={fieldItem.check}
                  options={formAlertCheckOptions}
                  onChange={selected => {
                    handleChange({
                      value: selected,
                      name: 'check'
                    })
                  }}
                />
              </FormGroup>
            )}
            {(fieldItem?.[fieldKey]?.length > 0 ||
              fieldItem?.contactInfos?.length) && (
              <InlineTooltip
                sx={{
                  my: 20,
                  mt: 0
                }}
                variant="warning"
                header={'Data Security Reminder'}
                tip="Remember, when sending form alerts via email, you will be sharing form answers outside of Watchtower.  Please consider and understand the importance of the data that could be included."
              />
            )}
          </>
        )
      }}
    </ModalButton>
  )
}

export default FormFieldItemFormAlertModal
