import {
  UNFINISHED_FORM_ALERTS,
  GET_MY_ACTIVITY_FEED
} from '../../hooks/queries'

import { Space, CenteredContent } from '../'

import { useQuery } from '@apollo/client'
import Spinner from 'src/images/icons/Spinner'

import get from 'lodash/get'

import { Box, Flex } from 'theme-ui'

import { useNetworkStatus } from '../../hooks/useNetworkStatus'

import FormGroup from '../Shared/Elements/FormGroup'
import { DateRangePicker } from '../Forms/DatePickerInput'

import { useEffect, useState } from 'react'
import moment from 'moment'

import uniqBy from 'lodash/uniqBy'
import last from 'lodash/last'
import SubmissionList from '../Stats/SubmissionList'
import Label from '../Shared/Elements/Label'
import Input from '../Shared/Elements/Input'
import Switch from '../Shared/Elements/Switch'
import { sortByDate } from 'src/utils'

const submissionTypes = [
  { value: 'submission', label: 'Incidents' },
  { value: 'formResponse', label: 'Form' },
  { value: 'bulkSubmissions', label: 'Bulk submission' }
]

const EditSubmissionsList = () => {
  const [allSubs, setAllSubs] = useState([])
  const [date, setDate] = useState({})
  const [draft, setDraft] = useState(null)
  const [includeTypes, setIncludeTypes] = useState(submissionTypes)

  const isOnline = useNetworkStatus()

  const dateArgs = date?.startDate && {
    createdAt: {
      gte: moment(date?.startDate)
        .startOf('day')
        .toDate(),
      lte: moment(date?.endDate)
        .endOf('day')
        .toDate()
    }
  }

  const getSubmissionVariables = {
    take: 10,
    orderBy: {
      createdAt: 'desc'
    },
    includeSummaryTitles: true,
    formResponseWhere: {
      ...(date?.startDate && dateArgs),

      ...(draft && {
        draft: {
          equals: true
        }
      }),
      submissionId: {
        equals: null
      },
      archived: {
        equals: false
      }
    },
    includeTypes: includeTypes.map(it => it.value),
    where: {
      ...(date && dateArgs),

      ...(draft && {
        formResponses: {
          some: {
            draft: {
              equals: true
            }
          }
        }
      }),

      archived: {
        equals: false
      }
    }
  }

  const { data, loading, fetchMore, networkStatus } = useQuery(
    GET_MY_ACTIVITY_FEED,
    {
      variables: getSubmissionVariables,
      notifyOnNetworkStatusChange: true
    }
  )

  useEffect(() => {
    const d = get(data, 'myActivityFeed', {})
    setAllSubs({
      ...d,
      feed: [...(d?.feed || [])]?.sort(
        sortByDate('createdAt', 'desc')
      )
    })
  }, [data])

  if (!isOnline) {
    return (
      <CenteredContent>
        Please connect to the internet before editing past submissions
      </CenteredContent>
    )
  }

  return (
    <Box sx={{ pb: '40px' }}>
      <Flex
        sx={{
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '30px',
          flexWrap: 'wrap',
          mb: '20px'
        }}
      >
        <FormGroup>
          <Label>Submission Date</Label>
          <Flex
            sx={{
              flex: 1,
              alignItems: 'center'
            }}
          >
            <DateRangePicker
              placeholderText="Enter date"
              value={date}
              maxDate={new Date()}
              onChange={date => setDate(date)}
              isClearable
              showTimeSelect={false}
            />
          </Flex>
        </FormGroup>
        <Switch
          variant="warning"
          onClick={() => {
            setDraft(!draft)
          }}
          checked={draft ? true : false}
          label={draft ? 'Showing only drafts' : 'Show drafts only'}
        />
      </Flex>
      <FormGroup>
        <Flex
          sx={{
            minWidth: '300px',
            gap: '10px'
          }}
        >
          {submissionTypes?.map(et => {
            const isChecked = includeTypes.some(
              it => et.value === it.value
            )
            return (
              <FormGroup check inline>
                <Input
                  id={et.value}
                  label={et.label}
                  type="checkbox"
                  name={et}
                  checked={Boolean(isChecked)}
                  onChange={e => {
                    if (isChecked) {
                      setIncludeTypes(
                        includeTypes.filter(i => i.value !== et.value)
                      )
                    } else {
                      setIncludeTypes(
                        uniqBy([...includeTypes, et], v => v.value)
                      )
                    }
                  }}
                />
              </FormGroup>
            )
          })}
        </Flex>
      </FormGroup>

      <>
        <h5>Your Recent Activity {loading && <Spinner />}</h5>
        <Space block border height={15} />
        <SubmissionList
          loadingMore={networkStatus === 3}
          groupBy={`callForService.id`}
          compact
          totalUnpaginatedResults={allSubs?.totalCount}
          cacheKey="myActivityFeed"
          cacheQuery={GET_MY_ACTIVITY_FEED}
          refetchQueries={['GetMyEvents']}
          includeGroups={true}
          variables={getSubmissionVariables}
          requestReviewOnly
          SubmissionsLoading={loading}
          submissions={allSubs?.feed}
          onLoadMore={() => {
            const lastIncident = last(
              allSubs?.feed.filter(
                as =>
                  !as.wasIncludedForGroups &&
                  as.__typename === 'Submission'
              )
            )

            const lastFormResponse = last(
              allSubs?.feed.filter(
                as => as.__typename === 'FormResponse'
              )
            )

            const lastBulkIncident = last(
              allSubs?.feed.filter(
                as =>
                  !as.wasIncludedForGroups &&
                  as.__typename === 'BulkSubmission'
              )
            )

            fetchMore({
              variables: {
                ...getSubmissionVariables,
                ...(allSubs?.feed &&
                  lastIncident && {
                    cursor: {
                      id: lastIncident.id
                    }
                  }),
                formResponseCursor:
                  allSubs?.feed && lastFormResponse
                    ? {
                        id: lastFormResponse?.id
                      }
                    : null,

                bulkSubmissionCursor:
                  allSubs?.feed && lastBulkIncident
                    ? {
                        id:
                          lastBulkIncident?.submissionIds?.[
                            lastBulkIncident?.submissionIds?.length -
                              1
                          ]
                      }
                    : null,
                take: 10
              },
              updateQuery: (previousResult, { fetchMoreResult }) => {
                const previousEntry =
                  previousResult.myActivityFeed?.feed
                const newsubmissions =
                  fetchMoreResult.myActivityFeed?.feed

                return {
                  // By returning `cursor` here, we update the `fetchMore` function
                  // to the new cursor.

                  // Put the new submissions in the front of the list
                  myActivityFeed: {
                    ...previousResult.myActivityFeed,
                    feed: uniqBy(
                      [...previousEntry, ...newsubmissions],
                      function(e) {
                        return e.id
                      }
                    )
                  },
                  __typename: previousEntry.__typename
                }
              }
            })
          }}
        />
        <Space height={50} />
      </>
    </Box>
  )
}

export default EditSubmissionsList
