import { useQuery } from '@apollo/client'

import { GET_FORM_QR_CODE } from 'src/app/hooks/queries'

import Spinner from 'src/images/icons/Spinner'
import InlineTooltip from '../../Shared/InlineTooltip'
import ModalButton from '../../Shared/ModalButton'
import Button from '../../Shared/Elements/Button'
import ModalBody from '../../Shared/Elements/ModalBody'

export const GenerateFormQRCode = ({ id }) => {
  return (
    <ModalButton
      buttonProps={{
        variant: 'secondary',
        icon: 'qrcode'
      }}
    >
      {() => {
        return <GenerateQRCodeModal id={id} />
      }}
    </ModalButton>
  )
}

const GenerateQRCodeModal = ({ id }) => {
  const { data, loading } = useQuery(GET_FORM_QR_CODE, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      id
    }
  })

  const uri =
    data?.form?.qrCode &&
    `data:image/svg+xml;utf8,${encodeURIComponent(
      data?.form?.qrCode
    )}`

  return (
    <ModalBody>
      {loading || !data ? (
        <Spinner centered />
      ) : (
        <>
          <InlineTooltip
            tip="Use this QR code to make this form accessible by mobile devices."
            action={
              <a href={uri} download={`${data.form.name}_QR.svg`}>
                <Button variant="primary">Download</Button>
              </a>
            }
            mb={25}
          />
          <a href={uri} download={`${data.form.name}_QR.svg`}>
            <img src={uri} alt="QR Code" />
          </a>
        </>
      )}
    </ModalBody>
  )
}
