import { useState } from 'react'

import FormError from './FormError'
import { useToast } from 'src/components/toasts'

import ModalButton from '../Shared/ModalButton'
import { useUpdateSubmission } from 'src/app/hooks/mutations'

import RichTextInput, {
  CustomRichText
} from '../Shared/RichTextInput'

import Button from '../Shared/Elements/Button'

import DOMPurify from 'dompurify'
import useGetNoteWriterInfo from 'src/app/hooks/useGetNoteWriterInfo'

const AddNoteModalForm = ({ submissionId }) => {
  const [value, setValue] = useState(null)
  const { add } = useToast()
  const noteWriterInfo = useGetNoteWriterInfo()

  const {
    updateOneSubmission,
    error,
    loading
  } = useUpdateSubmission()

  async function addNote(e, onFinish) {
    e?.preventDefault()
    try {
      const res = await updateOneSubmission({
        variables: {
          triggerSubscription: true,
          data: {
            note: DOMPurify.sanitize(value || ''),
            ...noteWriterInfo
          },
          where: {
            id: submissionId
          }
        }
      })

      if (res.data.updateOneSubmission) {
        add({
          color: 'success',
          content: 'Note Added'
        })
        onFinish()
        setValue(null)
      }
    } catch (e) {
      console.log(e)
      add({
        color: 'danger',
        content: 'Oops there was an error.'
      })
    }
  }

  return (
    <ModalButton
      buttonLabel="+ Add"
      buttonProps={{ variant: 'link' }}
      modalHeader="Add Note"
      contentWidth={'95vw'}
      renderModalActions={toggle => (
        <Button
          variant="primary"
          onClick={e => addNote(e, toggle)}
          disabled={loading}
        >
          {loading ? 'Saving...' : 'Save Note'}
        </Button>
      )}
    >
      {() => (
        <>
          <CustomRichText>
            <RichTextInput
              value={value || ''}
              onChange={content => {
                setValue(content)
              }}
              minHeight={'400px'}
            />
          </CustomRichText>
          {error && <FormError error={error.message} />}
        </>
      )}
    </ModalButton>
  )
}

export default AddNoteModalForm
