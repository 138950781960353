/** @jsxImportSource theme-ui */
import { Fragment, useState } from 'react'

import Spinner from 'src/images/icons/Spinner'

import { useQuery } from '@apollo/client'

import { prettyDate } from 'src/utils'
import { Box, Flex } from 'theme-ui'

import UserActionAuditModal from '../../../UserActionAuditModal'
import { RequestPDFReport } from '../../../Reporting/StatReport'
import { GET_FORM_RESPONSE } from 'src/app/hooks/queries'
import FormSubmitRenderer from '.'
import ModalButton from 'src/app/components/Shared/ModalButton'
import Button from 'src/app/components/Shared/Elements/Button'
import FormResponseTableView from '../FormResponseTable'

export const FormResponseSubmitRenderer = ({
  formResponseId,
  tableView: defaultTableView = false,
  includeReporting,
  hideLinkToIncident,
  hideEdit,
  spinnerProps,
  onFinish,
  onDraftFinish
}) => {
  const { data } = useQuery(GET_FORM_RESPONSE, {
    variables: {
      where: {
        id: formResponseId
      }
    },
    onCompleted: data => {
      if (data?.formResponse?.draft) {
        setTableView(false)
      }
    },
    fetchPolicy: 'cache-and-network'
  })
  const [tableView, setTableView] = useState(
    defaultTableView || !data?.formResponse?.draft
  )

  function toggleTableView() {
    setTableView(!tableView)
  }

  if (!data) {
    return spinnerProps === false ? (
      <Fragment />
    ) : (
      <Spinner containerHeight={'200px'} centered {...spinnerProps} />
    )
  }

  const formResponse = data.formResponse
  const form = formResponse?.form

  const allFieldItems = formResponse?.fields?.reduce((acc, field) => {
    return [...acc, ...field.fieldItems]
  }, [])

  return (
    <>
      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%'
        }}
      >
        <Box
          sx={{
            flex: 2
          }}
        >
          <Flex
            sx={{
              alignItems: 'center'
            }}
          >
            {form?.formTemplate &&
              form.formTemplate.secureImageUrl && (
                <img
                  alt={form.name}
                  src={form?.formTemplate.secureImageUrl}
                  width="60px"
                  height="60px"
                />
              )}
            <h5 style={{ margin: '0px' }}>{form.name}</h5>
            <UserActionAuditModal
              contextData={allFieldItems}
              rowId={formResponse.id}
            />
          </Flex>
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: 5,
              margin: 0,
              gap: '10px'
            }}
          >
            <div>
              {formResponse &&
                formResponse.submittedBy &&
                `${formResponse.submittedBy.name}`}{' '}
              {' - '}
              {`${prettyDate(formResponse.createdAt)}`}
            </div>

            {formResponse?.submission?.id && !hideLinkToIncident && (
              <Button
                size="sm"
                href={`/agency/stats?incidentId=${formResponse?.submission?.id}`}
                icon="linked"
                variant="warning"
              >
                Linked to Incident
              </Button>
            )}
          </p>
        </Box>
        <Flex
          sx={{
            gap: '20px',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          {includeReporting && (
            <ModalButton
              buttonLabel="Report"
              buttonProps={{ variant: 'warning' }}
              isValidToggle={() => {
                return true
              }}
            >
              {({ toggle }) => {
                return (
                  <RequestPDFReport
                    reportName={form.name}
                    formResponseId={formResponse.id}
                    onClose={toggle}
                  />
                )
              }}
            </ModalButton>
          )}
          {!hideEdit && !formResponse.draft && (
            <Button
              variant="linkGray"
              onClick={() => {
                toggleTableView()
              }}
              icon={tableView ? 'pencil' : 'close'}
            >
              {tableView ? 'Edit' : 'Cancel Edit'}
            </Button>
          )}
        </Flex>
      </Flex>

      {tableView ? (
        <FormResponseTableView
          formResponse={formResponse}
          formId={form?.id}
          spinnerProps={spinnerProps}
        />
      ) : (
        <FormSubmitRenderer
          hideTitle
          formResponse={formResponse}
          formId={form?.id}
          onFinish={() => {
            onFinish && onFinish()
            setTableView(true)
          }}
          onDraftFinish={onDraftFinish}
        />
      )}
    </>
  )
}
