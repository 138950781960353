import { useApolloClient, gql, useQuery } from '@apollo/client'

export const HIDDEN_GROUPS_QUERY = gql`
  query HiddenGroups {
    hiddenGroups @client
  }
`

export const useHiddenGroups = () => {
  const client = useApolloClient()
  const { data } = useQuery(HIDDEN_GROUPS_QUERY)

  const hiddenGroups = data?.hiddenGroups || []

  const hideGroup = (groupId = 'noCategory') => {
    const newHiddenGroups = [...hiddenGroups, groupId]
    client.writeQuery({
      query: HIDDEN_GROUPS_QUERY,
      data: {
        hiddenGroups: newHiddenGroups
      }
    })
  }

  const showGroup = (groupId = 'noCategory') => {
    const newHiddenGroups = hiddenGroups.filter(id => {
      if (!groupId && !id) return false

      return id !== groupId
    })
    client.writeQuery({
      query: HIDDEN_GROUPS_QUERY,
      data: {
        hiddenGroups: newHiddenGroups
      }
    })
  }

  return {
    hiddenGroups,
    hideGroup,
    showGroup
  }
}
