/** @jsxImportSource theme-ui */
import { useState, useEffect } from 'react'
import { Flex, Input } from 'theme-ui'
import {
  PhoneNumberUtil,
  PhoneNumberFormat
} from 'google-libphonenumber'

const phoneUtil = PhoneNumberUtil.getInstance()

const countryToDialCode = {
  US: '+1',
  AU: '+61',
  GB: '+44',
  INTL: '+'
  // Add more mappings as necessary
}

const languageToCountry = {
  'en-US': 'US',
  'en-AU': 'AU',
  'en-GB': 'GB'
}

function getDefaultFromLanguage(language) {
  if (languageToCountry[language]) {
    return languageToCountry[language]
  }

  return 'US'
}

function PhoneNumberInput({
  onChange,

  value = '',
  ...props
}) {
  const [nationalNumber, setNationalNumber] = useState('')
  const [valid, setValid] = useState(true)
  const [selectedCountry, setSelectedCountry] = useState('US')

  // Update the national number based on initial value
  useEffect(() => {
    if (value) {
      try {
        const number = phoneUtil.parse(value)
        const countryCode = phoneUtil.getRegionCodeForNumber(number)
        const formattedNumber = phoneUtil.format(
          number,
          PhoneNumberFormat.INTERNATIONAL
        )
        const isUS_AU_GB = ['US', 'AU', 'GB'].includes(countryCode)

        setSelectedCountry(isUS_AU_GB ? countryCode : 'INTL')
        setNationalNumber(
          isUS_AU_GB
            ? phoneUtil.format(number, PhoneNumberFormat.NATIONAL)
            : formattedNumber
        )
        setValid(true)
      } catch (error) {
        console.error(error)
        setNationalNumber('')
        setValid(false)
      }
    } else {
      const usersLanguage = window?.navigator?.language
      setSelectedCountry(getDefaultFromLanguage(usersLanguage))
    }
  }, [value])

  const handleChange = e => {
    const inputNumber = e.target.value
    setNationalNumber(inputNumber)

    try {
      const fullNumber = `${countryToDialCode[selectedCountry]}${inputNumber}`
      const number = phoneUtil.parseAndKeepRawInput(
        fullNumber,
        selectedCountry
      )
      const newValid = phoneUtil.isValidNumber(number)
      setValid(newValid)

      if (newValid && onChange) {
        onChange(phoneUtil.format(number, PhoneNumberFormat.E164))
      }
    } catch (error) {
      console.error(error)
      setValid(false)
    }
  }

  const handleCountryChange = e => {
    const newCountry = e.target.value
    setSelectedCountry(newCountry)
    setNationalNumber('') // Optionally clear the number on country change
    setValid(true) // Reset validation state
  }

  return (
    <Flex
      sx={{ position: 'relative', alignItems: 'center', gap: '4px' }}
    >
      <select
        sx={{ height: '34px', borderRadius: '4px', fontSize: '10px' }}
        value={selectedCountry}
        onChange={handleCountryChange}
      >
        {Object.keys(countryToDialCode).map(countryCode => (
          <option key={countryCode} value={countryCode}>
            {countryCode} {countryToDialCode[countryCode]}
          </option>
        ))}
      </select>

      <Input
        {...props}
        type="tel"
        value={nationalNumber}
        onChange={handleChange}
        placeholder="Enter phone number"
        style={valid ? {} : { border: '1px solid red' }}
      />
    </Flex>
  )
}

export default PhoneNumberInput
