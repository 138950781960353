import { useContext, useEffect, useState } from 'react'
import { ConfirmContext } from '../components/Shared/Elements/ConfirmModal'

export const useConfirm = () => {
  const [confirm, setConfirm] = useContext(ConfirmContext)
  const [needsCleanup, setNeedsCleanup] = useState(false)
  const isConfirmed = (
    prompt,
    acceptButtonText,
    secondaryActionText
  ) => {
    setNeedsCleanup(true)
    let customActionIndex
    const promise = new Promise((resolve, reject) => {
      setConfirm({
        prompt,
        secondaryActionText,
        acceptButtonText,
        isOpen: true,
        proceed: index => {
          customActionIndex = index || false
          resolve()
        },
        cancel: reject
      })
    })

    return promise.then(
      () => {
        setConfirm({ ...confirm, isOpen: false })

        return customActionIndex || true
      },
      () => {
        setConfirm({ ...confirm, isOpen: false })
        return false
      }
    )
  }
  useEffect(() => {
    return () => {
      if (confirm.cancel && needsCleanup) {
        confirm.cancel()
      }
    }
  }, [confirm, needsCleanup])
  return {
    ...confirm,
    isConfirmed
  }
}
