/** @jsxImportSource theme-ui */
import { forwardRef } from 'react'
import { Box } from 'theme-ui'

const InputGroupText = (
  { children, onSubmit, variant = null, sx, ...props },
  ref
) => {
  return (
    <Box
      as="form"
      onSubmit={onSubmit}
      variant={variant}
      {...props}
      sx={{ ...sx }}
    >
      {children}
    </Box>
  )
}

export default forwardRef(InputGroupText)
