import { useState } from 'react'
import { useUpdateManySubmissions } from 'src/app/hooks/mutations'
import { useGetCurrentAgencyContext } from 'src/app/hooks/queries'
import { useToast } from 'src/components/toasts'
import { cleanGraphQLError } from '../Forms/FormError'

import ModalButton from '../Shared/ModalButton'
import FormGroup from '../Shared/Elements/FormGroup'
import CustomSelectInput from '../Forms/CustomSelectInput'
import Button from '../Shared/Elements/Button'
import { Box, Flex } from 'theme-ui'
import ModalFooter from '../Shared/Elements/ModalFooter'

export const BulkSubmissionResourceForm = ({
  onFinish,
  submissionIds,
  resources,
  destination,
  mutationOptions
}) => {
  const { add } = useToast()

  const {
    updateManySubmissions,
    loading: loadingMany
  } = useUpdateManySubmissions(mutationOptions)
  const { positions, users } = useGetCurrentAgencyContext()
  const [values, setValues] = useState(resources)

  async function onSave() {
    const res = await updateManySubmissions({
      variables: {
        data: {
          resources: values
            .filter(r => r.unit || r.personnel)
            .map(r => ({
              ...(destination && {
                destination: {
                  connect: {
                    id: destination.id
                  }
                }
              }),
              personnel: {
                connect: {
                  id: r.personnel.id
                }
              },
              unit: {
                connect: {
                  id: r.unit.id
                }
              }
            }))
        },
        where: {
          ids: submissionIds
        }
      }
    })

    if (!res.errors) {
      onFinish()
    } else {
      console.log(res.errors)
      add({
        variant: 'danger',
        content: cleanGraphQLError(res.errors)
      })
    }
  }

  const removeResource = k => {
    setValues(prev => {
      const newValues = [...prev]
      newValues.splice(k, 1)
      return newValues
    })
  }

  const changedNestedValue = (key, k, e) => {
    setValues(prev => {
      const newValues = prev.map((item, index) =>
        index === k ? { ...item, [key]: e } : item
      )
      return newValues
    })
  }

  return (
    <Box>
      {values.map((r, k) => (
        <Flex
          sx={{
            width: '100%',
            alignItems: 'center',
            gap: '10px',
            mb: '15px'
          }}
        >
          <FormGroup
            sx={{
              minWidth: '200px',
              mb: '0px'
            }}
            key={k}
          >
            <CustomSelectInput
              getOptionLabel={v => v.name}
              getOptionValue={v => v.id}
              id={`unit${k}`}
              name={`unit${k}`}
              options={positions.filter(p => !p.locationOnly)}
              value={r.unit}
              onChange={e => {
                changedNestedValue('unit', k, e)
              }}
            />
          </FormGroup>
          <FormGroup
            sx={{
              minWidth: '200px',
              mb: '0px'
            }}
          >
            <CustomSelectInput
              getOptionLabel={v => v.name}
              getOptionValue={v => v.id}
              id={`personnel${k}`}
              name={`personnel${k}`}
              options={users}
              value={r.personnel}
              onChange={e => {
                changedNestedValue('personnel', k, e)
              }}
            />
          </FormGroup>
          <Button
            onClick={() => removeResource(k)}
            icon={'close'}
            variant="secondary"
          />
        </Flex>
      ))}
      <Button onClick={() => setValues(prev => [...prev, {}])}>
        Add Resource +
      </Button>
      <ModalFooter onClose={onFinish}>
        <Button
          onClick={onSave}
          disabled={loadingMany}
          icon={loadingMany && 'spinner'}
        >
          Update
        </Button>
      </ModalFooter>
    </Box>
  )
}

const BulkeSubmissionResourceModal = ({
  resources,
  destination,
  submissionIds,
  cacheQuery,
  cacheKey,
  variables
}) => {
  return (
    <ModalButton
      buttonProps={{
        icon: 'pencil',
        variant: 'link',
        size: 'sm',
        sx: {
          p: '0px'
        }
      }}
    >
      {({ toggle }) => {
        return (
          <BulkSubmissionResourceForm
            submissionIds={submissionIds}
            onFinish={toggle}
            resources={resources}
            destination={destination}
            mutationOptions={{
              refetchQueries: [
                {
                  query: cacheQuery,
                  variables
                }
              ]
            }}
          />
        )
      }}
    </ModalButton>
  )
}

export default BulkeSubmissionResourceModal
