/** @jsxImportSource theme-ui */
import { forwardRef } from 'react'
import { Flex } from 'theme-ui'

const NavBar = (
  { children, variant = 'NavBar', sx, ...props },
  ref
) => {
  return (
    <Flex as="nav" variant={variant} sx={{ ...sx }} {...props}>
      {children}
    </Flex>
  )
}

export default forwardRef(NavBar)
