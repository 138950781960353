import chroma from 'chroma-js'

export const getContrastColor = (
  color,
  contrast = 5,
  contrastColor = 'black' // Default to black
) => {
  return chroma.contrast(
    !color ? chroma('#fff') : chroma(color),
    contrastColor
  ) > contrast
    ? contrastColor // Return the contrastColor if contrast is greater than the threshold
    : 'background'
}
