function Dashboard(props) {
  const title = props.title || 'public dashboard'

  return (
    <svg
      height={props.height || '64'}
      width={props.width || '64'}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill={props.color || '#212529'}>
        <path d="M62,30H54a1,1,0,0,1,0-2h8a1,1,0,0,1,0,2Z" />
        <path d="M51,20a1,1,0,0,1-.5-1.868l7-4a1,1,0,0,1,.992,1.736l-7,4A.988.988,0,0,1,51,20Z" />
        <path d="M58,44a.988.988,0,0,1-.5-.132l-7-4a1,1,0,1,1,.992-1.736l7,4A1,1,0,0,1,58,44Z" />
        <path
          d="M24,47.406v4.173l-13-3.25V43.691l-3-.857V49.5a1.5,1.5,0,0,0,1.136,1.455l16,4A1.5,1.5,0,0,0,27,53.5V48.263Z"
          fill="#0d0c0c"
        />
        <path
          d="M44.6,7.2a1,1,0,0,0-.877-.163L5.351,18A6.027,6.027,0,0,0,1,23.772V34.228A6.027,6.027,0,0,0,5.351,40L43.725,50.961A1,1,0,0,0,45,50V8A1,1,0,0,0,44.6,7.2Z"
          fill={props.color || '#212529'}
        />
      </g>
    </svg>
  )
}

export default Dashboard
