/** @jsxImportSource theme-ui */
import { Box, useThemeUI } from 'theme-ui'

// Icons
import Profile from 'src/images/icons/Profile'
import HQ from 'src/images/icons/HQ'
import Dispatch from 'src/images/icons/Dispatch'
import Paperclip from 'src/images/icons/Paperclip'
import Lightning from 'src/images/icons/Lightning'
import Stats from 'src/images/icons/Stats'
import Help from 'src/images/icons/Help'
import Explore from 'src/images/icons/Explore'
import CloseBurger from 'src/images/icons/CloseBurger'
import OpenBurger from 'src/images/icons/OpenBurger'
import Phone from 'src/images/icons/Phone'
import Email from 'src/images/icons/Email'
import Spinner from 'src/images/icons/Spinner'
import Dashboard from 'src/images/icons/Dashboard'
import Agency from 'src/images/icons/Agency'
import DownArrow from 'src/images/icons/DownArrow'
import UpArrow from 'src/images/icons/UpArrow'
import Eyeball from 'src/images/icons/Eyeball'
import CheckMark from 'src/images/icons/CheckMark'
import Sync from 'src/images/icons/Sync'
import X from 'src/images/icons/X'
import Grid from 'src/images/icons/Grid'
import List from 'src/images/icons/List'
import Download from 'src/images/icons/Download'
import Success from 'src/images/icons/Success'
import OpenTab from 'src/images/icons/OpenTab'
import SubmitForm from 'src/images/icons/SubmitForm'
import Like from 'src/images/icons/Like'
import Polling from 'src/images/icons/Polling'
import EmptyList from 'src/images/icons/EmptyList'
import Pencil from 'src/images/icons/Pencil'
import DeleteForever from 'src/images/icons/Trash'
import Duplicate from 'src/images/icons/Duplicate'
import Restore from 'src/images/icons/Restore'
import Filter from 'src/images/icons/Filter'
import Dots from 'src/images/icons/Dots'
import Replay from 'src/images/icons/Repeat'
import IncidentsColored from 'src/images/icons/IncidentsColored'
import OperationsColored from 'src/images/icons/OperationsColored'
import BlankTemplate from 'src/images/icons/BlankTemplate'
import Team from 'src/images/icons/Team'
import Bar from '../../../images/icons/Bar'
import Share from 'src/images/icons/Share'
import Priority from 'src/images/icons/Priority'
import Carousel from 'src/images/icons/Carousel'
import Lock from 'src/images/icons/Lock'
import TwitterIconCheckbox from 'src/images/icons/TwitterIconCheckbox'
import SafeBeachDayCheckbox from 'src/images/icons/SafeBeachDayCheckbox'
import TextBroadcastCheckbox from 'src/images/icons/TextBroadcastCheckbox'
import TwitterSmallIcon from 'src/images/icons/TwitterSmallIcon'
import TextBroadcastSmallIcon from 'src/images/icons/TextBroadcastSmallIcon'
import Pie from 'src/images/icons/Pie'
import Table from 'src/images/icons/Table'
import Calendar from 'src/images/icons/Calendar'
import World from 'src/images/icons/world'
import MapLayer from 'src/images/icons/MapLayer'
import Pulse from 'src/images/icons/Pulse'
import QRCode from 'src/images/icons/QRCode'
import Alert from 'src/images/icons/Alert'
import Upload from 'src/images/icons/Upload'
import UploadSuccess from 'src/images/icons/UploadSuccess'
import CompactList from 'src/images/icons/CompactList'
import Google from 'src/images/icons/Google'
import Microsoft from 'src/images/icons/Microsoft'
import Lost from 'src/images/icons/Lost'
import Pin from 'src/images/icons/Pin'
import GPS from 'src/images/icons/GPS'
import Verified from 'src/images/icons/Verified'
import Time from 'src/images/icons/Time'
import MapIcon from 'src/images/icons/Map'
import CheckboxChecked from 'src/images/icons/CheckboxChecked'
import Bell from 'src/images/icons/Bell'
import Offline from 'src/images/icons/Offline'
import Asterix from 'src/images/icons/Asterix'
import ResizeX from 'src/images/icons/ResizeX'
import ResizeY from 'src/images/icons/ResizeY'
import SettingsGear from 'src/images/icons/Gear'
import Linked from 'src/images/icons/Linked'
import Heart from 'src/images/icons/Heart'
import Key from 'src/images/icons/Key'
import Sweep from 'src/images/icons/Sweep'
import Star from 'src/images/icons/Star'
import Sharing from 'src/images/icons/Sharing'

const Icon = ({ icon, color, ...props }) => {
  const { theme } = useThemeUI()

  //Make icons theme aware for colors
  color = color
    ? theme.rawColors[color] || color
    : theme.colors.gray900

  switch (icon?.toLowerCase()) {
    case 'star':
      return <Star color={color} {...props} />
    case 'heart':
      return <Heart color={color} {...props} />
    case 'uploadsuccess':
      return <UploadSuccess color={color} {...props} />
    case 'upload':
      return <Upload color={color} {...props} />
    case 'critical':
      return <Alert color={color} {...props} />
    case 'attention':
      return <Alert color={color} {...props} />
    case 'lock':
      return <Lock color={color} {...props} />
    case 'carousel':
      return <Carousel color={color} {...props} />
    case 'priority':
      return <Priority color={color} {...props} />
    case 'share':
      return <Share color={color} {...props} />
    case 'pulse':
      return <Pulse color={color} {...props} />
    case 'team':
      return <Team color={color} {...props} />
    case 'bar':
      return <Bar color={color} {...props} />
    case 'sharing':
      return <Sharing color={color} {...props} />
    case 'blanktemplate':
      return <BlankTemplate color={color} {...props} />
    case 'checkboxchecked':
      return <CheckboxChecked color={color} {...props} />
    case 'operationscolored':
      return <OperationsColored color={color} {...props} />
    case 'incidentscolored':
      return <IncidentsColored color={color} {...props} />
    case 'filter':
      return <Filter color={color} {...props} />
    case 'replay':
      return <Replay color={color} {...props} />
    case 'restore':
      return <Restore color={color} {...props} />
    case 'duplicate':
      return <Duplicate color={color} {...props} />
    case 'trash':
      return <DeleteForever color={color} {...props} />
    case 'opentab':
      return <OpenTab color={color} {...props} />
    case 'success':
      return <Success color={color} {...props} />
    case 'x':
      return <X color={color} {...props} />
    case 'checkmark':
      return <CheckMark color={color} {...props} />
    case 'hq':
      return <HQ color={color} {...props} />
    case 'qrcode':
      return <QRCode color={color} {...props} />
    case 'profile':
      return <Profile color={color} {...props} />
    case 'dispatch':
      return <Dispatch color={color} {...props} />
    case 'paperclip':
      return <Paperclip color={color} {...props} />
    case 'lightning':
      return <Lightning color={color} {...props} />
    case 'stats':
      return <Stats color={color} {...props} />
    case 'trend':
      return <Stats color={color} {...props} />
    case 'pie':
      return <Pie color={color} {...props} />
    case 'table':
      return <Table color={color} {...props} />
    case 'calendar':
      return <Calendar color={color} {...props} />
    case 'geo':
      return <World color={color} {...props} />
    case 'maplayer':
      return <MapLayer color={color} {...props} />
    case 'help':
      return <Help color={color} {...props} />
    case 'lost':
      return <Lost color={color} {...props} />
    case 'explore':
      return <Explore color={color} {...props} />
    case 'search':
      return <Explore title="search" color={color} {...props} />
    case 'close burger':
      return <CloseBurger color={color} {...props} />
    case 'open burger':
      return <OpenBurger color={color} {...props} />
    case 'phone':
      return <Phone color={color} {...props} />
    case 'email':
      return <Email color={color} {...props} />
    case 'spinner':
      return <Spinner color={color} {...props} />
    case 'dashboard':
      return <Dashboard color={color} {...props} />
    case 'agency':
      return <Agency color={color} {...props} />
    case 'down arrow':
      return <DownArrow color={color} {...props} />
    case 'compactlist':
      return <CompactList color={color} {...props} />
    case 'linked':
      return <Linked color={color} {...props} />
    case 'right arrow':
      return (
        <DownArrow
          {...props}
          color={color}
          style={{ ...props.style, transform: 'rotate(90deg)' }}
        />
      )
    case 'left arrow':
      return (
        <DownArrow
          {...props}
          color={color}
          style={{ ...props.style, transform: 'rotate(270deg)' }}
        />
      )
    case 'gps':
      return <GPS color={color} {...props} />
    case 'up arrow':
      return <UpArrow color={color} {...props} />
    case 'eyeball':
      return <Eyeball color={color} {...props} />
    case 'sync':
      return <Sync color={color} {...props} />
    case 'close':
      return <X color={color} {...props} />
    case 'grid':
      return <Grid color={color} {...props} />
    case 'list':
      return <List color={color} {...props} />
    case 'download':
      return <Download color={color} {...props} />
    case 'submit':
      return <SubmitForm color={color} {...props} />
    case 'like':
      return <Like color={color} {...props} />
    case 'polling':
      return <Polling color={color} {...props} />
    case 'empty list':
      return <EmptyList color={color} {...props} />
    case 'pencil':
      return <Pencil color={color} {...props} />
    case 'dots':
      return <Dots color={color} {...props} />
    case 'twitter-checkbox':
      // Forcing color to null because you can only use Twitter's blue when displaying their icons
      return <TwitterIconCheckbox color={null} {...props} />
    case 'twitter-small-icon':
      // Forcing color to null because you can only use Twitter's blue when displaying their icons
      return <TwitterSmallIcon color={null} {...props} />
    case 'safe-beach-day-checkbox':
      // Forcing color to null since SVG relies on several colors
      return <SafeBeachDayCheckbox color={null} {...props} />
    case 'text-broadcast-checkbox':
      // Forcing color to null since SVG relies on several colors
      return <TextBroadcastCheckbox color={null} {...props} />
    case 'text-broadcast-small-icon':
      // Forcing color to null since SVG relies on several colors
      return <TextBroadcastSmallIcon color={null} {...props} />
    case 'minus':
      return <Box color={color} {...props}>{`-`}</Box>
    case 'plus':
      return <Box color={color} {...props}>{`+`}</Box>
    case 'google':
      return <Google {...props} />
    case 'microsoft':
      return <Microsoft {...props} />
    case 'pin':
      return <Pin color={color} {...props} />
    case 'verified':
      return <Verified color={color} {...props} />
    case 'time':
      return <Time color={color} {...props} />
    case 'map':
      return <MapIcon color={color} {...props} />
    case 'bell':
      return <Bell color={color} {...props} />
    case 'offline':
      return <Offline color={color} {...props} />
    case 'asterix':
      return <Asterix color={color} {...props} />
    case 'resizex':
      return <ResizeX color={color} {...props} />
    case 'resizey':
      return <ResizeY color={color} {...props} />
    case 'gear':
      return <SettingsGear color={color} {...props} />
    case 'key':
      return <Key color={color} {...props} />
    case 'sweep':
      return <Sweep color={color} {...props} />
    default:
      return null
  }
}

export default Icon
