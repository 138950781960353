import React from 'react'

function Heart(props) {
  const title = props.title || 'hearts suit'

  return (
    <svg
      height={props?.height || '48'}
      width={props?.width || '48'}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill={props?.color || '#f7f7f7'}>
        <path
          d="M33.5,6A11.548,11.548,0,0,0,24,11.039,11.491,11.491,0,0,0,3,17.5a11.422,11.422,0,0,0,3.246,8L23.27,43.684a1,1,0,0,0,1.46,0l17.01-18.17A11.494,11.494,0,0,0,33.5,6Z"
          fill={props?.color || '#f7f7f7'}
        />
      </g>
    </svg>
  )
}

export default Heart
