import BackgroundImage from 'src/app/components/Shared/BackgroundImage'
import NotFound from '../images/NotFound.jpeg'
import Button from 'src/app/components/Shared/Elements/Button'
import Jumbotron from 'src/app/components/Shared/Elements/Jumbotron'
import { redirect, useNavigate } from 'react-router'

const NotFoundPage = () => {
  const navigate = useNavigate()
  return (
    <>
      <BackgroundImage
        style={{ height: '100vh' }}
        Tag="section"
        src={NotFound}
      >
        <Jumbotron
          style={{
            backgroundColor: 'transparent',
            color: 'white',
            textAlign: 'center'
          }}
        >
          <h1>Oops, this is probably our fault..</h1>
          <p>
            You just hit a route that doesn&#39;t exist... the
            sadness.
          </p>
          <Button
            sx={{
              backgroundColor: 'white',
              color: 'black',
              border: 'none',
              borderRadius: '5px',
              padding: '10px',
              cursor: 'pointer',
              margin: 'auto'
            }}
            onClick={() => navigate('/agency')}
          >
            Back to home
          </Button>
        </Jumbotron>
      </BackgroundImage>
    </>
  )
}

export default NotFoundPage
