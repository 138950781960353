function Attach(props) {
  const title = props.title || 'attach'

  return (
    <svg
      height={props.height || '64'}
      width={props.width || '64'}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill={props.color || '#303035'}>
        <path
          d="M10.973,53.708c-6.626-7.148-6.1-18.375.8-25.267L31.7,8.514A13.25,13.25,0,0,1,49.872,7.8a13.014,13.014,0,0,1,.475,18.837L31.455,45.524a8.189,8.189,0,0,1-11.113.574,8.008,8.008,0,0,1-.4-11.688L39.034,15.318a1,1,0,0,1,1.414,0l1.414,1.414a1,1,0,0,1,0,1.414L22.9,37.114A4.122,4.122,0,0,0,22.5,42.6a4,4,0,0,0,5.925.3L47.293,24.03a9.212,9.212,0,0,0,.645-12.5,9.01,9.01,0,0,0-13.149-.45L14.571,31.3A14.269,14.269,0,0,0,13.8,50.87a14.014,14.014,0,0,0,20.285.511L54.589,30.875a1,1,0,0,1,1.414,0l1.414,1.414a1,1,0,0,1,0,1.414L36.912,54.209A18.019,18.019,0,0,1,10.973,53.708Z"
          fill={props.color || '#303035'}
        />
      </g>
    </svg>
  )
}

export default Attach
