import { createContext } from 'react'

export const FormContext = createContext()

export function isValidValue(val) {
  return val !== undefined && val !== null && val !== ''
}

export function normalizeStringorNumber(item, isNumber) {
  if (!item) return item

  return typeof item === 'string' && !isNumber
    ? item
    : parseFloat(item)
}

export function getValueOfOtherAnswer(options, val, type) {
  if (!val || val.length === 0) return false
  const isNumber = type === 'number'
  const normalizedOptions = normalizeScalarOrArray(options, isNumber)
  const normalizedVal = normalizeScalarOrArray(val, isNumber)

  const otherVal = Array.isArray(normalizedVal)
    ? normalizedVal.filter(function(item) {
        return normalizedOptions.indexOf(item) === -1
      })[0]
    : !normalizedOptions.includes(normalizedVal) && normalizedVal

  return otherVal
}

function ciEquals(a, b) {
  return typeof a === 'string' && typeof b === 'string'
    ? a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0
    : a === b
}

export function getIsValAnswerSingleOrArray(option, val) {
  return Array.isArray(val)
    ? val.some(v => ciEquals(v, option))
    : ciEquals(val, option)
}

export function normalizeScalarOrArray(item, isNumber) {
  if (Array.isArray(item)) {
    return item.map(i =>
      typeof i !== 'object' ? normalizeStringorNumber(i, isNumber) : i
    )
  }

  return typeof item !== 'object'
    ? normalizeStringorNumber(item, isNumber)
    : item
}
