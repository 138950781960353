/** @jsxImportSource theme-ui */
import { Fragment } from 'react'
import { useMutation, useQuery } from '@apollo/client'

import { SUBMIT_FORM } from 'src/app/hooks/mutations'
import {
  GET_FORM,
  useGetLatestSupervisorReviews
} from 'src/app/hooks/queries'
import { useToast } from 'src/components/toasts'
import Spinner from 'src/images/icons/Spinner'
import { theme } from 'src/styles'
import { getContrastColor } from 'src/utils/getContrastColor'

import { cleanGraphQLError } from '../Forms/FormError'

import { mapValuesToSubmitForm } from '../Operations/Forms/formSubmitRenderer/dataMapping'
import { FormResponseSubmitRenderer } from '../Operations/Forms/formSubmitRenderer/FormResponseSubmitRenderer'
import { FormSubmitRendererModalButton } from '../Operations/Forms/formSubmitRenderer/FormSubmitRendererModalButton'
import Button from '../Shared/Elements/Button'
import ModalBody from '../Shared/Elements/ModalBody'
import ModalFooter from '../Shared/Elements/ModalFooter'

import InlineTooltip from '../Shared/InlineTooltip'
import ModalButton from '../Shared/ModalButton'
import { Box } from 'theme-ui'

const READY_FOR_REVIEW = 'Ready for Review'

function getReviewButtonProps(currentStatusColor) {
  const contrastColor = getContrastColor(
    theme.colors[currentStatusColor] || currentStatusColor,
    3
  )
  return {
    iconColor: contrastColor,

    sx: {
      bg: currentStatusColor,
      color: contrastColor,
      border: 'none',
      ':hover': {
        bg: currentStatusColor,
        opacity: 0.8
      }
    },
    size: 'sm'
  }
}

export const SupervisorReviewModalButton = ({
  formId,
  submissionId,
  currentStatus,
  currentStatusColor,
  callId,
  refetchQueries
}) => {
  return (
    <>
      <FormSubmitRendererModalButton
        refetchQueries={refetchQueries}
        submitToastText="Review Submitted"
        preFormRenderer={
          <SupervisorReviewPreview
            formId={formId}
            submissionId={
              currentStatus !== READY_FOR_REVIEW && submissionId
            }
          />
        }
        hideTitle
        formId={formId}
        submissionId={submissionId}
        modalButtonProps={{
          modalHeader: `Supervisor Review ${callId && `(${callId})`}`,
          buttonLabel: currentStatus,
          buttonProps: {
            ...getReviewButtonProps(currentStatusColor)
          }
        }}
      />
    </>
  )
}

const SupervisorReviewPreview = ({
  submissionId,
  emptyState,
  formId
}) => {
  const { data, error, loading } = useGetLatestSupervisorReviews(
    submissionId,
    {
      skip: !submissionId
    }
  )

  const lastReviews = data

  if (loading) return <Spinner containerHeight={200} centered />
  if (error) return <div>{cleanGraphQLError(error)}</div>
  if (!lastReviews?.length) return emptyState || <Fragment />

  return (
    <Box
      sx={{
        pt: 20
      }}
    >
      {lastReviews?.map((lr, k) => (
        <Box
          sx={{
            my: 4,
            pb: 4,
            borderBottom:
              k === lastReviews.length - 1 ? 'none' : 'default'
          }}
          key={k}
        >
          <FormResponseSubmitRenderer
            key={k}
            includeReporting={false}
            formId={formId}
            formResponseId={lr.id}
            hideLinkToIncident
            tableView
            spinnerProps={false}
          />
        </Box>
      ))}
    </Box>
  )
}

export const RequestSupervisorReview = ({
  currentStatus,
  onFinish,
  submissionId,
  formId
}) => {
  const { add } = useToast()
  const [submitForm, { loading: submitting }] = useMutation(
    SUBMIT_FORM
  )

  const { data, loading: loadingForm } = useQuery(GET_FORM, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip: !formId,
    variables: {
      where: { id: formId },
      fieldsWhere: {
        archived: {
          equals: false
        }
      },
      fieldItemsWhere: {
        archived: {
          equals: false
        }
      }
    }
  })
  const form = data?.form

  const defaultStateOptions =
    form?.supervisorReviewConfig?.stateOptions?.find(
      stateOption => stateOption.requestReviewState
    )?.state || READY_FOR_REVIEW
  const isRequested = currentStatus === defaultStateOptions

  async function requestReview() {
    try {
      const values = {
        formId: formId,
        submissionId,
        fields: form?.fields
          ?.filter(field => !field.archived)
          .map(field => ({
            ...field,
            fieldItems: field.fieldItems
              .filter(fieldItem => !fieldItem.archived)
              ?.map(fieldItem => ({
                ...fieldItem,
                value:
                  form?.supervisorReviewConfig
                    ?.reviewStatusFieldItemId === fieldItem.id
                    ? defaultStateOptions
                    : fieldItem.value
              }))
          }))
      }

      const mappeddata = mapValuesToSubmitForm(values)

      let res = await submitForm({
        variables: { data: mappeddata }
      })

      if (res && !res.errors) {
        add({
          color: 'success',
          content: 'Review Requested'
        })
        onFinish && onFinish()
      } else {
        add({
          content: cleanGraphQLError(res?.errors[0]),
          color: 'danger'
        })
      }
    } catch (e) {
      console.log(e)
      add({
        content: cleanGraphQLError(e),
        color: 'danger'
      })
    }
  }

  const loading = loadingForm

  if (loading) return <Spinner centered />
  return (
    <ModalBody
      sx={{
        pt: '40px'
      }}
    >
      {!formId ? (
        <InlineTooltip
          header={'No supervisor review form configured.'}
          tip={
            'This incident type does not have a supervisor review form configured.  Please contact your administrator.'
          }
          variant="warning"
          isCloseable={false}
        />
      ) : isRequested ? (
        <InlineTooltip
          header={'Request sent'}
          tip={
            'Your review has been requested.  Reach out to your supervisor with any questions or updates.'
          }
          variant="success"
          isCloseable={false}
        />
      ) : (
        <SupervisorReviewPreview
          formId={formId}
          submissionId={
            currentStatus !== READY_FOR_REVIEW && submissionId
          }
          emptyState={
            <InlineTooltip
              header={'No Review'}
              tip={
                'This submission has not been reviewed by a supervisor.  Click the button below to request a review.'
              }
              variant="warning"
              isCloseable={false}
            />
          }
        />
      )}

      {!isRequested && formId && (
        <ModalFooter>
          <Button
            variant="primary"
            onClick={requestReview}
            icon={
              submitting ? 'spinner' : isRequested ? 'success' : null
            }
            disabled={loadingForm || submitting || isRequested}
          >
            {isRequested ? 'Review Requested' : 'Request Review'}
          </Button>
        </ModalFooter>
      )}
    </ModalBody>
  )
}

export const RequestSupervisorReviewModalButton = props => {
  return (
    <ModalButton
      buttonProps={getReviewButtonProps(props.currentStatusColor)}
      buttonLabel={props.currentStatus}
      modalHeader="Request Supervisor Review"
      contentSize={'lg'}
    >
      {({ toggle }) => {
        return (
          <RequestSupervisorReview
            {...props}
            formId={props.formId}
            onFinish={() => {
              toggle()
              props.onFinish && props.onFinish()
            }}
          />
        )
      }}
    </ModalButton>
  )
}
