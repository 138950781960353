function BlankTemplate(props) {
  const title = props.title || 'BlankTemplate'

  return (
    <svg
      height={props.height || '48'}
      width={props.width || '48'}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g>
        <rect height="2" width="5" fill="#444444" x="1" y="11" />
        <rect height="2" width="5" fill="#444444" x="1" y="35" />
        <rect height="2" width="5" fill="#444444" x="42" y="11" />
        <rect height="2" width="5" fill="#444444" x="42" y="35" />
        <rect height="5" width="2" fill="#444444" x="11" y="1" />
        <rect height="5" width="2" fill="#444444" x="35" y="1" />
        <rect height="5" width="2" fill="#444444" x="11" y="42" />
        <rect height="5" width="2" fill="#444444" x="35" y="42" />
        <rect height="6" width="6" fill="#E6E6E6" x="12" y="12" />
        <rect height="6" width="6" fill="#FFFFFF" x="18" y="12" />
        <rect height="6" width="6" fill="#E6E6E6" x="24" y="12" />
        <rect height="6" width="6" fill="#FFFFFF" x="30" y="12" />
        <rect height="6" width="6" fill="#FFFFFF" x="12" y="18" />
        <rect height="6" width="6" fill="#E6E6E6" x="18" y="18" />
        <rect height="6" width="6" fill="#FFFFFF" x="24" y="18" />
        <rect height="6" width="6" fill="#E6E6E6" x="30" y="18" />
        <rect height="6" width="6" fill="#E6E6E6" x="12" y="24" />
        <rect height="6" width="6" fill="#FFFFFF" x="18" y="24" />
        <rect height="6" width="6" fill="#E6E6E6" x="24" y="24" />
        <rect height="6" width="6" fill="#FFFFFF" x="30" y="24" />
        <rect height="6" width="6" fill="#FFFFFF" x="12" y="30" />
        <rect height="6" width="6" fill="#E6E6E6" x="18" y="30" />
        <rect height="6" width="6" fill="#FFFFFF" x="24" y="30" />
        <rect height="6" width="6" fill="#E6E6E6" x="30" y="30" />
        <path
          d="M37,38H11c-0.553,0-1-0.448-1-1V11c0-0.552,0.447-1,1-1h26c0.553,0,1,0.448,1,1v26 C38,37.552,37.553,38,37,38z M12,36h24V12H12V36z"
          fill="#B3B3B3"
        />
      </g>
    </svg>
  )
}

export default BlankTemplate
