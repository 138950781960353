export function useIdentifyUser() {
  function identify(user) {
    if (user) {
      if (window.posthog) {
        window.posthog.identify(user.id, {
          name: user.name,
          email: user.email,
          agencyName: user.agency && user.agency.name
        })
      }
    }
  }
  return {
    identify
  }
}
