import CustomSelectInput from '../Forms/CustomSelectInput'

const FilterByTag = ({ currentFilter, tags, onFilter }) => {
  return (
    <>
      <CustomSelectInput
        isMulti
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
        options={tags}
        onChange={onFilter}
        value={currentFilter}
        placeholder="Filter by tag"
      />
    </>
  )
}

export default FilterByTag
