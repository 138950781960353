import { useGetCurrentAgencyContext } from 'src/app/hooks/queries'
import {
  validateEmail,
  validatePhoneorEmail
} from 'src/utils/validations'
import CustomSelectInput from '../Forms/CustomSelectInput'
import FormError from '../Forms/FormError'

export const CreatableSelectGroup = ({
  errorMessage,
  value = [],
  options,
  onAdd, // This should now expect an array of objects instead of strings
  ...rest
}) => {
  const handleChange = selected => {
    if (errorMessage) {
      return
    }

    onAdd(selected) // Assuming `onAdd` now handles an array of objects
  }

  return (
    <div>
      <CustomSelectInput
        placeholder="Select users to send to"
        isMulti={true}
        getOptionValue={v => v.value}
        getOptionLabel={v => v.label}
        value={value}
        onChange={handleChange}
        options={options}
        creatable
        getNewOptionData={(inputValue, optionLabel) => {
          return {
            value: inputValue,
            label: optionLabel,
            __isNew__: true
          }
        }}
        {...rest}
      />
      {errorMessage && <FormError customError={errorMessage} />}
    </div>
  )
}

const UserContactSelect = ({ emailOnly, ...props }) => {
  const { users } = useGetCurrentAgencyContext()

  const userEmails = users
    .map(u => ({
      value: u.email,
      label: `${u.name} <${u.email}>`
    }))
    ?.filter(u => u.value)

  const userPhones = emailOnly
    ? []
    : users
        .map(u => ({
          value: u.phoneNumber,
          label: `${u.name} <${u.phoneNumber}>`
        }))
        ?.filter(u => u.value)

  const valuesMapped =
    props?.value?.length &&
    props?.value?.map(v => {
      if (v?.value) return v

      const user = users.find(
        u => u.email === v || u.phoneNumber === v
      )
      return {
        value: v,
        label: user?.name ? `${user?.name} <${v}>` : v
      }
    })

  return (
    <CreatableSelectGroup
      {...props}
      value={valuesMapped || []}
      validateOnCreate={value => {
        if (
          emailOnly
            ? validateEmail(value)
            : validatePhoneorEmail(value)
        ) {
          return true
        } else {
          return false
        }
      }}
      options={[...userEmails, ...userPhones]}
    />
  )
}

export default UserContactSelect
