import Badge from 'src/app/components/Shared/Elements/Badge'

import { Flex } from 'theme-ui'
import { RichTextRender } from 'src/app/components/Shared/RichTextRender'
import FieldItemRenderer from './FormFieldItemRenderer'
import { forwardRef } from 'react'

const FormFieldRenderer = forwardRef(
  (
    { title, fieldItems, archived, sectionName, ...rest },
    ref // This is the ref you're forwarding
  ) => {
    const titleQuestion = fieldItems.length === 0
    return (
      <Flex
        data-section-name={sectionName}
        ref={ref}
        sx={{
          py: '40px',
          flexWrap: 'wrap',
          position: 'relative',
          borderBottom: '1px solid #e6e6e6'
        }}
      >
        <Flex
          sx={{
            paddingRight: '20px',
            borderRight: !titleQuestion && [
              'none',
              null,
              null,
              '1px solid lightgray'
            ],
            minWidth: '100px',
            margin: '0px',
            marginRight: '10px',
            width: titleQuestion
              ? '100%'
              : ['100%', null, null, '250px'],
            wordWrap: 'break-word',
            whiteSpace: 'normal',
            paddingTop: '25px',
            alignItems: 'center'
          }}
        >
          <RichTextRender style={{ fontSize: '18px' }} text={title} />
        </Flex>
        {archived && (
          <Badge
            style={{
              position: 'absolute',
              top: 5,
              right: 5,
              p: '3px',
              textAlign: 'center'
            }}
          >
            Inactive question.
          </Badge>
        )}
        {!titleQuestion && (
          <FieldItemList items={fieldItems} {...rest} />
        )}
      </Flex>
    )
  }
)

const FieldItemList = ({ items, id, fieldId, isUpdate, ...rest }) => {
  return (
    <Flex
      sx={{
        my: ['0px', '0px', '20px'],
        alignItems: 'stretch',
        flexWrap: 'wrap',
        flex: 1
      }}
    >
      {items.map((fieldItem, k) => {
        return (
          <FieldItemRenderer
            fieldId={id}
            {...fieldItem}
            field={rest}
            isUpdate={isUpdate}
            key={k}
          />
        )
      })}
    </Flex>
  )
}

export default FormFieldRenderer
