import { createContext, useEffect, useState } from 'react'

import { Box } from 'theme-ui'

import Modal from '../../components/Shared/Elements/Modal'
import { removeSearchParams } from 'src/utils'

import { QuickSurveyBranch } from 'src/app/containers/QuickSurveyBranch'
import useGetAllSearchParams from 'src/app/hooks/useGetAllSearchParams'

export const SubmissionModalContext = createContext()

const SubmissionModalLayout = ({ children }) => {
  const [submissionModal, showSubmissionModal] = useState(null)
  const { id } = useGetAllSearchParams()

  useEffect(() => {
    if (id) {
      showSubmissionModal({ id })
      removeSearchParams()
    }
  }, [id])

  function clearSub() {
    showSubmissionModal(null)
  }

  return (
    <SubmissionModalContext.Provider
      value={{
        submissionModal: submissionModal,
        showSubmissionModal
      }}
    >
      {submissionModal?.id && (
        <Modal
          isOpen={true}
          size="lg"
          shouldCloseOnOverlayClick={false}
          checkOnClose={true}
          toggle={() => {
            clearSub()
          }}
          {...(submissionModal?.modalProps || {})}
        >
          <Box
            sx={{
              paddingTop: '20px',
              ...submissionModal?.modalContentProps
            }}
          >
            <QuickSurveyBranch
              id={submissionModal?.id}
              onFinish={() => {
                clearSub()
              }}
              {...(submissionModal || {})}
            />
          </Box>
        </Modal>
      )}
      {children}
    </SubmissionModalContext.Provider>
  )
}
export default SubmissionModalLayout
