import { useMutation } from '@apollo/client'
import { gql } from '@apollo/client'
import Spinner from 'src/images/icons/Spinner'
import { cleanGraphQLError } from './FormError'
import { useToast } from 'src/components/toasts'
import Button from '../Shared/Elements/Button'
import { RolesOnly } from 'src/app/services/auth'

const ADD_MEDICAL_REPORT_COUNT = gql`
  mutation AddMedicalRecordCount($submissionId: ID!) {
    addMedicalRecordCount(submissionId: $submissionId) {
      success
      submission {
        id
        medicalRecordCount
        medicalReportId
      }
    }
  }
`

const AddMedicalReportCountButton = ({ submissionId }) => {
  const { add } = useToast()
  const [addMedicalReport, { loading }] = useMutation(
    ADD_MEDICAL_REPORT_COUNT
  )

  async function addMedCount(e) {
    e.preventDefault()
    try {
      const res = await addMedicalReport({
        variables: {
          submissionId
        }
      })

      if (res.data.addMedicalRecordCount) {
        add({
          color: 'success',
          content: 'External Record Added'
        })
      }
    } catch (e) {
      add({
        color: 'danger',
        content: cleanGraphQLError(e.message)
      })
    }
  }

  return (
    <RolesOnly roles={['USER', 'ADMIN', 'DISPATCHER', 'SUPERVISOR']}>
      <Button
        onClick={e => addMedCount(e)}
        disabled={loading}
        variant="link"
      >
        {loading ? <Spinner /> : '+ Add'}
      </Button>
    </RolesOnly>
  )
}

export default AddMedicalReportCountButton
