import uniq from 'lodash/uniq'
import { validatePhoneorEmail } from './validations'

export function checkContactInfoHasValue(contactInfos) {
  return (
    contactInfos?.length > 0 &&
    contactInfos?.every(ci => validatePhoneorEmail(ci.value || ci))
  )
}

export function handleContactInfosSet(contactInfos) {
  return checkContactInfoHasValue(contactInfos)
    ? {
        contactInfos: {
          set: uniq(contactInfos?.map(ci => ci.value || ci))
        }
      }
    : {}
}
