import styled from '@emotion/styled'

const BImage = styled.div`
  position: relative;
  margin-bottom: 15px;
  background: ${p => `linear-gradient(
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.6)
    ), url(${p.src})`};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`

const BackgroundImage = ({ children, fullHeight, src, ...props }) => {
  return (
    <BImage
      style={{ height: fullHeight && '100%' }}
      src={src}
      {...props}
    >
      {children}
    </BImage>
  )
}

export default BackgroundImage
