import { lazy, Suspense } from 'react'
import Spinner from 'src/images/icons/Spinner'
import { retry } from 'src/utils'
import { Box } from 'theme-ui'
import CustomModal from '../../Shared/Elements/Modal'
import ModalHeader from '../../Shared/Elements/ModalHeader'

const AsyncDocumentViewer = lazy(() =>
  retry(() => import('./DocumentViewer'))
)

const DocumentViewerModal = ({
  f,
  secureFileUrl,
  isOpen,
  setModalOpen
}) => {
  return (
    <CustomModal
      toggle={() => setModalOpen(!isOpen)}
      isOpen={isOpen}
      width="97vw"
    >
      <ModalHeader toggle={() => setModalOpen(!isOpen)}>
        {f.name}
        <a
          target={'_blank'}
          rel="noopener noreferrer"
          download={f.fileName || f.name}
          href={secureFileUrl}
        >
          Download
        </a>
      </ModalHeader>
      <Box sx={{ position: 'relative' }}>
        <Suspense fallback={Spinner}>
          <AsyncDocumentViewer
            uri={secureFileUrl}
            fileType={f.fileType}
          />
        </Suspense>
      </Box>
    </CustomModal>
  )
}

export default DocumentViewerModal
