import React from 'react'

function Refresh01(props) {
  const title = props.title || 'refresh 01'

  return (
    <svg
      height={props.height || '64'}
      width={props.width || '64'}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill={props.color || '#f7f7f7'}>
        <path
          d="M60,34.021c-1.095-0.155-2.107,0.603-2.263,1.696C55.919,48.42,44.855,58,32,58C17.664,58,6,46.337,6,32 C6,17.664,17.664,6,32,6c6.348,0,12.391,2.285,17.136,6.45l-6.843,6.843c-0.271,0.271-0.363,0.673-0.238,1.035 c0.126,0.362,0.447,0.62,0.828,0.665l17,2C59.922,22.998,59.961,23,60,23c0.264,0,0.519-0.104,0.707-0.293 c0.216-0.216,0.322-0.52,0.286-0.824l-2-17c-0.045-0.381-0.303-0.702-0.665-0.828c-0.362-0.125-0.765-0.034-1.035,0.238 l-5.326,5.326C46.462,4.703,39.412,2,32,2C15.458,2,2,15.458,2,32s13.458,30,30,30c14.833,0,27.599-11.056,29.696-25.717 C61.853,35.189,61.093,34.177,60,34.021z"
          fill={props.color || '#f7f7f7'}
        />
      </g>
    </svg>
  )
}

export default Refresh01
