import { Fragment } from 'react'
import { useGetCurrentAgencyContext } from 'src/app/hooks/queries'
import { Box, Flex } from 'theme-ui'
import { Overline } from '.'

const Airtemp = ({ height = 15, width = 15, title }) => {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title + ' ' + 'Air Temp'}</title>
      <g>
        <path
          d="M24,9c-0.55273,0-1-0.44775-1-1V2c0-0.55225,0.44727-1,1-1s1,0.44775,1,1v6C25,8.55225,24.55273,9,24,9z"
          fill="#EA9860"
        />
        <path
          d="M13.3934,13.3934c-0.39084,0.39084-1.02372,0.3905-1.41421,0L7.73654,9.15076 c-0.3905-0.3905-0.39084-1.02337,0-1.41421c0.39084-0.39084,1.02372-0.3905,1.41421,0l4.24264,4.24264 C13.7839,12.36968,13.78424,13.00256,13.3934,13.3934z"
          fill="#EA9860"
        />
        <path
          d="M9,24c0,0.55273-0.44775,1-1,1H2c-0.55225,0-1-0.44727-1-1s0.44775-1,1-1h6C8.55225,23,9,23.44727,9,24z"
          fill="#EA9860"
        />
        <path
          d="M13.3934,34.6066c0.39084,0.39084,0.3905,1.02372,0,1.41422l-4.24264,4.24264 c-0.3905,0.3905-1.02337,0.39084-1.41421,0c-0.39084-0.39084-0.3905-1.02372,0-1.41421l4.24264-4.24264 C12.36968,34.21611,13.00256,34.21576,13.3934,34.6066z"
          fill="#EA9860"
        />
        <path
          d="M24,39c0.55273,0,1,0.44775,1,1v6c0,0.55225-0.44727,1-1,1s-1-0.44775-1-1v-6C23,39.44775,23.44727,39,24,39 z"
          fill="#EA9860"
        />
        <path
          d="M34.6066,34.6066c0.39084-0.39084,1.02372-0.3905,1.41422,0l4.24264,4.24264 c0.3905,0.3905,0.39084,1.02337,0,1.41421s-1.02372,0.3905-1.41421,0l-4.24264-4.24264 C34.21611,35.63032,34.21576,34.99744,34.6066,34.6066z"
          fill="#EA9860"
        />
        <path
          d="M39,24c0-0.55273,0.44775-1,1-1h6c0.55225,0,1,0.44727,1,1s-0.44775,1-1,1h-6C39.44775,25,39,24.55273,39,24 z"
          fill="#EA9860"
        />
        <path
          d="M34.6066,13.3934c-0.39084-0.39084-0.3905-1.02372,0-1.41421l4.24264-4.24264 c0.3905-0.3905,1.02337-0.39084,1.41421,0c0.39084,0.39084,0.3905,1.02372,0,1.41421l-4.24264,4.24264 C35.63032,13.7839,34.99744,13.78424,34.6066,13.3934z"
          fill="#EA9860"
        />
        <path
          d="M24,37c-7.16797,0-13-5.83154-13-13s5.83203-13,13-13s13,5.83154,13,13S31.16797,37,24,37z"
          fill="#EFD358"
        />
      </g>
    </svg>
  )
}

const WaveHeight = ({ height = 15, width = 15, title }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 48 48"
    >
      <title>{title + ' ' + 'Wave Height'}</title>
      <g>
        <path
          d="M46,24H44c-6.226,0-9.449-4.357-9.81-8.406-.224-2.526.721-5.693,4.125-6.3a1,1,0,0,0,.606-1.607c-.164-.206-4.084-5.055-9.734-5.628a11.614,11.614,0,0,0-8.8,3c-3.187,2.66-4.64,6.248-6.045,9.716C12.258,19.928,10.607,24,4,24H2a1,1,0,0,0-1,1V36a1,1,0,0,0,1,1H46a1,1,0,0,0,1-1V25A1,1,0,0,0,46,24Z"
          fill="#82adb2"
        ></path>{' '}
        <path
          d="M46,46H2a1,1,0,0,1-1-1V35a1,1,0,0,1,1-1,9.18,9.18,0,0,0,6.766-3.079,1.033,1.033,0,0,1,.746-.321,1,1,0,0,1,.738.338,8.971,8.971,0,0,0,13.5,0,1.032,1.032,0,0,1,1.5,0,8.979,8.979,0,0,0,13.05.484,1,1,0,0,1,1.4,0A8.95,8.95,0,0,0,46,34a1,1,0,0,1,1,1V45A1,1,0,0,1,46,46Z"
          fill="#c8e9ec"
        ></path>
      </g>
    </svg>
  )
}

const WaveInterval = ({ height = 15, width = 15, title }) => {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title + ' ' + 'Wave Interval'}</title>
      <g>
        <path
          d="M47,21H1V9A1,1,0,0,1,2,8,9.179,9.179,0,0,0,8.766,4.921,1.033,1.033,0,0,1,9.512,4.6a1,1,0,0,1,.738.338A9,9,0,0,0,17,8a9,9,0,0,0,6.749-3.061.994.994,0,0,1,1.5,0A9,9,0,0,0,32,8a8.949,8.949,0,0,0,6.3-2.577,1,1,0,0,1,1.408.006A8.952,8.952,0,0,0,46.008,8,.993.993,0,0,1,47,9Z"
          fill="#82adb2"
        />
        <path
          d="M47,33H1V21a1,1,0,0,1,1-1,9.179,9.179,0,0,0,6.767-3.079,1.033,1.033,0,0,1,.746-.321,1,1,0,0,1,.738.338,8.971,8.971,0,0,0,13.5,0,.994.994,0,0,1,1.5,0,8.978,8.978,0,0,0,13.048.484,1,1,0,0,1,1.408.006A8.952,8.952,0,0,0,46.008,20,.993.993,0,0,1,47,21Z"
          fill="#a4dae0"
        />
        <path
          d="M46,44H2a1,1,0,0,1-1-1V33a1,1,0,0,1,1-1,9.18,9.18,0,0,0,6.766-3.079,1.033,1.033,0,0,1,.746-.321,1,1,0,0,1,.738.338,8.971,8.971,0,0,0,13.5,0,1.032,1.032,0,0,1,1.5,0,8.979,8.979,0,0,0,13.05.484,1,1,0,0,1,1.4,0A8.95,8.95,0,0,0,46,32a1,1,0,0,1,1,1V43A1,1,0,0,1,46,44Z"
          fill="#c8e9ec"
        />
      </g>
    </svg>
  )
}

const WindSpeed = ({ height = 15, width = 15, title }) => {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title + ' ' + 'Wind Speed'}</title>
      <g>
        <path
          d="M33 47H15C14.448 47 14 46.552 14 46C14 45.951 14.004 45.903 14.011 45.854L18.011 18.854C18.048 18.604 18.178 18.377 18.375 18.219L23.375 14.219C23.74 13.926 24.26 13.926 24.625 14.219L29.625 18.219C29.822 18.377 29.952 18.604 29.989 18.854L33.989 45.854C34.07 46.4 33.692 46.909 33.146 46.989C33.098 46.996 33.049 47 33 47Z"
          fill="url(#nc-windmill-2-0_linear_307_134)"
        ></path>{' '}
        <path
          d="M29.989 18.854C29.952 18.604 29.822 18.377 29.625 18.219L24.625 14.219C24.26 13.926 23.74 13.926 23.375 14.219L18.375 18.219C18.178 18.377 18.048 18.604 18.011 18.854L17.1 25H30.9L29.989 18.854Z"
          fill="url(#nc-windmill-2-1_linear_307_134)"
        ></path>{' '}
        <path
          d="M32 18C31.735 18 31.48 17.895 31.293 17.707L27.586 14L37.293 4.29299C37.683 3.90299 38.317 3.90299 38.707 4.29299L41.707 7.29299C42.097 7.68399 42.097 8.31699 41.707 8.70699L32.707 17.707C32.52 17.895 32.265 18 32 18Z"
          fill="url(#nc-windmill-2-2_linear_307_134)"
        ></path>{' '}
        <path
          d="M23 11C23 11.265 22.895 11.52 22.707 11.707L19 15.414L9.29299 5.70699C8.90299 5.31599 8.90299 4.68399 9.29299 4.29299L12.293 1.29299C12.684 0.902988 13.317 0.902988 13.707 1.29299L22.707 10.293C22.895 10.48 23.001 10.735 23 11Z"
          fill="url(#nc-windmill-2-3_linear_307_134)"
        ></path>{' '}
        <path
          d="M16 20C16.265 20 16.52 20.105 16.707 20.293L20.414 24L10.707 33.707C10.316 34.097 9.68299 34.097 9.29299 33.707L6.29299 30.707C5.90299 30.317 5.90299 29.683 6.29299 29.293L15.293 20.293C15.48 20.105 15.735 19.999 16 20Z"
          fill="url(#nc-windmill-2-4_linear_307_134)"
        ></path>{' '}
        <path
          d="M25 27.0001C25 26.7351 25.105 26.4801 25.293 26.2931L29 22.5861L38.707 32.2931C39.097 32.6831 39.097 33.3171 38.707 33.7071L35.707 36.7071C35.317 37.0971 34.683 37.0971 34.293 36.7071L25.293 27.7071C25.105 27.5201 24.999 27.2651 25 27.0001Z"
          fill="url(#nc-windmill-2-5_linear_307_134)"
        ></path>{' '}
        <path
          d="M10 33.9999C9.448 33.9999 9 33.5519 9 32.9999C9 32.7349 9.105 32.4809 9.293 32.2929L37.293 4.29295C37.677 3.89595 38.31 3.88495 38.707 4.26795C39.104 4.65195 39.115 5.28495 38.732 5.68195C38.724 5.68995 38.716 5.69795 38.707 5.70695L10.707 33.7069C10.52 33.8949 10.265 33.9999 10 33.9999Z"
          fill="url(#nc-windmill-2-6_linear_307_134)"
        ></path>{' '}
        <path
          d="M10 4C9.448 4 9 4.448 9 5C9 5.265 9.105 5.519 9.293 5.707L37.293 33.707C37.69 34.091 38.323 34.08 38.707 33.683C39.081 33.295 39.081 32.681 38.707 32.293L10.707 4.293C10.52 4.105 10.265 4 10 4Z"
          fill="url(#nc-windmill-2-7_linear_307_134)"
        ></path>{' '}
        <path
          d="M24 21C25.1046 21 26 20.1046 26 19C26 17.8954 25.1046 17 24 17C22.8954 17 22 17.8954 22 19C22 20.1046 22.8954 21 24 21Z"
          fill="url(#nc-windmill-2-8_linear_307_134)"
        ></path>{' '}
        <path
          d="M21 47V42C21 40.343 22.343 39 24 39C25.657 39 27 40.343 27 42V47H21Z"
          fill="url(#nc-windmill-2-9_linear_307_134)"
        ></path>{' '}
        <defs>
          {' '}
          <linearGradient
            id="nc-windmill-2-0_linear_307_134"
            x1="24"
            y1="13.9993"
            x2="24"
            y2="47"
            gradientUnits="userSpaceOnUse"
          >
            {' '}
            <stop stop-color="#B88163"></stop>{' '}
            <stop offset="1" stop-color="#976545"></stop>{' '}
          </linearGradient>{' '}
          <linearGradient
            id="nc-windmill-2-1_linear_307_134"
            x1="24"
            y1="13.9993"
            x2="24"
            y2="25"
            gradientUnits="userSpaceOnUse"
          >
            {' '}
            <stop stop-color="#B88163"></stop>{' '}
            <stop offset="1" stop-color="#976545"></stop>{' '}
          </linearGradient>{' '}
          <linearGradient
            id="nc-windmill-2-2_linear_307_134"
            x1="34.7927"
            y1="4.00049"
            x2="34.7927"
            y2="18"
            gradientUnits="userSpaceOnUse"
          >
            {' '}
            <stop stop-color="#E0E0E6"></stop>{' '}
            <stop offset="1" stop-color="#C2C3CD"></stop>{' '}
          </linearGradient>{' '}
          <linearGradient
            id="nc-windmill-2-3_linear_307_134"
            x1="16.0002"
            y1="1.00049"
            x2="16.0002"
            y2="15.414"
            gradientUnits="userSpaceOnUse"
          >
            {' '}
            <stop stop-color="#E0E0E6"></stop>{' '}
            <stop offset="1" stop-color="#C2C3CD"></stop>{' '}
          </linearGradient>{' '}
          <linearGradient
            id="nc-windmill-2-4_linear_307_134"
            x1="13.2072"
            y1="20"
            x2="13.2072"
            y2="33.9995"
            gradientUnits="userSpaceOnUse"
          >
            {' '}
            <stop stop-color="#E0E0E6"></stop>{' '}
            <stop offset="1" stop-color="#C2C3CD"></stop>{' '}
          </linearGradient>{' '}
          <linearGradient
            id="nc-windmill-2-5_linear_307_134"
            x1="31.9998"
            y1="22.5861"
            x2="31.9998"
            y2="36.9996"
            gradientUnits="userSpaceOnUse"
          >
            {' '}
            <stop stop-color="#E0E0E6"></stop>{' '}
            <stop offset="1" stop-color="#C2C3CD"></stop>{' '}
          </linearGradient>{' '}
          <linearGradient
            id="nc-windmill-2-6_linear_307_134"
            x1="24.0061"
            y1="3.98779"
            x2="24.0061"
            y2="33.9999"
            gradientUnits="userSpaceOnUse"
          >
            {' '}
            <stop stop-color="#F98E5E"></stop>{' '}
            <stop offset="1" stop-color="#EA6524"></stop>{' '}
          </linearGradient>{' '}
          <linearGradient
            id="nc-windmill-2-7_linear_307_134"
            x1="23.9938"
            y1="4"
            x2="23.9938"
            y2="33.988"
            gradientUnits="userSpaceOnUse"
          >
            {' '}
            <stop stop-color="#F98E5E"></stop>{' '}
            <stop offset="1" stop-color="#EA6524"></stop>{' '}
          </linearGradient>{' '}
          <linearGradient
            id="nc-windmill-2-8_linear_307_134"
            x1="24"
            y1="17"
            x2="24"
            y2="21"
            gradientUnits="userSpaceOnUse"
          >
            {' '}
            <stop stop-color="#393A46"></stop>{' '}
            <stop offset="1" stop-color="#17181C"></stop>{' '}
          </linearGradient>{' '}
          <linearGradient
            id="nc-windmill-2-9_linear_307_134"
            x1="24"
            y1="39"
            x2="24"
            y2="47"
            gradientUnits="userSpaceOnUse"
          >
            {' '}
            <stop stop-color="#393A46"></stop>{' '}
            <stop offset="1" stop-color="#17181C"></stop>{' '}
          </linearGradient>{' '}
        </defs>{' '}
      </g>
    </svg>
  )
}

const WaterTemp = ({ height = 15, width = 15, title }) => {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title + ' ' + 'Water Temp'}</title>
      <g>
        <path
          d="M12.447,38H28.2l5.982-4.387a2,2,0,0,0,.505-2.687L23.446,13.189,27.537,8.28a2,2,0,0,0-3.074-2.56l-5,6a2,2,0,0,0-.178,2.309l3.923,6.538L9.817,30.387a2,2,0,0,0-.505,2.687Z"
          fill="#5a7a84"
        />
        <circle cx="36" cy="20" fill="#5a7a84" r="5" />
        <path
          d="M46,42H2a1,1,0,0,1-1-1V34H2a9.006,9.006,0,0,0,6.749-3.061.994.994,0,0,1,1.5,0,8.971,8.971,0,0,0,13.5,0,.994.994,0,0,1,1.5,0,8.978,8.978,0,0,0,13.048.484,1,1,0,0,1,1.4,0A8.953,8.953,0,0,0,46,34h1v7A1,1,0,0,1,46,42Z"
          fill="#9bced3"
        />
      </g>
    </svg>
  )
}

const TideHeight = ({ height = 15, width = 15, title }) => {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title + ' ' + 'Tide Height'}</title>
      <g>
        <path
          d="M46,41a8.952,8.952,0,0,1-6.3-2.577,1,1,0,0,0-1.4,0q-.184.181-.379.352a8.979,8.979,0,0,1-12.669-.836,1.007,1.007,0,0,0-.1-.1.994.994,0,0,0-1.4.1,8.784,8.784,0,0,1-.841.841,8.971,8.971,0,0,1-12.659-.841,1.007,1.007,0,0,0-.1-.1.994.994,0,0,0-1.4.1A9.008,9.008,0,0,1,2,41H1v5a1,1,0,0,0,1,1H46a1,1,0,0,0,1-1V41Z"
          fill="#a4dae0"
        />
        <path
          d="M41,1a6.006,6.006,0,0,0-6,6v3H13V7a4,4,0,0,1,4-4,1,1,0,0,0,0-2,6.006,6.006,0,0,0-6,6V35a1,1,0,0,0,2,0V30H35v5a1,1,0,0,0,2,0V7a4,4,0,0,1,4-4,1,1,0,0,0,0-2ZM35,12v7H13V12ZM13,28V21H35v7Z"
          fill="#335262"
        />
      </g>
    </svg>
  )
}

export const weatherDataPoints = [
  {
    label: 'Air temp',
    name: 'airTemp',
    Component: Airtemp,
    weatherApiKey: 'airTemperature.formatted',
    getUnit: ({ useMeters }) => (useMeters ? '°C' : '°F')
  },
  {
    label: 'Water temp',
    name: 'waterTemp',
    Component: WaterTemp,
    weatherApiKey: 'waterTemperature.formatted',
    getUnit: ({ useMeters }) => (useMeters ? '°C' : '°F')
  },
  {
    label: 'Tide height',
    name: 'tideHeight',
    Component: TideHeight,
    weatherApiKey: 'currentTide.v',

    getUnit: ({ useMeters }) => (useMeters ? 'm' : 'ft')
  },
  {
    label: 'Wave height',
    name: 'waveHeight',
    Component: WaveHeight,
    weatherApiKey: 'waveHeight.formatted',
    getUnit: ({ useMeters }) => (useMeters ? 'm' : 'ft')
  },
  {
    label: 'Wave interval',
    name: 'waveInterval',
    Component: WaveInterval,
    getUnit: () => 's',
    weatherApiKey: 'wavePeriod.formatted'
  },

  {
    label: 'Wind speed',
    name: 'windSpeed',
    Component: WindSpeed,
    weatherApiKey: 'windSpeed.formatted',
    getUnit: ({ useMeters }) => (useMeters ? 'km/h' : 'mph'),
    subItem: {
      label: 'Wind direction',
      name: 'windDirection',
      weatherApiKey: 'windDirection.formatted'
    }
  }
]

const SurflineWeatherIcons = ({
  weather,
  extended,
  addUnits,

  ...props
}) => {
  const { currentAgency } = useGetCurrentAgencyContext()
  const { useMeters, useCelsius } = currentAgency?.settings || {}

  const allUndefined = weatherDataPoints.every(v => {
    return !weather?.[v.name]
  })
  if (!weather || allUndefined) return 'No weather data'

  return (
    <Flex
      sx={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        gap: '10px'
      }}
      {...props}
    >
      {weatherDataPoints.map(
        ({ name, Component, label, subItem, getUnit }) => {
          const unit = addUnits
            ? ` ${getUnit({ useMeters, useCelsius })}`
            : ''
          const title =
            (typeof weather[name] === 'string'
              ? weather[name]
              : round(weather[name], 2)) +
            unit +
            (subItem && weather[subItem?.name]
              ? ` (${weather[subItem?.name]})`
              : '')

          return weather[name] ? (
            <Box key={name} sx={{ textAlign: 'left' }}>
              {extended && <Overline>{label}</Overline>}

              <Flex
                sx={{
                  p: '5px',
                  flexDirection: extended ? 'row' : 'column',
                  gap: '10px',
                  fontSize: '12px'
                }}
              >
                {Component && <Component title={title} />} {title}
              </Flex>
            </Box>
          ) : (
            <Fragment key={name} />
          )
        }
      )}
    </Flex>
  )
}

function round(value, decimals) {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
}

export default SurflineWeatherIcons
