import React from 'react'

function ResizeX(props) {
  const title = props.title || 'resize x'

  return (
    <svg
      height={props.height || '48'}
      width={props.width || '48'}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill={props.color || '#f7f7f7'}>
        <path d="M31.625,15.219A1,1,0,0,0,30,16V32a1,1,0,0,0,1.625.781l10-8a1,1,0,0,0,0-1.562Z" />
        <path d="M17.434,15.1a1,1,0,0,0-1.059.12l-10,8a1,1,0,0,0,0,1.562l10,8A1,1,0,0,0,18,32V16A1,1,0,0,0,17.434,15.1Z" />
        <path
          d="M2,3A1,1,0,0,0,1,4V44a1,1,0,0,0,2,0V4A1,1,0,0,0,2,3Z"
          fill={props.color || '#f7f7f7'}
        />
        <path
          d="M46,3a1,1,0,0,0-1,1V44a1,1,0,0,0,2,0V4A1,1,0,0,0,46,3Z"
          fill={props.color || '#f7f7f7'}
        />
      </g>
    </svg>
  )
}

export default ResizeX
