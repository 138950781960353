/** @jsxImportSource theme-ui */

import { useMutation } from '@apollo/client'
import { SET_IMPORTANT } from 'src/app/hooks/mutations'
import { theme } from 'src/styles'

import Button from '../Shared/Elements/Button'
import Icon from '../Shared/Icon'

const SubmissionImportantButton = ({
  submissionId,
  important,
  ...props
}) => {
  const [setImportant, { loading }] = useMutation(SET_IMPORTANT, {
    variables: {
      id: submissionId
    }
  })

  return (
    <Button
      onClick={async () => {
        await setImportant()
      }}
      variant="link"
      {...props}
    >
      <Icon
        icon={loading ? 'spinner' : 'star'}
        width={20}
        color={
          important ? theme.colors.warning : theme.colors.gray300
        }
      />
    </Button>
  )
}

export default SubmissionImportantButton
