import { useMutation } from '@apollo/client'

import Button from '../Shared/Elements/Button'
import {
  COMPLETE_ALL_DISPATCH,
  COMPLETE_DISPATCH
} from 'src/app/hooks/mutations'

export const RevertAllDispatchButton = ({
  submissionId,
  onFinish,
  ...props
}) => {
  const [
    completeAllDispatchesBySubmission,
    { loading }
  ] = useMutation(COMPLETE_ALL_DISPATCH)

  return (
    <Button
      onClick={async () => {
        await completeAllDispatchesBySubmission({
          variables: {
            submissionId,
            time: null
          }
        })
        onFinish && onFinish()
      }}
      variant="danger"
      icon={loading && 'spinner'}
      disabled={loading}
      {...props}
    >
      Undo Close
    </Button>
  )
}

export const RevertDispatchButton = ({
  dispatchId,
  onFinish,
  ...props
}) => {
  const [completeDispatch, { loading }] = useMutation(
    COMPLETE_DISPATCH
  )

  return (
    <Button
      onClick={async () => {
        await completeDispatch({
          variables: {
            id: dispatchId,
            time: null
          }
        })
        onFinish && onFinish()
      }}
      variant="danger"
      disabled={loading}
      icon={loading && 'spinner'}
      {...props}
    >
      Revert Close
    </Button>
  )
}
