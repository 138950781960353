import { useEffect, useState } from 'react'
import { CenteredContent } from '../components/'
import qs from 'qs'
import get from 'lodash/get'
import { Window } from 'src/utils'
import { gql } from '@apollo/client'
import { useMutation } from '@apollo/client'
import { getToken, useHandleAuthTokens } from '../services/auth'

import { useMFAFlow } from '../hooks/useMFAFlow'
import { Box } from 'theme-ui'
import { useNavigate } from 'react-router'
import { AUTH_PAYLOAD_FRAGMENT } from '../hooks/fragments'
import MFABranch from '../components/LoginScreen/MFABranch'

const OAUTH = gql`
  mutation OAuth($token: String!, $inviteToken: String) {
    oauth(token: $token, inviteToken: $inviteToken) {
      ...AuthPayloadFragment
    }
  }
  ${AUTH_PAYLOAD_FRAGMENT}
`
export const MAGIC_LINKS = gql`
  mutation AuthMagicLink($token: String!) {
    authenticateMagicLink(token: $token) {
      ...AuthPayloadFragment
    }
  }
  ${AUTH_PAYLOAD_FRAGMENT}
`

export const SSO_LOGIN = gql`
  mutation SSOLogin($token: String!) {
    sso(token: $token) {
      ...AuthPayloadFragment
    }
  }
  ${AUTH_PAYLOAD_FRAGMENT}
`

const Oauth = () => {
  const navigate = useNavigate()
  const inviteToken = getToken()
  const [defaultPhone, setDefaultPhone] = useState()
  const { mfa, triggerMfa, mfaOptions } = useMFAFlow(
    afterVerification
  )
  const { token, stytch_token_type } = qs.parse(
    get(Window.location, 'search', ''),
    {
      ignoreQueryPrefix: true
    }
  )
  const [oauth] = useMutation(OAUTH)
  const [magicLink] = useMutation(MAGIC_LINKS)
  const [ssoLogin] = useMutation(SSO_LOGIN)
  const { handleTokens } = useHandleAuthTokens()

  useEffect(() => {
    async function loginOauth() {
      const { data, errors } = await oauth({
        variables: { token, inviteToken }
      })

      const res = data?.oauth
      const newToken = res?.token
      const mfaEnabled = res?.mfaEnabled
      const mfaRequired = res?.mfaRequired
      const organization_id = res?.user?.agency?.organizationId

      if (newToken && (mfaEnabled || mfaRequired)) {
        triggerMfa({
          newToken: newToken,
          user_id: res?.user?.stytchMemberId,
          mfaRequired,
          mfaEnabled,
          organization_id
        })
        setDefaultPhone(res?.user?.phone)
      } else if (!errors) {
        await handleTokens(newToken, res?.session)
        if (!res?.user?.agency) {
          navigate('/agency/onboarding')
        } else {
          navigate('/agency')
        }
      } else {
        navigate(`/agency/auth-error?message=${errors[0].message}`)
      }
    }

    async function loginMagicLink() {
      const { data, errors } = await magicLink({
        variables: { token }
      })

      if (errors) {
        navigate(`/agency/auth-error?message=${errors[0].message}`)
        return
      }

      const newToken = data?.authenticateMagicLink?.token
      const newSession = data?.authenticateMagicLink?.session
      const agency = data?.authenticateMagicLink?.user?.agency
      const mfaEnabled = data?.authenticateMagicLink?.mfaEnabled
      const mfaRequired = data?.authenticateMagicLink?.mfaRequired
      const user_id =
        data?.authenticateMagicLink?.user?.stytchMemberId
      const role = data?.authenticateMagicLink?.user?.role
      const organization_id =
        data?.authenticateMagicLink?.user?.agency?.organizationId

      if (newToken && (mfaEnabled || mfaRequired)) {
        triggerMfa({
          newToken,
          user_id,
          mfaRequired,
          mfaEnabled,
          organization_id
        })
      } else if (newToken) {
        await afterVerification(newToken, newSession, agency, role)
      }
    }

    async function loginSSO() {
      const { data, errors } = await ssoLogin({
        variables: { token }
      })

      if (errors) {
        navigate(`/agency/auth-error?message=${errors[0].message}`)
        return
      }

      const newToken = data?.sso?.token
      const newSession = data?.sso?.session
      const agency = data?.sso?.user?.agency
      const mfaEnabled = data?.sso?.mfaEnabled
      const mfaRequired = data?.sso?.mfaRequired
      const user_id =
        data?.sso?.user?.stytchMemberId
      const role = data?.sso?.user?.role
      const organization_id =
        data?.sso?.user?.agency?.organizationId

      if (newToken && (mfaEnabled || mfaRequired)) {
        triggerMfa({
          newToken,
          user_id,
          mfaRequired,
          mfaEnabled,
          organization_id
        })
      } else if (newToken) {
        await afterVerification(newToken, newSession, agency, role)
      }
    }


    if (token && stytch_token_type === 'sso') {
      loginSSO()
    } else if (token && stytch_token_type !== 'discovery_oauth') {
      loginMagicLink()
    } else if (token) {
      loginOauth()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  async function afterVerification(
    newToken,
    newSession,
    agency,
    role
  ) {
    await handleTokens(newToken, newSession)

    if (!agency) {
      navigate('/agency/onboarding')
    } else {
      navigate(
        role === 'READ_ONLY' ? '/agency' : '/agency/submission'
      )
    }
  }

  return (
    <CenteredContent>
      <>
        <Box
          variant="card"
          sx={{
            width: '100%',
            maxWidth: '600px',
            padding: '45px 20px'
          }}
        >
          <MFABranch
            mfa={mfa}
            mfaOptions={mfaOptions}
            defaultPhone={defaultPhone}
          />
        </Box>
      </>
    </CenteredContent>
  )
}

export default Oauth
