import { useColorMode } from 'theme-ui'
import Logo from '../images/Watchtowerlogofinal.png'
import WhiteLogo from '../images/WhiteWatchtowerlogofinal.png'

const LogoIcon = ({ ...props }) => {
  const [colorMode] = useColorMode()
  return (
    <img
      src={colorMode === 'dark' ? WhiteLogo : Logo}
      alt="Watchtower Logo"
      {...props}
    />
  )
}

export default LogoIcon
