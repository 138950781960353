/** @jsxImportSource theme-ui */
import React from 'react'
import { gql, useQuery } from '@apollo/client'
import Spinner from 'src/images/icons/Spinner'
import ToolTip from '../Shared/Elements/ToolTip'
import { Box, Flex } from 'theme-ui'
import Icon from '../Shared/Icon'

const GET_STATUS_TEMPLATE_AUTOMATIONS = gql`
  query StatusTemplateAutomations($id: String!) {
    myAgenciesStatusTemplates(where: { id: { equals: $id } }) {
      id
      updatedBy(where: { active: { equals: true } }) {
        valueFromFormQuestion {
          id
          helpText
          formField {
            id
            title
            form {
              id
              name
            }
          }
        }
      }
    }
  }
`

export const UpdatedByTooltipContainer = ({ statusTemplateId }) => {
  const { data, loading } = useQuery(
    GET_STATUS_TEMPLATE_AUTOMATIONS,
    {
      variables: { id: statusTemplateId }
    }
  )

  const st = data?.myAgenciesStatusTemplates?.[0]

  return loading ? (
    <Spinner />
  ) : (
    <UpdatedByTooltip
      id={st?.id}
      autoUpdates={st?.updatedBy}
      boxStyle={{
        p: '3px',
        px: '5px',
        borderRadius: '5px',
        marginY: '8px'
      }}
    />
  )
}

export const UpdatedByTooltip = ({
  id,
  autoUpdates = [],
  boxStyle
}) => {
  return (
    <>
      <ToolTip
        sx={{ minWidth: '300px' }}
        target={id + 'updatedBy'}
        place="top"
      >
        {autoUpdates?.length === 0 ? (
          '0 active form automations'
        ) : (
          <Flex
            sx={{
              textAlign: 'left',
              flexDirection: 'column',
              py: '15px'
            }}
          >
            <h6
              sx={{
                fontSize: '14px',
                fontWeight: 'bold',
                mb: '10px'
              }}
            >
              Forms that update this status:
            </h6>
            {autoUpdates.map(sa => (
              <Box>
                - {sa?.valueFromFormQuestion?.formField?.form?.name}{' '}
                Form
              </Box>
            ))}
          </Flex>
        )}
      </ToolTip>
      <Box
        data-tip
        data-for={id + 'updatedBy'}
        sx={{
          bg: autoUpdates?.length < 1 ? 'secondary' : 'success',
          borderRadius: '10px',
          color: 'white',
          p: 10,
          fontSize: '12px',
          whiteSpace: 'nowrap',
          cursor: 'pointer',
          ...boxStyle
        }}
      >
        <Icon icon="pulse" width="10px" height="10px" color="white" />{' '}
        {autoUpdates?.length}
      </Box>
    </>
  )
}
