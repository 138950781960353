import React from 'react'

function Key(props) {
  const title = props.title || 'access key'

  return (
    <svg
      height={props.height || '16'}
      width={props.width || '16'}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g
        fill={props.color || '#f7f7f7'}
        stroke={props.color || '#f7f7f7'}
        strokeMiterlimit="10"
        strokeWidth="1"
      >
        <polyline
          fill="none"
          points="11.5,7.5 12.5,8.5 15.5,5.5 14.5,4.5 "
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="5"
          cy="11"
          fill="none"
          r="3.5"
          stroke={props.color || '#f7f7f7'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line
          fill="none"
          stroke={props.color || '#f7f7f7'}
          strokeLinecap="round"
          strokeLinejoin="round"
          x1="7.5"
          x2="15.5"
          y1="8.5"
          y2="0.5"
        />
      </g>
    </svg>
  )
}

export default Key
