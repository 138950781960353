import { useEffect, useState } from 'react'
import { Space } from '..'
import FormGroup from 'src/app/components/Shared/Elements/FormGroup'
import { cleanGraphQLError } from '../../components/Forms/FormError'
import { useToast } from 'src/components/toasts'
import { OtpVerificationField } from '../AgencyProfile/OtpVerificationField'
import Spinner from 'src/images/icons/Spinner'

function isSixDigitNumber(value) {
  const regex = /^\d{6}$/
  return regex.test(value)
}

const OtpVerificationForm = ({ verify, resend }) => {
  const { add } = useToast()

  const [code, setCode] = useState('')

  async function onRequest() {
    verify.variables.code = code
    const res = await verify.fn({ variables: verify.variables })
    if (!res.errors) {
      add({
        content: 'Verified successfully.',
        color: 'success'
      })
      await verify.cb(res)
    } else {
      add({
        content: cleanGraphQLError(res.errors[0]),
        color: 'danger'
      })
      setCode('')
    }
  }

  //Added loading as it calling again and again if typing last number
  useEffect(() => {
    if (isSixDigitNumber(code) && !verify.loader) {
      onRequest()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code])

  async function resendCode() {
    const res = await resend.fn({
      variables: resend.variables
    })
    if (!res.errors) {
      add({
        content: 'Code sent to phone number!',
        color: 'success'
      })
    } else {
      add({
        content: cleanGraphQLError(res.errors[0]),
        color: 'danger'
      })
    }

    setCode('')
  }

  return (
    <>
      <h3>Verify Code {verify.loader && <Spinner />}</h3>
      <p>Enter 6-digit code sent to your phone.</p>
      <Space height={15} />
      <>
        <FormGroup>
          <OtpVerificationField
            setCode={setCode}
            code={code}
            resendCode={resendCode}
            requestLoading={resend.loader}
          />
        </FormGroup>
      </>
    </>
  )
}

export default OtpVerificationForm
