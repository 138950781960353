import React from 'react'
import ModalButton from '../../Shared/ModalButton'
import FormResponseList from './FormResponseList'

export const FormResponseModalButton = ({ form: f, ...props }) => {
  return (
    <ModalButton
      modalHeader={`${f.name}`}
      buttonLabel={`Responses (${f.responseCount})`}
      contentSize={'lg'}
      buttonProps={{
        variant: f.responseCount > 0 ? 'primary' : 'secondary'
      }}
    >
      {({ open, toggle }) =>
        open ? (
          <FormResponseList
            tabIndex={1}
            {...f}
            onFinish={toggle}
            formId={f.id}
          />
        ) : (
          <Fragment />
        )
      }
    </ModalButton>
  )
}
