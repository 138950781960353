import { gql, useLazyQuery } from '@apollo/client'

export const REQUEST_PDF_REPORT = gql`
  query RequestGeneratedReport($requestInput: ReportRequestInput) {
    requestGeneratedReport(requestInput: $requestInput) {
      success
      data
    }
  }
`

export const useRequestPDFReport = onError => {
  const [
    requestReport,
    { data, loading, error, called }
  ] = useLazyQuery(REQUEST_PDF_REPORT, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  })

  if (error) {
    onError && onError(error)
  }

  return {
    requestReport,
    success: data && data.requestGeneratedReport,
    error,
    loading,
    called
  }
}
