function Filter(props) {
  const title = props.title || 'setup preferences'

  return (
    <svg
      height={props.height || '48'}
      width={props.width || '48'}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill={props.color || '#F7F7F7'}>
        <path
          d="M3,10H8A1,1,0,0,0,8,8H3a1,1,0,0,0,0,2Z"
          fill={props.color || '#F7F7F7'}
        />
        <path
          d="M17,15a6,6,0,0,0,5.91-5H45a1,1,0,0,0,0-2H22.91A6,6,0,1,0,17,15Z"
          fill={props.color || '#F7F7F7'}
        />
        <path d="M45,23H40a1,1,0,0,0,0,2h5a1,1,0,0,0,0-2Z" />
        <path d="M3,25H25.09a6,6,0,1,0,0-2H3a1,1,0,0,0,0,2Z" />
        <path
          d="M8,38H3a1,1,0,0,0,0,2H8a1,1,0,0,0,0-2Z"
          fill={props.color || '#F7F7F7'}
        />
        <path
          d="M45,38H22.91a6,6,0,1,0,0,2H45a1,1,0,0,0,0-2Z"
          fill={props.color || '#F7F7F7'}
        />
      </g>
    </svg>
  )
}

export default Filter
