/** @jsxImportSource theme-ui */

import { Box, Flex } from 'theme-ui'

import { prettyDate } from 'src/utils'

import ModalButton from '../Shared/ModalButton'

import ListGroup from '../Shared/Elements/ListGroup'
import ListGroupItem from '../Shared/Elements/ListGroupItem'
import ListGroupItemHeading from '../Shared/Elements/ListGroupItemHeading'

import BulletinActions from '../Bulletins/BulletinActions'

import BulletinRichTextModalContent from '../Bulletins/BulletinRichTextModal'
import BulletinLiveUntilBadge from '../Bulletins/BulletinLiveUntilBadge'
import { RolesOnly } from '../../services/auth'
import Elipses from '../Shared/Elipses'

import { UrgencyIcon, PrivateIcon } from '../Bulletins/BulletinIcons'

export const BulletinListGroup = ({ bulletins, hideActions }) => {
  return (
    <ListGroup>
      {bulletins.map((i, k) => {
        return (
          <ListGroupItem key={k}>
            <Flex
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap'
              }}
            >
              <Box sx={{ minWidth: '60%' }}>
                <Flex
                  sx={{
                    alignItems: 'center',
                    gap: '8px',
                    mb: '10px'
                  }}
                >
                  <BulletinLiveUntilBadge {...i} />
                </Flex>
                <ModalButton
                  buttonLabel={
                    <ListGroupItemHeading
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        position: 'relative',
                        gap: '5px'
                      }}
                    >
                      {!i.hasRead && (
                        <Flex
                          sx={{
                            color: 'danger',
                            gap: '5px',
                            alignItems: 'center',
                            fontSize: 10
                          }}
                          title="Unread"
                        >
                          <Box
                            sx={{
                              bg: 'danger',
                              width: '10px',
                              height: '10px',
                              borderRadius: '100px'
                            }}
                          />
                        </Flex>
                      )}
                      <Elipses mw={'300px'}>{i.title}</Elipses>
                      <PrivateIcon isPrivate={i.isPrivate} />
                      <UrgencyIcon urgency={i.urgency} />
                    </ListGroupItemHeading>
                  }
                  buttonProps={{
                    variant: 'naked',
                    style: {
                      padding: '0px'
                    }
                  }}
                >
                  {({ toggle }) => (
                    <BulletinRichTextModalContent
                      {...i}
                      onClose={toggle}
                    />
                  )}
                </ModalButton>
                <p>
                  <Flex
                    sx={{
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Flex
                      sx={{
                        gap: '10px',
                        justifyContent: 'flex-start',
                        alignItems: 'center'
                      }}
                    >
                      <strong>
                        {i.author?.name || 'Watchtower Team'}
                      </strong>{' '}
                      on {prettyDate(i.createdAt)}
                    </Flex>
                  </Flex>
                </p>
              </Box>
              {!hideActions && (
                <RolesOnly roles={['ADMIN', 'SUPERVISOR']}>
                  <Flex sx={{ gap: '4px' }}>
                    <BulletinActions
                      bulletin={i}
                      buttonStyles={[
                        'secondary',
                        'warning',
                        'link',
                        'linkDanger'
                      ]}
                      // participantList={[]}
                    />
                  </Flex>
                </RolesOnly>
              )}
            </Flex>
          </ListGroupItem>
        )
      })}
    </ListGroup>
  )
}
