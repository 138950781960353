import { Fragment, useEffect, useState } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { useWindowSize } from 'src/styles'

import moment from 'moment'

// Widget-specific Icons
import raining from 'src/images/icons/Rain.svg'
import partlyCloudy from 'src/images/icons/Partly-Cloudy.svg'
import sunny from 'src/images/icons/Sun.svg'
import thunderstorm from 'src/images/icons/Thunderstorm.svg'
import windy from 'src/images/icons/Windy.svg'
import WindyLogo from 'src/images/WindyLogo.png'

import { CenteredContent } from '..'
import ModalButton from '../Shared/ModalButton'
import Spinner from 'src/images/icons/Spinner'
import { useGetWeather } from 'src/app/hooks/queries'

const getForecastIcon = forecast => {
  let weatherIcon
  switch (forecast.toLowerCase()) {
    case 'rain':
    case 'raining':
    case 'rain showers':
      weatherIcon = raining
      break
    case 'cloudy':
    case 'overcast':
    case 'partly cloudy':
    case 'mostly cloudy':
    case 'mostly clear':
    case 'mostly sunny':
    case 'partly sunny':
    case 'fog':
    case 'fog/mist':
    case 'chance rain showers':
      weatherIcon = partlyCloudy
      break
    case 'sunny':
    case 'clear':
      weatherIcon = sunny
      break
    case 'thunder':
    case 'thunderstorm':
      weatherIcon = thunderstorm
      break
    case 'windy':
    case 'wind':
    case 'partly cloudy and windy':
    case 'overcast and windy':
      weatherIcon = windy
      break
    default:
      weatherIcon = partlyCloudy
  }
  return weatherIcon
}

const WeatherWidget = ({
  noaaTidesStation,
  timezone,
  latitude,
  longitude,
  surflineSpotId,
  useMeters,
  useCelsius
}) => {
  const { data, loading, error } = useGetWeather({
    latitude,
    longitude,
    noaaTidesStation,
    agencyTz: timezone,
    surflineSpotId,
    useMeters,
    useCelsius
  })

  if (loading) {
    return (
      <CenteredContent height="200px" style={{ width: '100%' }}>
        <Spinner white />
      </CenteredContent>
    )
  }

  const {
    forecast,
    airTemperature,
    waterTemperature,
    waveHeight,
    wavePeriod,
    tideData,
    windSpeed,
    uvData
  } = data || {}

  let tides = false

  if (tideData) {
    tides = tideData?.map((tideReading, index) => {
      const time = moment(tideReading.t).format('hh:mma')
      const height = tideReading?.v + (tideReading?.unit || 'ft')

      return (
        <p sx={{ fontSize: '13px' }} key={index}>
          <span sx={{ fontWeight: 'bold' }}>{height}</span>
          {` at ${time}`}
        </p>
      )
    })
  }

  if (error || !data) {
    return (
      <CenteredContent
        height="200px"
        flexWrap="wrap"
        style={{ width: '100%' }}
      >
        <h4>Weather not available.</h4>
        <p style={{ maxWidth: '300px', textAlign: 'center' }}>
          Please reach out to your Watchtower representative to have
          this feature enabled.
        </p>
      </CenteredContent>
    )
  }

  return (
    <Flex sx={{ width: '100%', flexWrap: 'wrap' }}>
      <Flex
        sx={{
          alignItems: 'center',
          width: '100%',
          justifyContent: 'space-between',
          marginBottom: 15
        }}
      >
        <Flex sx={{ alignItems: 'center' }}>
          {forecast && (
            <img
              width="30px"
              height="30px"
              src={getForecastIcon(forecast)}
              alt={forecast}
            />
          )}
          <h5
            sx={{
              marginX: 0
            }}
          >
            {airTemperature?.formatted || 'No Data'}
          </h5>
          {forecast && (
            <span sx={{ variant: 'styles.span' }}>
              {forecast.split(' ').map((word, index) => (
                <Fragment key={index}>
                  <span key={index}>{word}</span>{' '}
                </Fragment>
              ))}
            </span>
          )}
        </Flex>
        <ModalButton
          buttonLabel={'Open Windy'}
          buttonProps={{
            customIcon: (
              <img
                width="20px"
                height="20px"
                src={WindyLogo}
                alt={'windy'}
              />
            ),
            variant: 'primary',
            outline: 'outline'
          }}
          contentHeight="96vh"
          contentWidth="95vw"
        >
          {() => {
            return (
              <iframe
                title="Windy App"
                height={'100%'}
                width={'100%'}
                src={`https://embed.windy.com/embed2.html?lat=${latitude}&lon=${longitude}&detailLat=${latitude}&detailLon=-${longitude}&width=650&height=450&zoom=20&level=surface&overlay=wind&product=ecmwf&menu=&message=&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=default&metricTemp=default&radarRange=-1`}
              />
            )
          }}
        </ModalButton>
      </Flex>
      <Flex sx={{ gap: '10px', width: '100%', flexWrap: 'wrap' }}>
        <OverviewBullet
          title="Water Temperature"
          value={waterTemperature?.formatted || 'No Data'}
          description={
            waterTemperature?.description
              ? waterTemperature.description
              : 'No Data'
          }
        />
        <OverviewBullet
          title="Wave Height"
          value={waveHeight?.formatted || 'No Data'}
          description={waveHeight?.description}
        />
        <OverviewBullet
          title="Wave Interval"
          value={wavePeriod?.formatted || 'No Data'}
        />
        <OverviewBullet title="Tide Height (MLLW)">
          {tides
            ? tides.map((t, key) => <Box key={key}>{t}</Box>)
            : 'No Data'}
        </OverviewBullet>
        <OverviewBullet
          title="Wind"
          value={windSpeed?.formatted || 'No Data'}
          description={windSpeed?.description}
        />
        <OverviewBullet
          title="UV Index"
          value={uvData?.formatted || 'No Data'}
          description={uvData?.description}
        />
      </Flex>
    </Flex>
  )
}

export default WeatherWidget

export function OverviewBullet(props) {
  const { title, value, description } = props
  const { mobile } = useWindowSize()

  return (
    <Box
      sx={{
        minHeight: '200px',
        padding: '10px',
        border: ['none', '1px solid #EAEDFF', null],
        borderRadius: '8px',
        textAlign: ['left', 'center', null],
        display: ['flex', 'block'],
        flex: 1,
        minWidth: ['100%', '200px', null]
      }}
    >
      <Box>
        <Box
          sx={{
            minHeight: [null, `${22.5 * 2}px`],
            display: [null, null, null, 'flex'],
            justifyContent: [null, null, null, 'center'],
            alignItems: [null, null, null, 'center'],
            margin: mobile ? '5px 0 7px' : '10px 0'
          }}
        >
          <h6>{title}</h6>
        </Box>
        {value && (
          <>
            <Box
              sx={{
                display: ['block', 'flex'],
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <h5
                sx={{
                  fontSize: mobile ? '14px' : '24px',
                  margin: ['0', '5px 5px 5px 15px']
                }}
              >
                {value}
              </h5>
            </Box>

            <Text
              sx={{
                fontSize: ['12px'],
                margin: ['0', '10px']
              }}
            >
              {description}
            </Text>
          </>
        )}
        {props.children}
      </Box>
    </Box>
  )
}
