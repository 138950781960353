import styled from '@emotion/styled'
import { Box } from 'theme-ui'

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: ${p => p.margin || '5px 0px'};
  background-color: ${p => p.theme.colors.gray200};
`

export const Overline = styled.p`
  text-transform: uppercase;
  color: ${p => p.theme.colors.gray600};
  font-size: 11px;
  margin-bottom: 5px;
`

export const Empty = ({ children, ...props }) => {
  return (
    <Box
      sx={{
        textAlign: 'center',
        p: 2,
        py: 5,
        bg: 'background',
        border: `1px dashed `,
        borderColor: 'gray400',
        position: 'relative',
        textTransform: 'capitalize'
      }}
      {...props}
    >
      {children}
    </Box>
  )
}
