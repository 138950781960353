import { Box } from 'theme-ui'

import Icon from 'src/app/components/Shared/Icon'

const FormFieldItemFormSummaryAnswerButton = ({
  fieldItem,
  handleChange
}) => {
  return (
    <Box
      style={{
        cursor: 'pointer',
        padding: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      color="naked"
      onClick={() =>
        handleChange({
          value: !fieldItem.summaryAnswer,
          name: 'summaryAnswer'
        })
      }
    >
      <Icon
        icon={'pin'}
        color={fieldItem.summaryAnswer ? 'success' : 'currentColor'}
        title="Pin Answer to Summary"
        height={15}
        width={15}
      />
    </Box>
  )
}

export default FormFieldItemFormSummaryAnswerButton
